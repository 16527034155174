import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
// eslint-disable-next-line no-unused-vars
import InputLabel from "@material-ui/core/InputLabel";
// eslint-disable-next-line no-unused-vars
import Select from "@material-ui/core/Select";
// eslint-disable-next-line no-unused-vars
import MenuItem from "@material-ui/core/MenuItem";
import { NOTIFICATION_SUCCCESS, THUMBNAIL_URL } from "../../Constants";
import { useStoreConsumer } from "../../Providers/StateProvider";
import { updateVideo } from "../../Services/UploadedVideo.service";
// eslint-disable-next-line no-unused-vars
import { postOrder } from "./../../Services/Razorpay.service";
import {
  saveCompetition,
  updateCompetition,
} from "../../Services/EnrollCompetition.service";
import {
  saveTestCompetition,
  updateTestCompetition,
} from "../../Services/TestingEnrollCompetition.service";
import { enableLoginFlow } from "../../Actions/LoginFlow";
import { setActiveCompetition } from "../../Actions/Competition";
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_INFO,
  ADMIN_EMAIL_STAGING,
} from "../../Constants";
import { displayNotification } from "../../Actions/Notification";
import { sendEmail } from "../../Services/Email.service";
import { formatDate, formatTime } from "../../Services/Utils";
import { isObjectEmpty } from "../../helpers";
import {
  serverTimeStamp,
  selectedUsersMailToUserForCompFirebaseRef,
  selectedUsersMailToAdminForCompFirebaseRef,
} from "../../Services/Other.service";
import { ImInfo } from "react-icons/im";
import Loader from "../Loader";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getUserByPhone } from "../../Services/User.service";
import { validateEmailId, validatePhoneNumber } from "../../helpers";

function EnrollCompetition({ isTesting, handleClose, changeSelectedVdo }) {
  const history = useHistory();
  const { state, dispatch } = useStoreConsumer();
  const loggedInUser = state.loggedInUser;
  const competitionDetails = state.activeCompetition;
  const SelectedVdo = competitionDetails.selectedVideo;
  // eslint-disable-next-line no-unused-vars
  const [IsUserSubscribed, setIsUserSubscribed] = useState(null);
  const [isLoaderActive, toggleLoading] = useState(false);
  const [buttonLoadingClass, toggleButtonLoading] = useState("");
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState(false);
  const [serverDateTime, setServerDateTime] = useState();
  const [error, setError] = useState({ id: null, text: "" });
  //comp category flow
  const [competitionCategory, setCompetitionCategory] = useState({
    fee: 0,
    name: "",
    type: "",
    members: [],
    merchandise: false,
  });
  const [catgeoryType, setCatgeoryType] = useState(""); //single or crew
  const [compTitle, setCompTitle] = useState(
    competitionDetails.name.toLowerCase()
  );

  useEffect(() => {
    setCompTitle(competitionDetails.name.toLowerCase());
    if (loggedInUser.subscribed) {
      setIsUserSubscribed(true);
    } else {
      setIsUserSubscribed(false);
    }
    const servTime = serverTimeStamp();
    setServerDateTime(servTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      competitionDetails &&
      competitionDetails.userSubmitedDetails &&
      competitionDetails.userSubmitedDetails.category
    ) {
      // let selectedCat = competitionDetails.categories.filter((c) => c.name == competitionDetails.userSubmitedDetails.category.name)
      setCompetitionCategory(competitionDetails.userSubmitedDetails.category);
    }
  }, [competitionDetails]);

  function onAgeGroupChange(event) {
    let compData = { ...competitionDetails };
    compData.ageGroup = event.target.value;
    dispatch(setActiveCompetition(compData));
  }

  const sendEmailToAdminForPasses = () => {
    let emailBody = `<div>
            <h6 style="font-size: 17px;margin-bottom: 26px;">New Video Uploaded for competition ${
              competitionDetails.name
            }</h6>
            <p>Hi ${
              loggedInUser.name
            } has just booked passes/tickets for the event.
            
            <h4>Details -</h4>
            
            <h6>Name: ${loggedInUser.name}</h6>
            <h6>Email: ${loggedInUser.email}</h6>
            <h6>Phone: ${loggedInUser.phone}</h6>
            <br/>
            <div>
              ${
                competitionCategory.name
                  ? `<h5>Below are Category Details: </h5>`
                  : ""
              }
                    ${
                      competitionCategory.name
                        ? `<p>Participated in <strong>${competitionCategory.name}</strong> category.</p>`
                        : ""
                    }
                    <br/>
                    ${
                      competitionCategory.crewLeader
                        ? `<p>Crew leader: <strong>${competitionCategory.crewLeader}</strong>.</p>`
                        : ""
                    }
            </div>
            <br/>
            ${
              competitionCategory?.members?.length
                ? `
                  No. of Passes/Tickets booked: ${
                    competitionCategory.members &&
                    competitionCategory.members.length
                      ? competitionCategory.members.length
                      : ""
                  }
              `
                : ""
            }    
      </div>`;
    let payload = {
      mailTo: ADMIN_EMAIL_STAGING,
      title: "Passes/Tickets Booking Details",
      content: emailBody,
    };
    sendEmail(payload).subscribe((res) => {
      try {
        selectedUsersMailToAdminForCompFirebaseRef
          .add(payload)
          .then((doc) => {});
      } catch (e) {
        console.log("error in sending mail to admin: ", e);
      }
      if (!("error" in res)) {
        console.log("Admin Email Send Successfully.");
      } else console.log("Admin Email Send Failed.");
    });
  };

  const sendEmailToUserForPasses = () => {
    let emailBody = `<div>
        <h2>Passes/Tickets</h2>
        <br/>

        <h3 style="color: #4bb543;">Congratulations!!</h3>
        <p>We are pleased to inform you that your booking is confirmed</p>
        <p>We have successfully received your payment. We will be sending you booking details within 24 hours.</p>
        <br/>
        <div>
        ${
          competitionCategory.name
            ? `<h5>Below are Category Details: </h5>`
            : ""
        }
              ${
                competitionCategory.name
                  ? `<p>Participated in <strong>${competitionCategory.name}</strong> category.</p>`
                  : ""
              }
              <br/>
              ${
                competitionCategory.crewLeader
                  ? `<p>Crew leader: <strong>${competitionCategory.crewLeader}</strong>.</p>`
                  : ""
              }
              <br/>
              ${
                competitionCategory.members &&
                competitionCategory.members.length
                  ? `<div>
                    Participant members are: 
                    ${competitionCategory.members
                      .map(
                        (item, index) => `
                        <p>${index + 1}: ${item.name}</p>`
                      )
                      .join("")} 
                </div>`
                  : ""
              }
            </div>
            <br/>
        <p>If you have any queries,you can contact our support team</p>
        <br/>
        <strong>Email: <a href="mailto:info@appda.in">info@appda.in</a></strong> / <strong><a href="mailto:hiphopinternationalhelp@gmail.com">hiphopinternationalhelp@gmail.com</a></strong> 
        <strong>Phone: <a href="tel:+919884588775">+919884588775</a></strong> / <strong><a href="tel:+918828419417">+918828419417</a></strong> 
    </div>`;
    let payload = {
      mailTo: loggedInUser.email,
      title: "Booking Confirmation",
      content: emailBody,
    };
    sendEmail(payload).subscribe((res) => {
      try {
        selectedUsersMailToUserForCompFirebaseRef
          .add(payload)
          .then((doc) => {});
      } catch (e) {
        console.log("error in sending email: ", e);
      }
      if (!("error" in res)) {
        console.log("User Email Send Successfully.");
      } else console.log("User Email Send Failed.");
    });
  };

  const sendEmailToAdmin = () => {
    let emailBody = `<div>
            <h6 style="font-size: 17px;margin-bottom: 26px;">New Video Uploaded for competition ${
              competitionDetails.name
            }</h6>
            <h4>User details -</h4>
            <h6>${loggedInUser.name}</h6>
            <h6>${loggedInUser.email}</h6>
            <h6>${loggedInUser.phone}</h6>
            <div>
              ${
                competitionCategory.name
                  ? `<p>Participated in <strong>${competitionCategory.name}</strong> category.</p>`
                  : ""
              }
              <br/>
              ${
                competitionCategory.crewLeader
                  ? `<p>Crew leader: <strong>${competitionCategory.crewLeader}</strong>.</p>`
                  : ""
              }
              <br/>
              ${
                competitionCategory.members &&
                competitionCategory.members.length
                  ? `<div>
                    Participant members are: 
                    ${competitionCategory.members
                      .map(
                        (item, index) => `
                        <p>${index + 1}: ${item.name}</p>`
                      )
                      .join("")} 
                </div>`
                  : ""
              }
            </div>
            ${
              competitionDetails?.selectedVideo?.url
                ? `<a href=${competitionDetails.selectedVideo.url} >Clik here to check uploaded video</a>`
                : ""
            }
            </div>`;
    let payload = {
      mailTo: ADMIN_EMAIL_STAGING,
      title: "Participant enrolled for Competition",
      content: emailBody,
    };
    sendEmail(payload).subscribe((res) => {
      try {
        selectedUsersMailToAdminForCompFirebaseRef
          .add(payload)
          .then((doc) => {});
      } catch (e) {
        console.log("error in sending mail to admin: ", e);
      }
      if (!("error" in res)) {
        console.log("Admin Email Send Successfully.");
      } else console.log("Admin Email Send Failed.");
    });
  };

  const sendEmailToUser = () => {
    let vdoUploadUpto = new Date(competitionDetails.startAt);
    let isVideoUploadFeature = competitionDetails.shouldVideoUpload !== "off";
    new Date(vdoUploadUpto.setDate(vdoUploadUpto.getDate() + 30));
    let displayDate =
      formatDate(vdoUploadUpto, 3) + " " + formatTime(vdoUploadUpto);

    let emailBody = `<div>
        ${
          isVideoUploadFeature
            ? `<p>Hi ${loggedInUser.name} you have enrolled in our ${competitionDetails.name}, now you can upload/change videos till ${displayDate}. </p>`
            : `<p>Hi ${loggedInUser.name}, your ${competitionDetails.name} registration is confirmed. Thank you for signing up.</p>`
        }

        <p>Registration details:</p>
        <br/>
        ${
          competitionCategory.name
            ? `<p>Category: <strong>${competitionCategory.name}</strong></p>`
            : `<p>Competition: <strong>${competitionDetails.name}</strong></p>`
        }
        <br/>
        ${
          competitionCategory.crewLeader
            ? `<p>Crew leader: <strong>${competitionCategory.crewLeader}</strong>.</p>`
            : ""
        }
        <br/>
        ${
          competitionCategory.members && competitionCategory.members.length
            ? `<div>
              <p>Members in crew: ${
                competitionCategory.members.length
              }<strong></strong></p></br>
              Names of all the crew members:
              ${competitionCategory.members
                .map(
                  (item, index) => `
                  <p>${index + 1}: ${item.name}</p>`
                )
                .join("")}
          </div>`
            : ""
        }
        ${
          isVideoUploadFeature && competitionDetails?.selectedVideo?.url
            ? `<h4> Upload your best performance video and be ready for exciting prizes</h4>`
            : ""
        }
        ${
          isVideoUploadFeature && competitionDetails?.selectedVideo?.url
            ? `<a href=${competitionDetails.selectedVideo.url} >Clik here to check uploaded video</a>`
            : ""
        }
          <br/>
          <br/>
          <p>If you have any queries, you can contact to our support team at:</p>
          <br/>
          <p>Email: <a href="mailto:info@appda.in" title="mail for information">info@appda.in</a></p>
          <p>Phone: <a href="tel:+919833033700" title="call for information">+91-9833033700</a></p>
    </div>`;
    let payload = {
      mailTo: loggedInUser.email,
      title: "Successfully enrolled for Competition",
      content: emailBody,
    };
    sendEmail(payload).subscribe((res) => {
      try {
        selectedUsersMailToUserForCompFirebaseRef
          .add(payload)
          .then((doc) => {});
      } catch (e) {
        console.log("error in sending email: ", e);
      }
      if (!("error" in res)) {
        console.log("User Email Send Successfully.");
      } else console.log("User Email Send Failed.");
    });
  };

  const submitForCompetition = (action) => {
    const isVideoPresent = competitionDetails?.selectedVideo?.key;
    const competitionObj = {
      compId: competitionDetails.key,
      compName: competitionDetails.name,
      compImg: competitionDetails.img,
      userId: loggedInUser.key,
      compStartDate: competitionDetails.startAt,
      compEndDate: competitionDetails.endAt,
      vdo: isVideoPresent
        ? {
            key: competitionDetails.selectedVideo.key,
            title: competitionDetails.selectedVideo.title,
            thumbnail: competitionDetails.selectedVideo.thumbnail,
            url: competitionDetails.selectedVideo.url,
            desc: competitionDetails.selectedVideo.desc,
            userId: loggedInUser.key,
            enrolledCompetition:
              competitionDetails.selectedVideo?.enrolledCompetition,
          }
        : null,
      ageGroup:
        competitionDetails?.ageGroup ||
        competitionDetails?.userSubmitedDetails?.ageGroup ||
        "",
      status: "Submitted",
      category: competitionCategory,
    };
    if (competitionDetails.isUserEnrolled) {
      if (action && action === "paymentDone") {
        toggleLoading(true);
        if (compTitle.includes("passes") || compTitle.includes("tickets")) {
          sendEmailToAdminForPasses();
          sendEmailToUserForPasses();
        } else {
          sendEmailToAdmin();
          sendEmailToUser();
        }
      }
      const previousObj = Object.assign(
        {},
        competitionDetails?.userSubmitedDetails?.vdo || competitionObj?.vdo
      );

      previousObj.enrolledCompetition = null;
      previousObj.userId = competitionDetails.userSubmitedDetails.userId;

      if (competitionObj?.vdo?.key) {
        toggleLoading(true);
        try {
          updateVideo(previousObj.key, previousObj).subscribe((resp) => {
            try {
              if (competitionDetails.isTesting || isTesting) {
                updateTestCompetition(
                  competitionDetails.userSubmitedDetails.key,
                  competitionObj
                ).subscribe((response) => {
                  updateVideo(
                    competitionObj.vdo.key,
                    competitionObj.vdo
                  ).subscribe((resp) => {
                    toggleLoading(false);
                    history.push("/profile");
                    dispatch(
                      displayNotification({
                        msg: `Video updated successfully`,
                        type: NOTIFICATION_SUCCCESS,
                        time: 6000,
                      })
                    );
                    handleClose();
                  });
                });
              } else {
                updateCompetition(
                  competitionDetails.userSubmitedDetails.key,
                  competitionObj
                ).subscribe((response) => {
                  updateVideo(
                    competitionObj.vdo.key,
                    competitionObj.vdo
                  ).subscribe((resp) => {
                    toggleLoading(false);
                    history.push("/profile");
                    dispatch(
                      displayNotification({
                        msg: `Video updated successfully`,
                        type: NOTIFICATION_SUCCCESS,
                        time: 6000,
                      })
                    );
                    handleClose();
                  });
                });
              }
            } catch (e) {
              toggleLoading(false);
              console.log("Error updating competition: ", e);
            }
          });
        } catch (e) {
          toggleLoading(false);
          console.log("update previous video error: ", e);
        }
      }
    } else {
      enrollInCompWithVideo(competitionObj);
    }
  };

  function enrollInCompWithVideo(competitionObj) {
    if (competitionObj?.vdo?.key) {
      try {
        updateVideo(competitionObj.vdo.key, competitionObj.vdo).subscribe(
          (resp) => {
            try {
              if (isTesting) {
                saveTestCompetition(competitionObj).subscribe((response) => {
                  toggleLoading(false);
                  sendEmailToAdmin();
                  sendEmailToUser();
                  dispatch(
                    displayNotification({
                      msg: `Video updated successfully`,
                      type: NOTIFICATION_SUCCCESS,
                      time: 6000,
                    })
                  );
                  handleClose();
                });
              } else {
                saveCompetition(competitionObj).subscribe((response) => {
                  toggleLoading(false);
                  sendEmailToAdmin();
                  sendEmailToUser();
                  dispatch(
                    displayNotification({
                      msg: `Video updated successfully`,
                      type: NOTIFICATION_SUCCCESS,
                      time: 6000,
                    })
                  );
                  handleClose();
                });
              }
            } catch (e) {
              toggleLoading(false);
              console.log("Error saving competition: ", e);
            }
          }
        );
      } catch (e) {
        toggleLoading(false);
        console.log("Error updating video before saving competition: ", e);
      }
    } else {
      try {
        if (isTesting) {
          saveTestCompetition(competitionObj).subscribe((response) => {
            toggleLoading(false);
            sendEmailToAdmin();
            sendEmailToUser();
            dispatch(
              displayNotification({
                msg: `Enrolled in competition successfully`,
                type: NOTIFICATION_SUCCCESS,
                time: 6000,
              })
            );
          });
        } else {
          saveCompetition(competitionObj).subscribe((response) => {
            toggleLoading(false);
            sendEmailToAdmin();
            sendEmailToUser();
            dispatch(
              displayNotification({
                msg: `Enrolled in competition successfully`,
                type: NOTIFICATION_SUCCCESS,
                time: 6000,
              })
            );
          });
        }
      } catch (e) {
        toggleLoading(false);
        console.log("Error saving competition: ", e);
      }
    }
  }

  function isEndDateExpired(endDate) {
    const myDate = new Date(endDate);
    myDate.setDate(myDate.getDate() + parseInt(1));
    const endDateDay = new Date(myDate).getDate();
    const endDateMonth = new Date(myDate).getMonth() + 1;
    const endDateYear = new Date(myDate).getFullYear();

    const todayISODay = new Date(serverDateTime).getDate();
    const todayISOMonth = new Date(serverDateTime).getMonth() + 1;
    const todayISOYear = new Date(serverDateTime).getFullYear();

    return (
      new Date(`${todayISOYear}-${todayISOMonth}-${todayISODay}`) >=
      new Date(`${endDateYear}-${endDateMonth}-${endDateDay}`)
    );
  }

  const proceedForPayment = (calculatedFee) => {
    if (!isObjectEmpty(loggedInUser)) {
      if (competitionDetails && isEndDateExpired(competitionDetails.endAt)) {
        dispatch(
          displayNotification({
            msg: `${competitionDetails.name} registration has been closed!`,
            type: NOTIFICATION_INFO,
            time: 6000,
          })
        );
      } else {
        if (!competitionCategory.fee || competitionCategory.fee === "0")
          submitForCompetition();
        else {
          toggleButtonLoading("loading");
          const userData = {
            amount: (calculatedFee || competitionCategory.fee) * 100,
            currency: "INR",
            receipt: loggedInUser.key,
          };
          if (
            competitionDetails.categories &&
            competitionDetails.categories.length !== 0
          ) {
            userData.amount =
              totalAmountAfterDiscount(
                competitionCategory,
                competitionDetails
              ) * 100;
          }
          let orderObj = {};
          const compType = competitionDetails.name
            .toLowerCase()
            .split(" ")
            .join("-");
          orderObj[compType] = userData;
          try {
            postOrder(
              orderObj,
              [compType],
              "competition enrollment",
              loggedInUser,
              afterPaymentResponse
            ).subscribe((response) => {
              toggleButtonLoading("");
            });
          } catch (e) {
            console.log("Error: ", e);
          }
        }
      }
    } else {
      dispatch(
        enableLoginFlow({ type: "competition", data: competitionDetails })
      );
      history.push({
        pathname: "/login",
        state: null,
      });
    }
  };

  const afterPaymentResponse = (response) => {
    setOpenPaymentSuccessModal(true);
    submitForCompetition("paymentDone");
  };

  const validateCategory = () => {
    return new Promise((res, rej) => {
      if (
        competitionDetails.categories &&
        competitionDetails.categories.length !== 0
      ) {
        let err = { id: null, text: "" };
        if (!competitionCategory.name) {
          err = { id: "name", text: "Please select category" };
        } else if (
          !(compTitle.includes("passes") || compTitle.includes("tickets")) &&
          catgeoryType === "crew" &&
          !competitionCategory.crewName
        ) {
          err = { id: "crewName", text: "Please enter crew name" };
        } else if (
          !(compTitle.includes("passes") || compTitle.includes("tickets")) &&
          catgeoryType === "crew" &&
          !competitionCategory.crewLocation
        ) {
          err = { id: "crewName", text: "Please enter crew state/city" };
        } else if (
          !(compTitle.includes("passes") || compTitle.includes("tickets")) &&
          catgeoryType === "crew" &&
          !competitionCategory.crewLeader
        ) {
          err = {
            id: "crewName",
            text: "Please enter crew choreographer/leader",
          };
        } else if (
          catgeoryType === "crew" &&
          competitionCategory.members.length < competitionCategory.minMember
        ) {
          err = {
            id: "crewName",
            text: `Please add minimum ${competitionCategory.minMember} member`,
          };
        }
        if (err.id) {
          setError(err);
          rej();
        } else {
          let isAnymemberInvalidate = competitionCategory.members.filter(
            (m, i) => !validateMember(m, i)
          );
          let isAnymemberNotRegister = competitionCategory.members.filter(
            (m) => !m.isRegistered
          );

          if (
            isAnymemberNotRegister.length === 0 &&
            isAnymemberInvalidate.length === 0
          )
            res();
          else {
            if (
              isAnymemberInvalidate.length === 0 &&
              isAnymemberNotRegister.length !== 0
            ) {
              err = {
                id: `member-not-registered`,
                text: `Please register ${isAnymemberNotRegister[0].name} with Da`,
              };
              setError(err);
            }
            rej();
          }
        }
      } else res();
    });
  };

  const validateMember = (data, index) => {
    let err = { id: null, text: "" };
    if (!data.name) {
      err = {
        id: `member-${index}-name`,
        text: `Please enter Member ${index + 1} name`,
      };
    } else if (!data.phone) {
      err = {
        id: `member-${index}-phone`,
        text: `Please enter Member ${index + 1} phone`,
      };
    } else if (data.phone && !validatePhoneNumber(data.phone)) {
      err = {
        id: `member-${index}-phone`,
        text: `Please enter valid phone for member ${index + 1}`,
      };
    } else if (!data.email) {
      err = {
        id: `member-${index}-email`,
        text: `Please enter Member ${index + 1} email`,
      };
    } else if (data.email && !validateEmailId(data.email)) {
      err = {
        id: `member-${index}-email`,
        text: `Please enter valid email id for member ${index + 1}`,
      };
    }
    if (err.id) {
      console.log("validateMember ", err);
      setError(err);
      return false;
    } else return true;
  };

  const proceedForRegistration = (fee) => {
    if (fee && fee.toString() !== "0") {
      if (
        competitionDetails.categories &&
        competitionDetails.categories.length !== 0
      ) {
        validateCategory()
          .then(() => {
            proceedForPayment();
          })
          .catch(() => {
            dispatch(
              displayNotification({
                msg: "Fill all the required fields!",
                type: NOTIFICATION_ERROR,
                time: 2000,
              })
            );
            console.log("validateCategory failed");
          });
      } else {
        if (
          (competitionDetails?.ageGroup &&
            competitionDetails?.ageGroup !== "null") ||
          (competitionDetails?.userSubmitedDetails?.ageGroup &&
            competitionDetails?.userSubmitedDetails?.ageGroup !== "null")
        ) {
          proceedForPayment(fee);
        } else {
          dispatch(
            displayNotification({
              msg: "Fill all the required fields!",
              type: NOTIFICATION_ERROR,
              time: 2000,
            })
          );
        }
      }
    } else {
      dispatch(
        displayNotification({
          msg: "New competition with registration will launch soon!",
          type: NOTIFICATION_INFO,
          time: 6000,
        })
      );
    }
  };

  // TODO: Need to enable once subscription active
  // eslint-disable-next-line no-unused-vars
  const proceedForSubscription = () => {
    if (
      (competitionDetails?.ageGroup &&
        competitionDetails?.ageGroup !== "null") ||
      (competitionDetails?.userSubmitedDetails?.ageGroup &&
        competitionDetails?.userSubmitedDetails?.ageGroup !== "null")
    ) {
      handleClose();
      dispatch(enableLoginFlow({ type: "competition-subscription" }));
      history.push({
        pathname: "/subscription",
        state: null,
      });
    } else {
      dispatch(
        displayNotification({
          msg: "Please select the age group!",
          type: NOTIFICATION_ERROR,
          time: 3000,
        })
      );
    }
  };

  const onCategoryChange = (category) => {
    let compCatData = { ...competitionCategory };
    if (category && category !== "default") {
      category = JSON.parse(category);
      if (
        competitionDetails &&
        competitionDetails.userSubmitedDetails &&
        competitionDetails.userSubmitedDetails.category &&
        competitionDetails.userSubmitedDetails.category.name === category.name
      ) {
        setCompetitionCategory(competitionDetails.userSubmitedDetails.category);
      } else {
        compCatData = { ...compCatData, ...category };
        compCatData.fee = Number(category.fee || 0);
        if (catgeoryType === "crew") {
          compCatData.crewName = "";
          compCatData.crewLocation = "";
          compCatData.crewLeader = "";
        }
        compCatData.members = [{ name: "", phone: "", email: "", age: "" }];
        setCompetitionCategory(compCatData);
      }
    } else {
      setCatgeoryType(null);
      setCompetitionCategory({
        name: "",
        type: "",
        members: [],
        merchandise: false,
      });
    }

    setCatgeoryType(category.maxMember > 1 ? "crew" : "single");
    setError({ id: null, text: "" });
  };

  const onClickSelfEnroll = (value) => {
    let compCatData = { ...competitionCategory };
    const selfAsMemberObj = {
      name: state.loggedInUser.name,
      phone: state.loggedInUser.phone,
      email: state.loggedInUser.email,
      age: "",
      isRegistered: true,
    };
    if (value) {
      if (catgeoryType === "crew") {
        //for crew
        compCatData.members.unshift(selfAsMemberObj);
        if (compCatData.members.length > compCatData.maxMember) {
          compCatData.members.splice(compCatData.members.length - 1, 1);
        }
      } else {
        //for single as member
        compCatData.members = [selfAsMemberObj];
      }
    } else {
      if (compCatData.members.length > 1) {
        //for crew
        compCatData.members.shift();
      } else {
        //for single
        compCatData.members = [{ name: "", phone: "", email: "", age: "" }];
      }
    }
    setError({ id: null, text: "" });
    setCompetitionCategory({ ...compCatData });
  };

  const addMerchandise = (props, value, from) => {
    if (from && from === "noCategory") {
      let compData = { ...competitionDetails };
      compData.buyMerchandise = value;
      dispatch(setActiveCompetition(compData));
    } else {
      setCompetitionCategory({ ...competitionCategory, [props]: value });
    }
  };

  const handleCrewDataChange = (from, value) => {
    setError({ id: null, text: "" });
    let compCatData = { ...competitionCategory };
    compCatData[from] = value;
    setCompetitionCategory({ ...compCatData });
  };

  const handleMemberDataChange = (from, value, index) => {
    if (from === "age" && value.length > 2) return;
    setError({ id: null, text: "" });
    let compCatData = { ...competitionCategory };
    compCatData.members[index][from] = value;
    if (from === "phone") {
      if (value.length) {
        getUserByPhone(value).subscribe((usersByPhone) => {
          if (usersByPhone.length !== 0) {
            const userCrewData = {
              name: usersByPhone[0].name,
              email: usersByPhone[0].email,
              phone: usersByPhone[0].phone,
            };
            compCatData.members[index] = {
              ...userCrewData,
              isRegistered: true,
            };
          }
          setCompetitionCategory({ ...compCatData });
        });
      } else {
        setCompetitionCategory({ ...compCatData });
        compCatData.members[index].isRegistered = false;
      }
    } else {
      setCompetitionCategory({ ...compCatData });
    }
  };

  const validateMemberPhone = (value, index) => {
    let err = { id: null, text: "" };
    if (!validatePhoneNumber(value)) {
      err = {
        id: `member-${index}-phone`,
        text: `Please enter valid phone number for member ${index + 1}`,
      };
      if (err.id) {
        setError(err);
        return false;
      }
    } else {
      setError(err);
      return true;
    }
  };

  const validateMemberEmail = (value, index) => {
    let err = { id: null, text: "" };
    if (!validateEmailId(value)) {
      err = {
        id: `member-${index}-email`,
        text: `Please enter valid Email Id for member ${index + 1}`,
      };
      if (err.id) {
        setError(err);
        return false;
      }
    } else {
      setError(err);
      return true;
    }
  };

  const addMember = () => {
    let compCatData = { ...competitionCategory };
    if (
      validateMember(
        compCatData.members[compCatData.members.length - 1],
        compCatData.members.length
      )
    ) {
      compCatData.members.push({ name: "", phone: "", email: "", age: "" });
      setCompetitionCategory({ ...compCatData });
    }
  };

  const removeMember = (memberIndex) => {
    let compCatData = { ...competitionCategory };
    compCatData.members.splice(memberIndex, 1);
    setCompetitionCategory({ ...compCatData });
  };

  const isUniqueMemberDiscountApplied = (competitionCategory) => {
    let isUnique = false;
    if (
      competitionCategory.uniqueMemberPercent &&
      competitionCategory.members.reduce((a, b) => a + (b.name ? 1 : 0), 0) > 0
    ) {
      const uniqueMemberCount = Math.ceil(
        (competitionCategory.members.length *
          competitionCategory.uniqueMemberPercent) /
          100
      );
      const duplicateEmail = competitionCategory.members
        .map((v) => v.email)
        .filter((v, i, vIds) => vIds.indexOf(v) !== i);
      const totalDuplicateList = competitionCategory.members.filter((obj) =>
        duplicateEmail.includes(obj.email)
      );
      if (totalDuplicateList.length <= uniqueMemberCount) {
        isUnique = true;
      }
    }
    return isUnique;
  };

  const calculateDiscountedAmount = (compData, from) => {
    let discountedAmount = 0;
    if (from && from === "noCategory") {
      const totalAmount = compData.buyMerchandise
        ? Number(compData.fee) + Number(compData.merchandiseAmount)
        : Number(compData.fee);
      discountedAmount = Math.round(
        (totalAmount * Number(compData.offerPercent || 0)) / 100
      );
    } else {
      const totalAmount = totalAmountToBePaid(compData);
      discountedAmount = Math.round(
        (totalAmount * Number(compData.offerPercent || 0)) / 100
      );
    }

    return discountedAmount;
  };

  const totalAmountToBePaid = (competitionDetails) => {
    let totalPayable =
      competitionCategory.fee *
      competitionCategory.members.reduce((a, b) => a + (b.name ? 1 : 0), 0);
    let totalMerchandise = 0;
    if (competitionCategory.merchandise) {
      totalMerchandise =
        competitionDetails.merchandiseAmount *
        competitionCategory.members.reduce((a, b) => a + (b.name ? 1 : 0), 0);
    }
    if (totalMerchandise) {
      totalPayable += totalMerchandise;
    }
    return totalPayable;
  };

  const totalAmountAfterDiscount = (category, compData, from) => {
    let discountedAmount = 0;
    if (from && from === "noCategory") {
      const totalAmount = compData.buyMerchandise
        ? Number(compData.fee) + Number(compData.merchandiseAmount)
        : Number(compData.fee);
      const discount = compData.offerPercent
        ? Math.round((totalAmount * compData.offerPercent) / 100)
        : 0;
      discountedAmount = totalAmount - discount;
    } else {
      discountedAmount = totalAmountToBePaid(compData);
      if (category?.maxMember >= 1 && isUniqueMemberDiscountApplied(category)) {
        discountedAmount -= calculateDiscountedAmount(compData);
      }
    }
    return discountedAmount;
  };

  const setDefaultSelectionOption = (selection) => {
    const categoryName = selection?.userSubmitedDetails?.category?.name;
    let isSelection = "";
    if (categoryName) {
      competitionDetails.categories.forEach((item) => {
        if (
          item.name === competitionDetails.userSubmitedDetails.category.name
        ) {
          isSelection = JSON.stringify(item);
        }
      });
    }
    return isSelection;
  };

  return (
    <div className="final-enrollment-wrap">
      <Loader value={isLoaderActive} />
      {/* <img src={competitionDetails.img} alt={competitionDetails.name} style={{ width: '20%' }} /> */}
      {/* <p id="description">{competitionDetails.desc}</p> */}
      <h2 id="title">Form for Enrollment</h2>
      <h3 className="notificationHeader">
        <i>
          <ImInfo />
        </i>
        Please do not refresh the page while the payment is being processed
      </h3>
      <div className="detailsWrapOuter">
        {competitionDetails.categories &&
        competitionDetails.categories.length !== 0 ? (
          <div className="basicDetailsWrapper categoryMemberInfo">
            <div className="category-selection-dropdown age-group-dropdown">
              <FormControl variant="outlined" className="input-field">
                <select
                  defaultValue={setDefaultSelectionOption(competitionDetails)}
                  id="select-outlined-ageGroup"
                  onChange={(e) => onCategoryChange(e.target.value)}
                >
                  <option value="default">Select category</option>
                  {competitionDetails.categories.map((category, i) => {
                    return (
                      <option key={i} value={JSON.stringify(category)}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </FormControl>
            </div>

            {competitionCategory.name && (
              <div className="crew-member-wrap">
                {!(
                  compTitle.includes("passes") || compTitle.includes("tickets")
                ) &&
                  (competitionCategory.minMember > 1 ||
                    competitionCategory.maxMember > 1) && (
                    <div className="crew-details-wrap">
                      <div className="inner-form-wrap">
                        <div className="input-wrap">
                          <TextField
                            className="input-field"
                            required
                            id="outlined-required-name"
                            label="Crew Name"
                            onChange={(e) =>
                              handleCrewDataChange("crewName", e.target.value)
                            }
                            value={competitionCategory.crewName || ""}
                            variant="outlined"
                          />
                        </div>
                        <div className="input-wrap">
                          <TextField
                            className="input-field"
                            required
                            id="outlined-required-name"
                            label="State/City"
                            onChange={(e) =>
                              handleCrewDataChange(
                                "crewLocation",
                                e.target.value
                              )
                            }
                            value={competitionCategory.crewLocation || ""}
                            variant="outlined"
                          />
                        </div>
                        <div className="input-wrap leaderInput">
                          <TextField
                            className="input-field"
                            required
                            id="outlined-required-name"
                            label="Choreographer/Leader"
                            onChange={(e) =>
                              handleCrewDataChange("crewLeader", e.target.value)
                            }
                            value={competitionCategory.crewLeader || ""}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                <div className="crew-member-note">
                  {competitionCategory?.fee ? (
                    <p className="memberFee">
                      <ImInfo /> <span>&#8377;{competitionCategory.fee}</span>{" "}
                      will be charged per member
                    </p>
                  ) : (
                    ""
                  )}
                  {!(
                    compTitle.includes("passes") ||
                    compTitle.includes("tickets")
                  ) ? (
                    <>
                      {competitionCategory.minMember ===
                      competitionCategory.maxMember ? (
                        <p>
                          <ImInfo /> Only {competitionCategory.maxMember}{" "}
                          {competitionCategory.maxMember > 1
                            ? "members"
                            : "member"}{" "}
                          can be added . All the members must be registered on
                          Da.
                        </p>
                      ) : (
                        <p>
                          <ImInfo /> You can add minimum{" "}
                          {competitionCategory.minMember} &amp; maximum{" "}
                          {competitionCategory.maxMember}. All the members must
                          be registered on Da.
                        </p>
                      )}
                      <p className="ageInfo">
                        <ImInfo /> Information entered should match with ID
                        proof. Incorrect information will lead to
                        disqualification.
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {!(
                  compTitle.includes("passes") || compTitle.includes("tickets")
                ) ? (
                  <div className="enroll-self-wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          color="primary"
                          className="selected-item-checkbox"
                          checked={
                            competitionCategory.members.length !== 0
                              ? competitionCategory.members[0].phone ===
                                state.loggedInUser.phone
                              : false
                          }
                          onChange={(e) => onClickSelfEnroll(e.target.checked)}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      }
                      label="INCLUDE ME AS A PARTICIPANT"
                    />
                    {competitionDetails.merchandise ||
                    competitionDetails.merchandiseAmount ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            color="primary"
                            className="selected-item-checkbox"
                            checked={competitionCategory.merchandise}
                            onChange={(e) =>
                              addMerchandise("merchandise", e.target.checked)
                            }
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        }
                        label={`INCLUDE MERCHANDISE ${
                          competitionCategory.merchandise
                            ? "(Rs." +
                              competitionDetails.merchandiseAmount +
                              " will be added for each member!)"
                            : ""
                        }`}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`members-list ${SelectedVdo ? "with-video" : ""}`}
                >
                  {competitionCategory.members.map((member, memberIndex) => {
                    return (
                      <div key={memberIndex} className="member-wrap">
                        <div className="inner-form-wrap">
                          <span className="memberLegendIndex">
                            Member {memberIndex + 1}
                          </span>
                          <div className="input-wrap">
                            <TextField
                              className={`input-field member-${memberIndex}-name'`}
                              required
                              type="text"
                              id="outlined-required-name"
                              label="Member Name"
                              onChange={(e) =>
                                handleMemberDataChange(
                                  "name",
                                  e.target.value,
                                  memberIndex
                                )
                              }
                              value={member.name}
                              variant="outlined"
                            />
                          </div>
                          <div className="input-wrap">
                            <TextField
                              className={`input-field member-${memberIndex}-phone`}
                              required
                              type="text"
                              id="outlined-required-name"
                              label="Member Phone"
                              onChange={(e) =>
                                handleMemberDataChange(
                                  "phone",
                                  e.target.value,
                                  memberIndex
                                )
                              }
                              onBlur={(e) =>
                                validateMemberPhone(e.target.value, memberIndex)
                              }
                              value={member.phone}
                              variant="outlined"
                            />
                          </div>
                          <div className="input-wrap">
                            <TextField
                              className={`input-field member-${memberIndex}-email`}
                              required
                              type="email"
                              id="outlined-required-name"
                              label="Member Email"
                              onChange={(e) =>
                                handleMemberDataChange(
                                  "email",
                                  e.target.value,
                                  memberIndex
                                )
                              }
                              onBlur={(e) =>
                                validateMemberEmail(e.target.value, memberIndex)
                              }
                              value={member.email}
                              variant="outlined"
                            />
                          </div>
                          <div className="input-wrap memberAgeInput">
                            <TextField
                              className="input-field"
                              type="number"
                              id="outlined-required-name"
                              label="Member Age"
                              onChange={(e) =>
                                handleMemberDataChange(
                                  "age",
                                  e.target.value,
                                  memberIndex
                                )
                              }
                              value={member.age}
                              variant="outlined"
                            />
                          </div>
                          {/* {
                        competitionCategory?.fee ? 
                        <p className="memberFee">X <span>&#8377;{competitionCategory.fee}</span></p>
                        : ''
                      } */}
                          {competitionCategory?.members?.length !== 1 && (
                            <Button
                              variant="contained"
                              color="secondary"
                              className="removeMemberBtn"
                              onClick={() => removeMember(memberIndex)}
                            >
                              <DeleteForeverIcon /> Remove
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {competitionCategory.maxMember !==
                    competitionCategory?.members?.length && (
                    <div className="add-member-btn">
                      <Button
                        variant="contained"
                        color="primary"
                        className="addNewMemberBtn"
                        onClick={addMember}
                      >
                        Add member +
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {error.id && <div className="error-note">{error.text}</div>}
          </div>
        ) : (
          <div className="basicDetailsWrapper categoryMemberInfo">
            <div className="userdata">
              <div className="user-info">
                <label>Name:</label>
                <span>{loggedInUser.name}</span>
              </div>
              <div className="user-info">
                <label>Phone:</label>
                <span>{loggedInUser.phone}</span>
              </div>
              <div className="user-info">
                <label>Email:</label>
                <span>{loggedInUser.email}</span>
              </div>
              <div className="user-info">
                <label>Gender:</label>
                <span>{loggedInUser.gender}</span>
              </div>
            </div>
            {!competitionDetails?.isUserEnrolled ? (
              <div className="age-group-dropdown">
                {competitionDetails.merchandise ||
                competitionDetails.merchandiseAmount ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        color="primary"
                        className="selected-item-checkbox"
                        checked={competitionDetails.buyMerchandise}
                        onChange={(e) =>
                          addMerchandise(
                            "merchandise",
                            e.target.checked,
                            "noCategory"
                          )
                        }
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label={`Want merchandise? ${
                      competitionDetails.buyMerchandise
                        ? "(Rs." +
                          competitionDetails.merchandiseAmount +
                          " will be added on total amount!)"
                        : ""
                    }`}
                  />
                ) : (
                  ""
                )}
                <FormControl variant="outlined" className="input-field">
                  {/* <InputLabel id="select-outlined-label">
                  Select Age Group
                </InputLabel>
                <Select
                  labelId="select-outlined-label"
                  id="select-outlined-ageGroup"
                  value={competitionDetails.ageGroup || 'Age 4 to 13 years'}
                  onChange={onAgeGroupChange}
                  label="Select Age Group"
                >
                  <MenuItem value="Age 4 to 13 years">
                    Age 4 to 13 years
                  </MenuItem>
                  <MenuItem value="Age 14 to 17 years">
                    Age 14 to 17 years
                  </MenuItem>
                  <MenuItem value="Age 18 and above">Age 18 and above</MenuItem>
                </Select> */}

                  <select
                    id="select-outlined-ageGroup"
                    onChange={onAgeGroupChange}
                  >
                    <option value="null">Select age group</option>
                    <option value="Age 4 to 13 years">4 to 13 years</option>
                    <option value="Age 14 to 17 years">14 to 17 years</option>
                    <option value="Age 18 and above">18 and above</option>
                  </select>
                </FormControl>
              </div>
            ) : (
              <div>
                Submitted age group -{" "}
                {competitionDetails.userSubmitedDetails.ageGroup}
              </div>
            )}
          </div>
        )}
        {SelectedVdo && (
          <div className="selected-vdo">
            {/* <div className="sub-title">Video you have selected</div> */}
            <div className="vdo-wrap">
              <div className="videoTitle">
                Video Title: <span>{SelectedVdo.title}</span>
              </div>
              <div className="imgWrap">
                <img
                  src={
                    SelectedVdo.thumbnail
                      ? SelectedVdo.thumbnail
                      : THUMBNAIL_URL
                  }
                  alt={SelectedVdo.title}
                />
              </div>
            </div>
            <div className="change-link" onClick={() => changeSelectedVdo()}>
              Change
            </div>
          </div>
        )}
      </div>
      {/* check for user subscribed or not */}
      {/* TODO: after we'll activate the subscription, need to revert the code */}
      <div className="continueButtonWrap">
        {/* <div>To upload video you need to subscribe</div> */}
        {!(
          competitionDetails.categories &&
          competitionDetails?.categories?.length !== 0
        ) ? (
          <div className="payBtnAmountBreakUp">
            {competitionDetails.offer && competitionDetails.offerPercent ? (
              <p className="infoForDiscount">
                <span className="applied">
                  {
                    <>
                      <i className="icon">
                        <ImInfo />
                      </i>{" "}
                      {competitionDetails.offerPercent}% discount has been
                      applied.
                    </>
                  }
                </span>
              </p>
            ) : (
              ""
            )}
            {competitionCategory.fee ? (
              <p className="totalAmountPaidLine">
                <span className="leftElement">Fees</span>
                <span className="rightElement">
                  <span>&#8377;</span>
                  {competitionCategory.fee} x 1
                </span>
              </p>
            ) : (
              ""
            )}
            {competitionDetails.buyMerchandise ? (
              <p className="totalAmountPaidLine">
                <span className="leftElement">Merchandise</span>
                <span className="rightElement">
                  <span>&#8377;</span>
                  {competitionDetails.merchandiseAmount} x 1
                </span>
              </p>
            ) : (
              ""
            )}
            {competitionDetails.offer && competitionDetails.offerPercent ? (
              <p className="totalAmountPaidLine discountSection">
                <span className="leftElement">
                  {competitionDetails.offerPercent
                    ? competitionDetails.offerPercent + "% "
                    : ""}{" "}
                  Discount
                </span>
                <span className="rightElement">
                  - <span>&#8377;</span>
                  {calculateDiscountedAmount(competitionDetails, "noCategory")}
                </span>
              </p>
            ) : (
              ""
            )}
            <p className="totalAmountPaidLine finalTotal">
              <span className="leftElement">Total: </span>
              <span className="rightElement">
                <span>&#8377;</span>
                {totalAmountAfterDiscount(
                  null,
                  competitionDetails,
                  "noCategory"
                )}
              </span>
            </p>
            <Button
              className={`${buttonLoadingClass} defaultPaymentBtn`}
              variant="contained"
              color="primary"
              onClick={() =>
                proceedForRegistration(
                  totalAmountAfterDiscount(
                    null,
                    competitionDetails,
                    "noCategory"
                  )
                )
              }
            >
              {competitionCategory.fee.toString() === "0" ? (
                "Launching Soon"
              ) : (
                <>
                  Pay &nbsp;<span>&#8377;</span>{" "}
                  {totalAmountAfterDiscount(
                    null,
                    competitionDetails,
                    "noCategory"
                  )}{" "}
                  and register
                </>
              )}
            </Button>
          </div>
        ) : competitionCategory.members.reduce(
            (a, b) => a + (b.name ? 1 : 0),
            0
          ) > 0 &&
          competitionCategory.type &&
          competitionCategory.type !== "default" ? (
          <div className="crewRegisterBtnWrap">
            {competitionDetails.offer && competitionDetails.offerPercent ? (
              <p className="infoForDiscount">
                {competitionCategory.uniqueMemberPercent === 0 ? (
                  <span className="notApplied">
                    {
                      <>
                        <i className="icon">
                          <ImInfo />
                        </i>{" "}
                        No offers for this category!
                      </>
                    }
                  </span>
                ) : isUniqueMemberDiscountApplied(competitionCategory) ? (
                  <span className="applied">
                    {
                      <>
                        <i className="icon">
                          <ImInfo />
                        </i>{" "}
                        Eligible to avail {competitionDetails.offerPercent}%
                        discount as the participants are registered members of
                        Da and are using separate email ID for registration.
                      </>
                    }
                  </span>
                ) : (
                  <span className="notApplied">
                    {
                      <>
                        <i className="icon">
                          <ImInfo />
                        </i>{" "}
                        Not applicable to avail{" "}
                        {competitionDetails.offerPercent}% discount as the
                        members are not using different email ID to register.
                      </>
                    }
                  </span>
                )}
              </p>
            ) : (
              ""
            )}
            <p className="totalAmountPaidLine">
              <span className="leftElement">
                Members
                {/* {competitionCategory.members.reduce((a, b) => a + (b.name ? 1 : 0), 0)}
                    {competitionCategory.members.reduce((a, b) => a + (b.name ? 1 : 0), 0) === 1 ? <> member</> : <> members</>} */}
              </span>
              <span className="rightElement">
                <span>&#8377;</span>
                {competitionCategory.fee} x{" "}
                {competitionCategory.members.reduce(
                  (a, b) => a + (b.name ? 1 : 0),
                  0
                )}
              </span>
            </p>
            {competitionCategory.merchandise ? (
              <p className="totalAmountPaidLine">
                <span className="leftElement">
                  Merchandise
                  {/* {competitionCategory.members.reduce((a, b) => a + (b.name ? 1 : 0), 0)}
                      {competitionCategory.members.reduce((a, b) => a + (b.name ? 1 : 0), 0) === 1 ? <> member</> : <> members</>} */}
                </span>
                <span className="rightElement">
                  <span>&#8377;</span>
                  {competitionDetails.merchandiseAmount} x{" "}
                  {competitionCategory.members.reduce(
                    (a, b) => a + (b.name ? 1 : 0),
                    0
                  )}
                </span>
              </p>
            ) : (
              ""
            )}
            {competitionDetails.offer &&
            competitionDetails.offerPercent &&
            isUniqueMemberDiscountApplied(competitionCategory) ? (
              <p className="totalAmountPaidLine discountSection">
                <span className="leftElement">
                  {competitionDetails.offerPercent
                    ? competitionDetails.offerPercent + "% "
                    : ""}{" "}
                  Discount
                  {/* {competitionCategory.members.reduce((a, b) => a + (b.name ? 1 : 0), 0)}
                      {competitionCategory.members.reduce((a, b) => a + (b.name ? 1 : 0), 0) === 1 ? <> member</> : <> members</>} */}
                </span>
                <span className="rightElement">
                  - <span>&#8377;</span>
                  {calculateDiscountedAmount(competitionDetails)}
                </span>
              </p>
            ) : (
              ""
            )}
            <p className="totalAmountPaidLine finalTotal">
              <span className="leftElement">Total: </span>
              <span className="rightElement">
                <span>&#8377;</span>
                {totalAmountAfterDiscount(
                  competitionCategory,
                  competitionDetails
                )}
              </span>
            </p>
            <Button
              className={buttonLoadingClass}
              variant="contained"
              color="primary"
              onClick={() => proceedForRegistration(competitionCategory.fee)}
            >
              {competitionCategory.fee.toString() === "0" ? (
                "Launching Soon"
              ) : (
                <>
                  Pay &nbsp;<span>&#8377;</span>{" "}
                  {totalAmountAfterDiscount(
                    competitionCategory,
                    competitionDetails
                  )}{" "}
                  and register
                </>
              )}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      {openPaymentSuccessModal ? (
        <div className="eventDetailModal">
          <div className="eventDetailInner success">
            <p
              className="closeModal"
              onClick={(e) => setOpenPaymentSuccessModal(false)}
            />
            <h3>Success!</h3>
            <div>
              <p className="subscriptionMessage success">
                Payment For Event Registration Recieved Successfully
                <br />
                And your video has been submitted for the competition.
              </p>
              <p>You can see more details on your profile page!</p>
              <div className="actionWrap success">
                <Button
                  className="btn btn-close"
                  variant="contained"
                  color="secondary"
                  title="close modal and stay here"
                  onClick={(e) => setOpenPaymentSuccessModal(false)}
                >
                  Explore more events
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default EnrollCompetition;
