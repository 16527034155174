/* eslint-disable array-callback-return */
import axios from "axios";
import React, { useEffect, useState } from "react";
import StudioCard from "./StudioCard";
import {
  getAllOfflineStudios,
  getOfflineStudiosByLocation,
  getFilteredStudio
} from "../../Services/OfflineStudios.service";
import { getStudioLandingPage } from "../../Services/Other.service";
import { OFFLINE_STUDIO_LOCATION, GOOGLE_MAP_API } from "../../Constants";
import { InputLabel, FormControl, MenuItem, Select } from "@material-ui/core";
import StudioFilterByOverlay from './StudioFilterByOverlay';
import { MdLocationOn } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Loader from "../Loader";

const Studio = () => {
  
  const [location, setLocation] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [locations, setLocations] = useState(OFFLINE_STUDIO_LOCATION);
  const [offlineStudios, setOfflineStudios] = useState([]);
  const [isFilterVisible, toggleFilter] = useState(false);
  const [isLocationFunctionCalled, locationFunctionCalled] = useState(false);
  const [landingPageBgData, setLandingPageBgData] = useState(null);
  const [isLoaderActive, toggleLoader] = useState(false);
  const [radioFilterValue, setRadioFilter] = useState('all');

  const history = useHistory();
  let params = new URLSearchParams();

  const handleChange = (event) => {
    const value = event.target.value;
    setLocation(value);

    params.append('location', value);
    history.push({ search: params.toString()});

    locationFunctionCalled(true);
  };

  useEffect(() => {
    try {
      getStudioLandingPage().subscribe( response => {
        setLandingPageBgData(response);
      });
    } catch(e) {
      console.log('error loading background: ', e)
    }
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const latLong = `&latlng=${position.coords.latitude},${position.coords.longitude}`;
        axios.get(`${GOOGLE_MAP_API}${latLong}`).then((res) => {
          const { data } = res;
          data?.results.map((item) => {
            if (item.types && item.types.length > 0) {
              item.types.map((type) => {
                if (type === "locality") {
                  setLocation(
                    item?.address_components[0]?.long_name.toLowerCase()
                  );
                }
              });
            }
          });
        });
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          toggleLoader(true);
          getAllOfflineStudios().subscribe((response) => {
            toggleLoader(false);
            setOfflineStudios(response);
          });
        }
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.geolocation]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParamLocation = urlParams.get('location');
    const myParamFilterRadio = urlParams.get('radioFilter');
    if (myParamLocation) {
      setLocation(myParamLocation);
    }
    if (myParamFilterRadio) {
      setRadioFilter(myParamFilterRadio);
    }
    if ((location && location?.length > 0) || myParamLocation) {
      toggleLoader(true);
      getOfflineStudiosByLocation(location || myParamLocation).subscribe((response) => {
        toggleLoader(false);
        if (myParamLocation) {
          toggleLoader(true);
          getFilteredStudio((location || myParamLocation), myParamFilterRadio, null).subscribe( resp => {
            toggleLoader(false);
            setOfflineStudios(resp);
          });
        } else {
          setOfflineStudios(response);
        }
      });
    }
  }, [location, radioFilterValue]);

  function toggleFilterOverlay(action, radioValue, filterData) {
    params = new URLSearchParams(window.location.search);
    if (radioValue && params.get('radioFilter') !== radioValue) {
      setRadioFilter(radioValue);
      params.delete('radioFilter');
      params.append("radioFilter", radioValue);
      history.push({ search: params.toString()});
    } else if(params.get('radioFilter')) {
      setRadioFilter(params.get('radioFilter'));
    }
    
    if (offlineStudios?.length) {
      toggleFilter(action);
      if (radioValue) {
        toggleLoader(true);
        getFilteredStudio(location, radioValue, filterData).subscribe( resp => {
          toggleLoader(false);
          setOfflineStudios(resp);
        });
      }
      
      // TODO: Need to add filter for checked styles
      if (filterData?.length) {
        console.log("Checked Data: ", filterData);
      }
    }
    return false;
  }

  return (
    <div className="studiosWrapper">
      <Loader value={isLoaderActive} />
      <div className="frameVideo">
        {
          landingPageBgData?.bgVideo ?
          <video autoPlay loop muted playsInline>
            <source src={`${landingPageBgData.bgVideo}`} type="video/mp4" />
          </video> : landingPageBgData?.bgImage ?
          <div className="imageWrapper">
            <img src={`${landingPageBgData.bgImage}`} alt={`${landingPageBgData.title}`} />
          </div> : ''
        }
      </div>
      {
        isFilterVisible ? 
        <StudioFilterByOverlay
          radioFilter={radioFilterValue} 
          filterAction = {(action, radioValue, checkedData) => toggleFilterOverlay(action, radioValue, checkedData)}
        /> : ''
      }
      <div className="studioInnerWrap">
        {
          offlineStudios && offlineStudios?.length ? '' :
          <h2 className="welcomeStudioMessage">
            {
              landingPageBgData?.title || 'Welcome to our Offline Studio Portal'
            }
          </h2>
        }
        <div className="inner-form-wrap input-select-wrap inputFilterWrap">
          <div className="input-wrap">
            <FormControl variant="outlined" className="input-field">
              <InputLabel id="outlined-filter-comp-label">
                Select location and book your centers
              </InputLabel>
              <Select
                labelId="location"
                id="select-outlined"
                value={location}
                onChange={handleChange}
                IconComponent={MdLocationOn}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {locations && locations.length
                  ? locations.map((item, index) => {
                      return (
                        <MenuItem
                          value={item.value}
                          key={`${item.value}-${index}`}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>
          </div>
          <div className="input-wrap filterToggle">
            <button disabled = {offlineStudios?.length ? false : true} onClick={ () => toggleFilterOverlay(true, null, null) }>
              <span className="filterText">Filter by</span>
              <span className="filterIcon">
                <FaFilter />
              </span>
            </button>
          </div>
        </div>
        {offlineStudios && offlineStudios?.length ?
          <div className="studioCardHolder">
            {offlineStudios.map((studio, studioIndex) => {
                  return (
                    <StudioCard
                      key={`${studioIndex}_${studio.name}`}
                      studio={studio}
                    />
                  );
                })
              }
          </div> :
            isLocationFunctionCalled ? 
            <div className="noStudioMessageWrap">{`Studios are coming soon for ${location.toUpperCase()}`}</div>
            : '' 
        }
      </div>
    </div>
  );
};

export default Studio;
