import { Observable } from 'rxjs';
import { formatDate, formatTime, timeStampToNewDate } from "./Utils";
import db from '../Database';

const competitionRef = db.collection('enrolledCompetitions');

export function getCompetitionsList() {
    return new Observable((observer) => {
        competitionRef.onSnapshot((querySnapshot) => {
            let cat = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.key = doc.id;
                let startingDate = new Date(data.startAt);
                data.startingDate = formatDate(startingDate, 3) + " " + formatTime(startingDate);
                let endingDate = new Date(data.endAt);
                data.endingDate = formatDate(endingDate, 3) + " " + formatTime(endingDate);
                cat.push(data);
            });
            cat.sort((a, b) => a.index - b.index);
            observer.next(cat);
        });
    });
}

export function getActiveCompetitionsList() {
    return new Observable((observer) => {
        competitionRef.onSnapshot((querySnapshot) => {
            let cat = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                if (data.active) {
                    let startingDate = new Date(data.startAt);
                    data.startingDate = formatDate(startingDate, 3) + " " + formatTime(startingDate);
                    let endingDate = new Date(data.endAt);
                    data.endingDate = formatDate(endingDate, 3) + " " + formatTime(endingDate);
                    data.key = doc.id;
                    cat.push(data);
                }
            });
            cat.sort((a, b) => a.index - b.index);
            observer.next(cat);
        });
    });
}

export function getCompetition(id) {
    return new Observable((observer) => {
        competitionRef.doc(id).get().then((doc) => {
            let data = doc.data();
            data.key = doc.id;
            let startingDate = new Date(data.startAt);
            data.startingDate = formatDate(startingDate, 3) + " " + formatTime(startingDate);
            let endingDate = new Date(data.endAt);
            data.endingDate = formatDate(endingDate, 3) + " " + formatTime(endingDate);
            observer.next(data);
        });
    });
}

export function getCompetitionByUserId(userId) {
    return new Observable((observer) => {
        competitionRef.where('userId', '==', userId).get().then((querySnapshot) => {
            let videos = []
            querySnapshot.forEach(function (doc) {
                let data = doc.data();
                data.key = doc.id;
                videos.push(data);
            })
            observer.next(videos);
        })
    })
}

export function getCompetitionsByFilter(filter) {
    if (typeof(filter) === 'object') {
        return new Observable((observer) => {
            competitionRef.get().then((querySnapshot) => {
                let videos = []
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    if (data[`${filter.key}`].toLowerCase() === filter?.value.toLowerCase()) {
                        data.key = doc.id;
                        videos.push(data);
                    }
                });
                observer.next(videos);
            });
        })
    } else {
        if (filter === 'notreviewed') { // will return only submitted but not reviewed list
            return new Observable((observer) => {
                competitionRef.get().then((querySnapshot) => {
                    let videos = []
                    querySnapshot.forEach(function (doc) {
                        let data = doc.data();
                        data.key = doc.id;
                        if (!data.status || data.status === '' || data?.status.toLowerCase() === "submitted") {
                            videos.push(data);
                        }
                    })
                    observer.next(videos);
                })
            })    
        } else { // now this will return if the passed status match
            return new Observable((observer) => {
                competitionRef.where('status', '==', filter).get().then((querySnapshot) => {
                    let videos = []
                    querySnapshot.forEach(function (doc) {
                        let data = doc.data();
                        data.key = doc.id;
                        videos.push(data);
                    });
                    observer.next(videos);
                });
            })
        }
    }
}

export function saveCompetition(data) {
    data.createdOn = new Date();
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        competitionRef.add(data).then((doc) => {
            observer.next({
                key: doc.id,
            });
        });
    });
};

export function updateCompetition(id, data) {
    data.createdOn = data.createdOn || new Date();
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        competitionRef.doc(id).set(data).then(() => {
            observer.next({ status: true });
        });
    });
}

export function getEnrolledCompetitionByCompId(compId) {
    return new Observable((observer) => {
        competitionRef.onSnapshot((querySnapshot) => {
            let cat = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                if (data.compId === compId) {
                    data.modifiedTimeStamp = timeStampToNewDate(data.modifiedOn);
                    cat.push(data);
                }
            });
            cat.sort((a, b) => b.modifiedTimeStamp - a.modifiedTimeStamp);
            observer.next(cat);
        });
    });
}