import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  setAdminOTP,
  getAdminOTP,
  setAdminPwd,
} from "../../Services/Other.service";
import { sendEmail } from "../../Services/Email.service";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { ADMIN_USER } from "../../Constants";

export default function AdminLogin(props) {
  const [isResetClicked, toggleResetForm] = useState(false);
  const [resetPwdData, setResetPwdData] = useState({});
  const [resetInfoMessages, setInfoMessages] = useState();
  const [resetOtpSentData, otpSentValidation] = useState({
    message: "",
    type: "",
  });
  const [otpResetMessage, setOtpResetMessage] = useState({
    message: "",
    type: "",
  });
  const [resetFieldDisabled, setFieldDisabled] = useState(true);
  const [confirmLoadingClass, setConfirmLoadingClass] = useState("");
  const [loginLoader, setloginLoader] = useState(false);
  const [pwdShow, setPwdShow] = useState(false);

  function handleAdminLogin(value, field) {
    props.inputChange(value, field);
  }

  function triggerLogin(event, action) {
    setloginLoader(true);
    props.submitLogin(event, action);
  }

  const toggleShowPwd = (event, isShow) => {
    event.stopPropagation();
    setPwdShow(isShow);
  };

  function validateOtp(event) {
    if (event.target.value) {
      getAdminOTPFromDB(event).then((resp) => {
        if (resp === event.target.value) {
          setFieldDisabled(false);
          const status = {
            message: "OTP matched!",
            type: "success",
          };
          setOtpResetMessage(status);
        } else {
          const status = {
            message: "OTP not matched, try again!",
            type: "error",
          };
          setFieldDisabled(true);
          setOtpResetMessage(status);
        }
      });
    } else {
      setFieldDisabled(true);
      setInfoMessages("Please enter OTP!");
    }
  }

  function getAdminOTPFromDB() {
    return new Promise((res, rej) => {
      try {
        getAdminOTP().subscribe((resp) => {
          if (resp) {
            res(resp);
          } else {
            rej(resp);
          }
        });
      } catch (e) {
        rej(e);
      }
    });
  }

  function saveAdminPwd(data) {
    if (data) {
      let status;
      setConfirmLoadingClass("loading");
      sendNewPwdToDB(data).then((resp) => {
        if (resp?.status) {
          setConfirmLoadingClass("");
          status = {
            message: "Password reset successfully!",
            type: "success",
          };
          setOtpResetMessage(status);
          setTimeout(() => {
            toggleResetForm(false);
          }, 2000);
        } else {
          status = {
            message: "Something went wrong, try again!",
            type: "error",
          };
          setConfirmLoadingClass("");
          setOtpResetMessage(status);
        }
      });
    }
  }

  function sendNewPwdToDB(data) {
    return new Promise((res, rej) => {
      try {
        setAdminPwd(data).subscribe((resp) => {
          res(resp);
        });
      } catch (e) {
        rej(e);
        if (e) {
          const status = {
            message: "Something went wrong, try again!",
            type: "error",
          };
          setConfirmLoadingClass("");
          setOtpResetMessage(status);
        }
      }
    });
  }

  function sendOTPResetPwd() {
    const getNewOtp = generateOTP();
    if (getNewOtp) {
      try {
        let emailBody = `<div>
            <p style="font-size: 16px; margin-bottom: 20px;">
              OTP for resetting admin panel Password
            </p>
            <h2><b>${getNewOtp}</b></h2>
            </div>`;
        let payload = {
          mailTo: { ADMIN_USER },
          title: "OTP for resetting Admin Panel Password!",
          content: emailBody,
        };
        return new Promise((res, rej) => {
          sendEmail(payload).subscribe((response) => {
            if (!("error" in response)) {
              response["otp"] = getNewOtp;
              res(response);
            } else {
              rej(response);
              otpSentValidation({
                message: "Something went wrong, try again!",
                type: "error",
              });
            }
          });
        });
      } catch (e) {
        console.log("e", e);
      }
    }
  }

  function sendOTPToDB(otp) {
    return new Promise((res, rej) => {
      setAdminOTP(otp).subscribe((resp) => {
        if (resp?.status) {
          res(resp);
        } else {
          rej(resp);
        }
      });
    });
  }

  function resetPassword(event, action) {
    event.stopPropagation();
    toggleResetForm(action);
    if (action) {
      sendOTPResetPwd().then((resp) => {
        if (resp?.status) {
          sendOTPToDB(resp?.otp).then((res) => {
            if (res?.status) {
              otpSentValidation({
                message: "OTP Send Successfully",
                type: "success",
              });
            } else {
              otpSentValidation({
                message: "Something went wrong, try again!",
                type: "error",
              });
            }
          });
        } else {
          otpSentValidation({
            message: "Something went wrong, try again!",
            type: "error",
          });
        }
      });
    }
  }

  function setNewPwdData(data, key) {
    setResetPwdData({ ...resetPwdData, [key]: data.target.value });
  }

  // Function to generate OTP
  function generateOTP() {
    // Declare a digits variable
    // which stores all digits
    let digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  }

  function triggerResetPwd(event) {
    event.stopPropagation();
    if (resetPwdData.pwd && resetPwdData.conf_pwd) {
      if (resetPwdData.pwd.length < 8) {
        setInfoMessages("password should be minimum 8 characters long!");
      } else if (resetPwdData.pwd !== resetPwdData.conf_pwd) {
        setInfoMessages("password not matched");
      } else {
        setInfoMessages("");
        setOtpResetMessage("");
        saveAdminPwd(resetPwdData);
      }
    } else {
      setInfoMessages("All fields are mandatory!");
    }
  }

  return (
    <div className="login-admin-form">
      {props.statusMessage ? (
        <p className="errorMessage">{props.statusMessage}</p>
      ) : (
        ""
      )}
      <div className="input-wrap">
        <TextField
          className="input-field"
          required
          id="admin-id"
          label="Email Id"
          onChange={(value) => handleAdminLogin(value, "email")}
          variant="outlined"
        />
      </div>
      <div className="input-wrap">
        <TextField
          className="input-field"
          required
          id="admin-pwd"
          type="password"
          label="Password"
          onChange={(value) => handleAdminLogin(value, "pwd")}
          variant="outlined"
        />
      </div>
      <div className="input-wrap action-wrap flexElement">
        <p
          className="linkElement"
          title="reset admin password"
          onClick={(e) => resetPassword(e, true)}
        >
          Reset password
        </p>
        <Button
          className={!props.statusMessage && loginLoader ? "loading" : ""}
          variant="contained"
          color="secondary"
          onClick={(e) => triggerLogin(e, true)}
        >
          Login
        </Button>
      </div>

      {/* Reset block */}
      {isResetClicked ? (
        <div className="adminResetBlock">
          {resetOtpSentData?.type === "success" ? (
            <>
              {resetInfoMessages ? (
                <p className={`otpMessage ${resetInfoMessages ? "error" : ""}`}>
                  {resetInfoMessages}
                </p>
              ) : otpResetMessage?.message ? (
                <p className={`otpMessage ${otpResetMessage.type}`}>
                  {otpResetMessage.message}
                </p>
              ) : (
                <p className="otpMessage">
                  Check <span>info@appda.in</span> email for OTP!
                </p>
              )}
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  required
                  id="pwd-otp"
                  label="Enter OTP sent on your email"
                  onBlur={(e) => validateOtp(e)}
                  variant="outlined"
                  tabIndex={2}
                />
              </div>
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  required
                  id="new-pwd"
                  type={pwdShow ? "text" : "password"}
                  disabled={resetFieldDisabled}
                  label="Enter new password"
                  onChange={(value) => setNewPwdData(value, "pwd")}
                  variant="outlined"
                  tabIndex={3}
                />
                <button
                  disabled={resetFieldDisabled}
                  className="showPwdBtn"
                  onClick={(e) => toggleShowPwd(e, !pwdShow)}
                >
                  {pwdShow ? <FiEyeOff /> : <FiEye />}
                </button>
              </div>
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  required
                  id="confirm-pwd"
                  type={pwdShow ? "text" : "password"}
                  disabled={resetFieldDisabled}
                  label="Confirm new password"
                  onChange={(value) => setNewPwdData(value, "conf_pwd")}
                  variant="outlined"
                  tabIndex={4}
                />
              </div>
              <div className="input-wrap action-wrap flexElement">
                <p
                  className="linkElement"
                  title="Cancel reset password"
                  onClick={(e) => resetPassword(e, false)}
                >
                  Cancel
                </p>
                <Button
                  className={`${confirmLoadingClass}`}
                  variant="contained"
                  color="secondary"
                  title="Set new password"
                  disabled={resetFieldDisabled}
                  onClick={(e) => triggerResetPwd(e, true)}
                >
                  Confirm
                </Button>
              </div>
            </>
          ) : (
            <div className="loaderWrapper">
              {resetOtpSentData?.type === "error" ? (
                <div className="tryAgainBox">
                  <p className="errorTitle">{resetOtpSentData?.message}</p>
                  <div className="actionWrap">
                    <p
                      className="linkElement"
                      title="cancel admin password resent"
                      onClick={(e) => resetPassword(e, false)}
                    >
                      Cancel
                    </p>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={(e) => resetPassword(e, true)}
                    >
                      Try again!
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="spinnerBox">
                  <div className="spinnerLoader">
                    <div className="loader"></div>
                    <span>Sending OTP to info@appda.in...</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
