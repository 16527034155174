import { Observable } from "rxjs";
import axios from "axios";
import boogaluLogo from "../Images/da logo.png";
const APP_API_URL = process.env.REACT_APP_API_URL,
  RAZORPAY_ORDERS_API_URL = process.env.REACT_APP_RAZORPAY_ORDERS_API_URL,
  RAZORPAY_TEST_KEY = process.env.REACT_APP_RAZORPAY_KEY,
  REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const header = new Headers();
header.append("Access-Control-Allow-Origin", "*");
header.append("Content-Type", "application/json");
header.append("mode", "cors");

export function postOrder(
  data,
  planType,
  planTenure,
  planDescription,
  loggedInUser,
  handlerFn,
  isRenew
) {
  return new Observable((observer) => {
    axios.post(RAZORPAY_ORDERS_API_URL, data).then((response) => {
      const responseData = response.data;
      const selectedPlanData = responseData[planType];
      console.log("postOrder response");
      let subscriptionData = {
        key: RAZORPAY_TEST_KEY,
        amount: selectedPlanData?.amount || 100,
        currency: selectedPlanData?.currency,
        name: loggedInUser?.name,
        description: planDescription,
        image: boogaluLogo,
        order_id: selectedPlanData?.id,
        handler: function (successResponse) {
          const data = {
            ...selectedPlanData,
            ...successResponse,
          };
          responseData[planType] = data;
          handlerFn(responseData, planType, isRenew, planTenure);
        },
        prefill: {
          name: loggedInUser.name,
          email: loggedInUser.email,
          contact: loggedInUser.phone,
        },
        theme: {
          color: "#191313",
        },
        modal: {
          ondismiss: function (e) {
            console.log("Checkout form closed!!!!");
          },
        },
      };
      const checkOutForm = new window.Razorpay(subscriptionData);
      checkOutForm.on("payment.failed", function (response) {
        console.log(" on payment failure");
      });
      checkOutForm.open();
      observer.next(response);
    });
  });
}

// export function enrollOfflineStudio(data, type, loggedInUser) {
//   console.log({ data, type, loggedInUser });
//   const tempURL = "https://us-central1-boogalusite.cloudfunctions.net";
//   return new Observable((observer) => {
//     axios
//       .post(`${REACT_APP_API_URL}/enrollOfflineStudio`, data)
//       .then((response) => {
//         const responseData = response.data;
//         // const selectedPlanData = responseData[planType];
//         console.log("enrollOfflineStudioNew responseData");
//         let subscriptionData = {
//           key: RAZORPAY_TEST_KEY,
//           // amount: selectedPlanData.amount,
//           // currency: selectedPlanData.currency,
//           name: loggedInUser.name,
//           // description: planDescription,
//           image: boogaluLogo,
//           // order_id: selectedPlanData.id,
//           handler: function (successResponse) {
//             const data = {
//               ...successResponse,
//             };
//             // responseData[planType] = data;
//             // handlerFn(responseData, planType, isRenew);
//           },
//           prefill: {
//             name: loggedInUser.name,
//             email: loggedInUser.email,
//             contact: loggedInUser.phone,
//           },
//           theme: {
//             color: "#191313",
//           },
//           modal: {
//             ondismiss: function (e) {
//               console.log("Checkout form closed!!!!");
//             },
//           },
//         };
//         const checkOutForm = new window.Razorpay(subscriptionData);
//         checkOutForm.on("payment.failed", function (response) {
//           console.log(" on payment failure");
//         });
//         checkOutForm.open();
//         observer.next(response);
//       });
//   });
// }

export function enrollOfflineStudio(
  data,
  planType,
  planDescription,
  loggedInUser,
  handlerFn,
  isRenew
) {
  return new Observable((observer) => {
    axios
      .post(`${REACT_APP_API_URL}/enrollOfflineStudio`, data)
      .then((response) => {
        const responseData = response.data;
        const selectedPlanData = responseData[planType];
        console.log("postOrder response");
        let subscriptionData = {
          key: RAZORPAY_TEST_KEY,
          amount: selectedPlanData?.amount || 100,
          currency: selectedPlanData?.currency,
          name: loggedInUser?.name,
          description: planDescription,
          image: boogaluLogo,
          order_id: selectedPlanData?.id,
          handler: function (successResponse) {
            const data = {
              ...selectedPlanData,
              ...successResponse,
            };
            responseData[planType] = data;
            handlerFn(responseData, planType, isRenew);
          },
          prefill: {
            name: loggedInUser.name,
            email: loggedInUser.email,
            contact: loggedInUser.phone,
          },
          theme: {
            color: "#191313",
          },
          modal: {
            ondismiss: function (e) {
              console.log("Checkout form closed!!!!");
            },
          },
        };
        const checkOutForm = new window.Razorpay(subscriptionData);
        checkOutForm.on("payment.failed", function (response) {
          console.log(" on payment failure");
        });
        checkOutForm.open();
        observer.next(response);
      });
  });
}

export function updatePayment(data) {
  return new Observable((observer) => {
    axios
      .post(APP_API_URL + "/updatePayment", data, header)
      .then((response) => {
        console.log("updatePayment API response");
        observer.next(response);
      });
  });
}
