import React, { useRef, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useStoreConsumer } from "../../Providers/StateProvider";
import { uploadVideo, uploadImage } from "../../Services/Upload.service";
// eslint-disable-next-line no-unused-vars
import { saveUploadedVideo, saveVideo } from "../../Services/UploadedVideo.service";
import { useHistory } from "react-router-dom";
import { THUMBNAIL_URL } from "../../Constants";
import ImageUploader from "react-images-upload";
import { disableLoginFlow } from "../../Actions/LoginFlow";
import { FaCloudUploadAlt } from "react-icons/fa";
import { setDataRefetchModuleName } from "../../Actions/Utility";
import { NOTIFICATION_ERROR, ADMIN_EMAIL_STAGING } from "../../Constants";
import { displayNotification } from "../../Actions/Notification";
import { sendEmail } from "../../Services/Email.service";
// modal imports
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
//circular progress
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Loader from "../Loader";
import { logAnalyticsEvent } from "../../Services/analytics.service";

export default function VideoUploader({
  selectedVdo,
  handleVdoUploadResponse,
}) {
  const history = useHistory();
  const { state, dispatch } = useStoreConsumer();
  const loggedInUser = state.loggedInUser;
  const uploaderRef = useRef(null);
  const [SelectedVideo, setSelectedVideo] = useState(
    selectedVdo ? selectedVdo : { title: "", desc: "", file: null }
  );
  const [UploadedVdoUrl, setUploadedVdoUrl] = useState(null);
  const [ThumbnailImage, setThumbnailImage] = useState(null);
  const [openVdoUploaderModal, setOpenVdoUploaderModal] = useState(true);
  const [progress, setProgress] = useState(0);
  const [ShowVdoUploadProgress, setShowVdoUploadProgress] = useState(false);
  const [isLoaderActive, toggleLoading] = useState(false);
  const [loadingClass, setLoadingClass] = useState('');
  // useEffect(() => {
  //     const timer = setInterval(() => {
  //         setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  //     }, 800);
  //     return () => {
  //         clearInterval(timer);
  //     };
  // }, []);


  useEffect(() => {
    logAnalyticsEvent('page_view', {
      page_location: window.location.href,
      page_path: 'upload-video',
      page_title: `HomePage-${window.location.href}`
    });
  }, [])

  useEffect(() => {
    dispatch(disableLoginFlow());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    console.log(file);
    // 1MB in Bytes is 1,048,576 so you can multiply it by the limit you need.
    if (file) {
      // TODO: currently upload limit is 200 MB will need to increase to 1 GB for user
      if (file.size > 220000000) {
        alert("File is too big!");
        dispatch(
          displayNotification({
            msg: "Video size is too big, should not exceed 200 MB!",
            type: NOTIFICATION_ERROR,
            time: 5000,
          })
        );
        setSelectedVideo({ ...SelectedVideo, file: null });
        uploaderRef.current.click();
      } else {
        setSelectedVideo({ ...SelectedVideo, file: null });
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setSelectedVideo({ ...SelectedVideo, file: reader.result });
        };
        reader.onerror = (error) => console.error(error);
      }
    }
  }

  const handleChange = (prop) => (event) => {
    setSelectedVideo({ ...SelectedVideo, [prop]: event.target.value });
  };

  async function uploadSelectedVideo() {
    if (!(SelectedVideo.title && SelectedVideo.desc)) {
      dispatch(
        displayNotification({
          msg: "Title and description are mandatory!",
          type: NOTIFICATION_ERROR,
          time: 3000,
        })
      );
      return;
    }
    var thumbnailImage = THUMBNAIL_URL;
    setLoadingClass('loading');
    if (ThumbnailImage && ThumbnailImage[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(ThumbnailImage[0]);
      reader.onload = () => {
        try {
          uploadImage(reader.result, "thumbnail", "small").subscribe(
            (downloadableUrl) => {
              thumbnailImage = downloadableUrl;
            }
          );
        } catch (e) {
          console.log('video upload error: ', e);
        }
      };
      reader.onerror = (error) => console.error(error);
    }

    const sendEmailToAdmin = (vdoUrl) => {
      let emailBody = `<div>
                <h6 style="font-size: 17px;margin-bottom: 26px;">New Video Uploaded</h6>
                <h4>User details -</h4>
                <h6>${loggedInUser.name}</h6>
                <h6>${loggedInUser.email}</h6>
                <h6>${loggedInUser.phone}</h6>
                <a href=${vdoUrl} >Clik here to check uploaded video</a>
                </div>`;
      let payload = {
        mailTo: ADMIN_EMAIL_STAGING,
        title: "New Video Uploaded",
        content: emailBody,
      };
      sendEmail(payload).subscribe((res) => {
        if (!("error" in res)) {
          console.log("Admin Email Send Successfully.");
        } else console.log("Admin Email Send Failed.");
      });
    };

    const sendEmailToUser = (vdoUrl) => {
      let emailBody = `<div>
                <p>Hi ${loggedInUser.name}, you have recently uploaded a video, ${SelectedVideo.title}</p>. 
                <a href=${vdoUrl} >Clik here to check uploaded video</a>
                </div>`;
      let payload = {
        mailTo: loggedInUser.email,
        title: "Video Uploaded",
        content: emailBody,
      };
      sendEmail(payload).subscribe((res) => {
        if (!("error" in res)) {
          console.log("User Email Send Successfully.");
        } else console.log("User Email Send Failed.");
      });
    };
    // eslint-disable-next-line no-unused-vars
    const id = new Date().getTime().toString();
    uploadVideo(SelectedVideo.file, null, null, null).subscribe((response) => { //here SelectedVideo.title is used as filename
      toggleLoading(true);
      setShowVdoUploadProgress(true);
      if (response.donePercentage) {
        setProgress(response.donePercentage);
      }
      if (response.downloadURL && !UploadedVdoUrl) {
        sendEmailToAdmin(response.downloadURL);
        sendEmailToUser(response.downloadURL);

        toggleLoading(true);
        setShowVdoUploadProgress(false);
        setUploadedVdoUrl(response.downloadURL);
        const uploadObj = {
          title: SelectedVideo.title,
          desc: SelectedVideo.desc,
          url: response.downloadURL,
          userId: loggedInUser.key,
          userProfile: {
            userName: loggedInUser.name,
            image: loggedInUser.profileImage,
            email: loggedInUser.email,
            privacy: loggedInUser.privacy
          },
          thumbnail: thumbnailImage,
        };
        // const vdoObj = {
        //   'finishedProcessing': false,
        //   'videoName': SelectedVideo.title,
        //   'firebaseUrl': response.downloadURL,
        //   'url': response.downloadURL,
        //   'thumbnail': thumbnailImage,
        //   'folder': 'userVideos',
        //   'title': SelectedVideo.title,
        //   'desc': SelectedVideo.desc,
        //   'userId': loggedInUser.key,
        //   'createdOn': new Date(),
        //   'modifiedOn': new Date()
        // }
        // saveVideo(vdoObj).subscribe((response) => {
        //   toggleLoading(false);
        //   setLoadingClass('');
        //   const pathName = history?.location?.pathname.split("/")[1];
        //   pathName.includes("profile") &&
        //     dispatch(setDataRefetchModuleName("user-uploaded-video"));
        //   closeUploaderModal();
        //   if (state.currentLoginFlow === "competition-uploadvdo")
        //     handleVdoUploadResponse(3);
        //   else history.push("/profile");
        // });

        saveUploadedVideo(uploadObj).subscribe((response) => {
          toggleLoading(false);
          setLoadingClass('');
          const pathName = history?.location?.pathname.split("/")[1];
          pathName.includes("profile") &&
            dispatch(setDataRefetchModuleName("user-uploaded-video"));
          closeUploaderModal();
          if (state.currentLoginFlow === "competition-uploadvdo")
            handleVdoUploadResponse(3);
          else history.push("/profile");
        });
      }
    });
  }

  const onThumbnailImgSelect = (picture) => {
    setThumbnailImage(picture);
  };

  const closeUploaderModal = () => {
    const pathName = history?.location?.pathname.split("/")[1];
    if (pathName.includes("register") || pathName.includes("login")) {
      history.push("/profile");
    }
    // else if (state.currentLoginFlow == 'competition-uploadvdo') history.push('/competition');
    // handleClose();
    if (typeof handleVdoUploadResponse !== "undefined") {
      handleVdoUploadResponse();
    }
    setOpenVdoUploaderModal(false);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <Loader value={isLoaderActive} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="competition-modal-box"
        open={openVdoUploaderModal}
        onClose={() => closeUploaderModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openVdoUploaderModal}>
          <div className="video-uploader-inner-modal">
            <IconButton
              className="close-modal-btn"
              onClick={() => {
                closeUploaderModal(false);
                dispatch(disableLoginFlow());
              }}
            >
              <CloseIcon />
            </IconButton>
            <h3>Upload your dance performance video!</h3>
            <span className="uploadInfo">
              ( Video size should not exceed 200 MB )
            </span>
            <div
              className={`uploader-wrap ${SelectedVideo.file ? "selected-file" : ""
                }`}
            >
              {!SelectedVideo.file ? (
                <div className="upload-input-wrap">
                  <i className="upload-icon">
                    <FaCloudUploadAlt />
                  </i>
                  <input
                    id="myInput"
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    ref={uploaderRef}
                    onChange={(e) => onChangeFile(e)}
                  />
                </div>
              ) : (
                ""
              )}

              {!SelectedVideo.file ? (
                <div className="upload-btn-file">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      uploaderRef.current.click();
                    }}
                  >
                    Upload video
                  </Button>
                </div>
              ) : (
                <div className="video-information-wrap">
                  <video controls>
                    <source src={SelectedVideo.file} />
                  </video>
                  <div className="change-video-btn">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        uploaderRef.current.click();
                      }}
                    >
                      Change video
                    </Button>
                  </div>
                  <div className="input-form-wrap">
                    <div className="input-wrap image-uploader-wrap">
                      <ImageUploader
                        withIcon={true}
                        buttonText="Select image"
                        onChange={onThumbnailImgSelect}
                        imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                        maxFileSize={2200000}
                        accept="image/*"
                        withPreview={true}
                        singleImage={true}
                        label="Select thumbnail image ( Max size 2 MB)"
                      />
                    </div>
                    <div className="text-form-wrapper">
                      <div className="input-field-wrap">
                        <TextField
                          className="input-field"
                          required
                          id="outlined-required-title"
                          label="Video title"
                          onChange={handleChange("title")}
                          value={SelectedVideo.title}
                          variant="outlined"
                          autoComplete="off"
                        />
                      </div>
                      <div className="input-field-wrap">
                        <TextField
                          className="input-field"
                          required
                          id="outlined-required-desc"
                          label="Video description"
                          onChange={handleChange("desc")}
                          value={SelectedVideo.desc}
                          variant="outlined"
                          autoComplete="off"
                        />
                      </div>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={loadingClass}
                        onClick={(e) => uploadSelectedVideo(e)}
                      >
                        Upload
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      {ShowVdoUploadProgress && (
        <div className="progress-wrap">
          <div className="progress-body">
            <CircularProgressWithLabel value={progress} />
          </div>
        </div>
      )}
    </div>
  );
}
