import { Observable } from 'rxjs';
import db from '../Database';
import { formatDate, timeStampToNewDate } from "./Utils";

const lessonsRef = db.collection('lessons');
const lessonsVideoRef = db.collection('lessonsVideos');

export function saveLesson(data) {
    data.createdOn = new Date();
    data.modifiedOn = new Date();
    data.id = data.name.toLowerCase();
    return new Observable((observer) => {
        lessonsRef.add(data).then((doc) => {
            observer.next({
                key: doc.id,
            });
        });
    });
}

export function updateLessonsVideoListAndThumbnail(id, videoList, thumbnailImage) {
    return new Observable((observer) => {
        lessonsRef.doc(`/${id}`).update({ 'videoList': videoList, 'thumbnailImage': thumbnailImage }).then(() => {
            observer.next();
        });
    });
}

export function getLessonByName(name) {
    return new Observable((observer) => {
        lessonsRef.where('id', '==', name.toLowerCase()).get().then((querySnapshot) => {
            let lesson = {}
            querySnapshot.forEach(function (doc) {
                let data = doc.data();
                console.log(" getLessonByName data is ", data);
                data.key = doc.id;
                lesson = data;
            })
            observer.next(lesson);
        })
    })
}

export function getLessonByPlanType(filter, levelFilter, styleFilter, kidsAgeFilter, subPage, params) {
    return new Observable((observer) => {
        filter = filter === 'all' ? '' : filter;
        if (filter && levelFilter && styleFilter) {
            lessonsRef.where('accessbility', '==', filter).where('expertiseLevel', '==', levelFilter).where('artForm', '==', styleFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }

                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            })
        } else if (filter && levelFilter) {
            lessonsRef.where('accessbility', '==', filter).where('expertiseLevel', '==', levelFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else if (filter && styleFilter) {
            lessonsRef.where('accessbility', '==', filter).where('artForm', '==', styleFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else if (levelFilter && styleFilter) {
            lessonsRef.where('expertiseLevel', '==', levelFilter).where('artForm', '==', styleFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else if (levelFilter) {
            lessonsRef.where('expertiseLevel', '==', levelFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else if (styleFilter) {
            lessonsRef.where('artForm', '==', styleFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else {
            lessonsRef.onSnapshot((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        }
    })
}

export function getLessonByPlanTypeOnlyPreview(filter, levelFilter, styleFilter, kidsAgeFilter, subPage, params) {
    return new Observable((observer) => {
        filter = filter === 'all' ? '' : filter;
        if (filter && levelFilter && styleFilter) {
            lessonsRef.where('accessbility', '==', filter).where('expertiseLevel', '==', levelFilter).where('artForm', '==', styleFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    let previewKeyOnly = data.videoList.preview;
                    data.videoList = {};
                    data.videoList['preview'] = previewKeyOnly;
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else if (filter && levelFilter) {
            lessonsRef.where('accessbility', '==', filter).where('expertiseLevel', '==', levelFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    let previewKeyOnly = data.videoList.preview;
                    data.videoList = {};
                    data.videoList['preview'] = previewKeyOnly;
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else if (filter && styleFilter) {
            lessonsRef.where('accessbility', '==', filter).where('artForm', '==', styleFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    let previewKeyOnly = data.videoList.preview;
                    data.videoList = {};
                    data.videoList['preview'] = previewKeyOnly;
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else if (levelFilter && styleFilter) {
            lessonsRef.where('expertiseLevel', '==', levelFilter).where('artForm', '==', styleFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    let previewKeyOnly = data.videoList.preview;
                    data.videoList = {};
                    data.videoList['preview'] = previewKeyOnly;
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else if (levelFilter) {
            lessonsRef.where('expertiseLevel', '==', levelFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    let previewKeyOnly = data.videoList.preview;
                    data.videoList = {};
                    data.videoList['preview'] = previewKeyOnly;
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else if (styleFilter) {
            lessonsRef.where('artForm', '==', styleFilter).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    let previewKeyOnly = data.videoList.preview;
                    data.videoList = {};
                    data.videoList['preview'] = previewKeyOnly;
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        } else {
            lessonsRef.onSnapshot((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    let previewKeyOnly = data.videoList.preview;
                    data.videoList = {};
                    data.videoList['preview'] = previewKeyOnly;
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                    if (subPage !== 'all') {
                        lessons = lessons.filter( item => item.subPage === subPage);
                    }
                    if (kidsAgeFilter) {
                        lessons = lessons.filter( item => item.kidsAgeGroup === kidsAgeFilter);
                    }
                })
                observer.next(lessons);
            });
        }
    })
}

export function getLessonsBySubPageType(subPage, params) {
    if (subPage === 'all') {
        return getAllLessons(params);
    } else {
        return new Observable((observer) => {
            lessonsRef.where('subPage', '==', subPage).get().then((querySnapshot) => {
                let lessons = [];
                let forLocal = [];
                let forLive = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    data.key = doc.id;
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                    lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
                })
                observer.next(lessons);
            });
        });
    }
}

export function getAllLessons(params) {
    return new Observable((observer) => {
        lessonsRef.onSnapshot((querySnapshot) => {
            let lessons = [];
            let forLocal = [];
            let forLive = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.key = doc.id;
                data.uploadedTime = formatDate(timeStampToNewDate(data.createdOn), 3);
                if (params === 'admin') {
                    forLive.push(data);
                } else {
                    if (params && params === 'test') {
                        if (data?.isLocal) {
                            forLive.push(data);
                        }
                    } else {
                        if (!data?.isLocal) {
                            forLive.push(data);
                        }
                    }
                }
                lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
            });
            observer.next(lessons);
        });
    });
}

export function deleteLesson(id) {
    return new Observable((observer) => {
        lessonsRef.doc(id).delete().then(() => {
            console.log("Document successfully deleted!");
            observer.next({ deleted: true });
        }).catch((error) => {
            console.error("Error removing document: ", error);
            observer.next({ deleted: false, error: error });
        });
    });
}

export function getLessonsWithOnlyPreview(params) {
    return new Observable((observer) => {
        lessonsRef.onSnapshot((querySnapshot) => {
            let lessons = [];
            let forLocal = [];
            let forLive = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                let previewKeyOnly = data.videoList.preview;
                data.key = doc.id;
                data.videoList = {};
                data.videoList['preview'] = previewKeyOnly;
                data.uploadedTime = formatDate(timeStampToNewDate(data.createdOn), 3);
                if (params && params === 'test') {
                    if (data?.isLocal) {
                        forLive.push(data);
                    }
                } else {
                    if (!data?.isLocal) {
                        forLive.push(data);
                    }
                }
                lessons = forLocal.length ? forLocal : forLive.length ? forLive : [];
            });
            observer.next(lessons);
        });
    });
}

export function updateLessonPlayTime(id, playedTimeData) {
    return new Observable((observer) => {
        lessonsRef.doc(id).get().then((doc) => {
            let data = doc.data();
            if (data && !data.lessonPlayedTimes) {
                data = { ...data, 'lessonPlayedTimes': [playedTimeData] }
            } else {
                if (data.lessonPlayedTimes.length) {
                    data.lessonPlayedTimes.forEach(item => {
                        if (item.userKey === playedTimeData.userKey) {
                            item.playedTime = playedTimeData.playedTime
                        } else {
                            data.lessonPlayedTimes.push(playedTimeData);
                        }
                    });

                    const setObj = new Set();
                    const uniqueResult = data.lessonPlayedTimes.reduce((acc, item) => {
                        if (!setObj.has(item.userKey)) {
                            setObj.add(item.userKey, item);
                            acc.push(item);
                        }
                        return acc;
                    }, []);
                    data.lessonPlayedTimes = uniqueResult;
                }
            }

            lessonsRef.doc(id).set(data).then(() => {
                observer.next();
            });
        });
    });
}

export function updateLessonActiveStatus(data, status) {
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        lessonsRef.doc(data.key).update({ 'isLocal': status }).then(() => {
            observer.next();
        });
    });
}

export function getLessonPlayedTime(id) {
    return new Observable((observer) => {
        lessonsRef.doc(id).get().then((doc) => {
            let data = doc.data();
            let playedTimeData = data?.lessonPlayedTimes;
            observer.next(playedTimeData);
        });
    });
}

export function saveLessonVideo(vdoObj) {
    return new Observable((observer) => {
        lessonsVideoRef.doc(vdoObj.videoName).set(vdoObj).then((doc) => {
            observer.next({
                status: 'success',
            });
        });
    });
}
