import React from "react";
import { Link } from "react-router-dom";
import championIcon from "../../Images/champion-box-icon.png";
import lessonsIcon from "../../Images/lessons-icon.png";
import subscribeIcon from "../../Images/subscribe-icon.png";
import usersIcon from "../../Images/users-icon.png";
import offlineStudioIcon from "../../Images/offline-studios.png";

const Navigation = () => {
  const navigationArray = [
    {
      id: "championship",
      url: "/adminpanel/competition",
      title: "create competitions",
      class: "",
      iconClass: "championIconWrap",
      iconSrc: championIcon,
      heading: "Competitions",
    },
    {
      id: "lessons",
      url: "/adminpanel/lessons",
      title: "upload new lessons",
      class: "",
      iconClass: "lessonsIconWrap",
      iconSrc: lessonsIcon,
      heading: "Lessons",
    },
    {
      id: "offlineStudios",
      url: "/adminpanel/offline-studios",
      title: "Offline Studios",
      class: "",
      iconClass: "subscribeIconWrap",
      iconSrc: offlineStudioIcon,
      heading: "Offline Studios",
    },
    {
      id: "users",
      url: "/adminpanel/users",
      title: "manage users",
      class: "",
      iconClass: "subscribeIconWrap",
      iconSrc: usersIcon,
      heading: "Users",
    },
    {
      id: "subscription",
      url: "/adminpanel/subscription",
      title: "create subscription",
      class: "",
      iconClass: "subscribeIconWrap",
      iconSrc: subscribeIcon,
      heading: "Subscription",
    }
  ];
  return (
    <nav className="adminNavigation">
      {navigationArray &&
        navigationArray.length > 0 &&
        navigationArray.map((item) => {
          return (
            <Link
              to={item?.url}
              title={item?.title}
              className="panelLink"
              key={item?.id}
            >
              <span className={`iconsWrap ${item?.iconClass}`}>
                <img src={item?.iconSrc} alt={item?.heading} />
              </span>
              <span className={`title ${item?.id}`}>{item?.heading}</span>
            </Link>
          );
        })}
    </nav>
  );
};

export default Navigation;
