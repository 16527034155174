import { Observable } from 'rxjs';
import db from '../Database';

const resetPwdRef = db.collection('resetpassword');

export function registerResetToken(data) {
    data.createdOn = new Date();
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        resetPwdRef.add(data).then((doc) => {
            observer.next({
                key: doc.id,
            });
        });
    });
}

export function getResetTokenData(tokenId) {
    return new Observable((observer) => {
        resetPwdRef.where('tokenId', '==', tokenId).get().then(querySnapshot => {
            let tokens = {};
            querySnapshot.forEach( doc => {
                let data = doc.data();
                data.key = doc.id;
                tokens = data;
            })
            observer.next(tokens);
        });
    });
}

export function removeResetTokenData(key) {
    return new Observable((observer) => {
        resetPwdRef.doc(key).delete().then(() => {
            console.log("Document successfully deleted!");
            observer.next({ deleted: true });
        }).catch((error) => {
            console.error("Error removing document: ", error);
            observer.next({ deleted: false, error: error });
        });
    });
}
