import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { useStoreConsumer } from "../../Providers/StateProvider";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import boogaluLogo from "../../Images/da logo.png";
import { BiUser, BiLockOpen } from "react-icons/bi";
import { FaFacebook } from "react-icons/fa";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowRightSharpIcon from "@material-ui/icons/ArrowRightSharp";
import { loginUser } from "../../Actions/User/index";
import {
  getUserByEmail,
  getUserByPhone,
  updatePassword,
} from "../../Services/User.service";
import { getUploadedVideosByUserId } from "../../Services/UploadedVideo.service";
import {
  registerResetToken,
  getResetTokenData,
  removeResetTokenData,
} from "../../Services/ResetPassword.service";
import VideoUploader from "../VideoUploader";
import Loader from "../Loader";
import GenericInfoModal from "../genericInfoModal";
import {
  displayNotification,
  removeNotification,
} from "../../Actions/Notification";
import { getUploadedVideosByUser } from "../../Actions/User";
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCCESS,
  VIDEO_LIMIT_COUNT,
} from "../../Constants";
import { validateEmailId } from "../../helpers";
import { sendEmail } from "../../Services/Email.service";
import { EmailTemplate } from "../EmailTemplate/Emailer";
import * as $ from "jquery";
const restLinkUrlQuery = "reset-password=";

export default function Login(props) {
  const { state, dispatch } = useStoreConsumer();
  const history = useHistory();
  const [loginCred, setloginCred] = useState({
    username: "",
    password: "",
    showPassWord: false,
  });
  const [LoginError, setLoginError] = useState(null);
  const [thirdPartyResponse, setThirdPartyResponse] = useState({
    isLogginSuccess: false,
    data: null,
    source: "",
  });
  const [openVdoUploadModal, setOpenVdoUploadModal] = useState(false);
  const [emailVerifyMessage, setEmailVerificationMessage] = useState("");
  const [emailVerifyClass, toggleEmailVerifyClass] = useState("");
  const [isResetClicked, toggleResetLink] = useState(false);
  const [isPageLoaderActive, togglePageLoader] = useState(false);
  const [isResetPasswordViewOpen, toggleResetPwdModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [confirmPasswordMessage, setConfirmPwdMessage] = useState("");
  const [resetPassword, setResetPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [openInformationModal, toggleInfoModal] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState("");
  const [infoModalMessage, setInfoModalMessage] = useState("");
  const [infoModalStatus, setInfoModalStatus] = useState("");
  const [navigateLink, setInfoModalNavigateLink] = useState("");
  const [isEmailSent, toggleEmailSentLoader] = useState(false);
  const [isResetPwdVisible, toggleResetPwd] = useState(true);
  const [isResetConfirmPwdVisible, toggleConfirmResetPwd] = useState(true);

  const userChangePwdEmail = useRef(null);
  const newPwdFirstInputRef = useRef(null);
  const newPwdConfirmInputRef = useRef(null);
  const changePwdBtnRef = useRef(null);
  const sendEmailBtnRef = useRef(null);

  useEffect(() => {
    if (thirdPartyResponse.source === "Facebook") signinUser("", "Facebook");
    if (thirdPartyResponse.source === "Google") signinUser("", "Google");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdPartyResponse]);

  useEffect(() => {
    //uncomment for test email template then copy console ouptput and paste it toemailer.html and run this file in new browser tab
    // testEmailTemplate();

    dispatch(
      removeNotification({
        msg: "",
        type: NOTIFICATION_SUCCCESS,
        time: 0,
      })
    );

    $("html,body").animate(
      {
        scrollTop: 0,
      },
      500
    );

    const searchQuery = history?.location?.search;
    if (searchQuery.includes("?")) {
      //check for reset link
      if (searchQuery.includes(restLinkUrlQuery)) {
        // validate reset code in url
        const resetCodeFromUrl = searchQuery.split("=")[1];
        if (resetCodeFromUrl) {
          //valid code
          toggleResetPwdModal(true);
          //redirect to reset password screen
        } else {
          //invalid code
          history.replace(window.location.pathname);
          dispatch(
            displayNotification({
              msg: "Password reset link expired!",
              type: NOTIFICATION_ERROR,
              time: 3000,
            })
          );
        }
      } else {
        //false query then remove it from url
        if (
          searchQuery.includes("isResetByMobile") &&
          searchQuery.split("=")[1] === "1"
        ) {
          toggleResetLink(true);
        } else {
          history.replace(window.location.pathname);
          toggleResetLink(false);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const testEmailTemplate = () => {
    const emailBodyConfig = {
      heading: `Hello, Da User we wanted to let you know that your password was changed.`,
      content: `<div>
            <p>To access your account just click the link bellow and try to login using your new password.</p>
            <div class="action-btn-wrap">
                <a class="action" href=${window.location.href}>Login</a> 
            </div>
        </div>`,
      bodyFooterText: `<div>Please do not reply to this email with your password. We will never ask for your password, and we strongly discourage you from sharing it with anyone.</div>`,
    };
    // eslint-disable-next-line no-unused-vars
    const emailTemplate = EmailTemplate(emailBodyConfig);
  };

  function shouldCloseInfoModal(navigationValue) {
    setInfoModalTitle("");
    setInfoModalMessage("");
    setInfoModalStatus("");
    setInfoModalNavigateLink("");
    toggleInfoModal(false);
    if (navigationValue) {
      history.push(navigationValue);
    }
  }

  const successResponseGoogle = function (response) {
    let loginResponse = {
      data: {
        name: response.profileObj.name,
        email: response.profileObj.email,
        token: response.googleId,
        profileImage: response.profileObj.imageUrl,
      },
      source: "Google",
    };
    setThirdPartyResponse(loginResponse);
  };

  const failureResponseGoogle = function (response) {
    if (response.error !== "idpiframe_initialization_failed") {
      setLoginError(
        "Sorry there was a problem with your google login request."
      );
    }
  };

  const responseFacebook = (response) => {
    if (response && response.userID) {
      let loginResponse = {
        isLogginSuccess: true,
        data: response,
        source: "Facebook",
      };
      setThirdPartyResponse(loginResponse);
    } else {
      setLoginError(
        "Sorry there was a problem with your facebook login request."
      );
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setLoginError(null);
    setloginCred({ ...loginCred, [prop]: event.target.value });
  };

  const showPassword = () => {
    setloginCred({
      ...loginCred,
      showPassWord: loginCred.showPassWord ? false : true,
    });
  };

  const checkForUserPhone = (phone) => {
    try {
      return new Promise((res, rej) => {
        getUserByPhone(phone).subscribe((data) => {
          if (data && data.length) res(data);
          else res(null);
        });
      });
    } catch (e) {
      console.log("check phone error: ", e);
    }
  };

  const checkForUserEmail = (email) => {
    try {
      return new Promise((res, rej) => {
        getUserByEmail(email).subscribe((data) => {
          if (data && data.length) res(data);
          else res(null);
        });
      });
    } catch (e) {
      console.log("check user email erro: ", e);
    }
  };

  const getUserLoginData = (userData) => {
    togglePageLoader(true);
    try {
      return new Promise((res, rej) => {
        if (thirdPartyResponse.data && thirdPartyResponse.data.email) {
          // with gmail or fb flow
          getUserByEmail(userData.email).subscribe((isRegisteredUser) => {
            togglePageLoader(false);
            if (isRegisteredUser.length) {
              res(isRegisteredUser[0]);
            } else {
              setLoginError("Please enter correct credentials.");
              rej({ ...userData, isRegistered: false });
            }
          });
        } else {
          // without gmail fb flow
          checkForUserEmail(userData.emailOrPhone).then((isRegisteredUser) => {
            togglePageLoader(false);
            if (isRegisteredUser && isRegisteredUser.length) {
              if (isRegisteredUser[0].password === userData.password)
                res(isRegisteredUser[0]);
              else {
                setLoginError("Please enter correct credentials.");
                rej();
              }
            } else {
              checkForUserPhone(userData.emailOrPhone).then(
                (isRegisteredUser) => {
                  togglePageLoader(false);
                  if (isRegisteredUser && isRegisteredUser.length) {
                    if (isRegisteredUser[0].password === userData.password)
                      res(isRegisteredUser[0]);
                    else {
                      setLoginError("Please enter correct credentials.");
                      rej();
                    }
                  } else {
                    setLoginError("Please enter correct credentials.");
                    rej();
                  }
                }
              );
            }
          });
        }
      });
    } catch (e) {
      togglePageLoader(false);
      console.log("user login data error: ", e);
    }
  };

  function getUsersVideoList(userKey) {
    if (userKey) {
      togglePageLoader(true);
      try {
        getUploadedVideosByUserId(userKey).subscribe((list) => {
          togglePageLoader(false);
          dispatch(getUploadedVideosByUser(list));
        });
      } catch (e) {
        togglePageLoader(false);
        console.log("Video fetch error: ", e);
      }
    }
  }

  const signinUser = (e, status) => {
    togglePageLoader(true);
    setLoginError(null);
    let userData = {};
    switch (status) {
      case "cred":
        setThirdPartyResponse({
          isLogginSuccess: false,
          data: null,
          source: "",
        });
        e.preventDefault();
        e.stopPropagation();
        userData = {
          ...loginCred,
          phone: loginCred.username,
          email: loginCred.username,
          emailOrPhone: loginCred.username,
        };
        getUserLoginData(userData)
          .then((data) => {
            //user is registered
            getUsersVideoList(data.key);
            togglePageLoader(false);
            data.source = "Website";
            dispatch(loginUser(data));
            dispatch(
              displayNotification({
                msg: "Login successfully",
                type: NOTIFICATION_SUCCCESS,
                time: 3000,
              })
            );
            if (
              state.currentLoginFlow === "competition" ||
              state.currentLoginFlow === "competition-event"
            ) {
              //event-competition ==> event click from compitition page
              history.push("/events");
            } else if (state.currentLoginFlow === "subscription") {
              history.push("/subscription");
            } else if (state.currentLoginFlow === "lessons") {
              history.push("/lessons");
            } else if (state.currentLoginFlow === "upload-video") {
              if (
                state.userVideosList &&
                state.userVideosList.length < VIDEO_LIMIT_COUNT.monthly
              ) {
                setOpenVdoUploadModal(true);
              } else {
                setVideoLimitParameters();
              }
            } else if (state.currentLoginFlow === "live-events") {
              history.push("/live-events");
            } else if (state.currentLoginFlow.includes("/studios")) {
              history.push(state.currentLoginFlow);
            } else {
              history.push("/lessons");
            }
          })
          .catch((data) => {
            togglePageLoader(false);
            if (data) {
              //user not registered
              history.push({
                pathname: "/register",
                state: data,
              });
            }
          });
        break;
      case "Google":
      case "Facebook":
        userData = {
          name: thirdPartyResponse.data.name,
          email: thirdPartyResponse.data.email,
        };
        getUserLoginData(userData)
          .then((data) => {
            //user is registered
            getUsersVideoList(data.key);
            togglePageLoader(false);
            data.source = thirdPartyResponse.source;
            dispatch(loginUser(data));
            dispatch(
              displayNotification({
                msg: "Login successfully",
                type: NOTIFICATION_SUCCCESS,
                time: 3000,
              })
            );
            if (
              state.currentLoginFlow === "competition" ||
              state.currentLoginFlow === "competition-event"
            ) {
              history.push("/events");
            } else if (state.currentLoginFlow === "subscription") {
              history.push("/subscription");
            } else if (state.currentLoginFlow === "lessons") {
              history.push("/lessons");
            } else if (state.currentLoginFlow === "upload-video") {
              if (
                state.userVideosList &&
                state.userVideosList.length < VIDEO_LIMIT_COUNT.monthly
              ) {
                setOpenVdoUploadModal(true);
              } else {
                setVideoLimitParameters();
                history.push("/profile");
              }
            } else if (state.currentLoginFlow === "live-events") {
              history.push("/live-events");
            } else {
              history.push("/lessons");
            }
          })
          .catch((data) => {
            togglePageLoader(false);
            if (data) {
              data.source = thirdPartyResponse.source;
              //user not registered
              history.push({
                pathname: "/register",
                state: data,
              });
            }
          });
        break;
      default:
        break;
    }
  };

  function setVideoLimitParameters() {
    setInfoModalMessage(
      `You have reached your maximum video upload limit of ${VIDEO_LIMIT_COUNT.monthly}, please delete some videos to upload another one!`
    );
    setInfoModalStatus("error");
    toggleInfoModal(true);
    setInfoModalNavigateLink("/profile");
  }

  function goToPrevious(event) {
    event.stopPropagation();
    event.preventDefault();
    props.backToHome("login");
    history.goBack();
  }

  function togglePwdResetLayer(action) {
    if (loginCred.username.includes("@") && action) {
      setResetEmail(loginCred.username);
    } else {
      setResetEmail("");
    }
    if (!action) {
      history.replace(window.location.pathname);
    }
    toggleResetLink(action);
    if (isResetPasswordViewOpen) {
      toggleResetPwdModal(false);
    }
  }

  const sendEmailBeforePasswordChange = (userDetails, resetLink) => {
    togglePageLoader(true);
    try {
      return new Promise((resolve, reject) => {
        const { email, name } = userDetails;
        const emailBodyConfig = {
          heading: `Hi ${name}, need to reset your password?`,
          content: `
                    <div>No problem! just click the link bellow and you'll be on your way. If you did not make this request, please ignore this email.</div>
                    <div class="action-btn-wrap">
                        <a class="action" href=${resetLink}>Reset Password</a> 
                    </div>`,
          bodyFooterText: `<div>Or, to get new password reset link visit:
                <a href=${window.location.href}>New Reset Password Link</a>
            </div>`,
        };
        let payload = {
          mailTo: email,
          title: "Da - Reset Password",
          content: EmailTemplate(emailBodyConfig),
        };
        sendEmail(payload).subscribe((res) => {
          togglePageLoader(false);
          if (!("error" in res)) {
            console.log("User Email Send Successfully.");
            resolve();
          } else {
            console.log("User Email Send Failed.");
            togglePageLoader(false);
            reject();
          }
        });
      });
    } catch (e) {
      togglePageLoader(false);
      console.log("email to user error: ", e);
    }
  };

  const sendEmailAfterPasswordChange = (email) => {
    try {
      return new Promise((resolve, reject) => {
        const loginLink = window.location.href;
        const emailBodyConfig = {
          heading: `Hello, Da User we wanted to let you know that your password was changed.`,
          content: `<div>
                    <p>To access your account just click the link bellow and try to login using your new password.</p>
                    <div class="action-btn-wrap">
                        <a class="action" href=${loginLink}>Login</a> 
                    </div>
                </div>`,
          bodyFooterText: `<div>Please do not reply to this email with your password. We will never ask for your password, and we strongly discourage you from sharing it with anyone.</div>`,
        };
        let payload = {
          mailTo: email,
          title: "Your password was changed successfully",
          content: EmailTemplate(emailBodyConfig),
        };
        sendEmail(payload).subscribe((res) => {
          if (!("error" in res)) {
            console.log("User Email Send Successfully.");
            resolve();
          } else {
            console.log("User Email Send Failed.");
            reject();
          }
        });
      });
    } catch (e) {
      togglePageLoader(false);
      console.log("email to user error: ", e);
    }
  };

  function onResetInputChange(event) {
    event.stopPropagation();
    setEmailVerificationMessage("");
    toggleEmailVerifyClass("");
    setResetEmail(event.target.value);
  }

  function sendResetEmailLink() {
    if (!validateEmailId(resetEmail)) {
      setEmailVerificationMessage("Enter valid email id!");
      toggleEmailVerifyClass("error");
    } else {
      setEmailVerificationMessage("");
      toggleEmailVerifyClass("");
      try {
        toggleEmailSentLoader(true);
        if (sendEmailBtnRef.current) {
          sendEmailBtnRef.current.disabled = true;
        }
        checkForUserEmail(resetEmail).then((isRegisteredUser) => {
          if (isRegisteredUser && isRegisteredUser.length !== 0) {
            const resetLinkCode = Math.random().toString(36).substring(2); //generate dynamic string for link identification
            let resetLink = "";
            if (window.location.href.includes("isResetByMobile")) {
              resetLink =
                window.location.href + "&" + restLinkUrlQuery + resetLinkCode;
            } else {
              resetLink =
                window.location.href + "?" + restLinkUrlQuery + resetLinkCode;
            }
            const resetData = {
              email: resetEmail,
              userId: isRegisteredUser[0].key,
              tokenId: resetLinkCode,
              resetTime: new Date(),
            };
            try {
              registerResetToken(resetData).subscribe((resp) => {
                sendEmailBeforePasswordChange(isRegisteredUser[0], resetLink)
                  .then(() => {
                    toggleEmailSentLoader(false);
                    if (sendEmailBtnRef.current) {
                      sendEmailBtnRef.current.disabled = false;
                    }
                    dispatch(
                      displayNotification({
                        msg: `Reset link send to ${resetEmail}`,
                        type: NOTIFICATION_SUCCCESS,
                        time: 3000,
                      })
                    );
                  })
                  .catch(() => {
                    //email sending failed so do nothing
                    toggleEmailSentLoader(false);
                    if (sendEmailBtnRef.current) {
                      sendEmailBtnRef.current.disabled = false;
                    }
                    dispatch(
                      displayNotification({
                        msg: "Reset link sending failed",
                        type: NOTIFICATION_ERROR,
                        time: 3000,
                      })
                    );
                  });
              });
            } catch (e) {
              toggleEmailSentLoader(false);
              if (sendEmailBtnRef.current) {
                sendEmailBtnRef.current.disabled = false;
              }
              dispatch(
                displayNotification({
                  msg: "Something went wrong with the network, please try in sometime!",
                  type: NOTIFICATION_ERROR,
                  time: 5000,
                })
              );
              console.log("send reset email error: ", e);
            }
          } else {
            //user not registered
            togglePageLoader(false);
            if (sendEmailBtnRef.current) {
              sendEmailBtnRef.current.disabled = false;
            }
            dispatch(
              displayNotification({
                msg: "Seems like you not registered yet, please register!",
                type: NOTIFICATION_ERROR,
                time: 3000,
              })
            );
            history.push("register");
          }
        });
      } catch (e) {
        togglePageLoader(false);
        console.log("Error in sending reset link: ", e);
      }
    }
  }

  function verifyChangeEmail(email, type) {
    if (type === "email") {
      if (!validateEmailId(email)) {
        if (newPwdFirstInputRef.current && newPwdConfirmInputRef.current) {
          newPwdFirstInputRef.current.disabled = true;
          newPwdConfirmInputRef.current.disabled = true;
        }
        if (email) {
          toggleEmailVerifyClass("error");
          setConfirmPwdMessage("Please enter valid email id!");
        } else {
          toggleEmailVerifyClass("");
          setConfirmPwdMessage("");
        }
      } else {
        toggleEmailVerifyClass("");
        setConfirmPwdMessage("");
        if (newPwdFirstInputRef.current && newPwdConfirmInputRef.current) {
          newPwdFirstInputRef.current.disabled = false;
          newPwdConfirmInputRef.current.disabled = false;
        }
      }
    }
  }

  function resetNewPassword(newPwd, type) {
    if (type === "new") {
      setResetPassword({ ...resetPassword, password: newPwd });
    } else {
      setResetPassword({ ...resetPassword, confirmPassword: newPwd });
    }
  }

  function updateUserPassword() {
    if (userChangePwdEmail.current && userChangePwdEmail.current.value) {
      if (!validateEmailId(userChangePwdEmail?.current?.value)) {
        toggleEmailVerifyClass("error");
        setConfirmPwdMessage("Please enter valid email for verification!");
      } else if (resetPassword.password && resetPassword.confirmPassword) {
        if (resetPassword.password === resetPassword.confirmPassword) {
          let resetCodeFromUrl = "";
          const urlString = window.location.search;
          let paramString = urlString.split("?")[1];
          let queryString = new URLSearchParams(paramString);

          for (let pair of queryString.entries()) {
            if (pair[0] && pair[0] === "reset-password") {
              resetCodeFromUrl = pair[1];
            }
          }
          toggleEmailSentLoader(true);
          toggleEmailVerifyClass("");
          setConfirmPwdMessage("");
          if (changePwdBtnRef.current) {
            changePwdBtnRef.current.disabled = true;
          }
          try {
            getResetTokenData(resetCodeFromUrl).subscribe((resp) => {
              if (resp && resp?.key) {
                if (resp.email === userChangePwdEmail?.current?.value) {
                  try {
                    updatePassword(
                      resp?.userId,
                      resetPassword.password
                    ).subscribe(() => {
                      //password reset success
                      sendEmailAfterPasswordChange(resp.email);
                      if (changePwdBtnRef.current) {
                        changePwdBtnRef.current.disabled = false;
                      }
                      //remove reset password code from url
                      removeResetTokenData(resp.key).subscribe((resp) => {
                        toggleResetPwdModal(false);
                        console.log("token removed succefully!");
                        history.replace("/login");
                      });
                      toggleEmailSentLoader(false);
                      dispatch(
                        displayNotification({
                          msg: "Password changed successfully",
                          type: NOTIFICATION_SUCCCESS,
                          time: 3000,
                        })
                      );
                      //to login with new password redirect to login screen
                    });
                  } catch (e) {
                    toggleEmailSentLoader(false);
                    if (changePwdBtnRef.current) {
                      changePwdBtnRef.current.disabled = false;
                    }
                    dispatch(
                      displayNotification({
                        msg: "Something went wrong, please try again in sometime!",
                        type: NOTIFICATION_ERROR,
                        time: 3000,
                      })
                    );
                    console.log("password change error: ", e);
                  }
                } else {
                  toggleEmailSentLoader(false);
                  if (changePwdBtnRef.current) {
                    changePwdBtnRef.current.disabled = false;
                  }
                  dispatch(
                    displayNotification({
                      msg: "Email didn't match, please enter correct email!",
                      type: NOTIFICATION_ERROR,
                      time: 5000,
                    })
                  );
                }
              } else {
                toggleEmailSentLoader(false);
                if (changePwdBtnRef.current) {
                  changePwdBtnRef.current.disabled = false;
                }
                dispatch(
                  displayNotification({
                    msg: "You haven't created reset link yet, please create and then try!",
                    type: NOTIFICATION_ERROR,
                    time: 5000,
                  })
                );
              }
            });
          } catch (e) {
            toggleEmailSentLoader(false);
            console.log("email verification error: ", e);
          }
        } else {
          toggleEmailVerifyClass("error");
          setConfirmPwdMessage("Password should match!");
        }
      } else {
        toggleEmailVerifyClass("error");
        setConfirmPwdMessage("Please enter password and confirm password!");
      }
    } else {
      toggleEmailVerifyClass("error");
      setConfirmPwdMessage("Please enter email for verification!");
    }
  }

  function showResetPassword(type) {
    if (type === "reset") {
      if (newPwdFirstInputRef.current) {
        if (newPwdFirstInputRef.current.type === "password") {
          toggleResetPwd(false);
          newPwdFirstInputRef.current.type = "text";
        } else {
          toggleResetPwd(true);
          newPwdFirstInputRef.current.type = "password";
        }
      }
    } else {
      if (newPwdConfirmInputRef.current) {
        if (newPwdConfirmInputRef.current.type === "password") {
          toggleConfirmResetPwd(false);
          newPwdConfirmInputRef.current.type = "text";
        } else {
          toggleConfirmResetPwd(true);
          newPwdConfirmInputRef.current.type = "password";
        }
      }
    }
  }

  return (
    <div className="login-wrap new-login-signup-ui phase1 clearfix gradient-bg-animation darkMode">
      {isPageLoaderActive ? <Loader /> : ""}
      <div className="inner-signup-wrap">
        <div className="leftSection img-wrap">
          <div className="titleWrap">
            <a href="/" className="logo" title="Back to Home">
              <img src={boogaluLogo} alt="Da" />
            </a>
            <h1>Welcome back</h1>
            <h3>Login to your existing Da account.</h3>
          </div>
        </div>
        <div className="rightSection">
          <div className="innerRightSection">
            <a href="/" className="logo" title="Back to Home">
              <img src={boogaluLogo} alt="Da" />
            </a>
            <div className="header-outer">
              <i
                onClick={(e) => goToPrevious(e)}
                className="arrow-back-home"
                title="Back"
              >
                <ArrowBackIcon />
              </i>
            </div>
            <form
              className="form-wrap clearfix"
              onSubmit={(e) => signinUser(e, "cred")}
            >
              <div className="heading-outer">
                <h2 className="heading1">Log In</h2>
                <div className="heading2">
                  Follow the instructions to make it easier to login and you
                  will be able to explore inside..
                </div>
              </div>
              <div className="form-outer clearfix">
                <div className="input-wrap">
                  <i className="labelIcons">
                    <BiUser />
                  </i>
                  <TextField
                    className="input-field"
                    required
                    id="outlined-required-username"
                    label="Email / Phone"
                    onChange={handleChange("username")}
                    value={loginCred.username}
                    variant="outlined"
                  />
                </div>
                <div className="input-wrap passwordInput">
                  <i className="labelIcons">
                    <BiLockOpen />
                  </i>
                  <TextField
                    className="input-field"
                    required
                    id="outlined-adornment-password"
                    label="Password"
                    type={loginCred.showPassWord ? "text" : "password"}
                    onChange={handleChange("password")}
                    value={loginCred.password}
                    variant="outlined"
                  />
                  <div className="showHideBtn">
                    <IconButton
                      title="Show/hide password"
                      aria-label="toggle password visibility"
                      onClick={showPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {loginCred.showPassWord ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div className="action-wrap">
                  <div
                    title="Forgot password"
                    className="forgot-password clearfix"
                    onClick={() => togglePwdResetLayer(true)}
                  >
                    <div>Forgot Password ?</div>
                  </div>
                  <div className="submit-btn clearfix">
                    <Button variant="contained" type="submit" color="primary">
                      Log In
                      <ArrowRightSharpIcon />
                    </Button>
                  </div>
                </div>
                {LoginError && <div className="login-error">{LoginError}</div>}
                <div className="or-seprator clearfix">
                  <div>
                    <span>Or Login with</span>
                  </div>
                </div>
                <div className="login-with">
                  <div className="login-with-google">
                    <GoogleLogin
                      className="google-login-btn"
                      clientId="417866547364-mesv7a9cn6bj4n3ge45s8b6hhl1vdam0.apps.googleusercontent.com"
                      buttonText="Google"
                      onSuccess={successResponseGoogle}
                      onFailure={failureResponseGoogle}
                    ></GoogleLogin>
                  </div>
                  <div className="login-with-fb">
                    <div className="login-with-fb">
                      <FacebookLogin
                        appId="813330422546108"
                        autoLoad={false}
                        textButton="Facebook"
                        fields="name,email,picture"
                        callback={responseFacebook}
                        cssClass="facebook-login-btn"
                        icon={<FaFacebook />}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="singupLink">
                New to Da?{" "}
                <Link
                  to="/register"
                  className="registerLink"
                  title="Register to Da"
                >
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
          {isResetClicked ? (
            <div className="forgotPwdBox">
              <div className="logoWrap">
                <img src={boogaluLogo} alt="Da" />
              </div>
              <p
                className="closeModal"
                onClick={() => togglePwdResetLayer(false)}
              ></p>
              <h2>Forgot password?</h2>
              <h3>
                Please enter your email ID you will receive reset password link
              </h3>
              {isEmailSent ? <div className="loader"></div> : ""}
              <div className="inputWrap">
                <input
                  className={`resetInput ${emailVerifyClass}`}
                  type="email"
                  placeholder="Enter valid email id"
                  value={resetEmail}
                  onChange={(e) => onResetInputChange(e)}
                  title="Email"
                />
                <div className="actions">
                  <button
                    className="emailLinkBtn cancelBtn"
                    onClick={() => togglePwdResetLayer(false)}
                    title="Close the modal"
                  >
                    <label>
                      <span>Cancel</span>
                    </label>
                  </button>
                  <button
                    className="emailLinkBtn"
                    ref={sendEmailBtnRef}
                    onClick={() => sendResetEmailLink()}
                    title="send link to email"
                  >
                    <label>
                      <span>Submit</span>
                    </label>
                  </button>
                </div>
              </div>
              <p className={`emailVerifyMessage ${emailVerifyClass}`}>
                {emailVerifyMessage}
              </p>
            </div>
          ) : (
            ""
          )}
          {isResetPasswordViewOpen ? (
            <div className="forgotPwdBox resetPwdBox">
              <div className="logoWrap">
                <img src={boogaluLogo} alt="Da" />
              </div>
              <p
                className="closeModal"
                onClick={() => togglePwdResetLayer(false)}
              ></p>
              <h2>Reset your password</h2>
              <h3>Create your new password</h3>
              {isEmailSent ? <div className="loader"></div> : ""}
              <div className="inputWrap">
                <input
                  type="email"
                  ref={userChangePwdEmail}
                  placeholder="Enter email for verification"
                  tabIndex="5"
                  onBlur={(e) => verifyChangeEmail(e.target.value, "email")}
                  title="email verification"
                />
                <div className="resetPwd">
                  <input
                    type="password"
                    ref={newPwdFirstInputRef}
                    placeholder="New password"
                    tabIndex="6"
                    value={resetPassword.password}
                    onChange={(e) => resetNewPassword(e.target.value, "new")}
                    title="New password"
                  />
                  <div className="showHideBtn">
                    <IconButton
                      title="Show/hide password"
                      aria-label="toggle password visibility"
                      onClick={() => showResetPassword("reset")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isResetPwdVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </div>
                <div className="resetPwd">
                  <input
                    type="password"
                    ref={newPwdConfirmInputRef}
                    tabIndex="7"
                    placeholder="Confirm password"
                    value={resetPassword.confirmPassword}
                    onChange={(e) =>
                      resetNewPassword(e.target.value, "confirm")
                    }
                    title="Confirm password"
                  />
                  <div className="showHideBtn">
                    <IconButton
                      title="Show/hide password"
                      aria-label="toggle password visibility"
                      onClick={() => showResetPassword("confirm")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isResetConfirmPwdVisible ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div className="actions">
                  <button
                    className="emailLinkBtn cancelBtn"
                    onClick={() => togglePwdResetLayer(false)}
                    title="Close the modal"
                  >
                    <label>
                      <span>Cancel</span>
                    </label>
                  </button>
                  <button
                    className="emailLinkBtn"
                    ref={changePwdBtnRef}
                    tabIndex="8"
                    onClick={() => updateUserPassword()}
                    title="send link to email"
                  >
                    <label>
                      <span>Reset password</span>
                    </label>
                  </button>
                </div>
              </div>
              <p className={`emailVerifyMessage ${emailVerifyClass}`}>
                {confirmPasswordMessage}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {openVdoUploadModal ? (
        <VideoUploader handleClose={() => setOpenVdoUploadModal(false)} />
      ) : (
        ""
      )}
      {openInformationModal ? (
        <GenericInfoModal
          title={infoModalTitle}
          message={infoModalMessage}
          status={infoModalStatus}
          navigateUrl={navigateLink}
          closeInfoModal={shouldCloseInfoModal}
        />
      ) : (
        ""
      )}
    </div>
  );
}
