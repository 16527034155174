/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable valid-typeof */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import {
  OFFLINE_STUDIO_LOCATION,
  OFFLINE_STUDIO_ADVANCED_FILTER,
  OPENING_HOURS,
} from "../../Constants";

import { getPostOfficeDataByPincode } from "../../Services/PostalCodes.service";
import { uploadImage, uploadVideo } from "../../Services/Upload.service";
import {
  createOfflineStudio,
  updateOfflineStudio,
} from "../../Services/OfflineStudios.service";

import CloseIcon from "@material-ui/icons/Close";

import OpeningHours from "./OpeningHours";
import UploadComponent from "./UploadComponent";
import PackagesComponent from "./PackagesComponent";
import AdvancedFilterComponent from "./AdvancedFilterComponent";
import { ArrowBackIos, ArrowDropDownCircleOutlined } from "@material-ui/icons";

const studioDataDetails = {
  location: { required: true, valid: false, value: "" },
  advancedFilter: {
    required: true,
    valid: false,
    value: OFFLINE_STUDIO_ADVANCED_FILTER,
  },
  name: { required: true, valid: false, value: "" },
  addressLineOne: { required: true, valid: false, value: "" },
  addressLineTwo: { required: true, valid: false, value: "" },
  addressPincode: { required: true, valid: false, value: "" },
  timings: { required: true, valid: false, value: [] },
  membershipFee: { required: false, valid: true, value: "" },
  membershipMonth: { required: false, valid: true, value: "1" },
  membershipOffer: { required: false, valid: true, value: "" },
  membershipDesc: { required: false, valid: true, value: "" },
  photos: { required: false, valid: true, value: [] },
  videos: { required: false, valid: true, value: [] },
  phoneNumber: { required: false, valid: true, value: "" },
  emailAddress: { required: false, valid: true, value: "" },
  termsAndConditions: { required: false, valid: true, value: "" },
  isActive: { required: false, valid: true, value: true },
  isAdmissionOpen: { required: false, valid: true, value: true },
};

const OfflineStudioForm = (props) => {
  const { data, isEditEnabled } = props;
  const offlineStudioImageUpload = [
    {
      name: "previewImage",
      value: null,
      validValue: null,
      isUploading: false,
      uploadProgress: 0,
      allowedFileSize: "2 MB",
      fileSize: 2000000,
      ref: useRef(),
      acceptFileType: "image/*",
      required: false,
      fileType: "image",
      label: "Preview Image",
    },
    {
      name: "imageOne",
      value: null,
      validValue: null,
      isUploading: false,
      uploadProgress: 0,
      allowedFileSize: "5 MB",
      fileSize: 5000000,
      ref: useRef(),
      acceptFileType: "image/*",
      required: false,
      fileType: "image",
      label: "Image One",
    },
    {
      name: "imageTwo",
      value: null,
      validValue: null,
      isUploading: false,
      uploadProgress: 0,
      allowedFileSize: "5 MB",
      fileSize: 5000000,
      ref: useRef(),
      acceptFileType: "image/*",
      required: false,
      fileType: "image",
      label: "Image Two",
    },
    {
      name: "imageThree",
      value: null,
      validValue: null,
      isUploading: false,
      uploadProgress: 0,
      allowedFileSize: "5 MB",
      fileSize: 5000000,
      ref: useRef(),
      acceptFileType: "image/*",
      required: false,
      fileType: "image",
      label: "Image Three",
    },
  ];

  const offlineStudioVideoUpload = [
    {
      name: "introVideo",
      value: null,
      validValue: null,
      isUploading: false,
      uploadProgress: 0,
      allowedFileSize: "520 MB",
      fileSize: 520000000,
      ref: useRef(),
      acceptFileType: "video/mp4,video/x-m4v,video/*",
      required: false,
      fileType: "video",
      label: "Intro Video",
    },
  ];

  // Offline Studio Video Size 520000000 Bytes
  const [selectedFormData, setSelectedFormData] = useState(studioDataDetails);
  const [locations, setLocations] = useState(OFFLINE_STUDIO_LOCATION);
  const [advancedFilter, setAdvancedFilter] = useState(
    OFFLINE_STUDIO_ADVANCED_FILTER
  );
  const [postalPincodeData, setPostalPincodeData] = useState([]);

  const [offlineStudioImagesToUpload, setOfflineStudioImagesToUpload] =
    useState(offlineStudioImageUpload);
  const [offlineStudioVideosToUpload, setOfflineStudioVideosToUpload] =
    useState(offlineStudioVideoUpload);

  const [messageClass, setFormMessageClass] = useState("");
  const [formMessageBox, setFormMessage] = useState("");
  const [btnLoadingClass, toggleBtnLoadingClass] = useState("");
  const [disableUploadButton, setUploadButtonState] = useState(true);

  const [openingHours, setOpeningHours] = useState(OPENING_HOURS);
  const [openingHoursForCategory, setOpeningHoursForCategory] = useState({});

  const [isFormLoaded, toggleFormLoader] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const handleChange = (prop, index) => (event) => {
    event.stopPropagation();
    let value =
      prop === "isLocal" || prop === "isActive" || prop === "isAdmissionOpen"
        ? event.target.checked
        : event.target.value;
    if (prop === "addressPincode" && value && value.length > 0) {
      const validPincodeRegExp = new RegExp(/^(\d{6})$/);
      if (!validPincodeRegExp.test(value)) {
        setSelectedFormData({
          ...selectedFormData,
          [prop]: {
            ...selectedFormData[prop],
            value: value,
            valid: false,
            validField: false,
          },
        });
      } else {
        setSelectedFormData({
          ...selectedFormData,
          [prop]: {
            ...selectedFormData[prop],
            value: value,
            valid: true,
          },
        });
      }
    } else if (prop === "location" && value && value.length > 0) {
      setFormMessage("");
      setFormMessageClass("");
      setSelectedFormData({
        ...selectedFormData,
        [prop]: {
          ...selectedFormData[prop],
          value: value,
          valid: value && value.length > 0,
        },
        ["addressPincode"]: {
          ...selectedFormData["addressPincode"],
          value: "",
          valid: false,
          validField: false,
        },
      });
    } else {
      setSelectedFormData({
        ...selectedFormData,
        [prop]: {
          ...selectedFormData[prop],
          value: value,
          valid: value && value.length > 0,
        },
      });
    }
  };

  const onChangeFile = async (
    event,
    ref,
    view,
    data,
    parentArray,
    parentArrayFn
  ) => {
    event.stopPropagation();
    event.preventDefault();
    const currentElement = event.currentTarget;
    const file = event.target.files[0];
    const selectedElement = event.currentTarget.parentNode;
    const blobURL = URL.createObjectURL(file);
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    if (parentArray && parentArray.length > 0) {
      parentArray.forEach((item) => {
        if (item.name === data.name && item.validValue === null) {
          item.validValue = blobURL;
        }
      });
      parentArrayFn([parentArray].flat());
    } else {
      parentArray.validValue = blobURL;
      parentArrayFn({ ...parentArray, validValue: blobURL });
    }

    reader.onload = (e) => {
      const fileDataType = data?.fileType;
      if (fileDataType === "image") {
        setSelectedFormData({
          ...selectedFormData,
          ["photos"]: {
            ...selectedFormData["photos"],
            value: [
              ...selectedFormData.photos.value,
              { id: view, value: reader.result },
            ],
          },
        });
      } else {
        setSelectedFormData({
          ...selectedFormData,
          ["videos"]: {
            ...selectedFormData["videos"],
            value: [
              ...selectedFormData.videos.value,
              { id: view, value: reader.result },
            ],
          },
        });
      }
    };
    reader.onerror = (error) => console.error(error);
  };

  const imageUploadHandler = (selectedFormData, studioDataToBeSave) => {
    return new Promise((resolve, reject) => {
      selectedFormData["photos"].value.map((item, itemIndex) => {
        item.isUploading = true;
        uploadImage(item?.value, "offlineStudio", item?.id).subscribe(
          (response) => {
            if (response.donePercentage) {
              offlineStudioImagesToUpload.forEach((childItem) => {
                if (childItem.name === item.id) {
                  childItem.isUploading = true;
                  childItem.uploadProgress = response?.donePercentage;
                }
              });
              setOfflineStudioImagesToUpload(
                [offlineStudioImagesToUpload].flat()
              );
            }
            if (response.downloadURL) {
              delete studioDataToBeSave["photos"][itemIndex].isUploading;
              delete studioDataToBeSave["photos"][itemIndex].uploadProgress;
              delete studioDataToBeSave["photos"][itemIndex].value;
              studioDataToBeSave = {
                ...studioDataToBeSave,
                ["photos"]: [
                  ...studioDataToBeSave["photos"].slice(0, itemIndex),
                  {
                    ...studioDataToBeSave["photos"][itemIndex],
                    downloadURL: response.downloadURL,
                  },
                  ...studioDataToBeSave["photos"].slice(itemIndex + 1),
                ],
              };
              offlineStudioImagesToUpload.forEach((childItem) => {
                if (childItem.name === item.id) {
                  childItem.isUploading = false;
                  childItem.uploadProgress = 0;
                }
              });
              setOfflineStudioImagesToUpload(
                [offlineStudioImagesToUpload].flat()
              );
              if (
                selectedFormData["photos"].value.length ===
                studioDataToBeSave["photos"].length
              ) {
                const validStudioDataToBeSave = studioDataToBeSave[
                  "photos"
                ].filter(
                  (photo) => photo?.downloadURL && photo?.downloadURL !== ""
                );

                if (
                  validStudioDataToBeSave.length ===
                  studioDataToBeSave["photos"].length
                ) {
                  studioDataToBeSave.photos = validStudioDataToBeSave;
                  resolve(studioDataToBeSave);
                }
              } else {
                reject("error");
              }
            }
          }
        );
      });
    });
  };

  const videoUploadHandler = (selectedFormData, studioDataToBeSave) => {
    return new Promise((resolve, reject) => {
      selectedFormData["videos"].value.map((item, itemIndex) => {
        item.isUploading = true;
        uploadVideo(item?.value, "offlineStudio", item?.id).subscribe(
          (response) => {
            if (response.donePercentage) {
              offlineStudioVideosToUpload.forEach((childItem) => {
                if (childItem.name === item.id) {
                  childItem.isUploading = true;
                  childItem.uploadProgress = response?.donePercentage;
                }
              });
              setOfflineStudioVideosToUpload(
                [offlineStudioVideosToUpload].flat()
              );
            }
            if (response.downloadURL) {
              delete studioDataToBeSave["videos"][itemIndex].isUploading;
              delete studioDataToBeSave["videos"][itemIndex].uploadProgress;
              delete studioDataToBeSave["videos"][itemIndex].value;
              studioDataToBeSave = {
                ...studioDataToBeSave,
                ["videos"]: [
                  ...studioDataToBeSave["videos"].slice(0, itemIndex),
                  {
                    ...studioDataToBeSave["videos"][itemIndex],
                    downloadURL: response.downloadURL,
                  },
                  ...studioDataToBeSave["videos"].slice(itemIndex + 1),
                ],
              };
              offlineStudioVideosToUpload.forEach((childItem) => {
                if (childItem.name === item.id) {
                  childItem.isUploading = false;
                  childItem.uploadProgress = 0;
                }
              });
              setOfflineStudioVideosToUpload(
                [offlineStudioVideosToUpload].flat()
              );

              if (
                selectedFormData["videos"].value.length ===
                studioDataToBeSave["videos"].length
              ) {
                resolve(studioDataToBeSave);
              } else {
                reject("error");
              }
            }
          }
        );
      });
    });
  };

  const submitOfflineStudioForm = async () => {
    toggleBtnLoadingClass("loading");
    let studioDataToBeSave = {};
    let imagesArePresentToUpload = false;
    let videosArePresentToUpload = false;
    for (const [key, value] of Object.entries(selectedFormData)) {
      if (value?.value && typeof value.value === "array") {
        const newValue = [];
        value?.value.map((item) => {
          newValue.push(item);
        });
        studioDataToBeSave[key] = newValue;
      } else {
        studioDataToBeSave[key] = value?.value;
      }
      if (key === "name" && value?.value && value?.value.length > 0) {
        studioDataToBeSave["studioURL"] = value?.value
          .replace(/ /g, "-")
          .toLowerCase();
      }
    }
    if (
      selectedFormData["photos"] &&
      selectedFormData["photos"].value &&
      selectedFormData["photos"].value.length > 0
    ) {
      imagesArePresentToUpload = true;
    }

    if (
      selectedFormData["videos"] &&
      selectedFormData["videos"].value &&
      selectedFormData["videos"].value.length > 0
    ) {
      videosArePresentToUpload = true;
    }
    if (!isEditEnabled) {
      if (imagesArePresentToUpload && videosArePresentToUpload) {
        imageUploadHandler(selectedFormData, studioDataToBeSave).then(
          (imageUploadResponse) => {
            videoUploadHandler(selectedFormData, imageUploadResponse).then(
              (videoUploadResponse) => {
                createOfflineStudio(videoUploadResponse).subscribe(
                  (response) => {
                    toggleBtnLoadingClass("");
                  }
                );
              }
            );
          }
        );
      } else if (imagesArePresentToUpload) {
        imageUploadHandler(selectedFormData, studioDataToBeSave).then(
          (imageUploadResponse) => {
            createOfflineStudio(imageUploadResponse).subscribe((response) => {
              toggleBtnLoadingClass("");
            });
          }
        );
      } else if (videosArePresentToUpload) {
        videoUploadHandler(selectedFormData, studioDataToBeSave).then(
          (videoUploadResponse) => {
            createOfflineStudio(videoUploadResponse).subscribe((response) => {
              toggleBtnLoadingClass("");
            });
          }
        );
      } else {
        studioDataToBeSave.key = window.btoa(studioDataToBeSave?.key);
        createOfflineStudio(studioDataToBeSave).subscribe((response) => {
          toggleBtnLoadingClass("");
          setFormMessage("Studio created successfully");
          setTimeout(() => {
            setFormMessage("");
          }, 3000);
        });
      }
    } else {
      updateOfflineStudio(studioDataToBeSave).subscribe((response) => {
        toggleBtnLoadingClass("");
        setFormMessage("Studio updated successfully");
        setTimeout(() => {
          setFormMessage("");
        }, 3000);
      });
    }
  };

  useEffect(() => {
    const { valid, value, validField } = selectedFormData?.addressPincode;
    if (valid && !validField) {
      getPostOfficeDataByPincode(value).subscribe((res) => {
        const { PostOffice } = res;
        if (PostOffice && PostOffice?.length > 0) {
          PostOffice.map((item) => {
            if (
              selectedFormData?.location?.value ===
                item?.District.toLowerCase() ||
                selectedFormData?.location?.value === item?.Division.toLowerCase() ||
                selectedFormData?.location?.value === item?.Block.toLowerCase()
            ) {
              setFormMessage("");
              setFormMessageClass("");
              return setSelectedFormData({
                ...selectedFormData,
                ["addressPincode"]: {
                  ...selectedFormData["addressPincode"],
                  validField: true,
                },
              });
            } else {
              setFormMessage(
                `Pincode you have entered is not valid for the selected location : ${selectedFormData?.location?.value.toUpperCase()}`
              );
              setFormMessageClass("error");
              return setSelectedFormData({
                ...selectedFormData,
                ["addressPincode"]: {
                  ...selectedFormData["addressPincode"],
                  valid: false,
                },
              });
            }
          });
        } else {
          setFormMessage(
            `Pincode you have entered is not valid for the selected location : ${selectedFormData?.location?.value.toUpperCase()}`
          );
          setFormMessageClass("error");
          return setSelectedFormData({
            ...selectedFormData,
            ["addressPincode"]: {
              ...selectedFormData["addressPincode"],
              valid: false,
            },
          });
        }
      });
    }
  }, [selectedFormData]);

  useEffect(() => {
    if (openingHours && openingHours.length > 0) {
      setSelectedFormData({
        ...selectedFormData,
        timings: {
          ...selectedFormData.timings,
          valid: true,
          value: openingHours,
        },
      });
    } else {
      setSelectedFormData({
        ...selectedFormData,
        timings: {
          ...selectedFormData.timings,
          valid: false,
          value: [],
        },
      });
    }
  }, [openingHours]);

  useEffect(() => {
    if (
      openingHoursForCategory &&
      Object.keys(openingHoursForCategory).length > 0
    ) {
      const { parentNode, currentNode, data, parentIndex, currentIndex } =
        openingHoursForCategory;
      advancedFilter[parentIndex].types[currentIndex].expanded = true;
      setAdvancedFilter([advancedFilter].flat());
    }
  }, [openingHoursForCategory]);

  useEffect(() => {
    let requiredFieldCount = 0;
    let requiredFieldValidCount = 0;
    for (const item in selectedFormData) {
      if (selectedFormData[item].required) {
        requiredFieldCount++;
      }
      if (
        selectedFormData[item].required &&
        selectedFormData[item].valid &&
        selectedFormData[item].value &&
        selectedFormData[item].value.length > 0
      ) {
        requiredFieldValidCount++;
      }
    }
    if (requiredFieldCount === requiredFieldValidCount) {
      setUploadButtonState(false);
    } else {
      setUploadButtonState(true);
    }
  }, [selectedFormData]);

  const [openModal, setOpenModal] = useState(false);

  function handleOpenModal(e) {
    e.stopPropagation();
    setOpenModal(true);
    setTimeout(() => {
      toggleFormLoader(true);
    }, 1);
  }

  function handleCloseModal() {
    toggleFormLoader(false);
    setOpenModal(false);
  }

  const getDataFromAdvancedFilterModal = (data) => {
    delete data.selectedPackages;
    delete data.selectedTiming;
    const tempAdvancedFilterData = data;
    tempAdvancedFilterData.map((item) => {
      console.log("tempAdvancedFilterData item", item);
      if (item.valid) {
        item.types = item.types.filter((type) => type.valid);
      } else {
        item.types = [];
      }
    });
    console.log(
      "getDataFromAdvancedFilterModal tempAdvancedFilterData",
      tempAdvancedFilterData
    );
    setSelectedFormData({
      ...selectedFormData,
      advancedFilter: {
        required: true,
        valid: true,
        value: tempAdvancedFilterData,
      },
    });
    setSelectedData(tempAdvancedFilterData);
    handleCloseModal();
  };

  useEffect(() => {
    if (data && Object.entries(data).length > 0) {
      let updatedFormData = studioDataDetails;
      for (const [key, value] of Object.entries(data)) {
        updatedFormData = {
          ...updatedFormData,
          [key]: {
            ...selectedFormData[key],
            value: value,
            valid: true,
          },
        };
        if (key === "advancedFilter") {
          setSelectedData(value);
        }
        setSelectedFormData(updatedFormData);
      }
    }
  }, [data]);

  return (
    <div className="usersListWrap offlineStudioAdminWrap">
      <div className="inner-form-wrap">
        <div className="input-wrap input-wrap-full">
          <FormControl variant="outlined" className="input-field">
            <InputLabel id="location" required>
              Location
            </InputLabel>
            <Select
              required
              labelId="location"
              id="select-outlined"
              value={selectedFormData?.location?.value}
              onChange={handleChange("location")}
              label="Location"
            >
              {locations && locations.length
                ? locations.map((item, index) => {
                    return (
                      <MenuItem
                        value={item.value}
                        key={`${item.value}-${index}`}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </FormControl>
        </div>
        {/* <div className="input-wrap input-wrap-full">
          <Modal
            open={openModal}
            className="advancedFilterModal"
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            // onClose={() => handleClose(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            disableEnforceFocus
          >
            <Fade in={openModal}>
              <div className="advancedFilterInnerModal">
                <div className="advancedFilterInnerModalHeader">
                  <IconButton
                    className="close-modal-btn"
                    onClick={handleCloseModal}
                  >
                    <CloseIcon />
                  </IconButton>
                  <h3>Apply Advanced Filters</h3>
                </div>
                <div className="advancedFilterInnerModalBody">
                  <AdvancedFilterComponent
                    callbackHandler={getDataFromAdvancedFilterModal}
                  />
                </div>
              </div>
            </Fade>
          </Modal>
        </div> */}
        <div className="input-wrap">
          <div className="formControlHolder">
            <TextField
              className="input-field"
              required
              id="name"
              label="Name"
              variant="outlined"
              value={selectedFormData.name?.value}
              onChange={handleChange("name")}
            />
          </div>
          <div className="formControlHolder">
            <TextField
              className="input-field"
              required
              id="addressLineOne"
              label="Address"
              variant="outlined"
              value={selectedFormData.addressLineOne?.value}
              onChange={handleChange("addressLineOne")}
            />
          </div>
          <div className="formControlHolder">
            <TextField
              className="input-field"
              required
              id="addressLineTwo"
              label="Street"
              variant="outlined"
              value={selectedFormData.addressLineTwo?.value}
              onChange={handleChange("addressLineTwo")}
            />
          </div>
          <div className="formControlHolder">
            <FormControl variant="outlined" className="input-field">
              {selectedFormData?.location?.value &&
              selectedFormData?.location?.value.length > 0 ? (
                <>
                  <TextField
                    className="input-field"
                    required
                    type="number"
                    maxLength="6"
                    error={!selectedFormData.addressPincode?.valid}
                    id="addressPincode"
                    value={selectedFormData.addressPincode?.value}
                    label="Pincode"
                    variant="outlined"
                    onChange={handleChange("addressPincode")}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    // inputProps={{ pattern: "[0-9]{1,6}", maxLength: 6 }}
                  />
                  {/* <InputLabel id="addressPincode" required>
                    Pincode
                  </InputLabel>
                  <Select
                    required
                    labelId="addressPincode"
                    id="addressPincode"
                    value={selectedFormData.addressPincode?.value}
                    onChange={handleChange("addressPincode")}
                    label="Pincode"
                  >
                    {postalPincodeData && postalPincodeData.length > 0
                      ? postalPincodeData.map((item, index) => {
                          return (
                            <MenuItem
                              value={item.Pincode}
                              key={`${item.Pincode}-${index}`}
                            >
                              {item.Pincode}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>{" "} */}
                </>
              ) : (
                <TextField
                  className="input-field"
                  required
                  disabled
                  id="addressPincode"
                  label="Please select location first"
                  variant="outlined"
                />
              )}
            </FormControl>
          </div>
          <label className="membershipLabel">Membership Section</label>
          <div className="formControlHolder flexbox memberShipContainer zeroBottomMargin">
            <TextField
              className="input-field"
              id="membershipFee"
              label="Membership fee"
              variant="outlined"
              type="number"
              value={selectedFormData.membershipFee?.value}
              onChange={handleChange("membershipFee")}
            />
            <TextField
              className="input-field"
              id="membershipMonth"
              label="Month"
              variant="outlined"
              type="number"
              value={selectedFormData.membershipMonth?.value}
              onChange={handleChange("membershipMonth")}
            />
            <TextField
              className="input-field"
              id="membershipOffer"
              label="Offer(%)"
              variant="outlined"
              type="number"
              value={selectedFormData.membershipOffer?.value}
              onChange={handleChange("membershipOffer")}
            />
            <TextField
              className="input-field memberShipDescription"
              id="membershipDesc"
              label="Membership description"
              variant="outlined"
              value={selectedFormData.membershipDesc?.value}
              onChange={handleChange("membershipDesc")}
            />
          </div>
        </div>
        <div className="input-wrap">
          <OpeningHours
            data={openingHours}
            selectedTiming={data?.timings}
            // openingHours={openingHours}
            updatedTimingsData={setOpeningHours}
          />
        </div>
        <div className="input-wrap offlineStudioImageWrap">
          {offlineStudioImagesToUpload &&
            offlineStudioImagesToUpload.length > 0 &&
            offlineStudioImagesToUpload.map((item) => {
              return (
                <UploadComponent
                  data={item}
                  onChangeFile={onChangeFile}
                  parentArray={offlineStudioImagesToUpload}
                  parentArrayFn={setOfflineStudioImagesToUpload}
                />
              );
            })}
        </div>
        <div className="input-wrap offlineStudioVideoWrap">
          {offlineStudioVideosToUpload &&
            offlineStudioVideosToUpload.length > 0 &&
            offlineStudioVideosToUpload.map((item) => {
              return (
                <UploadComponent
                  data={item}
                  onChangeFile={onChangeFile}
                  parentArray={offlineStudioVideosToUpload}
                  parentArrayFn={setOfflineStudioVideosToUpload}
                />
              );
            })}
        </div>
        <div className="input-wrap advanceFilterWrap input-wrap-full">
          <InputLabel htmlFor="advancedFilter" required>
            Advanced Filter
          </InputLabel>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleOpenModal(e)}
          >
            {`${isEditEnabled ? "Update" : "Add"} Advance filter(s)`} (
            {selectedFormData.advancedFilter?.value.reduce(
              (a, b) => Number(a) + Number(b.types?.length),
              0
            )}
            )
          </Button>
        </div>
        <div className="input-wrap">
          <TextField
            className="input-field"
            id="phoneNumber"
            label="Phone Number"
            variant="outlined"
            value={selectedFormData.phoneNumber?.value}
            onChange={handleChange("phoneNumber")}
          />
        </div>
        <div className="input-wrap">
          <TextField
            className="input-field"
            id="emailAddress"
            label="Email Address"
            variant="outlined"
            value={selectedFormData.emailAddress?.value}
            onChange={handleChange("emailAddress")}
          />
        </div>
        <div className="input-wrap input-wrap-full lessonsDescriptionBox">
          <TextField
            className="textarea"
            id="termsAndConditions"
            variant="outlined"
            label="Terms And Conditions"
            multiline
            rowsMax={4}
            value={selectedFormData.termsAndConditions?.value}
            onChange={handleChange("termsAndConditions")}
          />
        </div>

        <p className={`messageWrap ${messageClass}`}>{formMessageBox}</p>
        <div className="upload-input-wrap button-container">
          <FormControlLabel
            className="active-checkbox"
            control={
              <Checkbox
                color="primary"
                checked={selectedFormData?.isActive?.value}
                onChange={handleChange("isActive")}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label="Active studio"
          />
          <Button
            className={btnLoadingClass}
            disabled={disableUploadButton}
            variant="contained"
            color="primary"
            onClick={() => submitOfflineStudioForm()}
          >
            {isEditEnabled ? "Update Offline Studio" : "Add Offline Studio"}
          </Button>
        </div>
      </div>

      {openModal ? (
        <div className="advancedFilterModal">
          <div className="advancedFilterInnerModal">
            <div className="advancedFilterInnerModalHeader">
              <IconButton
                className="close-modal-btn"
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
              <h3>Apply Advanced Filters</h3>
            </div>
            <div className="advancedFilterInnerModalBody">
              {isFormLoaded ? (
                <AdvancedFilterComponent
                  selectedData={selectedData}
                  callbackHandler={getDataFromAdvancedFilterModal}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default OfflineStudioForm;
