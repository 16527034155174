/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { OFFLINE_STUDIO_ADVANCED_FILTER } from "../../Constants";
import { Checkbox, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";


const STUDIO_FILTER_DATA = new Array(...OFFLINE_STUDIO_ADVANCED_FILTER);

const StudioFilterByOverlay = (props) => {
  const [filterData, setFilterData] = useState(STUDIO_FILTER_DATA);
  const [radioValue, setRadioValue] = useState(props.radioFilter);

  useEffect(() => {
    setRadioValue(props.radioFilter);
  }, [props.radioFilter]);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    let filterData = STUDIO_FILTER_DATA;
    switch(event.target.value) {
      case 'all':   filterData = new Array(...OFFLINE_STUDIO_ADVANCED_FILTER);
                    setFilterData(filterData)
                    break;
                  
      case 'onlyDance': filterData = new Array(OFFLINE_STUDIO_ADVANCED_FILTER[0]);
                        setFilterData(filterData)
                        break;
      case 'onlyGym':   filterData = new Array(OFFLINE_STUDIO_ADVANCED_FILTER[1]);
                        setFilterData(filterData)
                        break;
      default: break;
    }
  };

  function toggleOtherFilters(e, action) {
    props.filterAction(action);
  }

  function resetAppliedFilters(e, action) {
    props.filterAction(action, 'all');
    // eslint-disable-next-line array-callback-return
    OFFLINE_STUDIO_ADVANCED_FILTER.map((parentItem) => {
      parentItem.types.checked = false;
      parentItem.types.map((childItem) => {
        childItem.checked = false;
      });
      parentItem.selectedCount = 0;
    });
    setFilterData([OFFLINE_STUDIO_ADVANCED_FILTER].flat());
  }

  function filterStudios(e) {
    props.filterAction(false, radioValue, filterData);
  }

  function handleChildChange(e, type, itemIndex, parentNode) {
    const isChecked = e?.target.checked;
    let checkedChildren = [];
    // eslint-disable-next-line array-callback-return
    filterData.map((parentItem) => {
      if (parentItem.id === parentNode.id) {
        parentItem.types[itemIndex].checked = isChecked;
        checkedChildren = parentItem.types.filter(
          (childItem) => childItem.checked
        );
        parentItem.selectedCount = checkedChildren.length;
      }
    });
    setFilterData([filterData].flat());
  }

  return (
    <div className="otherFiltersWrap studioFiltersWrap">
      <div className="innerBox">
        <p
          className="close-modal-icon dark"
          onClick={(e) => toggleOtherFilters(e, false)}
          title="close filter"
        ></p>
        <h3>Filter by</h3>
        <div className="filterInnerWrap">
          <div className="mainRadioFilters">
            <RadioGroup
              aria-labelledby="dance-studio-filter"
              defaultValue="all"
              row
              name="advance-filter-group"
              className="radioGroupFilters"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel value="onlyDance" control={<Radio />} label="Dances" />
              <FormControlLabel value="onlyGym" control={<Radio />} label="Gyms" />
            </RadioGroup>
          </div>
          {filterData?.length
            ? filterData.map((parentNode) => {
                return (
                  <div className="filterItem" key={parentNode?.id}>
                    {parentNode?.types?.length ? (
                      <>
                        <p className="filterTitle">{parentNode?.label}</p>
                        <div className="optionsWrap">
                          {parentNode.types.map((childNode, childIndex) => {
                            return (
                              <FormControlLabel
                                key={childNode.id}
                                label={childNode.label}
                                control={
                                  <Checkbox
                                    checked={childNode?.checked}
                                    onChange={(event) =>
                                      handleChildChange(
                                        event,
                                        childNode,
                                        childIndex,
                                        parentNode
                                      )
                                    }
                                  />
                                }
                              />
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            : ""}
        </div>
        <div className="filterActionWrap">
          <button
            className="btn primary-light"
            onClick={(e) => resetAppliedFilters(e, false)}
          >
            Reset &amp; cancel
          </button>
          <button
            className="btn primary-dark"
            onClick={(e) => filterStudios(e)}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudioFilterByOverlay;
