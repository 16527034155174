import React, { useEffect, useState, useRef } from "react";
// eslint-disable-next-line no-unused-vars
import { sendEmail } from "../../Services/Email.service";
import boogaluLogo from "../../Images/da logo.png";
import { useHistory } from "react-router-dom";
import JudgesLogin from "./judgeslogin";
import JudgesPrivacyPolicy from "./judgesPrivacyPolicy";
import JudgesTermsUse from "./judgesTermsOfUse";
import { PRE_JUDGES_USER, PRE_JUDGES_PWD } from "../../Constants";
import {
  getCompetitionsList,
  updateCompetition,
  getCompetitionsByFilter,
} from "../../Services/EnrollCompetition.service";
import { getUserById } from "../../Services/User.service";
import RippleLoader from "../RippleLoader";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { getYearDifferenceInTwoDates } from "../../helpers";
import JudgesFilters from "./filters";

const checkJudgesLogin = JSON.parse(sessionStorage.getItem("preJudgesLogin"));
const checkPreRulesRead = JSON.parse(sessionStorage.getItem("preJudgeRules"));

function PreFinalRound() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  const [isJudgesLoggedIn, toggleJudgesLogin] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const [isPrivacyPolicyVisible, togglePrivacyPolicy] = useState(false);
  const [isTermsOfUseVisible, toggleTermsOfUse] = useState(false);
  const [shouldEnableVideoList, enableVideoLists] = useState(false);
  const [isVideosLoading, toggleRippleLoader] = useState(false);
  const [compeitionListData, setComptitionList] = useState([]);
  const [messageHandler, handleDataFectchMessages] = useState("");
  const [selectOrRejection, toggleSelectionRejectionText] = useState("");
  const [videoActionSelection, setVideoActionValue] = useState("");
  const [isVideoActionModalOpen, toggleVideoActionModal] = useState(false);
  const [contestantName, setContestantName] = useState("");
  const [competionForAction, setCompetitionActionValue] = useState(null);
  const [isFiltersVisible, toggleFilterVisibility] = useState(false);
  const [filterBtnElements, setFilterBtns] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [otherFilterOverlay, toggleOtherFilterOverlay] = useState(false);
  const [defaultNameFilter, setDefaultNameFilterValue] = useState("");

  const selectBtnRef = useRef(null);
  const rejectBtnRef = useRef(null);
  const submitBtnRef = useRef(null);
  const contestantCommentRef = useRef(null);
  const judgeNameInputRef = useRef(null);
  const judgeNameMessageRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const { REACT_APP_URL } = process.env;

  useEffect(() => {
    toggleJudgesLogin(checkJudgesLogin);
    if (checkPreRulesRead && checkPreRulesRead.termsAccepted) {
      enableVideoLists(true);
      if (filterStatus) {
        getFilteredCompetitionLists(filterStatus);
      } else {
        getEnrolledCompetitionList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function judgesLoginStatus(value) {
    if (value && !value?.user && !value?.pwd) {
      setLoginMessage("Enter username & Password!");
    } else if (
      value &&
      (value?.user !== PRE_JUDGES_USER || value?.pwd !== PRE_JUDGES_PWD)
    ) {
      setLoginMessage("Enter correct username & Password!");
    } else {
      setLoginMessage("");
      toggleJudgesLogin(true);
      sessionStorage.setItem("preJudgesLogin", true);
    }
  }

  function shouldShowPrivacyPolicy(action) {
    togglePrivacyPolicy(action);
  }

  function shouldShowTermsOfUse(action) {
    toggleTermsOfUse(action);
  }

  function showCompetitionVideos(event, stage) {
    event.stopPropagation();
    if (judgeNameInputRef.current && judgeNameInputRef.current.value) {
      const preJudgeData = {
        termsAccepted: true,
        stage: stage,
        judgeName: judgeNameInputRef.current.value,
      };
      sessionStorage.setItem("preJudgeRules", JSON.stringify(preJudgeData));
      enableVideoLists(true);
      getEnrolledCompetitionList();
    } else {
      if (judgeNameInputRef.current) {
        judgeNameInputRef.current.classList.add("shake");
        setTimeout(() => {
          judgeNameInputRef.current.classList.remove("shake");
        }, 500);
      }
      if (judgeNameMessageRef.current) {
        judgeNameMessageRef.current.classList.add("error");
        judgeNameMessageRef.current.innerText = "Please enter judge name!";
      }
    }
  }

  function getEnrolledCompetitionList() {
    toggleRippleLoader(true);
    handleDataFectchMessages("");
    try {
      getCompetitionsList().subscribe((response) => {
        if (response.length) {
          const compList = [];
          response.forEach((item) => {
            getUserById(item.userId).subscribe((resp) => {
              const compObj = {
                ...item,
                userDetails: JSON.parse(JSON.stringify(resp)),
              };
              compList.push(compObj);
              if (compList.length === response.length) {
                handleDataFectchMessages("");
                toggleRippleLoader(false);
                toggleFilterVisibility(true);
                setComptitionList(compList);
                const filterBtns = document.querySelectorAll(".js-filterBtn");
                setFilterBtns(filterBtns);
              }
            });
          });
        } else {
          setComptitionList([]);
          toggleRippleLoader(false);
          handleDataFectchMessages(
            "There are no videos uploaded for competition by Users!"
          );
        }
      });
    } catch (e) {
      toggleRippleLoader(false);
      handleDataFectchMessages(
        "Oops! something went wrong, please try in sometime!"
      );
      console.log("Competition list error: ", e);
    }
  }

  function getEnrolledCompetitionsByFilter(event, filterValue) {
    event.stopPropagation();
    handleDataFectchMessages("");
    if (filterValue && filterValue === "others") {
      toggleOtherFilterOverlay(true);
    } else {
      setFilterStatus(filterValue);
      if (filterBtnElements && filterBtnElements.length) {
        filterBtnElements.forEach((item) => {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          }
        });
      }
      event.target.classList.add("active");
      if (filterValue === "all") {
        getEnrolledCompetitionList();
      } else {
        toggleRippleLoader(true);
        getFilteredCompetitionLists(filterValue);
      }
    }
  }

  function getFilteredCompetitionLists(filterValue) {
    try {
      getCompetitionsByFilter(filterValue).subscribe((response) => {
        handleDataFectchMessages("");
        if (response.length) {
          const compList = [];
          response.forEach((item) => {
            getUserById(item.userId).subscribe((resp) => {
              const compObj = {
                ...item,
                userDetails: JSON.parse(JSON.stringify(resp)),
              };
              compList.push(compObj);
              if (compList.length === response.length) {
                toggleRippleLoader(false);
                setComptitionList(compList);
              } else {
                toggleRippleLoader(false);
                setComptitionList([]);
              }
            });
          });
        } else {
          setComptitionList([]);
          toggleRippleLoader(false);
          handleDataFectchMessages(
            "There are no competition videos matching the filter applied, change filter & try!"
          );
        }
      });
    } catch (e) {
      toggleRippleLoader(false);
      handleDataFectchMessages(
        "Oops! something went wrong, please try in sometime!"
      );
      console.log("Competition filter error: ", e);
    }
  }

  function getFiltersByOthers(filters) {
    toggleRippleLoader(true);
    try {
      getCompetitionsByFilter(filters).subscribe((response) => {
        if (response.length) {
          const compList = [];
          response.forEach((item) => {
            getUserById(item.userId).subscribe((resp) => {
              const compObj = {
                ...item,
                userDetails: JSON.parse(JSON.stringify(resp)),
              };
              compList.push(compObj);
              if (compList.length === response.length) {
                toggleRippleLoader(false);
                setComptitionList(compList);
              } else {
                toggleRippleLoader(false);
                setComptitionList([]);
              }
            });
          });
        } else {
          setComptitionList([]);
          toggleRippleLoader(false);
          handleDataFectchMessages(
            "There are no competition videos matching the filter applied, change filter & try!"
          );
        }
      });
    } catch (e) {
      toggleRippleLoader(false);
      console.log("error: ", e);
    }
  }

  function toggleUserVideoSelection(event, action) {
    event.stopPropagation();
    if (action && action === "select") {
      if (selectBtnRef.current && rejectBtnRef.current) {
        rejectBtnRef.current.classList.remove("active");
        selectBtnRef.current.classList.add("active");
        if (submitBtnRef.current && submitBtnRef.current.disabled) {
          submitBtnRef.current.disabled = false;
        }
        setVideoActionValue("Selected");
        toggleSelectionRejectionText("Selection");
      }
    } else {
      if (selectBtnRef.current && rejectBtnRef.current) {
        selectBtnRef.current.classList.remove("active");
        rejectBtnRef.current.classList.add("active");
        if (submitBtnRef.current && submitBtnRef.current.disabled) {
          submitBtnRef.current.disabled = false;
        }
        setVideoActionValue("Rejected");
        toggleSelectionRejectionText("Rejection");
      }
    }
  }

  function submitActionSelection(event) {
    event.stopPropagation();
    if (videoActionSelection && competionForAction?.key) {
      competionForAction.status = videoActionSelection;
      const getJudgesData = JSON.parse(sessionStorage.getItem("preJudgeRules"));
      if (contestantCommentRef.current && contestantCommentRef.current.value) {
        competionForAction["judgesComment"] =
          contestantCommentRef.current.value;
      }

      if (getJudgesData?.judgeName) {
        competionForAction["judgeData"] = getJudgesData;
      }

      if (submitBtnRef.current) {
        submitBtnRef.current.classList.add("loading");
      }

      try {
        updateCompetition(competionForAction.key, competionForAction).subscribe(
          (resp) => {
            console.log("update enrolled comp: ", resp);
            if (filterStatus) {
              getFilteredCompetitionLists(filterStatus);
            }
            sendEmailToUser(competionForAction);
            toggleVideoActionModal(false);
            if (submitBtnRef.current) {
              submitBtnRef.current.classList.remove("loading");
            }
          }
        );
      } catch (e) {
        console.log("update enrolled comp error: ", e);
        toggleVideoActionModal(false);
        if (submitBtnRef.current) {
          submitBtnRef.current.classList.remove("loading");
        }
      }
    }
  }

  function sendEmailToUser(comp) {
    const userEmail = comp?.userDetails?.email;
    const userName = comp?.userDetails?.name;
    const compName = comp?.compName;
    const compImg = comp?.compImg;
    const resultStatus = comp?.status;
    const judgeComment = comp?.judgesComment;

    let emailBody = `<div>
            <h3 style="font-weight: 400;">Hi ${userName}</h3>
            <p>Our judges has reviewed your performance, here is the verdict:</p>
            <h4>Result: <span style="color: ${
              resultStatus === "Rejected" ? "red" : "green"
            }">${
      resultStatus === "Rejected" ? "Not selected" : resultStatus
    }<span></h4>
            <h4>Judges comment: <span style="font-weight: 400;">${judgeComment}</span></h4>
            <p>${
              resultStatus === "Rejected"
                ? "Better luck for next time!"
                : "Congralutations! now be prepared for next round results!"
            }</p>
            <h4> Till then, keep dancing and keep shining!</h4>
            <p style="height: 150px;">
                <img style="width: 100%;" src=${compImg} alt=${compName}/>
            </p>
            <p>
                For more information visit:<br/>
                <a href="https://appda.in" target="_blank" rel="noreferrer">www.appda.in</a>
            </p>
            `;
    let payload = {
      mailTo: userEmail,
      title: `${compName} - results for first round!`,
      content: emailBody,
    };
    sendEmail(payload).subscribe((res) => {
      if (!("error" in res)) {
        console.log("User Email Send Successfully.");
      } else console.log("User Email Send Failed.");
    });
  }

  function toggleActionModalBox(compItem) {
    toggleSelectionRejectionText("");
    setContestantName("");
    setCompetitionActionValue(null);
    toggleVideoActionModal(!isVideoActionModalOpen);
    setTimeout(() => {
      if (submitBtnRef.current) {
        submitBtnRef.current.disabled = true;
      }
    }, 1);
    if (compItem) {
      const userFirstName = `${compItem?.userDetails?.name?.split(" ")[0]}'s`;
      setContestantName(userFirstName);
      setCompetitionActionValue(compItem);
    }
  }

  function toggleOtherFilters(action, value) {
    toggleOtherFilterOverlay(action);
    if (value && value === "reset") {
      setDefaultNameFilterValue("");
      getEnrolledCompetitionList();
    }
  }

  function setOtherFilterValue(filters) {
    toggleOtherFilterOverlay(false);
    setDefaultNameFilterValue(filters?.value);
    getFiltersByOthers(filters);
  }

  return (
    <div className="judgementWrap">
      {isVideoActionModalOpen ? (
        <div className="videoActionsWrap">
          <div className="actionsBox">
            <h2>What do you want to do with {contestantName} performance?</h2>
            <p
              className="closeModalLink"
              title="Close modal"
              onClick={() => toggleActionModalBox(null)}
            ></p>
            <button
              className="mainActionBtn select"
              ref={selectBtnRef}
              onClick={(e) => toggleUserVideoSelection(e, "select")}
            >
              Select
            </button>
            <button
              className="mainActionBtn reject"
              ref={rejectBtnRef}
              onClick={(e) => toggleUserVideoSelection(e, "reject")}
            >
              Reject
            </button>
            <textarea
              className="comments"
              ref={contestantCommentRef}
              placeholder={`Comments ${
                selectOrRejection ? "for" : ""
              } ${selectOrRejection}`}
            ></textarea>
            <button
              className="btn mainActionBtn submitBtn"
              ref={submitBtnRef}
              onClick={(e) => submitActionSelection(e)}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {otherFilterOverlay ? (
        <JudgesFilters
          setFilter={setOtherFilterValue}
          toggleFilter={toggleOtherFilters}
          defaultNameFilterSelection={defaultNameFilter}
        />
      ) : (
        ""
      )}
      {isJudgesLoggedIn ? (
        <div className="compeitionsVideoAndRules">
          <a href="/" className="logo" title="Back to Home">
            <img src={boogaluLogo} alt="Da" />
          </a>
          <h1>Welcome to Competitions judgement Pre-final round</h1>
          <div
            className={`innerContentBox ${
              shouldEnableVideoList ? "videoListInnerContent" : ""
            }`}
          >
            {!shouldEnableVideoList ? (
              <div className="rulesBox">
                <h2>Rules of Judging</h2>
                <p className="paraTexts">
                  All the videos which is visible in the portal are the videos
                  uploaded by our contestants for the competition we have
                  organised on{" "}
                  <a
                    href="https://www.appda.in/events"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.appda.in/events
                  </a>
                  .
                </p>
                <p className="paraTexts">
                  The purpose of this portal is to only judge the videos and
                  give the verdict to the contestants fairly. By accepting this
                  terms you are giving the consent that these videos and
                  information of our contestants will not be used for any
                  personal or advertisment purpose apart from judging them in
                  our portal.
                </p>
                <p className="paraTexts">
                  For more information and future contact we would like to take
                  some informations of judges such as name. This information
                  will only going to use inside our website for future reference
                  and getting information about the verdict a judge provided to
                  a contestant's video.
                </p>
                <label
                  htmlFor="judgeName"
                  className="judgeNameBox"
                  title="enter judge name"
                >
                  <input
                    type="text"
                    id="judgeName"
                    ref={judgeNameInputRef}
                    placeholder="Enter judge name"
                  />
                  <span className="messageBox" ref={judgeNameMessageRef}></span>
                </label>
                <button
                  className="btn"
                  onClick={(e) => showCompetitionVideos(e, "pre-judge")}
                >
                  Agreed &amp; continue
                </button>
              </div>
            ) : (
              <div className="videoListBox">
                {isFiltersVisible ? (
                  <div className="filters">
                    <p className="filterTitle">Filter by: </p>
                    <div className="filtersBtn">
                      <button
                        className="btn primary-light js-filterBtn"
                        onClick={(e) =>
                          getEnrolledCompetitionsByFilter(e, "all")
                        }
                      >
                        All
                      </button>
                      <button
                        className="btn primary-light js-filterBtn"
                        onClick={(e) =>
                          getEnrolledCompetitionsByFilter(e, "notreviewed")
                        }
                      >
                        Not reviewed
                      </button>
                      <button
                        className="btn primary-light js-filterBtn"
                        onClick={(e) =>
                          getEnrolledCompetitionsByFilter(e, "Selected")
                        }
                      >
                        Selected
                      </button>
                      <button
                        className="btn primary-light js-filterBtn"
                        onClick={(e) =>
                          getEnrolledCompetitionsByFilter(e, "Rejected")
                        }
                      >
                        Rejected
                      </button>
                      <button
                        className="btn primary-light js-filterBtn"
                        onClick={(e) =>
                          getEnrolledCompetitionsByFilter(e, "others")
                        }
                      >
                        Others
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {isVideosLoading ? (
                  <RippleLoader />
                ) : compeitionListData && compeitionListData.length ? (
                  compeitionListData.map((item, index) => {
                    return (
                      <div className="competitionBox" key={`comp-box-${index}`}>
                        <p
                          className={`compTitle ${
                            item?.status ? item.status.toLowerCase() : ""
                          }`}
                        >
                          <span className="name">{item?.compName}</span>
                          <span className="status">
                            {item?.status === "Submitted"
                              ? "Not reviewed"
                              : item?.status || "Not reviewed"}
                          </span>
                        </p>
                        <div className="videoWrap">
                          <VideoPlayer
                            vdoObj={item.vdo}
                            shouldDownload={true}
                          />
                        </div>
                        <div className="userVideoDetails">
                          <span className="innerValues ageGroup">
                            Age criteria: {item.ageGroup}
                          </span>
                          <span className="innerValues userName">
                            Contestant Name: {item?.userDetails?.name}
                          </span>
                          <span className="innerValues userAge">
                            Age:{" "}
                            {getYearDifferenceInTwoDates(
                              new Date(),
                              new Date(item?.userDetails?.dob)
                            )}{" "}
                            years old
                          </span>
                          <button
                            className="videoActionBtn"
                            title="Video actions"
                            onClick={() => toggleActionModalBox(item)}
                          >
                            <span></span>
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  ""
                )}

                {messageHandler ? (
                  <p className="messageHandlingBox">{messageHandler}</p>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {!isJudgesLoggedIn ? (
        <JudgesLogin
          message={loginMessage}
          showPrivacyPolicy={shouldShowPrivacyPolicy}
          showTermsOfUse={shouldShowTermsOfUse}
          loginAction={judgesLoginStatus}
        />
      ) : (
        ""
      )}

      {isPrivacyPolicyVisible ? (
        <JudgesPrivacyPolicy closeModalBox={shouldShowPrivacyPolicy} />
      ) : (
        ""
      )}

      {isTermsOfUseVisible ? (
        <JudgesTermsUse closeModalBox={shouldShowTermsOfUse} />
      ) : (
        ""
      )}
    </div>
  );
}

export default PreFinalRound;
