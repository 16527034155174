import React, { useState, useEffect } from "react";
import CompetitionsDetails from "../CompetitionsDetails";
import { getActiveTestCompetitionsList } from "../../Services/TestingCompetition.service";
import { useStoreConsumer } from "../../Providers/StateProvider";
import { setActiveCompetition } from "../../Actions/Competition";
import { getTestCompetitionByUserId } from "../../Services/TestingEnrollCompetition.service";
import { getActiveSubscriptionsList } from "../../Services/Subscription.service";
import ImageCarousel from "../ImageCarousel";
import { truncateLargeText } from "../../helpers";
import { disableLoginFlow, enableLoginFlow } from "../../Actions/LoginFlow";
import { loginUser } from "../../Actions/User";
import * as $ from "jquery";
import { updateUser } from "../../Services/User.service";
// eslint-disable-next-line no-unused-vars
import { postOrder } from "../../Services/Razorpay.service";
import Button from "@material-ui/core/Button";
import { isObjectEmpty } from "../../helpers";
import { useHistory } from "react-router-dom";
import { displayNotification } from "../../Actions/Notification";
import { NOTIFICATION_INFO, NOTIFICATION_SUCCCESS } from "../../Constants";
import { EmailTemplate } from "../EmailTemplate/Emailer";
import { sendEmail } from "../../Services/Email.service";
import { logAnalyticsEvent } from "../../Services/analytics.service";
import { serverTimeStamp } from "../../Services/Other.service";
import Loader from "../Loader";

const eventsList = require("../../Data/events.json");
const shouldLoadEvents = false;

function TestCompetitions() {
  const history = useHistory();
  const { state, dispatch } = useStoreConsumer();
  const [eventsData, setEventsData] = useState(shouldLoadEvents ? eventsList.events : []);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [CompletitionList, setCompletitionList] = useState(null);
  const [shouldOpenEventModal, toggleEventModal] = useState(false);
  const [clickedEventData, setEventData] = useState(null);
  const [initialStep, setInitialStep] = useState(1);
  const loggedInUser = state.loggedInUser;
  const [buttonLoadingClass, toggleButtonLoading] = useState("");
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState(false);
  const [subscriptionsList, setSubscriptionList] = useState([]);
  const [isLoaderActive, toggleLoading] = useState(false);
  const [allUpcomingEvents, setUpcomingEvents] = useState([]);
  const [serverDateTime, setServerDateTime] = useState();

  const prepareUserCompData = (allCompList) => {
    return new Promise((res, rej) => {
      getTestCompetitionByUserId(loggedInUser.key).subscribe((userCompList) => {
        toggleLoading(false);
        if (userCompList.length) {
          allCompList.forEach((compDetails) => {
            let isUserEnrolled = userCompList.filter(
              (userCompData) => userCompData.compId === compDetails.key
            );
            if (isUserEnrolled.length) {
              compDetails.isUserEnrolled = true;
              compDetails.userSubmitedDetails = isUserEnrolled[0];
            }
          });
          res(allCompList);
        } else res(allCompList);
      });
    });
  };

  useEffect(() => {
    logAnalyticsEvent('page_view', {
      page_location: window.location.href,
      page_path: 'competitions',
      page_title: `HomePage-${window.location.href}`
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    $("html,body").animate(
      {
        scrollTop: 0,
      },
      700
    );
    toggleLoading(true);
    getActiveTestCompetitionsList().subscribe((allCompList) => {
      if (allCompList.length && loggedInUser.email && loggedInUser.phone) {
        // get user submitted competition details
        prepareUserCompData(allCompList).then((compListWithUserData) => {
          setCompletitionList(compListWithUserData);
        });
      } else {
        toggleLoading(false);
        setCompletitionList(allCompList);
      }
      if (allCompList && allCompList.length) {
        const upcomingList = [];
        allCompList.forEach(item => {
          if (item.type === 'upcoming') {
            upcomingList.push(item);
          }
        });
        if (upcomingList.length) {
          setUpcomingEvents(upcomingList);
        }
      }
    });
    try {
      toggleLoading(true);
      getActiveSubscriptionsList().subscribe((subscriptionsList) => {
        toggleLoading(false);
        setSubscriptionList(subscriptionsList);
      });
    } catch (e) {
      toggleLoading(false);
      console.log("Fetching subscription error: ", e);
    }
    // if user come from login page
    if (state.currentLoginFlow === "competition") {
      dispatch(disableLoginFlow());
      setIsOpenDetailsModal(true);
    } else if (state.currentLoginFlow === "competition-subscription") {
      // if user come from subscription page
      dispatch(disableLoginFlow());
      setIsOpenDetailsModal(true);
      setInitialStep(1);
    } else if (state.currentLoginFlow === "competition-uploadvdo") {
      // if user come from vdoUpload page
      dispatch(disableLoginFlow());
      setIsOpenDetailsModal(true);
      setInitialStep(3);
    } else if (state.currentLoginFlow === "competition-event") {
      eventImageClicked(state.currentLoginFlowData);
      dispatch(disableLoginFlow());
    }

    const servTime = serverTimeStamp();
    setServerDateTime(servTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(loggedInUser)) {
      if (loggedInUser?.events) {
        const eventsDataCopy = [...eventsData];
        eventsDataCopy.forEach((event) => {
          let isEventAlreadyRegistered = loggedInUser?.events?.filter(
            (data) => data.type === event.type
          );
          if (
            isEventAlreadyRegistered &&
            isEventAlreadyRegistered?.length !== 0
          )
            event.isRegistered = true;
        });
        setEventsData(eventsDataCopy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  const openDetailsModal = (competition) => {
    if (competition.isUserEnrolled) setInitialStep(1);
    dispatch(setActiveCompetition(competition));
    setIsOpenDetailsModal(true);
  };

  function eventImageClicked(data, event) {
    if (event) {
      event.stopPropagation();
    }
    if (data && data?.type && data?.type === 'upcoming') {
      openDetailsModal(data);
    } else {
      if (data?.id) {
        setEventData(data);
        toggleEventModal(true);
      }
    }
  }

  function closeEventModal(event) {
    event.stopPropagation();
    setEventData(null);
    toggleEventModal(false);
  }

  const sendEmailAfterEventRegSuccess = () => {
    try {
      return new Promise((resolve, reject) => {
        const { email } = loggedInUser;
        const emailBodyConfig = {
          heading: `Hi ${loggedInUser.name},`,
          content: `<div>
                    <p>Thanks for registering for ${clickedEventData.name
            } You’re all set.</p>
                    ${clickedEventData.offers
              ? `<p>By registerging you will get ${clickedEventData.offers}.`
              : ""
            }
                    <p>To know more about event just click the link bellow.</p>
                    <div class="action-btn-wrap">
                        <a class="action" href=${window.location.href
            }>Events</a> 
                    </div>
                </div>`,
          bodyFooterText: `<div>See you soon!</div>`,
        };
        let payload = {
          mailTo: email,
          title: `Your registration is confirmed for ${clickedEventData.name}`,
          content: EmailTemplate(emailBodyConfig),
        };
        sendEmail(payload).subscribe((res) => {
          if (!("error" in res)) {
            console.log("User Email Send Successfully.");
            resolve();
          } else {
            console.log("User Email Send Failed.");
            reject();
          }
        });
      });
    } catch (e) {
      console.log("email to user error: ", e);
    }
  };

  const afterPaymentResponse = (finalAmount) => {
    let updatedUserData = {
      ...loggedInUser,
    };
    let updatedEvent = {
      id: clickedEventData.id,
      type: clickedEventData.type,
      name: clickedEventData.name,
      fees: finalAmount,
      paymentDate: new Date(),
    };
    if (clickedEventData.offers) {
      updatedEvent["offer"] = clickedEventData.offers;
    }
    if (!loggedInUser?.isSubscriptionOffer) {
      let offerSub = subscriptionsList.filter(
        (subData) =>
          subData.planType === clickedEventData?.subscription || "startup"
      );
      updatedUserData = {
        ...loggedInUser,
        subscribed: true,
        subEndingReminderSend: false,
        subEndedReminderSend: false,
        planType: offerSub[0].planType,
      };
      if (
        loggedInUser &&
        loggedInUser?.subscribed &&
        loggedInUser?.planType === offerSub[0].planType
      ) {
        updatedUserData.subscriptions.forEach((subData) => {
          if (subData.planType === offerSub[0].planType && !subData.isExpired) {
            subData.validity += clickedEventData?.offerValidity;
          }
        });
      } else {
        let userSub = {
          id: offerSub[0]?.key,
          name: offerSub[0]?.name,
          planType: offerSub[0].planType,
          validity: clickedEventData?.offerValidity || 1,
          subscribedOn: new Date(),
          isExpired: false,
          isRenewed: false,
        };
        if ("subscriptions" in updatedUserData) {
          updatedUserData.subscriptions.forEach((data, index) => {
            data.isExpired = true; //mark expired to all previous subscriptions
            if (index === updatedUserData.subscriptions.length - 1)
              updatedUserData.subscriptions.push(userSub);
          });
        } else updatedUserData.subscriptions = [userSub];
      }
    }
    try {
      if ("events" in loggedInUser) {
        updatedUserData.events.push(updatedEvent);
      } else {
        updatedUserData.events = [updatedEvent];
      }
      updateUser(updatedUserData.key, updatedUserData).subscribe(() => {
        dispatch(loginUser(updatedUserData));
        toggleEventModal(false);
        setEventData(null);
        setOpenPaymentSuccessModal(true);
        sendEmailAfterEventRegSuccess();
        dispatch(
          displayNotification({
            msg: `${clickedEventData.name} Event Registration successfully`,
            type: NOTIFICATION_SUCCCESS,
            time: 4000,
          })
        );
      });
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  function isCurrentDateMatchStartDate(startDate) {
    const myDate = new Date(startDate);

    const startDateDay = new Date(myDate).getDate();
    const startDateMonth = new Date(myDate).getMonth() + 1;
    const startDateYear = new Date(myDate).getFullYear();

    const todayISODay = new Date(serverDateTime).getDate();
    const todayISOMonth = new Date(serverDateTime).getMonth() + 1;
    const todayISOYear = new Date(serverDateTime).getFullYear();

    return (new Date(`${todayISOYear}-${todayISOMonth}-${todayISODay}`) < new Date(`${startDateYear}-${startDateMonth}-${startDateDay}`));
  }

  function isEndDateExpired(endDate) {
    const myDate = new Date(endDate);
    myDate.setDate(myDate.getDate() + parseInt(1));
    const endDateDay = new Date(myDate).getDate();
    const endDateMonth = new Date(myDate).getMonth() + 1;
    const endDateYear = new Date(myDate).getFullYear();

    const todayISODay = new Date(serverDateTime).getDate();
    const todayISOMonth = new Date(serverDateTime).getMonth() + 1;
    const todayISOYear = new Date(serverDateTime).getFullYear();

    return (new Date(`${todayISOYear}-${todayISOMonth}-${todayISODay}`) >= new Date(`${endDateYear}-${endDateMonth}-${endDateDay}`));
  }

  function proceedForPayment(finalAmount) {
    if (!isObjectEmpty(loggedInUser)) {
      if (
        clickedEventData &&
        new Date(
          clickedEventData?.info?.registrationLastDate
        ).toDateString() === new Date().toDateString()
      ) {
        dispatch(
          displayNotification({
            msg: `${clickedEventData.name} registration has been closed!`,
            type: NOTIFICATION_INFO,
            time: 6000,
          })
        );
      } else {
        toggleButtonLoading("loading");
        const userData = {
          amount: finalAmount * 100,
          currency: "INR",
          receipt: loggedInUser.key,
        };

        let orderObj = {};
        orderObj[clickedEventData?.type] = userData;
        try {
          postOrder(
            orderObj,
            [clickedEventData?.type],
            "Monthly Subscription",
            loggedInUser,
            afterPaymentResponse(finalAmount)
          ).subscribe((response) => {
            toggleButtonLoading("");
          });
        } catch (e) {
          console.log("Error: ", e);
        }
      }
    } else {
      dispatch(
        enableLoginFlow({ type: "competition-event", data: clickedEventData })
      );
      history.push({
        pathname: "/login",
        state: null,
      });
    }
  };

  function returnEventFormatedDate(eventInfo) {
    return `
            ${new Date(eventInfo?.from).toDateString()} to ${new Date(
      eventInfo?.to
    ).toDateString()}
            `;
  }

  return (
    <div className="competition-wrap">
      <Loader value={isLoaderActive} />
      <ImageCarousel
        carouselData={eventsData}
        upcomingData={allUpcomingEvents}
        imgClickCallback={eventImageClicked}
      />
      <div className="competition-inner">
        <div className="title-wrap">
          <h1>Events</h1>
          <div className="competition-desc">
            Participate in various competitions and win exciting prizes
          </div>
        </div>
        <ul className="competition-list">
          {eventsData && eventsData.length
            ? eventsData.map((event) => {
              return (
                <li
                  key={event.id}
                  onClick={(e) => eventImageClicked(event, e)}
                >
                  <img src={event.imgUrlForTiles} alt={event.name} />
                  <h2>
                    <span className="title">{event.name}</span>
                    <span className="otherInfo">
                      Registration fees: <i>&#8377;</i>
                      {event.fees}/- only
                      <br />
                    </span>
                    <span className="otherInfo">
                      Offer: {event.offers}
                      <br />
                    </span>
                    {clickedEventData &&
                      event.id === clickedEventData?.id &&
                      clickedEventData?.isRegistered ? (
                      <span className="enrolledMessage">
                        Already registered
                      </span>
                    ) : (
                      ""
                    )}
                  </h2>
                </li>
              );
            })
            : ""}
          {CompletitionList &&
            CompletitionList.map((competition) => {
              return (
                <li
                  key={competition.name + "-id"}
                  onClick={() => openDetailsModal(competition)}
                >
                  {competition.type && competition.type === "upcoming" && isCurrentDateMatchStartDate(competition.startAt) ? (
                    <span className="upcomingLabel">Upcoming</span>
                  ) : (
                    isEndDateExpired(competition.endAt) ?
                      <span className="endLabel">Registration closed</span>
                      :
                      <span className="startedLabel">Registration started</span>
                  )}
                  <img src={competition.img} alt={competition.name} />
                  <h2>
                    <span className="title lineClamp-1">{competition.name}</span>
                    <span>Challenge and win exciting prizes.</span>
                    <span className="otherInfo lineClamp-1">
                      {truncateLargeText(competition.desc, 60)}
                      <br />
                    </span>
                    <span className="otherInfo">
                      Enrollment open: {competition.startingDate} to{" "}
                      {competition.endingDate}
                      <br />
                    </span>
                    {/* {competition.isUserEnrolled ? (
                      <span className="enrolledMessage">
                        {
                          competition?.userSubmitedDetails?.vdo ?
                          "Already Enrolled - Choose another video" :
                          "Already Enrolled - Upload video"
                        }
                      </span>
                    ) : (
                      ""
                    )} */}
                  </h2>
                </li>
              );
            })}
        </ul>
        {isOpenDetailsModal && (
          <CompetitionsDetails
            isTesting={true}
            open={isOpenDetailsModal}
            handleClose={() => setIsOpenDetailsModal(false)}
            initialStep={initialStep}
          />
        )}
      </div>
      {shouldOpenEventModal && clickedEventData?.id ? (
        <div className="eventDetailModal">
          <div className="eventDetailInner">
            <p className="closeModal" onClick={(e) => closeEventModal(e)} />
            <h3>{clickedEventData.name}</h3>
            <div className="mainInnerConent">
              {clickedEventData?.imgUrl ? (
                <div className="imgWrap">
                  <img
                    src={clickedEventData.imgUrl}
                    alt={`alt-${clickedEventData.id}`}
                  />
                </div>
              ) : (
                ""
              )}
              {
                clickedEventData?.notes ?
                  <div className="eventDate registrationFees">
                    <span>NOTE:</span>&nbsp;
                    <span className="value">{clickedEventData?.notes}</span>
                  </div>
                  : ''
              }
              {clickedEventData?.fees ? (
                <div className="eventDate registrationFees">
                  <span>New Registration fee: </span>
                  <span className="value">
                    <i>&#8377;</i> {`${clickedEventData.fees}/-`} only
                  </span>
                </div>
              ) : (
                ""
              )}

              {
                clickedEventData?.discountFee ?
                  <div className="eventDate registrationFees">
                    <span>{clickedEventData?.discountFee?.msg} </span>
                    <span className="value">
                      <i>&#8377;</i> {`${clickedEventData?.discountFee?.fees}/-`} only
                    </span><br />
                    <span className="smallText">(*T&amp;C - Strictly only for season 9 participants)</span>
                  </div> : ''
              }
              {clickedEventData?.offers &&
                !loggedInUser?.isSubscriptionOffer ? (
                <div className="eventDate registrationFees">
                  <span>Offer: </span>
                  <span className="value">{clickedEventData.offers}</span>
                </div>
              ) : (
                ""
              )}
              <div className="modalIfoWrap">
                <h3>More Information</h3>
                <div className="innerInformation">
                  {clickedEventData?.info?.registrationStartDate ? (
                    <div className="eventDate">
                      <span>Registration start date: </span>
                      <span className="value">
                        {new Date(
                          clickedEventData.info.registrationStartDate
                        ).toDateString()}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {clickedEventData?.info?.registrationLastDate ? (
                    <div className="eventDate">
                      <span>Registration last date: </span>
                      <span className="value">
                        {new Date(
                          clickedEventData.info.registrationLastDate
                        ).toDateString()}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {clickedEventData?.info?.categories &&
                    clickedEventData?.info?.categories.length ? (
                    <div className="eventInnerSection">
                      <h4>Categories:</h4>
                      {clickedEventData.info.categories.map((item, index) => {
                        return (
                          <p key={`category-item-${index}`}>
                            <span className="number strong">
                              {index + 1}.&nbsp;
                            </span>
                            <span className="division strong">
                              {item.div}&nbsp;
                            </span>
                            <span className="age light">
                              [{item.age}]&nbsp;
                            </span>
                            <span className="members light">
                              ({item.members}) members
                            </span>
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  {clickedEventData?.info?.indianBattles &&
                    clickedEventData?.info?.indianBattles.length ? (
                    <div className="eventInnerSection">
                      <h4>Indian Battles:</h4>
                      {clickedEventData.info.indianBattles.map((item, index) => {
                        return (
                          <p key={`battles-item-${index}`}>
                            <span className="number strong">
                              {item}
                            </span>
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  {clickedEventData?.info?.preVideoSubmisstionDate ? (
                    <div className="eventInnerSection">
                      <h4>Preliminary round:</h4>
                      <p>
                        <strong>Submission date:</strong>
                        &nbsp;
                        <span>
                          {returnEventFormatedDate(
                            clickedEventData?.info?.preVideoSubmisstionDate
                          )}
                        </span>
                      </p>
                      <p>
                        <strong>Broadcasting date:</strong>
                        &nbsp;
                        <span>
                          {new Date(
                            clickedEventData?.info?.preVideoSubmisstionDate?.broadCastingDate
                          ).toDateString()}
                        </span>
                      </p>
                      <p>
                        <strong>Result date:</strong>
                        &nbsp;
                        <span>
                          {new Date(
                            clickedEventData?.info?.preRoundResultDate
                          ).toDateString()}
                        </span>
                      </p>
                      <p>
                        <strong>Late fee:</strong>
                        &nbsp;
                        <span>
                          <i>&#8377;</i>{" "}
                          {
                            clickedEventData?.info?.preVideoSubmisstionDate
                              ?.lateFee
                          }{" "}
                          /-
                        </span>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {clickedEventData?.info?.grandFinaleVideoSubmissionDate ? (
                    <div className="eventInnerSection">
                      <h4>Grand Finale round:</h4>
                      <p>
                        <strong>Submission date:</strong>
                        &nbsp;
                        <span>
                          {returnEventFormatedDate(
                            clickedEventData?.info
                              ?.grandFinaleVideoSubmissionDate
                          )}
                        </span>
                      </p>
                      <p>
                        <strong>Broadcasting date:</strong>
                        &nbsp;
                        <span>
                          {new Date(
                            clickedEventData?.info?.grandFinaleVideoSubmissionDate?.broadCastingDate
                          ).toDateString()}
                        </span>
                      </p>
                      <p>
                        <strong>Late fee:</strong>
                        &nbsp;
                        <span>
                          <i>&#8377;</i>{" "}
                          {
                            clickedEventData?.info
                              ?.grandFinaleVideoSubmissionDate?.lateFee
                          }{" "}
                          /-
                        </span>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {clickedEventData?.info?.inquiry ? (
                    <div className="eventInnerSection">
                      <h4>Inquiry/Contact details:</h4>
                      <p>
                        <strong>Phone:</strong>&nbsp;
                        {clickedEventData?.info?.inquiry?.phone1 ? (
                          <a
                            title="Call for any query"
                            href={`tel:${clickedEventData.info.inquiry.phone1}`}
                          >
                            {clickedEventData.info.inquiry.phone1}
                          </a>
                        ) : (
                          ""
                        )}
                        {clickedEventData?.info?.inquiry?.phone2 ? (
                          <a
                            title="Call for any query"
                            href={`tel:${clickedEventData.info.inquiry.phone2}`}
                          >{` | ${clickedEventData.info.inquiry.phone2}`}</a>
                        ) : (
                          ""
                        )}
                      </p>
                      {clickedEventData?.info?.inquiry?.whatsapp ? (
                        <p>
                          <strong>Whatsapp:</strong>
                          &nbsp;
                          <a
                            title="Whatsapp for any query"
                            href={`https://wa.me/${clickedEventData.info.inquiry.whatsapp
                              }?text=${encodeURIComponent(
                                clickedEventData.info.inquiry.whatsappMessage
                              )}`}
                          >{`+${clickedEventData.info.inquiry.whatsapp}`}</a>
                        </p>
                      ) : (
                        ""
                      )}
                      {clickedEventData?.info?.inquiry?.email ? (
                        <p>
                          <strong>Email:</strong>
                          &nbsp;
                          <a
                            title="email for any query"
                            href={`mailto:${clickedEventData.info.inquiry.email}`}
                          >
                            {clickedEventData.info.inquiry.email}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {clickedEventData?.info?.followLinks ? (
                    <div className="eventInnerSection">
                      <h4>Follow us for updates:</h4>
                      <p>
                        <strong>Facebook:</strong>
                        <br />
                        <a
                          href={clickedEventData?.info?.followLinks?.facebook}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {clickedEventData?.info?.followLinks?.facebook}
                        </a>
                      </p>
                      <p>
                        <strong>Youtube:</strong>
                        <br />
                        <a
                          href={clickedEventData?.info?.followLinks?.youtube}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {clickedEventData?.info?.followLinks?.youtube}
                        </a>
                      </p>
                      <p>
                        <strong>Twitter:</strong>
                        <br />
                        <a
                          href={clickedEventData?.info?.followLinks?.twitter}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {clickedEventData?.info?.followLinks?.twitter}
                        </a>
                      </p>
                      <p>
                        <strong>Instagram:</strong>
                        <br />
                        <a
                          href={clickedEventData?.info?.followLinks?.instagram}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {clickedEventData?.info?.followLinks?.instagram}
                        </a>
                      </p>
                      <p>
                        <strong>HHI Website:</strong>
                        <br />
                        <a
                          href={clickedEventData?.info?.followLinks?.website}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {clickedEventData?.info?.followLinks?.website}
                        </a>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {clickedEventData?.info?.rules ? (
                <div className="eventDate">
                  <a
                    href={clickedEventData.info.rules}
                    target="_blank"
                    rel="noreferrer"
                    title="Rules and regulations"
                  >
                    Terms &amp; rules of event
                  </a>
                </div>
              ) : (
                ""
              )}
              {clickedEventData?.info?.judges &&
                clickedEventData?.info?.judges.length ? (
                <div className="judgesWrap modalIfoWrap">
                  <h3>Our Judges</h3>
                  {clickedEventData.info.judges.map((item, index) => {
                    return (
                      <div className="judgesItem" key={`judge-id-${index}`}>
                        <p className="name">
                          {item.name},<span>{item.place}</span>
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
            {isEndDateExpired(clickedEventData.info.registrationLastDate) ? (
              <p className="btn primary-light registeredInfoBtn disabled">
                Registration closed
              </p>
            ) : clickedEventData.isRegistered ? (
              <p className="btn primary-light registeredInfoBtn">
                You have already registered
              </p>
            ) : (
              <div className="eventActions">
                <button
                  className={
                    buttonLoadingClass
                      ? `${buttonLoadingClass} btn primary-dark`
                      : "btn primary-dark"
                  }
                  onClick={() => proceedForPayment(clickedEventData?.fees)}
                >
                  New Users pay {clickedEventData?.fees}/- &amp; register
                </button>
                <button
                  className={
                    buttonLoadingClass
                      ? `${buttonLoadingClass} btn primary-light`
                      : "btn primary-light"
                  }
                  onClick={() => proceedForPayment(clickedEventData?.discountFee?.fees)}
                >
                  IHHDC 2021 pay {clickedEventData?.discountFee?.fees}/- &amp; register
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {openPaymentSuccessModal ? (
        <div className="eventDetailModal">
          <div className="eventDetailInner">
            <p
              className="closeModal"
              onClick={(e) => setOpenPaymentSuccessModal(false)}
            />
            <h3>Success !</h3>
            <div>
              <p className="subscriptionMessage success">
                Payment For Event Registration Recieved Successfully
              </p>
              <div className="actionWrap success">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => setOpenPaymentSuccessModal(false)}
                >
                  Explore competition
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TestCompetitions;
