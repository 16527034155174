import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const AddCompititionCategories = forwardRef((props, ref) => {
    const [categoryList, setCategoryList] = useState(props?.competitionData?.categories || []);
    const [isCategoryFieldPresent, toggleCategoryFeild] = useState(true);
    const [error, setError] = useState({ id: '', text: '' });
    // The component instance will be extended with whatever you return from the callback passed as the second argument
    useImperativeHandle(ref, () => ({
        validateCategories() {
            return validateCategories()
        },
        getCategories() {
            return getCategories()
        }
    }))

    useEffect(() => {
        if (props?.isFromEdit) {
            setCategoryList(props?.competitionData?.categories || []);
        }
    }, [props])

    useEffect(() => {
        //display member count warning for 3sec
        // setCategoryList(props?.competitionData?.categories || []);
        if (error.id === 'minMember' || error.id === "maxMember") setTimeout(() => setError({ id: '', text: '' }), 3000);
    }, [error])


    const validateCategories = () => {
        if (!error.id) {
            let err = { id: '', text: '' }
            // eslint-disable-next-line array-callback-return
            categoryList.map((cat, i) => {
                if (!err.id) {
                    if (!cat.name) {
                        err = { id: `cat-${i}`, text: 'Enter category name' };
                    }

                    if (!cat.fee) {
                        err = { id: `cat-${i}`, text: 'Enter category fee' };
                    }
                }
            })
            if (err.id) return false;
            else return true
        } return false;
    }

    const getCategories = () => {
        if (validateCategories()) {
            return categoryList;
        }
    }

    const handleChange = (value, from, index) => {
        setError({ id: '', text: '' })
        const categoryListCopy = [...categoryList];
        if (from === 'maxMember' && value >= 1 && value < categoryListCopy[index].minMember) {
            setError({ id: 'maxMember', text: 'Maximum member count cannot be less than minimum member count' });
        } else if (from === 'minMember' && value > 1 && value > categoryListCopy[index].maxMember) {
            setError({ id: 'minMember', text: 'Minimum member count cannot be greater than maximum member count' });
        } else {
            categoryListCopy[index][from] = value;
            setCategoryList(categoryListCopy);
        }
    }

    const setNewCategory = (categoriesListCopy) => {
        const emptyCatObj = { name: '', fee: '', type: 'crew', uniqueMemberPercent: 50, minMember: 1, maxMember: 1 };
        categoriesListCopy.push(emptyCatObj)
        if (categoriesListCopy.length && !isCategoryFieldPresent) {
            toggleCategoryFeild(true);
        }
        setCategoryList(categoriesListCopy);
    }

    function addNewCategory() {
        const categoriesListCopy = [...categoryList];
        if (categoriesListCopy.length) {
            if (!categoriesListCopy[categoriesListCopy.length - 1].name) {
                setError({ id: 'name', text: 'Please enter category name' });
            } else {
                setNewCategory(categoriesListCopy);
            }

            if (!categoriesListCopy[categoriesListCopy.length - 1].fee) {
                setError({ id: 'fee', text: 'Please enter category fee' });
            } else {
                setNewCategory(categoriesListCopy);
            }
        } else {
            setNewCategory(categoriesListCopy);
        }
    }

    const removeCategory = (index) => {
        setError({ id: '', text: '' })
        const categoriesListCopy = [...categoryList];
        categoriesListCopy.splice(index, 1);
        if (!categoriesListCopy.length) {
            toggleCategoryFeild(false);
        }
        setCategoryList(categoriesListCopy);
    }

    return (
        <>

            {props?.competitionData?.categories && <div className='comp-category-wrap'>
                <div className='category-lists'>
                    <div className="inputGroupWrap">
                        <div className="innerControlGroup">
                            {categoryList.map((category, index) => {
                                return <div className="controlGroup" key={index} id={`cat-${index}`}>
                                    <label className="controlLabel">
                                        Category {index + 1}
                                        {
                                            index === 0 ? <span>(at least one category is must)</span> : ''
                                        }
                                    </label>
                                    <div className="controlsWrapper">
                                        <div className="controls categoryName">
                                            <TextField
                                                className="fullWidthInput"
                                                id="category-name-1"
                                                label="Category name"
                                                value={category.name || ''}
                                                onChange={(e) => handleChange(e.target.value, 'name', index)}
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className="controls categoryFee">
                                            <TextField
                                                className="fullWidthInput"
                                                id="category-fee-1"
                                                label="Fee(per person)"
                                                value={category.fee || ''}
                                                onChange={(e) => handleChange(e.target.value, 'fee', index)}
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className="controls counter-wrap">
                                            <div className="controls">
                                                <div className="placeholder">Min member</div>
                                                <div className="fullWidthInput counter-btn-wrap">
                                                    <div className="btn" onClick={() => handleChange((category.minMember === 1 ? category.minMember : category.minMember - 1), 'minMember', index)}>
                                                        <IconButton><RemoveIcon /></IconButton>
                                                    </div>
                                                    <div className="btn">{category.minMember}</div>
                                                    <div className="btn" onClick={() => handleChange((category.minMember + 1), 'minMember', index)}>
                                                        <IconButton><AddIcon /></IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="controls">
                                                <div className="placeholder">Max member</div>
                                                <div className="fullWidthInput counter-btn-wrap">
                                                    <div className="btn" onClick={() => handleChange((category.maxMember === 1 ? category.maxMember : category.maxMember - 1), 'maxMember', index)}>
                                                        <IconButton><RemoveIcon /></IconButton>
                                                    </div>
                                                    <div className="btn">{category.maxMember}</div>
                                                    <div className="btn" onClick={() => handleChange((category.maxMember + 1), 'maxMember', index)}>
                                                        <IconButton><AddIcon /></IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="controls uniqueMemberCounter">
                                                <div className="placeholder">Unique Members</div>
                                                <div className="fullWidthInput counter-btn-wrap">
                                                    <div className="btn" onClick={() => handleChange((category.uniqueMemberPercent === 0 ? category.uniqueMemberPercent : category.uniqueMemberPercent - 10), 'uniqueMemberPercent', index)}>
                                                        <IconButton><RemoveIcon /></IconButton>
                                                    </div>
                                                    <div className="btn">{category.uniqueMemberPercent + '%'}</div>
                                                    <div className="btn" onClick={() => handleChange((category.uniqueMemberPercent === 100 ? category.uniqueMemberPercent : category.uniqueMemberPercent + 10), 'uniqueMemberPercent', index)}>
                                                        <IconButton><AddIcon /></IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        categoryList?.length > 1 ?
                                            <Button variant="contained" color="secondary" onClick={() => removeCategory(index)}><DeleteForeverIcon /> Remove</Button>
                                            : ''
                                    }
                                </div>
                            })}
                        </div>
                        {error.id && <div className="error-note">{error.text}</div>}
                        <Button variant="contained" color="primary" className="addCategoryBtn" onClick={addNewCategory}>{isCategoryFieldPresent ? 'Add category +' : 'Add categories'}</Button>
                    </div>
                </div>
            </div>}
        </>
    )
}
)

export default AddCompititionCategories;
