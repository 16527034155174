import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { getUploadedVideosList } from "../../Services/UploadedVideo.service";
// eslint-disable-next-line no-unused-vars
import VideoPlayer from "../VideoPlayer/VideoPlayer";
// eslint-disable-next-line no-unused-vars
import Favorite from "@material-ui/icons/Favorite";
import {
  FaInstagram,
  FaInstagramSquare,
  FaYoutube,
  FaFacebookSquare,
} from "react-icons/fa";
import Loader from "../Loader";
import { useStoreConsumer } from "../../Providers/StateProvider";
import {
  displayNotification,
  removeNotification,
} from "../../Actions/Notification";
import {
  NOTIFICATION_ERROR,
  SHOULD_ENABLE_APP,
  PLAY_STORE,
  APP_STORE,
} from "../../Constants";
import { isElementInViewport } from "../../helpers";
import { logAnalyticsEvent } from "../../Services/analytics.service";

import PlayStoreLogo from "../../Images/gplay-logo.png";
import AppStoreLogo from "../../Images/istore-logo.png";
import LearnPhoneImg from "../../Images/home-page/learn/31316341@2x.png";
import KidsLearnImg from "../../Images/home-page/learn/kids-learn-2x.png";
import BeginLearnImg from "../../Images/home-page/learn/begin-learn-2x.png";
import AdvLearnImg from "../../Images/home-page/learn/advance-learn-2x.png";
import InterLearnImg from "../../Images/home-page/learn/inter-learn-2x.png";
import StyleClassicalImg from "../../Images/home-page/styles/classical-2x.png";
import StyleFitnessImg from "../../Images/home-page/styles/fitness-2x.png";
import StyleHipHopImg from "../../Images/home-page/styles/hip-hop-2x.png";
import Judge1 from "../../Images/home-page/judges/omar.jpg";
import Judge2 from "../../Images/home-page/judges/maria.jpg";
import Judge3 from "../../Images/home-page/judges/adrien.jpg";
import NimbulFunk from "../../Images/home-page/instructors/nimble-funk.jpg";
import MohanPandey from "../../Images/home-page/instructors/mohan-pandey.jpg";
import SnehaInstructor from "../../Images/home-page/instructors/sneha.jpg";
import Karthik from "../../Images/home-page/instructors/karthik.JPG";
import Gonza from "../../Images/home-page/instructors/gonza.jpg";
import Horz1 from "../../Images/home-page/gallery/horz-1.png";
import Horz2 from "../../Images/home-page/gallery/horz-2.png";
import Horz3 from "../../Images/home-page/gallery/horz-3.png";
import Horz4 from "../../Images/home-page/gallery/horz-4.png";
import Vert1 from "../../Images/home-page/gallery/vert-1.png";
import Vert2 from "../../Images/home-page/gallery/vert-2.png";
import Big1 from "../../Images/home-page/gallery/big-1.png";
import Big2 from "../../Images/home-page/gallery/big-2.png";
import MirrorIcon from "../../Images/home-page/features/mirror-icon.svg";
import FrontBackIcon from "../../Images/home-page/features/front-back-icon.svg";
import SlowMoIcon from "../../Images/home-page/features/slowmo-icon.svg";
import RepeatIcon from "../../Images/home-page/features/repeat-icon.svg";
import VRIcon from "../../Images/home-page/features/vr-icon.svg";
import MobileImgInteractive from "../../Images/home-page/features/mobile-img.png";

const isAppAlreadyLoaded = JSON.parse(localStorage.getItem("isAppLoaded"));

export default function Homepage() {
  const history = useHistory();
  const { dispatch } = useStoreConsumer();
  // const [danceImageVisibleClass, activeDanceImage] = useState('');
  const [UserUploadedVideoList, setUserUploadedVideoList] = useState([]);
  // const [isMobile, toggleMobile] = useState(false);
  const [loadImageClass, toggleLoadImage] = useState("");
  const [firstImageLoaded, toggleFirstImageLoad] = useState("");
  const [loadMessageBox, toggleMessageClass] = useState("");
  const [firstMessageLoaded, toggleFirstMessageLoad] = useState("");
  const [defaultFirstMessageLoaded, toggleDefaultMessageLoad] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [headingAnimateClass1, toggleHeadingClass] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [headingAnimateClass2, toggleHeadingClassNew] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [firstHeadingLoaded, toggleFirstHeadingLoad] = useState("");
  const [startButtonAnimateClass, animateStartButton] = useState("");
  const [firstStartButtonLoaded, toggleFirstStartButtonLoad] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [videoAnimateClass, animateVideoContainer] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [firstVideoAnimateLoaded, toggleVideoAnimateLoad] = useState("");
  const [isLoaderActive, toggleLoading] = useState(false);

  const sectionServiceRef = useRef(null);
  const sectionLessonRef = useRef(null);
  const sectionCompetitionRef = useRef(null);
  const sectionFeatureRef = useRef(null);
  const sectionInstructorRef = useRef(null);
  const cardShowcaseRef = useRef(null);
  const cardCompRef = useRef(null);
  const cardLessonRef = useRef(null);
  const sectionStylesRef = useRef(null);
  const sectionChallengeRef = useRef(null);
  const sectionEventsGalleryRef = useRef(null);
  const sectionSubscriptionRef = useRef(null);

  useEffect(() => {
    logAnalyticsEvent("page_view", {
      page_location: window.location.href,
      page_path: "/",
      page_title: `HomePage-${window.location.href}`,
    });
    toggleLoading(true);
    try {
      getUploadedVideosList().subscribe((videos) => {
        toggleLoading(false);
        setUserUploadedVideoList(videos);
      });
    } catch (e) {
      toggleLoading(false);
      dispatch(
        displayNotification({
          msg: "Something went wrong! Please reload and try again!",
          type: NOTIFICATION_ERROR,
          time: 5000,
        })
      );
      setTimeout(() => {
        dispatch(
          removeNotification({
            msg: "",
            type: NOTIFICATION_ERROR,
            time: 0,
          })
        );
      }, 5000);
    }
    if (isAppAlreadyLoaded) {
      toggleFirstImageLoad("noFirstImageLoad");
      toggleDefaultMessageLoad("noFirstMessageLoad");
      toggleFirstMessageLoad("");
      toggleFirstHeadingLoad("");
      toggleFirstStartButtonLoad("");
      toggleVideoAnimateLoad("");
    } else {
      toggleFirstImageLoad("firstTimeLoaded");
      toggleFirstMessageLoad("firstMessageLoaded");
      toggleFirstHeadingLoad("firstHeadingLoaded");
      toggleFirstStartButtonLoad("firstButtonLoaded");
      toggleVideoAnimateLoad("firstVideoLoaded");
      setTimeout(() => {
        // activeDanceImage('show');
        toggleLoadImage("show");
      }, 100);
      setTimeout(() => {
        toggleMessageClass("show");
      }, 200);
      setTimeout(() => {
        toggleHeadingClass("animate");
        toggleHeadingClassNew("animate");
      }, 300);
      setTimeout(() => {
        animateStartButton("animate");
      }, 400);
      setTimeout(() => {
        animateVideoContainer("animate");
        localStorage.setItem("isAppLoaded", true);
      }, 500);
    }

    window.addEventListener("scroll", onWindowScroll);

    return () => window.removeEventListener("scroll", onWindowScroll);
    // let windowViewPortWidth = window.innerWidth;
    // if (windowViewPortWidth > 1023) {
    //     toggleMobile(false);
    // } else {
    //     toggleMobile(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onWindowScroll(event) {
    event.stopPropagation();
    if (window.scrollY > 100) {
      if (
        (sectionServiceRef.current &&
          !sectionServiceRef.current.classList.contains("animateContent")) ||
        (sectionLessonRef.current &&
          !sectionLessonRef.current.classList.contains("animateContent")) ||
        (sectionCompetitionRef.current &&
          !sectionCompetitionRef.current.classList.contains(
            "animateContent"
          )) ||
        (sectionFeatureRef.current &&
          !sectionFeatureRef.current.classList.contains("animateContent"))
      ) {
        setTimeout(() => {
          if (sectionServiceRef.current) {
            const headingEle =
              sectionServiceRef.current.querySelectorAll("h2")[0];
            if (isElementInViewport(headingEle)) {
              sectionServiceRef.current.classList.add("animateContent");
              setTimeout(() => {
                if (cardShowcaseRef.current) {
                  cardShowcaseRef.current.classList.add("show");
                }
              }, 50);
              setTimeout(() => {
                if (cardCompRef.current) {
                  cardCompRef.current.classList.add("show");
                }
              }, 150);
              setTimeout(() => {
                if (cardLessonRef.current) {
                  cardLessonRef.current.classList.add("show");
                }
              }, 200);
            }
          }
          if (sectionLessonRef.current) {
            const headingEle =
              sectionLessonRef.current.querySelectorAll("h2")[0];
            if (isElementInViewport(headingEle)) {
              sectionLessonRef.current.classList.add("animateContent");
            }
          }
          if (sectionCompetitionRef.current) {
            const headingEle =
              sectionCompetitionRef.current.querySelectorAll("h2")[0];
            if (isElementInViewport(headingEle)) {
              sectionCompetitionRef.current.classList.add("animateContent");
            }
          }
          if (sectionFeatureRef.current) {
            const headingEle =
              sectionFeatureRef.current.querySelectorAll("h2")[0];
            if (isElementInViewport(headingEle)) {
              sectionFeatureRef.current.classList.add("animateContent");
            }
          }
        }, 300);
      }
    }
  }

  function scrollToNextSection(event, nextSection) {
    event.stopPropagation();
    if (nextSection.current) {
      window.scrollTo({
        top: nextSection.current.offsetTop - 45,
        behavior: "smooth",
      });
    }
  }

  return (
    <div className="homepage clearfix">
      {isLoaderActive ? <Loader /> : ""}
      <div
        className={`homepage-display-1 ${firstImageLoaded} ${loadImageClass} ${
          !UserUploadedVideoList.length ? "no-video" : ""
        }`}
      >
        <section
          className={`main-bg-message ${defaultFirstMessageLoaded} ${firstMessageLoaded} ${loadMessageBox}`}
        >
          <div className="boxContent">
            <h2>Connect your rhyme &amp; moves with Da</h2>
            <p>
              Explore Innovative learning experience for your dance &amp;
              fitness journey
            </p>
            {/* This get start button if we'll use for scroll to next section */}
            {/* <button className={`btn primary-light get_started ${firstStartButtonLoaded} ${startButtonAnimateClass}`} onClick={(e) => scrollToNextSection(e, sectionServiceRef)}>Start learning now</button> */}
            <div className="homeOptions">
              <p onClick={() => history.push("/lessons")} title="let's learn">
                learn
              </p>
              <p
                onClick={() => history.push("/events")}
                title="let's participate"
              >
                challenge
              </p>
              <p onClick={() => history.push("/lessons")} title="let's learn">
                groove
              </p>
            </div>
            <a
              href="/events"
              className={`btn primary-light get_started new-blue-primary btn-with-shadow ${firstStartButtonLoaded} ${startButtonAnimateClass}`}
              title="goto events"
            >
              Start participating now
            </a>
            {SHOULD_ENABLE_APP ? (
              <div className="appDownloadWrap">
                <a
                  title="download from Play store"
                  className="googlePlayLink"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={PLAY_STORE}
                >
                  <img alt="Get it on Google Play" src={PlayStoreLogo} />
                </a>
                <a
                  title="download from app store"
                  className="applePlayLink"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={APP_STORE}
                >
                  <img alt="Get it on Google Play" src={AppStoreLogo} />
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* <h4 className={`${firstHeadingLoaded} ${headingAnimateClass1}`}>
                        The world’s best <span className="color-text-yellow">Dance</span> learning tools,
                    </h4>
                    <h5 className={`${firstHeadingLoaded} ${headingAnimateClass2}`}>
                        at your <span className="color-text-green">Fingertips</span>.
                    </h5>
                    <button className={`btn primary-light get_started ${firstStartButtonLoaded} ${startButtonAnimateClass}`} onClick={() => {
                        history.push('/login');
                    }}>Get Started</button>
                    <div className={`flex-container video-main-wrap ${firstVideoAnimateLoaded} ${videoAnimateClass}`}> */}
          {/* {UserUploadedVideoList.length !== 0 ?
                            <div className="feed-wrap">
                                {UserUploadedVideoList && UserUploadedVideoList.map((vdo) => {
                                    return <div key={vdo.key} className="vdo-card">
                                        <div>
                                            <VideoPlayer vdoObj={vdo} />
                                        </div>
                                        <div className="video-title-like-wrap">
                                            <div className="title">{vdo.title}</div>
                                            <div className="like-comment">
                                                <Favorite title="Likes" />
                                                {vdo.likes && vdo.likes.length > 0 && <div className="likes-count">{vdo.likes.length} {vdo.likes.length > 1 ? 'Likes' : 'Like'}</div>}
                                            </div>

                                        </div>
                                    </div>
                                })}
                            </div>
                            :
                            ''}
                    </div> */}
          <div
            className="scrollToNexBtn"
            title="Scroll to Showcase"
            onClick={(e) => scrollToNextSection(e, sectionServiceRef)}
          >
            <p className="mouseBtnWrap">
              <span className="mouse">
                <span></span>
              </span>
            </p>
            <p className="text">next</p>
          </div>
        </section>
        <section
          className="otherSections sectionServices"
          ref={sectionServiceRef}
        >
          <h2>Learn it the easy way</h2>
          <h3 className="subHeadings">
            A blend of lessons curated by internationally renowned artist for
            all age groups &amp; level
          </h3>
          <div className="othersInnerSection">
            <ul>
              <li>
                <a href="/lessons?sp=kids" title="Kids lesson videos">
                  <span className="title">kids</span>
                  <span className="imgWrap">
                    <img className="intro" src={KidsLearnImg} alt="kids" />
                    <img
                      className="gifAnime"
                      src="https://wp-modula.com/wp-content/uploads/2018/12/gifgif.gif"
                      alt="sample"
                    />
                  </span>
                </a>
              </li>
              <li>
                <a href="/lessons" title="our more lessons">
                  <span className="title">beginner</span>
                  <span className="imgWrap">
                    <img className="intro" src={BeginLearnImg} alt="beginner" />
                    <img
                      className="gifAnime"
                      src="https://wp-modula.com/wp-content/uploads/2018/12/gifgif.gif"
                      alt="sample"
                    />
                  </span>
                </a>
              </li>
              <li>
                <a href="/lessons" title="our more lessons">
                  <span className="title">intermediate</span>
                  <span className="imgWrap">
                    <img
                      className="intro"
                      src={InterLearnImg}
                      alt="intermediate"
                    />
                    <img
                      className="gifAnime"
                      src="https://wp-modula.com/wp-content/uploads/2018/12/gifgif.gif"
                      alt="sample"
                    />
                  </span>
                </a>
              </li>
              <li>
                <a href="/lessons" title="our more lessons">
                  <span className="title">advance</span>
                  <span className="imgWrap">
                    <img className="intro" src={AdvLearnImg} alt="advance" />
                    <img
                      className="gifAnime"
                      src="https://wp-modula.com/wp-content/uploads/2018/12/gifgif.gif"
                      alt="sample"
                    />
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="sectionImgWrap">
            <img src={LearnPhoneImg} alt="phone" />
          </div>
          <div
            className="scrollToNexBtn"
            title="Scroll to instructors"
            onClick={(e) => scrollToNextSection(e, sectionInstructorRef)}
          >
            <p className="mouseBtnWrap">
              <span className="mouse">
                <span></span>
              </span>
            </p>
            <p className="text">next</p>
          </div>
        </section>
        <section
          className="otherSections sectionInsructors"
          ref={sectionInstructorRef}
        >
          <div className="innerInstructorSection">
            <h2>FREEDOM TO CHOOSE YOUR FAV INSTRUCTOR</h2>
            <h3 className="subHeadings">
              Enjoy your access to learn from celebrity instructors located
              globally, anytime, anywhere.
            </h3>
            <div className="instScroller">
              <ul>
                <li>
                  <img src={NimbulFunk} alt="nimble" />
                  <p className="name">NIMBLE FUNK</p>
                  {/* <p className="style">Hiphop</p> */}
                </li>
                <li>
                  <img src={Karthik} alt="karthik" />
                  <p className="name">
                    KARTHIK <br />
                    PRIYADARSHAN
                  </p>
                  {/* <p className="style">Hiphop</p> */}
                </li>
                <li>
                  <img src={SnehaInstructor} alt="sneha" />
                  <p className="name">Sneha Jadhav</p>
                  {/* <p className="style">Hiphop</p> */}
                </li>
                <li>
                  <img src={MohanPandey} alt="mohan" />
                  <p className="name">MOHAN PANDEY</p>
                  {/* <p className="style">Hiphop</p> */}
                </li>
                <li>
                  <img src={Gonza} alt="gonza" />
                  <p className="name">B BOY GONZA</p>
                  {/* <p className="style">Hiphop</p> */}
                </li>
              </ul>
            </div>
            <div
              className="scrollToNexBtn"
              title="Scroll to lessons"
              onClick={(e) => scrollToNextSection(e, sectionStylesRef)}
            >
              <p className="mouseBtnWrap">
                <span className="mouse">
                  <span></span>
                </span>
              </p>
              <p className="text">next</p>
            </div>
          </div>
        </section>
        <section className="otherSections sectionStyles" ref={sectionStylesRef}>
          <h2>Styles</h2>
          <h3 className="subHeadings">Wide range of dance styles to choose.</h3>
          <div className="instScroller styleScroller">
            <ul>
              <li>
                <img src={StyleHipHopImg} alt="hiphop" />
                <p className="name">hiphop</p>
              </li>
              <li>
                <img src={StyleClassicalImg} alt="classical" />
                <p className="name">bollywood</p>
              </li>
              <li>
                <img src={StyleFitnessImg} alt="freestyle" />
                <p className="name">freestyle</p>
              </li>
            </ul>
          </div>
          <div
            className="scrollToNexBtn"
            title="Scroll to lessons"
            onClick={(e) => scrollToNextSection(e, sectionChallengeRef)}
          >
            <p className="mouseBtnWrap">
              <span className="mouse">
                <span></span>
              </span>
            </p>
            <p className="text">next</p>
          </div>
        </section>
        <section
          className="otherSections challengeSection"
          ref={sectionChallengeRef}
        >
          <h2>Challenges - Go Full Out !</h2>
          <h3 className="subHeadings">up your game</h3>
          <div className="othersInnerSection">
            <p className="points">showcase your talent</p>
            <p className="points">compete win &amp; get fame</p>
            <p className="points">
              Judged by internationally renowned celebrity dancers.
            </p>
          </div>
          <div className="judgesSection">
            <div className="judges">
              <img src={Judge1} alt="judge1" />
              <p className="name">Omar Paloma</p>
              <div className="socials">
                <a
                  href="https://www.instagram.com/omarpaloma.op/"
                  title="see insta profile"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                {/* <a href="#link" title="see twitter profile" target="_blank" rel="noreferer noopener"><FaTwitter /></a> */}
              </div>
            </div>
            <div className="judges">
              <img src={NimbulFunk} alt="judge2" />
              <p className="name">NIMBLE FUNK</p>
              <div className="socials">
                <a
                  href="https://www.instagram.com/nimble_funk/"
                  title="see insta profile"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                {/* <a href="#link" title="see twitter profile" target="_blank" rel="noreferer noopener"><FaTwitter /></a> */}
              </div>
            </div>
            <div className="judges">
              <img src={Judge2} alt="judge3" />
              <p className="name">MARIA BUDOLAK</p>
              <div className="socials">
                <a
                  href="https://www.instagram.com/mariabudolak/"
                  title="see insta profile"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                {/* <a href="#link" title="see twitter profile" target="_blank" rel="noreferer noopener"><FaTwitter /></a> */}
              </div>
            </div>
            <div className="judges">
              <img src={Judge3} alt="judge4" />
              <p className="name">ADRIENN REZ</p>
              <div className="socials">
                <a
                  href="https://www.instagram.com/adriennrez/"
                  title="see insta profile"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                {/* <a href="#link" title="see twitter profile" target="_blank" rel="noreferer noopener"><FaTwitter /></a> */}
              </div>
            </div>
            <div className="judges">
              <img src={Karthik} alt="judge5" />
              <p className="name">KARTHIK PRIYADARSHAN</p>
              <div className="socials">
                <a
                  href="https://www.instagram.com/karthikpriyadarshan_/"
                  title="see insta profile"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                {/* <a href="#link" title="see twitter profile" target="_blank" rel="noreferer noopener"><FaTwitter /></a> */}
              </div>
            </div>
            <div className="judges">
              <img src={Gonza} alt="judge6" />
              <p className="name">B BOY GONZA</p>
              <div className="socials">
                <a
                  href="https://www.instagram.com/gonzabkk/"
                  title="see insta profile"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                {/* <a href="#link" title="see twitter profile" target="_blank" rel="noreferer noopener"><FaTwitter /></a> */}
              </div>
            </div>
          </div>
          <div
            className="scrollToNexBtn"
            title="Scroll to lessons"
            onClick={(e) => scrollToNextSection(e, sectionEventsGalleryRef)}
          >
            <p className="mouseBtnWrap">
              <span className="mouse">
                <span></span>
              </span>
            </p>
            <p className="text">next</p>
          </div>
        </section>
        <section
          className="otherSections eventsGallerySection"
          ref={sectionEventsGalleryRef}
        >
          <h2>Events gallery</h2>
          <div className="othersInnerSection">
            <div className="imageContainer">
              <div className="imgWrap horizontal">
                <img src={Horz3} alt="event 3" />
              </div>
              <div className="imgWrap">
                <img src={Big2} alt="event 8" />
              </div>
              <div className="imgWrap big">
                <img src={Horz2} alt="event 2" />
              </div>
              <div className="imgWrap vertical big">
                <img src={Vert1} alt="event 5" />
              </div>
              <div className="imgWrap horizontal big">
                <img src={Horz1} alt="event 1" />
              </div>
              <div className="imgWrap big">
                <img src={Big1} alt="event 7" />
              </div>
              <div className="imgWrap big">
                <img src={Vert2} alt="event 6" />
              </div>
              <div className="imgWrap big horizontal">
                <img src={Horz4} alt="event 4" />
              </div>
            </div>
          </div>
          <div
            className="scrollToNexBtn"
            title="Scroll to lessons"
            onClick={(e) => scrollToNextSection(e, sectionFeatureRef)}
          >
            <p className="mouseBtnWrap">
              <span className="mouse">
                <span></span>
              </span>
            </p>
            <p className="text">next</p>
          </div>
        </section>
        <section
          className="otherSections featureSection"
          ref={sectionFeatureRef}
        >
          <h2>Interactive learning experience</h2>
          <h3 className="subHeadings">
            A blended curated lessons by international &amp; renowned artist /
            instructors for all age groups &amp; level
          </h3>
          <div className="othersInnerSection">
            <div className="featureBoxes">
              <div className="box">
                <i className="icon">
                  <img src={MirrorIcon} alt="mirror" />
                </i>
                <p className="title">Mirror</p>
                <p className="desc">
                  Mirror view feature to get rid of left and right puzzlement.
                </p>
              </div>
              <div className="box">
                <i className="icon">
                  <img src={FrontBackIcon} alt="front-back" />
                </i>
                <p className="title">Front/Back</p>
                <p className="desc">
                  Flip the view from front to back or from back to front for
                  efficient and hassle-free understanding.
                </p>
              </div>
              <div className="box">
                <i className="icon">
                  <img src={SlowMoIcon} alt="slow-mo" />
                </i>
                <p className="title">Slow Motion</p>
                <p className="desc">play video at your own pace.</p>
              </div>
              <div className="box">
                <i className="icon">
                  <img src={RepeatIcon} alt="repeat" />
                </i>
                <p className="title">Repeat</p>
                <p className="desc">play any part of the session in loop.</p>
              </div>
              <div className="box">
                <i className="icon">
                  <img src={VRIcon} alt="vr-mode" />
                </i>
                <p className="title">VR &amp; 360</p>
                <p className="desc">Experience like learning in the studio.</p>
              </div>
            </div>
            <div className="phoneImage">
              <div className="imgWrap">
                <img src={MobileImgInteractive} alt="phone" />
              </div>
            </div>
          </div>
          <div
            className="scrollToNexBtn"
            title="Scroll to lessons"
            onClick={(e) => scrollToNextSection(e, sectionSubscriptionRef)}
          >
            <p className="mouseBtnWrap">
              <span className="mouse">
                <span></span>
              </span>
            </p>
            <p className="text">next</p>
          </div>
        </section>
        <section
          className="otherSections subscriptionSection"
          ref={sectionSubscriptionRef}
        >
          <h2>Da - your everyday dance partner</h2>
          <h3 className="subHeadings">
            Unlimited features in the affordable price.
          </h3>
          <div className="othersInnerSection">
            <div className="freeTrailSection innerSection">
              <p className="title">Basic</p>
              <p className="priceText">Free trail</p>
              <p className="desc">Free trail for 1 Month</p>
            </div>
            <div
              className="priceSection innerSection"
              onClick={(e) => history.push("/subscription")}
            >
              <p className="title">Startup</p>
              <p className="priceText">
                <span>&#8377;</span> 199 / month
              </p>
              <p className="desc">And enjoy unlimited access to our feaures</p>
            </div>
          </div>
          <a
            href="/subscription"
            className="btn primary-light get_started new-blue-primary btn-with-shadow"
            title="goto lessons"
          >
            Get started now
          </a>
        </section>
        <section className="otherSections lastInfoSection">
          <h2>be the next dance sensational</h2>
          <h3>watch, learn, dance, share &amp; tag us</h3>
          <ul className="menu-lists menu-lists-2">
            <li>
              <a
                href="https://www.instagram.com/boogaluapp/"
                title="Follow us on Insta"
                target="_blank"
                rel="noreferrer"
              >
                <i>
                  <FaInstagramSquare />
                </i>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=100069963219467"
                title="Like our FB page"
                target="_blank"
                rel="noreferrer"
              >
                <i>
                  <FaFacebookSquare />
                </i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCTKxp4m_Rlzfi1Kd_7sdbEA"
                title="Subscribe our channel"
                target="_blank"
                rel="noreferrer"
              >
                <i>
                  <FaYoutube />
                </i>
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
