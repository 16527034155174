import React from "react";

function JudgesPrivacyPolicy(props) {
  function closeModal() {
    props.closeModalBox(false);
  }

  return (
    <div className="judgesModalBox">
      <p className="closeModal" title="close" onClick={closeModal}></p>
      <h2>Privacy Statement</h2>
      <div className="innerInformationBox">
        <p>
          At CHOREO CULTURE PRIVATE LIMITED we recognize that privacy is
          important and we are committed to protecting your privacy rights
          ("Policy"). We use the information we collect about you to process
          orders, to improve the content of the Website and to provide a more
          personalized experience. We may also use it to periodically inform you
          about changes on our Website and new products and services. We do not
          sell, trade or rent your personal information to others.
        </p>
        <p>
          This Policy applies to all of the products, services and websites
          offered by Da, the mobile or tablet versions thereof, any Da software,
          and any applications created by Da whether available through a social
          networking site or its subsidiaries or affiliated companies
          (collectively, the "Service"). Please note that the availability of
          any Da applications on a social networking site, mobile or tablet
          device, internet television or other technology platform does not
          indicate any relationship or affiliation between Da and such social
          networking site, mobile or tablet device, or internet television or
          other technology platform. If you have any questions about this
          Policy, please feel free to contact us at&nbsp;
          <a href="mailto:info@appda.in" title="mail us">
            info@appda.in
          </a>
        </p>
        <p>
          By using the Service, you consent to the collection and use of your
          personal information as outlined in this Policy. As this Policy may be
          amended from time to time, you should visit this page periodically to
          review any changes to the Policy. Through your continued use of the
          Website after changes are made to the Policy, you accept the Policy as
          modified.
        </p>
        <h2>Information We Collect and How We Use It</h2>
        SECTION-1 : Information you provide
        <br />
        <p>
          When you sign up for a Da account we ask you for personal information
          (such as your name, username, phone number, email address, country,
          state, password, date of birth, gender, bio). We may combine the
          information you submit under your account with information from other
          Da services or third parties in order to provide you with a better
          experience and to improve the quality of our services.
        </p>
        <p>
          We also use your name, e-mail address and other information on our
          system to notify you of new product releases, service notifications,
          events, and to solicit your feedback and input. We may also use your
          information, or a portion thereof, to send you marketing materials
          and/or Da newsletters or other information Da thinks you may need,
          unless you notify Da that you do not wish to receive such materials.
          Please{" "}
          <a href="mailto:info@appda.in" title="mail us">
            info@appda.in
          </a>{" "}
          to request that you not be sent any or all of such information.
        </p>
        SECTION 2 - Da cookies
        <br />
        Da
        <p>
          When you visit Da, we send one or more cookies - a small file
          containing a string of characters - to your computer that uniquely
          identifies your browser. We use cookies to improve the quality of our
          Service by storing user preferences and tracking user trends. Most
          browsers are initially set up to accept cookies, but you can reset
          your browser to refuse all cookies or to indicate when a cookie is
          being sent. However, some Da features and services may not function
          properly if your cookies are disabled. Cookies collect the date and
          time of your visit and your registration information.
        </p>
        <p>
          These cookies may either be permanent or temporary. Da uses permanent
          cookies. This means that the cookie stays on your hard drive until you
          delete it. You can delete our cookie at any time by following the
          instructions contained in your browser’s help file, or by contacting
          that company providing your browser’s technical support.
        </p>
        <p>
          Da does not use cookies to store information such as credit/debit card
          numbers, telephone numbers, or any other sensitive information. Da may
          use cookies to deliver content specific to your interests, to save
          your password so you don’t have to re-enter it each time you visit our
          site, or for other purposes.
        </p>
        SECTION 3 - Activity Information
        <br />
        <p>
          In order to provide the best possible service and to allow us to make
          certain internal reports and make recommendations, we collect
          aggregate information about the use of the Service, including
          information about users accessing the Website such as internet
          protocol addresses, browser type, browser language, referring / exit
          pages and URLs, other browser history, platform type, number of
          clicks, domain names, landing pages, pages viewed and the order of
          those pages, the amount of time spent on particular pages, and the
          date and time. By using the Service, you consent to our retaining all
          of your Activity Information.
        </p>
        SECTION 4 - IP Address
        <br />
        <p>
          An Internet Protocol (IP) address is a number that's automatically
          assigned to your computer whenever you're surfing the Web. We do not
          retain your IP address as this is legal only when user consent is
          taken. We take few data such as location, time, frequency of surfing
          to improve the services.
        </p>
        SECTION 5 - User communications
        <br />
        <p>
          When you send email or other communication to Da, we may retain those
          communications in order to process your inquiries, respond to your
          requests and improve our services.
        </p>
        SECTION 6 - Affiliated sites <br />
        <p>
          We offer some of our services in connection with other websites.
          Personal information that you provide to those websites may be sent to
          Da in order to deliver the Service. We process such information in
          accordance with this Policy. The affiliated websites may have
          different privacy practices and we encourage you to read their privacy
          policies. <strong>Links</strong> - Da may present links in a format
          that enables us to keep track of whether these links have been
          followed. We use this information to improve the quality of our
          Website.
        </p>
        SECTION 7 - Other websites
        <br />
        <p>
          This Policy applies to websites and services that are owned and
          operated by Da. We do not exercise control over the websites displayed
          as search results or links from within our various services. These
          other websites may place their own cookies or other files on your
          computer, collect data or solicit personal information from you.
        </p>
        <h2>What Does Da Do With The Collected Information?</h2>
        SECTION 1 - Personal information
        <br />
        <p>
          We may use your personal information for the purposes described in
          this Policy and/or privacy notice for specific services. Such purposes
          include, but are not limited to: Providing our products and services
          to users, including the display of customized content and advertising;
          Sharing information to our business partners who perform core services
          (such as hosting, billing, data storage, security, and reporting
          services) related to the operation of the Website and Service;
          Collecting and processing information about your actual location to
          provide location aware products and services and other content;
          Verifying your identity; Following up with transactions initiated on
          the Website; Auditing, research and analysis in order to maintain,
          protect and improve our services; Ensuring the technical functioning
          of our network; and Developing new services.
        </p>
        <p>
          Da processes personal information on its servers in the India and in
          other countries. In some cases, we process personal information on a
          server outside your own country. We provide personal and non-personal
          information to our subsidiaries, affiliated companies or other trusted
          third parties or persons for the purpose of processing personal
          information on our behalf. We require that these parties agree to
          process such personal information based on our instructions and in
          compliance with the appropriate confidentiality and security measures.
        </p>
        <p>
          We may also disclose personal information if required to do so by law
          or in the good-faith belief that such action is necessary to: <br />
          (1) conform to the edicts of the law or comply with legal process
          served on Da or its parent company, subsidiaries, or affiliates,{" "}
          <br />
          (2) protect and defend the rights or property of Da or the users of
          the Website, or <br />
          (3) act under exigent circumstances to protect the safety of the
          public or users of the Website.
        </p>
        SECTION 2 - Choices for personal information <br />
        <p>
          When you sign up for the Service, we ask you to provide personal
          information. If we use this information in a manner different than
          those described in this Policy and/or in the specific service notices,
          then we will ask for your consent prior to such use.
        </p>
        <p>
          If we propose to use personal information for any purposes other than
          those described in this Policy and/or in the specific service notices,
          and you decline, Da may not be able to provide its Service to you.
        </p>
        SECTION 3 - Maintenance of personal information
        <br />
        <p>
          You can access your information contained in Da’s database by emailing
          us on info@appda.in. If you believe any of your information is
          incorrect or needs updating, please let us know at the email address
          listed in this section. Da will use reasonable efforts to correct your
          information and our records upon verification of the requested change.
          If you no longer wish to receive email notifications from Da, please
          let us know by emailing us on{" "}
          <a href="mailto:info@appda.in" title="mail us">
            info@appda.in
          </a>
          . In the text of your email, specify which notifications you no longer
          wish to receive and provide your exact name, email address and/or
          address so that we can do our best to ensure that you stop receiving
          the notifications that you no longer wish to receive. After such a
          request is received, Da will use reasonable efforts to refrain from
          including you when sending such notifications to Da’s
          visitors/users/customers.
        </p>
        <p>
          We do not currently maintain any procedures for you to review the
          information that we collect about you.
        </p>
        SECTION 4 - Retention of personal information
        <br />
        <p>
          When you close or de-activate your account or if your account is
          otherwise terminated, we have the right, but not the obligation, to
          store your personal information, settings, saved and completed
          classes, and teachers you have followed.
        </p>
        SECTION 5 - Information Security
        <br />
        <p>
          We take appropriate security measures to protect against unauthorized
          access to or unauthorized alteration, disclosure or destruction of
          data. These include internal reviews of our data collection, storage
          and processing practices and security measures, as well as physical
          security measures to guard against unauthorized access to systems
          where we store personal data.
        </p>
        <p>
          We restrict access to personal information to Da employees,
          contractors and agents who need to know that information in order to
          operate, develop or improve our services. These individuals are bound
          by confidentiality obligations and may be subject to discipline,
          including termination and criminal prosecution, if they fail to meet
          these obligations.
        </p>
        <p>
          However, please note that no data transmission over the Internet can
          be guaranteed to be 100% secure, so while Da strives to protect your
          information, Da cannot ensure or warrant the security of any
          information that you voluntarily give to Da.
        </p>
        SECTION 6 - Accessing and updating personal information <br />
        <p>
          When you use the Services, we make good faith efforts to provide you
          with access to your personal information and either to correct this
          data if it is inaccurate or to delete such data at your request if it
          is not otherwise required to be retained by law or for legitimate
          business purposes. We ask individual users to identify themselves and
          the information requested to be accessed, corrected or removed before
          processing such requests, and we may decline to process requests that
          are unreasonably repetitive or systematic, require disproportionate
          technical effort, jeopardize the privacy of others, or would be
          extremely impractical (for instance, requests concerning information
          residing on backup tapes), or for which access is not otherwise
          required. In any case where we provide information access and
          correction, we perform this service free of charge, except if doing so
          would require a disproportionate effort.
        </p>
        <h2>Website Areas Beyond Da’s Control</h2>
        SECTION 1 - Third party links, co-brand relationships and public forums
        <br />
        <p>
          In an attempt to provide increased value to our visitors, Da may
          choose various third party websites to link to, link from, and frame
          within, the Website. Da also may participate in co-branding and other
          relationships to offer e-commerce and other services and features to
          its users. However, even if the third party is affiliated with Da, we
          have no control over these linked sites, each of who have separate
          privacy and data collection practices independent of Da. We have no
          responsibility or liability for these independent policies or actions,
          and are not responsible for the privacy practices or the content of
          any such web sites. Please make sure you are comfortable with the
          privacy practices of every site you visit before providing the site
          with your personal information. These linked sites are only for your
          convenience and you therefore access them at your own risk.
        </p>
        <p>
          We also may make chat rooms, forums and message boards available to
          you on the Website. Please remember that we cannot control the
          information that is shared by members and that anything you
          voluntarily provide in any public area of the Internet will be
          publicly available to other visitors on that web site and potentially
          to other third parties. Thus, please note that you should always
          exercise caution when deciding to publicly disclose any of your
          personal information in these and similar areas.
        </p>
        SECTION 2 - Enforcement
        <br />
        <p>
          Da regularly reviews its compliance with this Policy. Please feel free
          to direct any questions or concerns regarding this Policy or Da's
          treatment of personal information by emailing us on{" "}
          <a href="mailto:info@appda.in" title="mail us">
            info@appda.in
          </a>
          . When we receive formal written complaints at this address, it is
          Da's policy to contact the complaining user regarding his or her
          concerns. We will cooperate with the appropriate regulatory
          authorities, including local data protection authorities, to resolve
          any complaints regarding the transfer of personal data that cannot be
          resolved between Da and an individual.
        </p>
        SECTION 3 - Changes To This Policy
        <br />
        <p>
          Please note that this Policy may change from time to time. We will not
          reduce your rights under this Policy without your explicit consent,
          and we expect most such changes will be minor. Regardless, we will
          post any Policy changes on this page and, if the changes are
          significant, we will provide a more prominent notice (including, for
          certain services, email notification of Policy changes). Each version
          of this Policy will be identified at the bottom of the page by its
          effective date, and we will also keep prior versions of this Privacy
          Policy in an archive for your review.
        </p>
        SECTION 4 - Da Terms and Conditions of Use
        <br />
        <p>
          A complete statement of our Terms and Conditions of Use can be found{" "}
          <a
            href="/termsandconditions"
            target="_blank"
            rel="noreferrer"
            title="terms and conditions"
          >
            Here
          </a>
          . Da's Terms and Conditions of Use are expressly incorporated into
          this Policy by this reference.
        </p>
        SECTION 5 - Your consent <br />
        <p>
          By using our Website, you consent to the collection and use of
          information by Da as set forth in this Policy. If we decide to change
          our Policy, we will post those changes on this page so that you will
          always be aware of what information we collect, how we use it, and
          under what limited circumstances we disclose it. Continued access or
          use of the Website shall constitute your express acceptance of any
          modifications to this Policy.
        </p>
      </div>
    </div>
  );
}

export default JudgesPrivacyPolicy;
