/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { OPENING_HOURS } from "../../Constants";
import { alpha } from "@material-ui/core/styles";
import moment from "moment";
const OpeningHours = (props) => {
  const {
    data,
    setOpeningHours,
    parentNode,
    parentIndex,
    currentNode,
    currentIndex,
    updatedTimingsData,
    selectedTiming,
  } = props;
  const [timings, setTimings] = useState(structuredClone(OPENING_HOURS));

  useEffect(() => {
    if (selectedTiming && selectedTiming.length > 0) {
      for (let i = 0; i < timings.length; i++) {
        // const element = timings[i];
        for (let j = 0; j < selectedTiming.length; j++) {
          // const element = selectedTiming[j];
          if (timings[i].id === selectedTiming[j].id) {
            timings[i] = selectedTiming[j];
          }
        }
      }
      setTimings(timings);
    }
  }, [selectedTiming, timings]);

  const handleTimeChange = (date, item, type) => {
    const timeValue = moment(date).format("hh:mm a");
    const { id } = item;
    for (let index = 0; index < timings.length; index++) {
      const element = timings[index];
      if (element?.id === id) {
        element[type] = timeValue;
      }
    }
    const checkedItems = timings.filter((childItem) => childItem.isSelected);
    if (parentNode && currentNode) {
      const tempOpeningHours = {
        parentNode,
        currentNode,
        parentIndex,
        currentIndex,
        checkedItems,
        expanded: true,
      };
      updatedTimingsData(tempOpeningHours);
    } else {
      updatedTimingsData(checkedItems);
    }
    setTimings([...timings].flat());
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    for (let index = 0; index < timings.length; index++) {
      const element = timings[index];
      if (element?.id === name) {
        element.isSelected = checked ? true : false;
      }
    }
    const checkedItems = timings.filter((childItem) => childItem.isSelected);
    if (parentNode && currentNode) {
      const tempOpeningHours = {
        parentNode,
        currentNode,
        parentIndex,
        currentIndex,
        checkedItems,
        expanded: true,
      };
      updatedTimingsData(tempOpeningHours);
    } else {
      updatedTimingsData(checkedItems);
    }
    setTimings([...timings].flat());
  };
  return (
    <FormControl variant="outlined" className="input-field">
      <FormLabel component="legend" required>
        Opening Hours
      </FormLabel>
      {timings &&
        timings.length > 0 &&
        timings.map((item, itemIndex) => {
          return (
            <div className="timings-row" key={`${itemIndex + 1}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={item?.id}
                    onChange={handleChange}
                    checked={item?.isSelected}
                  />
                }
                label={item?.label}
                className="day-label"
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  size="small"
                  inputVariant="outlined"
                  format="HH:mm"
                  disabled={item?.isSelected ? false : true}
                  margin="normal"
                  id={`${item?.id}OpensAt`}
                  label="Opens at"
                  value={moment("2023-12-31 " + item?.opensAt)}
                  onChange={(date) => handleTimeChange(date, item, "opensAt")}
                  inputFormat="HH:MM"
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  size="small"
                  inputVariant="outlined"
                  format="HH:mm"
                  disabled={!item?.isSelected}
                  margin="normal"
                  id={`${item?.id}ClosesAt`}
                  label="Closes at"
                  value={moment("2023-12-31 " + item?.closesAt)}
                  onChange={(event) =>
                    handleTimeChange(event, item, "closesAt")
                  }
                  inputFormat="HH:MM"
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          );
        })}
    </FormControl>
  );
};

export default OpeningHours;
