import React, { useState, useRef, useEffect } from 'react';
import { MdShare, MdLock } from 'react-icons/md';
import { useStoreConsumer } from '../../Providers/StateProvider';
import { useHistory } from "react-router-dom";
import { enableLoginFlow } from "../../Actions/LoginFlow";
import { isObjectEmpty } from '../../helpers';
import { updateLessonPlayTime, getLessonPlayedTime } from '../../Services/Lessons.service';
import { updateLessonsTaken, getUsersLessonsOnly } from '../../Services/User.service';
import { loginUser } from '../../Actions/User/index';
import VideoWrapper from '../VideoWrapper/VideoWrapper';

function LessonsVideoContainer({
    lessonKey,
    title,
    artist,
    desc,
    videoUserLevel,
    artForm,
    duration,
    isPaid,
    thumbNail,
    activeVideosList,
    m3u8VideoList,
    videoId
}) {
    const { state, dispatch } = useStoreConsumer();
    const loggedInUser = state.loggedInUser;
    const history = useHistory();
    const [lastVideoPlayedTime, setCurrentVideoPlayTime] = useState(0);
    const [isVideoOverlayActive, toggleisVideoOverlayActive] = useState(false);
    const [isLoggedInUser, setLoggedInUserValue] = useState(false);
    const [isSubscribedUser, setSubscribedUser] = useState(false);
    const [showVideoWrapperModal, setShowVideoWrapperModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [shouldShowPaymentStrip, setPaymentStripVisiblity] = useState(false);
    const thumbNailOverlayRef = useRef(null);

    useEffect(() => {
        if (!isObjectEmpty(loggedInUser)) {
            setLoggedInUserValue(true);
        }

        if (!isObjectEmpty(loggedInUser) && state.loggedInUser.subscribed) {
            setSubscribedUser(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setVideoCurrentTimeToDB(lastPlayedTime) {
        if (lastPlayedTime) {
            const videoData = {
                userKey: loggedInUser.key,
                playedTime: lastPlayedTime
            }
            try {
                updateLessonPlayTime(lessonKey, videoData).subscribe(res => {
                    console.log('Res: ', res);
                });
            } catch (e) {
                console.log('Update lesson Error: ', e);
            }
        }
    }

    function fetchLessonPlayedTime() {
        try {
            getLessonPlayedTime(lessonKey).subscribe(resp => {
                if (resp && resp.length) {
                    const matchedUser = resp.filter(user => user.userKey === loggedInUser.key);
                    if (matchedUser?.length && matchedUser[0]?.playedTime) {
                        setCurrentVideoPlayTime(Math.round(matchedUser[0].playedTime));
                        setShowVideoWrapperModal(true);
                    } else {
                        setShowVideoWrapperModal(true);
                    }
                } else {
                    setShowVideoWrapperModal(true);
                }
            });
        } catch(e) {
            console.log('lesson time error: ', e);
        }
    }

    function toggleVideoOverlay(event, overlayBox) {
        event.stopPropagation();
        if (isLoggedInUser) {
            if (isPaid === 'free' || (isPaid === 'paid' && isSubscribedUser)) {
                toggleisVideoOverlayActive(true);
                fetchLessonPlayedTime();
            } else {
                redirectToSubscription();
            }
        } else {
            dispatch(enableLoginFlow({ type: 'lessons' }));
            history.push({
                pathname: '/login',
                state: null
            });
        }
    }

    function redirectToSubscription() {
        dispatch(enableLoginFlow({ type: 'subscription' }));
        history.push({
            pathname: '/subscription',
            search: `?from=lesson&planType=${isPaid === 'paid' ? 'startup' : isPaid}`,
            state: null
        });
    }

    function shareLessonDetails(event) {
        event.stopPropagation();
        if (navigator.share) {
            navigator.share({
                title: 'Learn from the Experts',
                url: `${window.location.origin}/lessons`
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        }
    }

    function redirectToLogin(event) {
        event.stopPropagation();
        history.push('/login');
    }

    function playStopPreviewVideo(event, action) {
        event.stopPropagation();
        if (!isVideoOverlayActive) {
            const previewVideoItem = event.currentTarget.querySelectorAll('.js-previewVideo')[0];
            if (action && action === 'play') {
                const previePlay = previewVideoItem.play();
                if (previePlay !== undefined) {
                    previePlay.then(_ => {
                        console.log('Preview played..');
                    }).catch(e => {
                        console.log('Video play error: ', e);
                    });
                }
            } else {
                previewVideoItem.currentTime = 0;
                previewVideoItem.pause();
            }
        }
    }

    function setLessonsDataInUser(data) {
        if (loggedInUser?.key && loggedInUser?.myLessons) {
            loggedInUser.myLessons.push(data);
        } else {
            loggedInUser['myLessons'] = [data];
        }
        dispatch(loginUser(loggedInUser));
        try {
            getUsersLessonsOnly(loggedInUser.key).subscribe(resp => {
                if (resp?.myLessons?.length) {
                    const isLessonPresent = resp?.myLessons.some(item => item.lessonKey === data.lessonKey);
                    if (!isLessonPresent) {
                        updateLessonsTaken(loggedInUser.key, loggedInUser).subscribe(resp => {
                            console.log("User: ", resp);
                        });
                    }
                } else {
                    updateLessonsTaken(loggedInUser.key, loggedInUser).subscribe(resp => {
                        console.log("User: ", resp);
                    });
                }
            });
        } catch (e) {
            console.log('lesson saved error!: ', e);
        }
    }

    const handleVdoWrapperClose = () => {
        setShowVideoWrapperModal(false);
        toggleisVideoOverlayActive(false);
    }

    return (
        <div className={`video-component-wrap ${isPaid}`} onMouseOver={(e) => playStopPreviewVideo(e, 'play')} onMouseLeave={(e) => playStopPreviewVideo(e, 'stop')}>
            <div className="videoThumbnailOverlay" ref={thumbNailOverlayRef} onClick={(e) => toggleVideoOverlay(e, `js-${videoId}`)}>
                <img src={thumbNail} alt="preview" className={isVideoOverlayActive ? 'hideImage' : ''} />
            </div>
            {
                !isVideoOverlayActive ?
                    <div className="previewVideoWrap">
                        {
                            activeVideosList?.preview ?
                                <video controlsList="nodownload" muted className="js-previewVideo" onClick={(e) => toggleVideoOverlay(e, `js-${videoId}`)}>
                                    <source src={activeVideosList?.preview} type="video/mp4" />
                                </video> : ''
                        }
                    </div>
                    : ''
            }

            {
                shouldShowPaymentStrip && isPaid === 'free' ?
                    <span className="diagonalStrip free">
                        <span>Free</span>
                    </span>
                    : ''
            }

            {
                shouldShowPaymentStrip && isPaid === 'paid' ?
                    <span className="diagonalStrip paid">
                        <span>Startup</span>
                    </span>
                    : ''
            }

            {
                shouldShowPaymentStrip && isPaid === 'pro' ?
                    <span className="diagonalStrip pro">
                        <span>Pro</span>
                    </span>
                    : ''
            }

            {
                shouldShowPaymentStrip && isPaid === 'premium' ?
                    <span className="diagonalStrip premium">
                        <span>Premium</span>
                    </span>
                    : ''
            }

            {
                (!isLoggedInUser && isPaid === 'free') ?
                    <p className="lockIconWrap" title="Login to unlock this lesson" onClick={(e) => redirectToLogin(e)}>
                        <MdLock />
                        {
                            (isPaid === 'paid' || isPaid === 'pro' || isPaid === 'premium') ?
                            <span className="paidInfoLabel">Paid</span>
                            : ''
                        }
                    </p> :
                    (isLoggedInUser && (!isSubscribedUser && (isPaid === 'paid' || isPaid === 'pro' || isPaid === 'premium'))) ?
                        <p className="lockIconWrap" title="Subscribe to unlock this lesson" onClick={(e) => redirectToLogin(e)}>
                            <MdLock />
                            {
                            (isPaid === 'paid' || isPaid === 'pro' || isPaid === 'premium') ?
                                <span className="paidInfoLabel">Paid</span>
                                : ''
                            }
                        </p> :
                        (!isLoggedInUser && !isSubscribedUser && (isPaid === 'paid' || isPaid === 'pro' || isPaid === 'premium')) ?
                            <p className="lockIconWrap" title="Login &amp; subscribe to unlock this lesson" onClick={(e) => redirectToLogin(e)}>
                                <MdLock />
                                {
                                    (isPaid === 'paid' || isPaid === 'pro' || isPaid === 'premium') ?
                                    <span className="paidInfoLabel">Paid</span>
                                    : ''
                                }
                            </p> : ''
            }

            <div className="videoInfoWrap" onClick={(e) => toggleVideoOverlay(e, `js-${videoId}`)}>
                <h4>{title}</h4>
                <p className="desc">{desc}</p>
                <p className="subTexts">
                    <span>
                        By: <strong>{artist}</strong>
                    </span>
                    {
                        duration ?
                        <span>
                            &nbsp; | &nbsp;
                            <strong>{duration}</strong>
                        </span>
                        : ''
                    }
                </p>
            </div>

            <i className="shareIcon" title="Share this lesson" onClick={(e) => shareLessonDetails(e)}>
                <MdShare />
            </i>

            <div className="videoTags">
                {
                    videoUserLevel ?
                        <span className={`userLevel ${videoUserLevel.toLowerCase()}`}>{videoUserLevel}</span>
                        : ''
                }

                {
                    artForm ?
                        <span className="danceStyle">{artForm}</span>
                        : ''
                }
            </div>
            {showVideoWrapperModal && <VideoWrapper
                m3u8VideoList={m3u8VideoList}
                videosList={activeVideosList}
                loggedInUser={loggedInUser}
                videoId={videoId}
                videoLastPlayedTime={lastVideoPlayedTime}
                thumbNail={thumbNail}
                setVideoCurrentTimeToDB={(e) => setVideoCurrentTimeToDB(e)}
                handleVdoWrapperClose={() => handleVdoWrapperClose()}
                setLessonsDataInUser={(data) => setLessonsDataInUser(data)}
                userLesson={{
                    "lessonKey": lessonKey,
                    "title": title,
                    "artForm": artForm,
                    "isPaid": isPaid,
                    "thumbNail": thumbNail,
                }}
            />}
        </div>
    )
};

export default LessonsVideoContainer;