import { Observable } from 'rxjs';
import { getServerTimeStamp } from "./firebase";
import db from '../Database';

const selectedUserRef = db.collection('selectedUsersForComp');

export const SaveSelectedUser = (data) => {
    data.createdOn = new Date();
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        selectedUserRef.add(data).then((doc) => {
            observer.next({
                key: doc.id,
            });
        });
    });
}

export const GetUserByEmail = (email) => {
    return new Observable((observer) => {
        selectedUserRef.where('email', '==', email).get().then((querySnapshot) => {
            let user = []
            querySnapshot.forEach(function (doc) {
                let data = doc.data();
                data.key = doc.id;
                user.push(data);
            })
            observer.next(user);
        })
    })
}

export const GetUserByCompId = (key) => {
    return new Observable((observer) => {
        selectedUserRef.where('compKey', '==', key).get().then((querySnapshot) => {
            let user = []
            querySnapshot.forEach(function (doc) {
                let data = doc.data();
                data.key = doc.id;
                user.push(data);
            })
            observer.next(user);
        })
    })
}

export function DeleteSelectedUserEntry(id) {
    return new Observable((observer) => {
        selectedUserRef.doc(id).delete().then(() => {
            console.log("Document successfully deleted!");
            observer.next({ deleted: true });
        }).catch((error) => {
            console.error("Error removing document: ", error);
            observer.next({ deleted: false, error: error });
        });
    });
}

export const serverTimeStamp = () => {
    return getServerTimeStamp();
}
