import React, { useEffect, useState } from "react";
import { useStoreConsumer } from "../../Providers/StateProvider";
import ProfileImage from "../ProfileImage";
import { getAllUserLazyLoad } from "../../Services/User.service";
import { useHistory } from "react-router-dom";
import FollowButton from "../FollowButton";
import Loader from "../Loader";
import { logAnalyticsEvent } from "../../Services/analytics.service";
// import { getUserById, updateUser, updateFollowUnfollow } from "../../Services/User.service";

let lastUserDataForCall = null;
let previousStoredData = [];
let scrollCount = 0;

function ViewAllMembers() {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [followButtonText, setFollowButtonText] = useState("Follow");
  // const [followStatus, setFollowStatus] = useState("");
  const [userList, setUserList] = useState([]);
  const [isLoadinMoreVisible, toggleLoadingMore] = useState(false);
  const [isAllDataLoaded, setAllDataLoaded] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading more...");
  const { state } = useStoreConsumer();
  const loggedInUser = state.loggedInUser;
  const [isLoaderActive, toggleLoading] = useState(false);

  useEffect(() => {
    logAnalyticsEvent("page_view", {
      page_location: window.location.href,
      page_path: "view-members",
      page_title: `HomePage-${window.location.href}`,
    });
  }, []);

  useEffect(() => {
    if (loggedInUser && loggedInUser.key) {
      setUserList([]);
      getAllUserList(loggedInUser.key, lastUserDataForCall);
    } else {
      redirectToLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onListScroll(event) {
    if (event?.currentTarget && !isAllDataLoaded) {
      if (
        event.currentTarget.offsetHeight + event.currentTarget.scrollTop + 5 >=
        event.currentTarget.scrollHeight
      ) {
        if (scrollCount === 0) {
          scrollCount++;
          getAllUserList(null, lastUserDataForCall);
        }
      }
    }
  }

  function redirectToLogin() {
    history.push("/login");
  }

  function getAllUserList(userKey, lastUser) {
    if (userKey) {
      toggleLoading(true);
    } else {
      toggleLoadingMore(true);
    }
    try {
      getAllUserLazyLoad(lastUser).subscribe((users) => {
        if (users) {
          let updatedUserList = [];
          lastUserDataForCall = null;
          if (userKey) {
            toggleLoading(false);
          } else {
            toggleLoadingMore(false);
          }
          if (users && users.length) {
            users.forEach((user, userIndex) => {
              let currentuser = user;
              if (currentuser && !currentuser.privacy) {
                currentuser = { ...currentuser, privacy: "public" };
              }
              if (currentuser.following && currentuser.following.length > 0) {
                const userFollowingMe = currentuser.following.filter(
                  (requestId) => requestId === loggedInUser.key
                );
                if (userFollowingMe && userFollowingMe.length > 0) {
                  currentuser = {
                    ...currentuser,
                    followingLoggedInUser: true,
                  };
                }
              }
              updatedUserList.push(currentuser);
            });
            if (updatedUserList.length) {
              for (let index = 0; index < updatedUserList.length; index++) {
                let userItem = updatedUserList[index];
                if (userItem.privacy.toLowerCase() === "public") {
                  if (userItem.followedBy && userItem.followedBy.length > 0) {
                    const amIFollowing = userItem.followedBy.filter(
                      (requestId) => requestId === loggedInUser.key
                    );
                    if (amIFollowing && amIFollowing.length > 0) {
                      updatedUserList[index] = {
                        ...updatedUserList[index],
                        imFollowing: true,
                        followButtonStatus: "following",
                      };
                      // setFollowStatus("following");
                    } else {
                      updatedUserList[index] = {
                        ...updatedUserList[index],
                        followButtonStatus: "",
                      };
                      // setFollowStatus("");
                    }
                  } else {
                    updatedUserList[index] = {
                      ...updatedUserList[index],
                      followButtonStatus: "",
                    };
                    // setFollowStatus("");
                  }
                } else if (userItem.privacy.toLowerCase() === "private") {
                  if (userItem) {
                    if (
                      userItem.followRequestedBy &&
                      userItem.followRequestedBy.length > 0
                    ) {
                      const amIFollowing = userItem.followRequestedBy.filter(
                        (requestId) => requestId === loggedInUser.key
                      );
                      if (amIFollowing && amIFollowing.length > 0) {
                        updatedUserList[index] = {
                          ...updatedUserList[index],
                          iRequestedFollow: true,
                          followButtonStatus: "requested",
                        };
                        // setFollowStatus("requested");
                      } else {
                        updatedUserList[index] = {
                          ...updatedUserList[index],
                          followButtonStatus: "",
                        };
                        // setFollowStatus("");
                      }
                    } else if (
                      userItem.followedBy &&
                      userItem.followedBy.length > 0
                    ) {
                      const amIFollowing = userItem.followedBy.filter(
                        (requestId) => requestId === loggedInUser.key
                      );
                      if (amIFollowing && amIFollowing.length > 0) {
                        updatedUserList[index] = {
                          ...updatedUserList[index],
                          imFollowing: true,
                          followButtonStatus: "following",
                        };
                        // setFollowStatus("following");
                      } else {
                        updatedUserList[index] = {
                          ...updatedUserList[index],
                          followButtonStatus: "",
                        };
                        // setFollowStatus("");
                      }
                    } else {
                      updatedUserList[index] = {
                        ...updatedUserList[index],
                        followButtonStatus: "",
                      };
                      // setFollowStatus("");
                    }
                  } else {
                    updatedUserList[index] = {
                      ...updatedUserList[index],
                      followButtonStatus: "",
                    };
                    // setFollowStatus("");
                  }
                } else {
                  updatedUserList[index] = {
                    ...updatedUserList[index],
                    followButtonStatus: "",
                  };
                  // setFollowStatus("");
                }
              }
            }
            const newUserList = [...previousStoredData, ...updatedUserList];
            previousStoredData = newUserList;
            setUserList(newUserList);
            lastUserDataForCall = newUserList[newUserList.length - 1];
            scrollCount = 0;
          }
        } else {
          setAllDataLoaded(true);
          scrollCount = 0;
          setLoadingMessage("Loaded all Da users");
          setTimeout(() => {
            toggleLoadingMore(false);
          }, 1000);
        }
      });
    } catch (e) {
      if (userKey) {
        toggleLoading(false);
      } else {
        toggleLoadingMore(false);
      }
      console.log("Users fetch error: ", e);
    }
  }

  const callbackHandler = () => {
    const lastUserDoc = previousStoredData[previousStoredData.length - 10];
    getAllUserList(null, lastUserDoc);
  };

  const redirectToUserProfile = (event, user) => {
    history.push(`/profile/${window.btoa(user.email)}`);
  };

  return (
    <div className="userDashBoardAfterLogin viewAllMemberDashBoard paddingTop90">
      <Loader value={isLoaderActive} />
      <div className="user-dashboard-wrap membersUserDashBoard">
        <h2>Our members</h2>
        <div className="user-list-wrap" onScroll={(e) => onListScroll(e)}>
          {userList &&
            userList.map((user, index) => {
              return (
                <div
                  key={`${user.key.split("").join(`-BU-${index}`)}`}
                  className="user-icon-wrap userMemberListing"
                  title={`View ${user.username}`}
                  style={{ zIndex: userList.length - index }}
                >
                  <ProfileImage
                    src={user.profileImage}
                    size="medium"
                    onClick={(e) => redirectToUserProfile(e, user)}
                  />
                  <div
                    className="userNameWrap"
                    onClick={(e) => redirectToUserProfile(e, user)}
                  >
                    <span className="userName">{user.username}</span>
                    <span className="userFullName">{user.name}</span>
                    {/* This will be dynamic data */}
                    <span className="followsInfo">
                      {user.followingLoggedInUser ? `Follows you / ` : ""}
                      {user.followedBy?.length > 0
                        ? `Followed by ${user.followedBy?.length}`
                        : ""}
                    </span>
                  </div>

                  <FollowButton
                    status={user.followButtonStatus}
                    onClickHandler={callbackHandler}
                    user={user}
                    loggedInUser={loggedInUser}
                  />
                </div>
              );
            })}
        </div>
        {isLoadinMoreVisible ? (
          <div className="loadingMoreData">{loadingMessage}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ViewAllMembers;
