import { Observable } from 'rxjs';
import db from '../Database';

const studioRef = db.collection('enrolledOfflineStudio');

export function enrolledOfflineStudio(data) {
    data.createdOn = new Date();
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        studioRef.add(data).then((doc) => {
            observer.next({
                key: doc.id,
            });
        });
    });
};