import React from "react";
import UseWindowDimensions from '../WindowDimensionHook';
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const ImageCarousel = (props) => {
    const { carouselData, upcomingData } = props;

    let newCarouselData = [];
    
    newCarouselData = [...carouselData || [], ...upcomingData || []];
    
    const { viewportWidth } = UseWindowDimensions();

    function eventDataTrigger(event, data) {
        event.stopPropagation();
        props.imgClickCallback(data);
    }

    function onSliderChange() {
        const videoTag = document.getElementById('previewVideo');
        if (videoTag && !videoTag.paused) {
            videoTag.pause();
        }
    }

    return (
        <div className="carouselWrap">
            {
                (newCarouselData && newCarouselData.length) ?
                    <Carousel
                        autoPlay={false}
                        interval={3500}
                        infiniteLoop={true}
                        showThumbs={false}
                        onChange={() => onSliderChange()}
                    >
                        {
                            newCarouselData.map((item, index) => {
                                return (
                                    <div
                                        className="imageContentWrap" key={`event-banner-${index}`}
                                        aria-label={`${item?.name || item?.id}-${index} aria label`}
                                        onClick={(e) => eventDataTrigger(e, item)}
                                    >
                                        {
                                            props?.dataFrom === 'studio' ?
                                                item?.id === 'introVideo' ?
                                                <video 
                                                    id="previewVideo" 
                                                    width="100%" 
                                                    height="100%"
                                                    autoPlay
                                                    loop
                                                    muted 
                                                    controls
                                                    controlsList="disablepictureinpicture nodownload noremoteplayback noplaybackrate"
                                                >
                                                    <source src={item.downloadURL} type="video/mp4" />
                                                </video> :
                                                <img className="loading" src={item.downloadURL} alt={item.id} /> 
                                            :
                                            <>
                                                {
                                                    viewportWidth <= 640 ?
                                                        <img className="loading" src={item?.imgUrlMobile || item?.bannerMobileImg} alt={`banner-${index}-mobile`} />
                                                        :
                                                        <img className="loading" src={item?.imgUrl || item?.bannerImg} alt={`banner-${index}-desktop`} />
                                                }
                                                {
                                                    item?.amount ?
                                                        <>
                                                            { item.isRegistered ?
                                                                <button className="evetnRegisterBtn btn primary-light">Registered</button>
                                                                :
                                                                <button className="evetnRegisterBtn btn primary-light">Register Now</button>
                                                            }
                                                        </>
                                                        :
                                                        ''
                                                }
                                            </>
                                        }
                                    </div>
                                )
                            })
                        }
                    </Carousel> : ''
            }
        </div>
    );
}

export default ImageCarousel;