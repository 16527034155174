import React, {useState, useEffect } from "react";
import { BsDownload } from "react-icons/bs";
import AdultScoreSheet from '../../Images/results/HHI-India-Result-30July-Adult-List.pdf';
import AdultSelected from '../../Images/results/HHI-India-Result-30July-Adult.pdf';
import VarsityScoreSheet from '../../Images/results/HHI-India-Result-30July-Varsity-Score-List.pdf';
import VarsitySelected from '../../Images/results/HHI-India-Result-30July-Varsity.pdf';
import JuniorScoreSheet from '../../Images/results/HHI-India-Result-30July-Junior-List.pdf';
import JuniorSelected from '../../Images/results/HHI-India-Result-30July-Junior.pdf';
import MegaScoreSheet from '../../Images/results/HHI-India-Result-30July-Mega-List.pdf';
import MegaSelected from '../../Images/results/HHI-India-Result-30July-Mega.pdf';
import MiniScoreSheet from '../../Images/results/HHI-India-Result-30July-Mini-List.pdf';
import MiniSelected from '../../Images/results/HHI-India-Result-30July-Mini.pdf';
import AllPreResult from '../../Images/results/all-results-2021-hhi-pre.pdf';

import DoubleRedHHIImg from '../../Images/results/Double-red-HHI.png';
import HHILogo2019Img from '../../Images/results/HHI-LOGO-2019.png';
import HHILogoOfficialImg from '../../Images/results/HHI-Logo-OfficialNational.png';
import TheKingsImg from '../../Images/results/the-kings.png';

const resultList = require("../../Data/results.json");

function Results (props) {
    const { isCallByNativeApp } = props;
    const [scoreSheet, setScoreSheet] = useState(null);
    const [selectedCrew, setSelectedCrew] = useState(null);
    
    useEffect(() => {
        setScoreSheet(resultList?.results?.scores);
        setSelectedCrew(resultList?.results?.selected);
    }, []);
    
    function fetchScoreSheetImg(scoreSheet) {
        let scoreSheetImg = '';
        switch(scoreSheet) {
            case 'adult division-score': scoreSheetImg = AdultScoreSheet;
                                    break;
            case 'adult division-selected': scoreSheetImg = AdultSelected;
                                    break;
            case 'varsity division-score': scoreSheetImg = VarsityScoreSheet;
                                    break;
            case 'varsity division-selected': scoreSheetImg = VarsitySelected;
                                    break;
            case 'junior division-score': scoreSheetImg = JuniorScoreSheet;
                                    break;
            case 'junior division-selected': scoreSheetImg = JuniorSelected;
                                    break;
            case 'mega crew division-score': scoreSheetImg = MegaScoreSheet;
                                    break;
            case 'mega crew division-selected': scoreSheetImg = MegaSelected;
                                    break;
            case 'mini division-score': scoreSheetImg = MiniScoreSheet;
                                    break;
            case 'mini division-selected': scoreSheetImg = MiniSelected;
                                    break;
            default:    scoreSheetImg = AllPreResult; 
                        break;
        }

        return scoreSheetImg;
    }

    return (
        <div className={`liveEventWrap resultsPageWrap ${isCallByNativeApp ? 'inNativeApp' : ''}`}>
            <div className="sponsersLogos doubleRedHHI">
                <img src={DoubleRedHHIImg} alt="double hhi red" />
            </div>
            <div className="sponsersLogos hhiLogo2019">
                <img src={HHILogo2019Img} alt="hhi logo 2019" />
            </div>
            <div className="sponsersLogos hhiLogoOfficial">
                <img src={HHILogoOfficialImg} alt="hhi official" />
            </div>
            <div className="sponsersLogos theKingsLogo">
                <img src={TheKingsImg} alt="the kings" />
            </div>
            {
                scoreSheet && scoreSheet.length ?
                <div className="innerContainer">
                    <div className="dataWrap">
                        <h2>
                            QUALIFIERS FOR GRAND FINALE SCORESHEET
                            <a href={AllPreResult} className="downloadIcons downloadIcon donwloadAll" download="HHI-pre-2021-results.pdf" title={`download all results`}>
                                <BsDownload />
                                <span>Download all</span>
                            </a>
                        </h2>
                        {
                            scoreSheet && scoreSheet.length ?
                            scoreSheet.map( (item, index) => {
                                return (
                                    <div className="tableWrap" key={`score-sheet-${index}`}>
                                        <h3>
                                            {item.division}
                                            <a href={fetchScoreSheetImg(item.division + '-score')} className="downloadIcons downloadIcon" download={`${item.division.split(' ').join('-')}-score.pdf`} title={`download ${item.division} score sheet`}>
                                                <BsDownload />
                                                <span>Download</span>
                                            </a>
                                        </h3>
                                        <div className="innerTableWrap">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>rank</th>
                                                        <th>team name</th>
                                                        <th>city</th>
                                                        <th>state</th>
                                                        <th>deduction</th>
                                                        <th>total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        item.data && item.data.length ?
                                                        item.data.map((ele, index) => {
                                                            return (
                                                                <tr key={`score-value-${index}`}>
                                                                    <td>{ele.rank}</td>
                                                                    <td>{ele.team_name}</td>
                                                                    <td>{ele.city}</td>
                                                                    <td>{ele.state}</td>
                                                                    <td>{ele.deduction}</td>
                                                                    <td>{ele.total}</td>
                                                                </tr>                                                            
                                                            )
                                                        }) : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            }) : ''
                        }
                    </div>
                    <div className="dataWrap">
                        <h2>SELECTED CREWS FOR GRAND FINALE</h2>
                        {
                            selectedCrew && selectedCrew.length ?
                            selectedCrew.map( (item, index) => {
                                return (
                                    <div className="tableWrap" key={`selected-crew-${index}`}>
                                        <h3>
                                            {item.division}
                                            <a href={fetchScoreSheetImg(item.division + '-selected')} className="downloadIcons downloadIcon" download={`${item.division.split(' ').join('-')}-selected.pdf`} title={`download ${item.division} final selected`}>
                                                <BsDownload />
                                                <span>Download</span>
                                            </a>
                                        </h3>
                                        <div className="innerTableWrap">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>rank</th>
                                                        <th>team name</th>
                                                        <th>city</th>
                                                        <th>state</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        item.data && item.data.length ?
                                                        item.data.map((ele, index) => {
                                                            return (
                                                                <tr key={`selected-value-${index}`}>
                                                                    <td>{ele.rank}</td>
                                                                    <td>{ele.team_name}</td>
                                                                    <td>{ele.city}</td>
                                                                    <td>{ele.state}</td>
                                                                </tr>                                                            
                                                            )
                                                        }) : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            }) : ''
                        }
                    </div>
                </div>
                : <p>Results will be coming soon....</p>
            }
            <div className="contactDetails">
                <div className="innerContact">
                    <p>For more details/queries contact below:</p>
                    <p>Email: <a href="mailto: hiphopinternationalindiareg@gmail.com">hiphopinternationalindiareg@gmail.com</a></p>
                    <p>Contact: <a href="tel:+919884588775">+91 98845 88775</a> | <a href="tel:+919619828307">+91 96198 28307</a></p>
                    <p>Website: <a href="https://www.hiphopinternationalindia.com" target="_blank" rel="noreferrer">www.hiphopinternationalindia.com</a></p>
                </div>
            </div>
        </div>
    )
}

export default Results;