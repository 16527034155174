import { LinearProgress } from "@material-ui/core";
import React from "react";
import { FaCloudUploadAlt } from "react-icons/fa";

const UploadComponent = (props) => {
  const { data, onChangeFile, parentArray, parentArrayFn } = props;
  const {
    name,
    value,
    validValue,
    isUploading,
    allowedFileSize,
    ref,
    label,
    acceptFileType,
    required,
    uploadProgress,
  } = data;
  return (
    <div className="uploadContainer">
      <div
        className={
          validValue && validValue?.length
            ? "upload-input-wrap selected"
            : "upload-input-wrap"
        }
      >
        {isUploading ? <div className="boxSpinner"></div> : ""}
        <h6 className="heading">
          {label} {required && <sup className="mandatAsterisk">*</sup>}
        </h6>
        <h6 className="sub-heading">Maximum size {allowedFileSize}</h6>
        <i className="upload-icon">
          <FaCloudUploadAlt />
        </i>
        <input
          id={name}
          type="file"
          accept={acceptFileType}
          value={value}
          ref={ref}
          onChange={(e) =>
            onChangeFile(e, ref, name, data, parentArray, parentArrayFn)
          }
        />
        {isUploading && (
          <LinearProgress
            className="uploadProgressBar"
            variant="determinate"
            value={uploadProgress}
          />
        )}
      </div>
    </div>
  );
};

export default UploadComponent;
