import React from "react";

export default function RefundPolicy(props) {
  const { isCallByNativeApp } = props;

  return (
    <div
      className={`terms-page refund-policy-page charcoal-bg paddingTop90 ${
        isCallByNativeApp ? "inNativeApp" : ""
      }`}
    >
      <div className="container">
        <h1>Cancellation/Refund Policy</h1>
        <p>
          We know you’ll love DA, but we also understand that it’s not always a
          perfect fit for everyone. If you're unsatisfied with your recent DA
          subscription purchase or you decide that you’d like to close your
          subscription plan, our Support Team will be more than happy to issue a
          refund.
        </p>
        <p>
          <strong>
            In order to be eligible for a refund, you must contact us within 3
            working days of the transaction date.
          </strong>{" "}
          Your subscription will close immediately upon being refunded for your
          payment. Our team is not responsible for any different in amount
          refunded due to currency exchange rates for our international
          payments. Refunds for online courses are only given under the
          following circumstances:
        </p>
        <ul>
          <li>
            The student/user did not access any portion of the online course AND
            the student/user requests a refund, in writing via email within
            three business days from the date of the registration (email
            notification sent). There will be no refunds for any online courses
            (or curricula) once a course has been accessed in any manner.
          </li>
          <li>
            Refund will be issued after deducting 40% of course fees as
            administrative fee and 100% GST amount.
          </li>
        </ul>
        <p>
          Please understand that with the enrollment and accessing of your
          online course, you have read and agree to the aforementioned refund
          policy.
        </p>

        <h2>Requesting a Refund</h2>
        <p>
          To submit a refund request, please submit a ticket with the following
          information in a message by sending an email to{" "}
          <a href="mailto:info@appda.in">info@appda.in</a>:
        </p>
        <ol>
          <li>
            Subject: <strong>DA Cancellation &amp; Refund Request</strong>
          </li>
          <li>
            Your <strong>DA</strong> email address
          </li>
          <li>
            Payment method used (credit card, PayPal, or Google Play Store)
          </li>
          <li>
            An email receipt of the payment you are requesting a refund for
            (bank receipts are acceptable)
          </li>
          <li>Any feedback you'd like to leave us!</li>
        </ol>
        <h2>Apple App Store (iOS) Subscriptions</h2>
        <p>
          Unfortunately, subscriptions created from the Apple App Store are
          processed and controlled directly by Apple, so we are unable to issue
          any refunds from our end. You can, however, cancel your subscription
          and request a refund from Apple.
        </p>
        <p>
          <a
            href="https://support.apple.com/en-in/HT202039"
            rel="noreferrer"
            target="_blank"
            title="How to cancel DA ios subscription"
          >
            Here's how to cancel your iOS DA subscription created in the App
            Store
          </a>
        </p>
        <p>
          Once you have canceled your iOS subscription, you can request a refund
          from Apple by following the steps below:
        </p>
        <ol>
          <li>
            <a
              href="https://reportaproblem.apple.com/"
              rel="noreferrer"
              target="_blank"
              title="Report a problem"
            >
              Go to Apple to report a problem
            </a>
          </li>
          <li>
            Sign-in with your Apple ID and password (that you used for DA)
          </li>
          <li>
            Scroll to DA and click <strong>Report a problem</strong>
          </li>
          <li>Describe the problem in the form and click Submit</li>
        </ol>
        <p>
          If you are experiencing any issues, you can refer to Apple Support’s
          article,{" "}
          <a
            href="https://reportaproblem.apple.com/"
            rel="noreferrer"
            target="_blank"
            title="how to cancel a subscription"
          >
            How to cancel a Subscription
          </a>
          , and then{" "}
          <a
            href="https://support.apple.com/en-us/HT204084"
            rel="noreferrer"
            target="_blank"
            title="request a refund"
          >
            request a refund
          </a>{" "}
          directly from Apple. While we cannot guarantee that Apple will issue a
          refund, we have seen success from members when they submit multiple
          refunds if their initial requests are denied.
        </p>

        <h2>Additional Information</h2>
        <p>
          We’d like to inform you that issuing a refund is a process that can
          only be done by our Support Team. Simply cancelling or deleting the DA
          app will not trigger a refund. You can expect your refund to be issued
          back to your bank/lender within 5 - 10 business days. If you are still
          without your refund, we encourage you to reach back out to verify the
          status of your refund.
        </p>
        <p>
          <i>
            There are times when banks will decide to remove the charge
            altogether, rather than issuing a refund, so please check with your
            bank/lender to see if a removal occurred instead.
          </i>
        </p>
        <p>
          The decision to issue a refund and any subsequent actions regarding
          your subscription plan is ultimately up to the discretion of the
          Support Team, so we encourage you to reach out to us about any unique
          circumstances and/or situations.
        </p>
        <p>
          If you still need more information, feel free to submit a help ticket
          at: <a href="mailto:info@appda.in">info@appda.in</a>
        </p>
      </div>
    </div>
  );
}
