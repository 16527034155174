/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
} from "@material-ui/core";
import { PACKAGES_DATA } from "../../Constants";

const PackagesComponent = (props) => {
  const {
    parentNode,
    parentIndex,
    currentNode,
    currentIndex,
    packagesData,
    updatedPackagesData,
    selectedPackages,
  } = props;
  const [packages, setPackages] = useState(() => {
    const packagesData = structuredClone(PACKAGES_DATA);
    if (
      packagesData &&
      packagesData.length > 0 &&
      selectedPackages &&
      selectedPackages.length > 0
    ) {
      for (let i = 0; i < packagesData.length; i++) {
        for (let j = 0; j < selectedPackages.length; j++) {
          if (packagesData[i].id === selectedPackages[j].id) {
            packagesData[i] = selectedPackages[j];
            packagesData[i].valid = true;
          }
        }
      }
    }
    return packagesData;
  });

  const handleChange = (itemIndex, state) => {
    const stateString = state?.toString();
    if (stateString && stateString.length > 0) {
      packages[itemIndex].isActive = !state;
      if (!packages[itemIndex].isActive) {
        packages[itemIndex]["price"] = null;
        packages[itemIndex]["discount"] = null;
        packages[itemIndex]["valid"] = false;
      }
    }
    setPackages((prevValue) => {
      prevValue[itemIndex].isActive = !state;
      if (!prevValue[itemIndex].isActive) {
        prevValue[itemIndex]["price"] = null;
        prevValue[itemIndex]["discount"] = null;
        prevValue[itemIndex]["valid"] = false;
      }
      return [...prevValue];
    });
    // const checkedItems = packages.filter((childItem) => childItem?.isActive);

    // const tempPackages = {
    //   parentNode,
    //   currentNode,
    //   parentIndex,
    //   currentIndex,
    //   checkedItems,
    // };
    // updatedPackagesData(tempPackages);
  };

  useEffect(() => {
    const checkedItems = packages.filter((childItem) => childItem?.isActive);
    const validItems = checkedItems.filter((childItem) => childItem?.valid);
    if (validItems && validItems.length > 0) {
      const tempPackages = {
        parentNode,
        currentNode,
        parentIndex,
        currentIndex,
        validItems,
      };
      updatedPackagesData(tempPackages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packages]);

  return (
    <FormControl variant="outlined" className="input-field">
      <FormLabel component="legend" required>
        Create Subscriptions
      </FormLabel>
      <div className="packagesContainer">
        {packages &&
          packages.length > 0 &&
          packages.map((item, itemIndex) => {
            return (
              <div className="package">
                <FormControlLabel
                  control={
                    <Checkbox
                      name={item?.id}
                      onChange={(event) =>
                        // handleChange(itemIndex, item?.isActive)
                        setPackages((prevValue) => {
                          prevValue[itemIndex].isActive = !item?.isActive;
                          if (!prevValue[itemIndex].isActive) {
                            prevValue[itemIndex]["price"] = null;
                            prevValue[itemIndex]["discount"] = null;
                            prevValue[itemIndex]["valid"] = false;
                          }
                          return [...prevValue];
                        })
                      }
                      checked={item?.isActive}
                    />
                  }
                  label={item?.label}
                  className="rowItem"
                />
                <TextField
                  label="Price"
                  variant="outlined"
                  value={item?.price}
                  className="rowItem"
                  name="price"
                  // onInput={(event) => handleInputChange(event, itemIndex)}
                  onInput={(event) => {
                    setPackages((prevValue) => {
                      prevValue[itemIndex][event.target.name] =
                        event.target.value;
                      if (
                        prevValue[itemIndex]["price"] !== null &&
                        prevValue[itemIndex]["discount"] !== null
                      ) {
                        prevValue[itemIndex]["valid"] = true;
                      } else {
                        prevValue[itemIndex]["valid"] = false;
                      }
                      return [...prevValue];
                    });
                  }}
                  required={item?.isActive}
                  disabled={!item?.isActive}
                />
                <TextField
                  label="Discount in %"
                  variant="outlined"
                  value={item?.discount}
                  className="rowItem"
                  name="discount"
                  // onInput={(event) => handleInputChange(event, itemIndex)}
                  onInput={(event) => {
                    setPackages((prevValue) => {
                      prevValue[itemIndex][event.target.name] =
                        event.target.value;
                      if (
                        prevValue[itemIndex]["price"] !== null &&
                        prevValue[itemIndex]["discount"] !== null
                      ) {
                        prevValue[itemIndex]["valid"] = true;
                      } else {
                        prevValue[itemIndex]["valid"] = false;
                      }
                      return [...prevValue];
                    });
                  }}
                  required={item?.isActive}
                  disabled={!item?.isActive}
                />
                <span>
                  Final Price :
                  {Math.round(
                    item?.price - item?.price * (item?.discount / 100)
                  )}
                </span>
              </div>
            );
          })}
      </div>
    </FormControl>
  );
};

export default PackagesComponent;
