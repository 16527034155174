const stepListData = {
    stepOne: [{
        heading: "Your Experience",
        title: "Brand New",
        desc: "I’m looking to build the foundation needed to be a confident dancer.",
        isSelected: false
    }, {
        heading: "Your Experience",
        title: "Beginner",
        desc: "I’ve taken some classes and understand basic dance terminology.",
        isSelected: false
    }, {
        heading: "Your Experience",
        title: "Intermediate",
        desc: "I have been dancing regularly and am ready to dive in.",
        isSelected: false
    }, {
        heading: "Your Experience",
        title: "Advanced",
        desc: "I have a lot of experience in the dance style I want to explore.",
        isSelected: false
    }],
    stepTwo: [{
        heading: "Your Goal",
        title: "Foundations",
        desc: "I want to learn basic dance foundations.",
        isSelected: false
    }, {
        heading: "Your Goal",
        title: "Freestyle",
        desc: "I want to learn how to freestyle.",
        isSelected: false
    }, {
        heading: "Your Goal",
        title: "Choreography",
        desc: "I want to learn how to choreograph.",
        isSelected: false
    }, {
        heading: "Your Goal",
        title: "Confidence",
        desc: "I want to dance in public confidently.",
        isSelected: false
    }, {
        heading: "Your Goal",
        title: "Health",
        desc: "I want to stay healthy with fun dance classes.",
        isSelected: false
    }],
    stepThree: [
        {
            "desc": "Learn the safe and correct techniques of top rocks, power moves, and footwork for this athletic style.",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "Breaking",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/breaking.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/breaking/breaking.m3u8",
        },
        {
            "desc": "Get a fun workout while slowly learning how to dance better! You'll burn calories, stretch out, build strength and control, and prevent injuries.",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "Dance Workout",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/dance-workout.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/dance-workout/dance-workout.m3u8",
        },
        {
            "desc": "Straight from the dance halls of Jamaica, Dancehall is a fun, high-energy, feel-good style.",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "Dancehall",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/dancehall.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/dancehall/dancehall.m3u8",
        },
        {
            "desc": "Wanna feel sexy and powerful? Learn how to walk, turn, dance, and create beautiful lines and poses with your body–all in heels! These feminine, sensual moves will unlock your confidence as you wow yourself with what you're capable of!",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "Heels",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/heels.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/heels/heels.m3u8",
        },
        {
            "desc": "Hip Hop Party/Social dances are fun, catchy moves and grooves to iconic Hip Hop songs–you might recognize them! ",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "Hip Hop",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/hip-hop.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/hip-hop/hip-hop.m3u8",
        },
        {
            "desc": "The foundation of House is the Jack, where your upper body undulates to the heavy bass beat.",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "House",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/house.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/house/house.m3u8",
        },
        {
            "desc": "Immerse yourself in the world of K-Pop! Learn these flashy, catchy, cute, and powerful dance covers to the most popular K-Pop songs.",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "K-Pop",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/k-pop.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/k-pop/k-pop.m3u8",
        },
        {
            "desc": "Though its movements are sharp, powerful, and aggressive, Krump is meant to be a tool for storytelling and creative expression.",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "Krump",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/krump.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/krump/krump.m3u8",
        },
        {
            "desc": "Find choreography routines to your favorite songs here! All broken down step-by-step by your favorite choreographers.",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "Popular Choreography",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/popular-choreography.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/popular-choreography/popular-choreography.m3u8",
        },
        {
            "desc": "Take a break from dancing to learn helpful tips and tricks and listen to lectures on different styles' history and culture.",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "Tips & Lectures",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/tips-and-lectures.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/tips-and-lectures/tips-and-lectures.m3u8",
        },
        {
            "desc": "With sharp arm movements and and strong poses, Whacking will make you feel fierce and empowered.",
            "isSelected": false,
            "heading": "Your Interest",
            "title": "Whacking",
            "previewThumbnailUrl": "https://steezy.imgix.net/category-thumbnails/whacking.jpg",
            "previewVideoUrl": "https://steezy.freetls.fastly.net/category-reels/whacking/whacking.m3u8",
        }
    ],
    stepFour: [
        {
            heading: "Your Strength",
            title: "15 minutes or less",
            desc: "Short drills to learn or practice techniques.",
            isSelected: false
        }, {
            heading: "Your Strength",
            title: "15-45 minutes",
            desc: "Quick dance practices that let you get a sweat in.",
            isSelected: false
        }, {
            heading: "Your Strength",
            title: "+45 minutes",
            desc: "Studio class length. Perfect for learning full routines.",
            isSelected: false
        }
    ]
}
export default stepListData;