import { Observable } from "rxjs";
import db from "../Database";

const offlineStudiosRef = db.collection("offlineStudios");

export function createOfflineStudio(data) {
  data.createdOn = new Date();
  data.modifiedOn = new Date();
  return new Observable((observer) => {
    offlineStudiosRef.add(data).then((doc) => {
      observer.next({
        key: doc.id,
      });
    });
  });
}

export function updateOfflineStudio(data) {
  // const decodedStudioKey = window.atob(data?.id);
  data.createdOn = data.createdOn || new Date();
  data.modifiedOn = new Date();
  const id = window.atob(data?.key);
  console.log("data", data);
  delete data.key;
  return new Observable((observer) => {
    offlineStudiosRef
      .doc(id)
      .update(data)
      .then(() => {
        observer.next();
      });
  });
}

export const getAllOfflineStudios = (location) => {
  return new Observable((observer) => {
    offlineStudiosRef.onSnapshot((querySnapshot) => {
      let studios = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.key = window.btoa(doc.id);
        if (data.isActive) {
          studios.push(data);
        }
      });
      observer.next(studios);
    });
  });
};

export const getOfflineStudiosByLocation = (location) => {
  return new Observable((observer) => {
    offlineStudiosRef.onSnapshot((querySnapshot) => {
      let studios = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.key = window.btoa(doc.id);
        if (data.isActive) {
          studios.push(data);
        }
      });
      const studiosByLocation = studios.filter(
        (studio) => studio.location === location
      );
      observer.next(studiosByLocation);
    });
  });
};

export const getOfflineStudioById = (id) => {
  const decodedStudioKey = window.atob(id);
  return new Observable((observer) => {
    offlineStudiosRef
      .doc(decodedStudioKey)
      .get()
      .then((doc) => {
        let data = doc.data();
        data.key = window.btoa(doc.id);
        if (data) {
          observer.next(data);
        } else {
          observer.next({
            data: [],
            message: "No studios found!",
          });
        }
      });
  });
};
// This service will get all(active, inactive) the studios for Admin
export function getOfflineStudiosForAdmin() {
  return new Observable((observer) => {
    offlineStudiosRef.orderBy("createdOn").onSnapshot((querySnapshot) => {
      let cat = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.key = doc.id;
        cat.push(data);
      });
      cat.sort((a, b) => b.index - a.index);
      observer.next(cat);
    });
  });
}

export function toggleActivateDeactivateStudio(data, action) {
  data.createdOn = data.createdOn || new Date();
  data.modifiedOn = new Date();
  return new Observable((observer) => {
    offlineStudiosRef
      .doc(data.value.id)
      .update({ isActive: action })
      .then(() => {
        observer.next();
      });
  });
}

export function toggleAdmissionStatus(data, action) {
  data.createdOn = data.createdOn || new Date();
  data.modifiedOn = new Date();
  return new Observable((observer) => {
    offlineStudiosRef
      .doc(data.value.id)
      .update({ isAdmissionOpen: action })
      .then(() => {
        observer.next();
      });
  });
}

export function deleteStudioByKey(id) {
  return new Observable((observer) => {
    offlineStudiosRef
      .doc(id)
      .update({ isDeleted: true, isActive: false })
      .then(() => {
        observer.next();
      })
      .catch((error) => {
        console.error("Error removing Studio: ", error);
        observer.next({ deleted: false, error: error });
      });
  });
}

export function getFilteredStudio(location, byBasic, byPrograms) {
  return new Observable((observer) => {
    offlineStudiosRef.onSnapshot((querySnapshot) => {
      let studios = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.key = window.btoa(doc.id);
        if (data.isActive) {
          studios.push(data);
        }
      });

      const studiosByLocation = studios.filter(
        (studio) => studio.location === location
      );

      let studiosByAdvanceFilter;
      switch (byBasic) {
        case "all":
          studiosByAdvanceFilter = studiosByLocation;
          break;

        case "onlyDance":
          studiosByAdvanceFilter = filterStudioWithDance(studiosByLocation);
          break;

        case "onlyGym":
          studiosByAdvanceFilter = filterStudioWithGym(studiosByLocation);
          break;

        default:
          studiosByAdvanceFilter = studiosByLocation;
          break;
      }
      observer.next(studiosByAdvanceFilter);
    });
  });
}

function filterStudioWithDance(studios) {
  return studios.filter((item) => {
    return item.advancedFilter.find((ele) => {
      return ele?.id === "danceStudio" && ele?.types?.length;
    });
  });
}

function filterStudioWithGym(studios) {
  return studios.filter((item) => {
    return item.advancedFilter.find((ele) => {
      return ele?.id === "gym" && ele?.types?.length;
    });
  });
}
