/* eslint-disable no-undef */
export const PAYMENT_STAGING_URL =
  "http://localhost:5001/boogalusite/us-central1/payment";
export const PAYMENT_PROD_URL =
  "https://us-central1-boogalusite.cloudfunctions.net/payment";

export const PAYMENT_STAGING_CALLBACK_URL =
  "http://localhost:5001/boogalusite/us-central1/paymentCallback";
export const PAYMENT_PROD_CALLBACK_URL =
  "https://us-central1-boogalusite.cloudfunctions.net/paymentCallback";

export const THUMBNAIL_URL =
  "https://firebasestorage.googleapis.com/v0/b/boogalusite.appspot.com/o/uploads%2Fthumbnail%2Fthumbnail.jpg?alt=media&token=8cb7b59f-67b1-4d30-bf7f-8da066bf4408";
export const MALE_PROFILE_DEFAULT_IMAGE = "https://i.imgur.com/Nks5KZe.png";
export const FEMALE_PROFILE_DEFAULT_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/boogalusite.appspot.com/o/uploads%2Fuser%2Ffemale-profile.png?alt=media&token=0c40e1cd-c965-4edf-b307-1cea5f9f1f72";
export const EMAIL_SENDING_STAGING_API_URL =
  "http://localhost:5001/boogalusite/us-central1/sendEmail";
export const EMAIL_SENDING_PROD_API_URL =
  "https://us-central1-boogalusite.cloudfunctions.net/sendEmail";
export const ADMIN_EMAIL_STAGING = "boogalu.email.test@gmail.com";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SIGN_UP_USER = "SIGN_UP_USER";

export const ENABLE_LOGIN_FLOW = "ENABLE_LOGIN_FLOW";
export const DISABLE_LOGIN_FLOW = "DISABLE_LOGIN_FLOW";

export const ENABLE_LOADER = "ENABLE_LOADER";
export const DISABLE_LOADER = "DISABLE_LOADER";

export const SET_ACTIVE_COMPETITION = "SET_ACTIVE_COMPETITION";
export const SET_ACTIVE_VIDEO_FOR_COMPETITION =
  "SET_ACTIVE_VIDEO_FOR_COMPETITION";

export const SET_ACTIVE_SUBSCRIPTION = "SET_ACTIVE_SUBSCRIPTION";
export const SUBSCRIPTION_ACTIVE_STATUS = "Active";
export const SUBSCRIPTION_ENDED_STATUS = "Ended";

export const SET_REFETCH_DATA_MODULE = "SET_REFETCH_DATA_MODULE";
export const REMOVE_REFETCH_DATA_MODULE = "REMOVE_REFETCH_DATA_MODULE";

export const DISPLAY_NOTIFICATION = "DISPLAY_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const NOTIFICATION_SUCCCESS = "NOTIFICATION_SUCCCESS";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const NOTIFICATION_INFO = "NOTIFICATION_INFO";
export const NOTIFICATION_WARNING = "NOTIFICATION_WARNING";

// Admin Login
export const ADMIN_USER = "info@appda.in";
export const ADMIN_PWD = "Boogalu.hiphop1@@2020";

// Pre-judges Login
export const PRE_JUDGES_USER = "pre-round@appda.in";
export const PRE_JUDGES_PWD = "Pre-round@1001";

// Final-judges Login
export const FINAL_JUDGES_USER = "final-round@appda.in";
export const FINAL_JUDGES_PWD = "Final-round@1001";

// Subscription Plans Mapping
export const SUBSCIPTION_PLANS_MAP = {
  startup: {
    modalMessage:
      "get a chance to participate in active compeitions and take paid lessons.",
    className: "startup",
    features: {
      lessons: ["paid"],
      competition: true,
    },
  },
  pro: {
    modalMessage:
      "will have access to all the features of Start-up plan and additionally you can have access to all Pro lesson videos.",
    className: "pro",
    features: {
      lessons: ["paid", "pro"],
      competition: true,
    },
  },
  premium: {
    modalMessage:
      "will have access to all the features of Start-up & pro plans, additionally you can have access to all Premium lesson videos.",
    className: "premium",
    features: {
      lessons: ["paid", "pro", "premium"],
      competition: true,
    },
  },
};

// User Videos Count
export const GET_UPLOADED_VIDEOS_BY_USER = "GET_UPLOADED_VIDEOS_BY_USER";

// boogalu.email.test@gmail.com / Box-puppet@1001

// Video Upload Limit Count
export const VIDEO_LIMIT_COUNT = {
  monthly: 2,
  yearly: 48,
};

// Create lessons sub-pages
export const LESSONS_STYLES_ALL = [
  {
    label: "Hip Hop",
    value: "hip-hop",
  },
  {
    label: "Open style",
    value: "open-style",
  },
  {
    label: "Locking",
    value: "locking",
  },
  {
    label: "Popping",
    value: "popping",
  },
  {
    label: "House",
    value: "house",
  },
  {
    label: "Krump",
    value: "krump",
  },
  {
    label: "Breaking",
    value: "breaking",
  },
  {
    label: "Whacking",
    value: "whacking",
  },
];

export const LESSONS_STYLES_KIDS = {
  "3-5": [
    {
      label: "Alphabetic Dance",
      value: "alphabetic-dance",
    },
    {
      label: "Rhymes",
      value: "rhymes",
    },
    {
      label: "Rhythmic Dance",
      value: "rhythmic-dance",
    },
    {
      label: "Kids Dance Choreography",
      value: "kids-dance-choreography",
    },
  ],
  "6-12": [
    ...LESSONS_STYLES_ALL,
    {
      label: "Bollywood",
      value: "bollywood",
    },
    {
      label: "Semi classical",
      value: "semi-classical",
    },
    {
      label: "Kollywood",
      value: "kollywood",
    },
    {
      label: "Tollywood",
      value: "tollywood",
    },
  ],
};

export const LESSONS_SUBPAGES = [
  {
    name: "trials",
    isLevel: true,
    styles: LESSONS_STYLES_ALL,
  },
  {
    name: "class",
    isLevel: true,
    styles: LESSONS_STYLES_ALL,
  },
  {
    name: "kids",
    ageGroup: [
      {
        name: "3yrs to 5 yrs",
        value: "3-5",
        isLevel: false,
        styles: LESSONS_STYLES_KIDS["3-5"],
      },
      {
        name: "6yrs to 12 yrs",
        value: "6-12",
        isLevel: true,
        styles: LESSONS_STYLES_KIDS["6-12"],
      },
    ],
  },
  {
    name: "moves",
    isLevel: true,
    styles: LESSONS_STYLES_ALL,
  },
];

// Feature toggles
export const SHOULD_ENABLE_APP = true;
export const IS_LESSONS_UPCOMING_PAGE = false;
export const SHOULD_ENABLE_M3U8 = false;

// Apps link
export const PLAY_STORE =
  "https://play.google.com/store/apps/details?id=com.boogaluu";
export const APP_STORE = "https://apps.apple.com/us/app/boogaluu/id1580963784";

export const INDIAN_HIP_HOP = "indian hip hop dance championship";

export const POSTAL_PINCODE_API = "https://api.postalpincode.in/pincode/";

export const OFFLINE_STUDIO_LOCATION = [
  { value: "delhi", label: "Delhi", code: 123 },
  { value: "mumbai", label: "Mumbai", code: 124 },
  { value: "new mumbai", label: "Navi Mumbai", code: 125 },
  { value: "bangalore", label: "Bangalore", code: 126 },
  { value: "kolkata", label: "Kolkata", code: 127 },
  { value: "hyderabad", label: "Hyderabad", code: 128 },
  { value: "pune", label: "Pune", code: 129 },
  { value: "falakata", label: "Falakata", code: 130 },
];

export const OPENING_HOURS = [
  {
    id: "monday",
    label: "Monday",
    isSelected: false,
    opensAt: "09:00 am",
    closesAt: "06:00 pm",
  },
  {
    id: "tuesday",
    label: "Tuesday",
    isSelected: false,
    opensAt: "09:00 am",
    closesAt: "06:00 pm",
  },
  {
    id: "wednesday",
    label: "Wednesday",
    isSelected: false,
    opensAt: "09:00 am",
    closesAt: "06:00 pm",
  },
  {
    id: "thursday",
    label: "Thursday",
    isSelected: false,
    opensAt: "09:00 am",
    closesAt: "06:00 pm",
  },
  {
    id: "friday",
    label: "Friday",
    isSelected: false,
    opensAt: "09:00 am",
    closesAt: "06:00 pm",
  },
  {
    id: "saturday",
    label: "Saturday",
    isSelected: false,
    opensAt: "09:00 am",
    closesAt: "06:00 pm",
  },
  {
    id: "sunday",
    label: "Sunday",
    isSelected: false,
    opensAt: "09:00 am",
    closesAt: "06:00 pm",
  },
];
export const PACKAGES_DATA = [
  {
    id: "monthly",
    label: "1 Month",
    price: null,
    discount: null,
    isActive: false,
  },
  {
    id: "quarterly",
    label: "3 Months",
    price: null,
    discount: null,
    isActive: false,
  },
  {
    id: "halfYearly",
    label: "6 Months",
    price: null,
    discount: null,
    isActive: false,
  },
  {
    id: "yearly",
    label: "12 Months",
    price: null,
    discount: null,
    isActive: false,
  },
];

export const OFFLINE_STUDIO_ADVANCED_FILTER = [
  {
    id: "danceStudio",
    label: "Dance Studio",
    types: [
      {
        id: "hiphop",
        label: "Hip Hop",
      },
      {
        id: "bollywood",
        label: "Bollywood",
      },
      {
        id: "kathak",
        label: "Kathak",
      },
      {
        id: "openstyle",
        label: "Open Style",
      },
      {
        id: "jazz",
        label: "Jazz",
      },
      {
        id: "ballet",
        label: "Ballet",
      },
      {
        id: "zumba",
        label: "Zumba",
      },
      {
        id: "bellydance",
        label: "Belly Dance",
      },
      {
        id: "bachata",
        label: "Bachata",
      },
      {
        id: "kidbatch",
        label: "Kid Batch",
      },
      {
        id: "bharatnatyam",
        label: "Bharatnatyam",
      },
      {
        id: "gymnastics",
        label: "Gymnastics",
      },
      {
        id: "allStyles",
        label: "All Styles",
      },
    ],
  },
  {
    id: "gym",
    label: "Gym",
    types: [
      {
        id: "weightlossandgain",
        label: "Weight Loss & Gain",
      },
      {
        id: "pilates",
        label: "Pilates",
      },
      {
        id: "pyramidworkout",
        label: "Pyramid Workout",
      },
      {
        id: "cardioandcardioboxing",
        label: "Cardio & Cardio Boxing",
      },
      {
        id: "airyoga",
        label: "Air Yoga",
      },
      {
        id: "totalbodyworkout",
        label: "Total Body Workout",
      },
      {
        id: "callanetics",
        label: "Callanetics",
      },
      {
        id: "crossfit",
        label: "CrossFit",
      },
      {
        id: "circuitofitness",
        label: "Circuito Fitness",
      },
      {
        id: "hybridclassesonline",
        label: "Hybrid Classes (Online)",
      },
      {
        id: "allGym",
        label: "All Gyms",
      },
    ],
  },
];

export const PERSONAL_CLASS_DETAILS = {
  formLink: "https://forms.gle/Kak9QugnzM9rQdC16",
  title: "Personal Instructor",
  label: "Personal instructor form",
};

export const MAPS_API_KEY = "AIzaSyCXXLHV1SRfL-gzzT5CcrlxOVIFtU2F6no";
export const GOOGLE_MAP_API = `https://maps.googleapis.com/maps/api/geocode/json?key=${MAPS_API_KEY}`;
export const RAZORPAY_KEY_ID = "rzp_test_vPa2CXPfJxDdrL";
export const RAZORPAY_KEY_SECRET = "iBy9vvzUwnPaFJfpls90HMRk";
