import React, { useState, useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import TextField from '@material-ui/core/TextField';

const MAX_FIELDS = 6;
const priceArray = [
    {
        key: 'item-1',
        name: null,
        value: null,
    },
    {
        key: 'item-2',
        name: null,
        value: null,
    },
    {
        key: 'item-3',
        name: null,
        value: null,
    }
];

export default function AddMoreInputGroup(props) {
    const [maxFeildCount, setMaxFiledCount] = useState(3);
    const [isFourthInputBoxTrue, toggleFourthInputBox] = useState(false);
    const [isFifthInputBoxTrue, toggleFifthInputBox] = useState(false);
    const [isSixthInputBoxTrue, toggleSixthInputBox] = useState(false);

    const [item1InputName, setItem1Name] = useState('');
    const [item1InputValue, setItem1Value] = useState('');
    const [item2InputName, setItem2Name] = useState('');
    const [item2InputValue, setItem2Value] = useState('');
    const [item3InputName, setItem3Name] = useState('');
    const [item3InputValue, setItem3Value] = useState('');
    const [item4InputName, setItem4Name] = useState('');
    const [item4InputValue, setItem4Value] = useState('');
    const [item5InputName, setItem5Name] = useState('');
    const [item5InputValue, setItem5Value] = useState('');
    const [item6InputName, setItem6Name] = useState('');
    const [item6InputValue, setItem6Value] = useState('');
    
    useEffect(() => {
        if (props?.preSelectedData?.length) {
            setDefaultValues();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setDefaultValues() {
        props.preSelectedData.forEach( item => {
            switch(item.key) {
                case 'item-1':  setItem1Name(item.name);
                                setItem1Value(item.value);
                                break;
    
                case 'item-2':  setItem2Name(item.name);
                                setItem2Value(item.value);
                                break;
    
                case 'item-3':  setItem3Name(item.name);
                                setItem3Value(item.value);
                                break;
    
                case 'item-4':  setItem4Name(item.name);
                                setItem4Value(item.value);
                                break;
    
                case 'item-5':  setItem5Name(item.name);
                                setItem5Value(item.value);
                                break;
    
                case 'item-6':  setItem6Name(item.name);
                                setItem6Value(item.value);
                                break;
    
                default: break;
            }
        });
    }

    function handleChange(event, type, item) {
        switch(item) {
            case 'item-1':  setPriceObject(type, item, event.target.value);
                            if (type === 'name') {
                                setItem1Name(event.target.value);
                            } else {
                                setItem1Value(event.target.value);
                            }
                            break;

            case 'item-2':  setPriceObject(type, item, event.target.value);
                            if (type === 'name') {
                                setItem2Name(event.target.value);
                            } else {
                                setItem2Value(event.target.value);
                            }
                            break;

            case 'item-3':  setPriceObject(type, item, event.target.value);
                            if (type === 'name') {
                                setItem3Name(event.target.value);
                            } else {
                                setItem3Value(event.target.value);
                            }
                            break;

            case 'item-4':  setPriceObject(type, item, event.target.value);
                            if (type === 'name') {
                                setItem4Name(event.target.value);
                            } else {
                                setItem4Value(event.target.value);
                            }
                            break;

            case 'item-5':  setPriceObject(type, item, event.target.value);
                            if (type === 'name') {
                                setItem5Name(event.target.value);
                            } else {
                                setItem5Value(event.target.value);
                            }
                            break;

            case 'item-6':  setPriceObject(type, item, event.target.value);
                            if (type === 'name') {
                                setItem6Name(event.target.value);
                            } else {
                                setItem6Value(event.target.value);
                            }            
                            break;

            default: break;
        }
    };

    function setPriceObject(type, item, value) {
        if (priceArray.length) {
            priceArray.forEach(ele => {
                if (value) {
                    if (ele.key === item) {
                        if (type === 'name') {
                            ele.name = value;
                        } else {
                            ele.value = value;
                        }
                    }
                }
            });
        }
        
        if (priceArray.length === maxFeildCount) {
            let isEmptyArray = 0;
            priceArray.forEach( item => {
                if (item.name === null) {
                    isEmptyArray++;
                }
            });
            if (isEmptyArray === priceArray.length) {
                props.setPriceData([]);
            } else {
                props.setPriceData(priceArray);
            }
        }
    }

    function removeExtraInputBox(event, removeItem) {
        event.stopPropagation();
        let incrementValue = maxFeildCount;
        incrementValue--;
        let removeIndex = '';
        switch(removeItem) {
            case 4: toggleFourthInputBox(false);
                    removeIndex = priceArray.map(function(item) { return item.key; }).indexOf('item-4');
                    priceArray.splice(removeIndex, 1);
                    break;
            case 5: toggleFifthInputBox(false);
                    removeIndex = priceArray.map(function(item) { return item.key; }).indexOf('item-5');
                    priceArray.splice(removeIndex, 1);
                    break;
            case 6: toggleSixthInputBox(false);
                    removeIndex = priceArray.map(function(item) { return item.key; }).indexOf('item-6');
                    priceArray.splice(removeIndex, 1);
                    break;

            default: break;
        }
        setMaxFiledCount(incrementValue);
    }

    function addNewFiledBox(event) {
        event.stopPropagation();
        let incrementValue = maxFeildCount;
        incrementValue++;
        let arrayObj = {};
        switch(incrementValue) {
            case 4: toggleFourthInputBox(true);
                    arrayObj = {
                        key: 'item-4',
                        name: null,
                        value: null
                    }
                    priceArray.push(arrayObj);
                    break;
            case 5: toggleFifthInputBox(true);
                    arrayObj = {
                        key: 'item-5',
                        name: null,
                        value: null
                    }
                    priceArray.push(arrayObj);
                    break;
            case 6: toggleSixthInputBox(true);
                    arrayObj = {
                        key: 'item-6',
                        name: null,
                        value: null
                    }
                    priceArray.push(arrayObj);
                    break;

            default: break;
        }
        setMaxFiledCount(incrementValue);
    }

    return (
        <div className="inputGroupWrap">
            <div className="innerControlGroup">
                <div className="controlGroup" id="control-0">
                    <label className="controlLabel">Prize item 1</label>
                    <div className="controls">
                        <TextField 
                            className="input-field"
                            id="price-name-1"
                            label="Prize name"
                            value={item1InputName}
                            onChange={(e) => handleChange(e, 'name', 'item-1')}
                            variant="outlined"
                        />
                        <TextField 
                            className="input-field"
                            id="price-value-1"
                            label="Prize value"
                            value={item1InputValue}
                            onChange={(e) => handleChange(e, 'value', 'item-1')}
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className="controlGroup" id="control-1">
                    <label className="controlLabel">Prize item 2</label>
                    <div className="controls">
                        <TextField 
                            className="input-field"
                            id="price-name-2"
                            label="Prize name"
                            value={item2InputName}
                            onChange={(e) => handleChange(e, 'name', 'item-2')}
                            variant="outlined"
                        />
                        <TextField 
                            className="input-field"
                            id="price-value-2"
                            label="Prize value"
                            value={item2InputValue}
                            onChange={(e) => handleChange(e, 'value', 'item-2')}
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className="controlGroup" id="control-2">
                    <label className="controlLabel">Prize item 3</label>
                    <div className="controls">
                        <TextField 
                            className="input-field"
                            id="price-name-3"
                            label="Prize name"
                            value={item3InputName}
                            onChange={(e) => handleChange(e, 'name', 'item-3')}
                            variant="outlined"
                        />
                        <TextField 
                            className="input-field"
                            id="price-value-3"
                            label="Prize value"
                            value={item3InputValue}
                            onChange={(e) => handleChange(e, 'value', 'item-3')}
                            variant="outlined"
                        />
                    </div>
                </div>
                {
                    isFourthInputBoxTrue ?
                    <div className="controlGroup extraControlGroup" id="control-3">
                        <label className="controlLabel">Prize item 4</label>
                        <div className="controls">
                            <TextField 
                                className="input-field"

                                id="price-name-4"
                                label="Prize name"
                                value={item4InputName}
                                onChange={(e) => handleChange(e, 'name', 'item-4')}
                                variant="outlined"
                            />
                            <TextField 
                                className="input-field"

                                id="price-value-4"
                                label="Prize value"
                                value={item4InputValue}
                                onChange={(e) => handleChange(e, 'value', 'item-4')}
                                variant="outlined"
                            />
                        </div>
                        <p className="deleteInputBox" title="remove this Prize item" onClick={(e) => removeExtraInputBox(e, 4)}>
                            <FaTrashAlt />
                        </p>
                    </div>
                    : ''
                }
                {
                    isFifthInputBoxTrue ?
                    <div className="controlGroup extraControlGroup" id="control-4">
                        <label className="controlLabel">Prize item 5</label>
                        <div className="controls">
                            <TextField 
                                className="input-field"

                                id="price-name-5"
                                label="Prize name"
                                value={item5InputName}
                                onChange={(e) => handleChange(e, 'name', 'item-5')}
                                variant="outlined"
                            />
                            <TextField 
                                className="input-field"

                                id="price-value-5"
                                label="Prize value"
                                value={item5InputValue}
                                onChange={(e) => handleChange(e, 'value', 'item-5')}
                                variant="outlined"
                            />
                        </div>
                        <p className="deleteInputBox" title="remove this Prize item" onClick={(e) => removeExtraInputBox(e, 5)}>
                            <FaTrashAlt />
                        </p>
                    </div>
                    : ''    
                }
                {
                    isSixthInputBoxTrue ?
                    <div className="controlGroup extraControlGroup" id="control-5">
                        <label className="controlLabel">Prize item 6</label>
                        <div className="controls">
                            <TextField 
                                className="input-field"

                                id="price-name-6"
                                label="Prize name"
                                value={item6InputName}
                                onChange={(e) => handleChange(e, 'name', 'item-6')}
                                variant="outlined"
                            />
                            <TextField 
                                className="input-field"

                                id="price-value-6"
                                label="Prize value"
                                value={item6InputValue}
                                onChange={(e) => handleChange(e, 'value', 'item-6')}
                                variant="outlined"
                            />
                        </div>
                        <p className="deleteInputBox" title="remove this Prize item" onClick={(e) => removeExtraInputBox(e, 6)}>
                            <FaTrashAlt />
                        </p>
                    </div>
                    : ''                
                }
            </div>
            {
                maxFeildCount < MAX_FIELDS ?
                <p className="addMoreLink" onClick={(e) => addNewFiledBox(e)}>Add more +</p> : ''
            }
        </div>
    )
}