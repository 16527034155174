import * as React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function SwitchLabels({ onSwitchChange, checked, label }) {
    function handleChange(event) {
        onSwitchChange(event.target.checked);
    }

    return (
        <FormGroup>
            <FormControlLabel 
                control={<Switch onChange={handleChange} checked={!checked} />} 
                label={label} />
        </FormGroup>
    );
}
