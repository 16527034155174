import React, { useState, useRef, useEffect, createRef } from "react";
import LessonsVideoContainer from "../LessonVideoComponent";
import Lessons from "../../Data/Dummy";
import { useStoreConsumer } from "../../Providers/StateProvider";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import {
  getAllLessons,
  getLessonByPlanType,
  getLessonsBySubPageType,
  getLessonsWithOnlyPreview,
  getLessonByPlanTypeOnlyPreview,
} from "../../Services/Lessons.service";
import { FaFilter, FaChild } from "react-icons/fa";
import { MdDirectionsWalk, MdSelectAll, MdLibraryBooks, MdToc } from "react-icons/md";
import { getParameterByName, isObjectEmpty } from "../../helpers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as $ from "jquery";
import Loader from "../Loader";
import { logAnalyticsEvent } from "../../Services/analytics.service";

const FILTER_BY = require("../../Data/lessonFilters.json");

const levelFilterRefs = [
  {
    ref: 'beginnersRef',
  },
  {
    ref: 'intermediateRef',
  },
  {
    ref: 'advanceRef',
  }
];

const KIDS_3TO5_STYLES = getKidsStyle(FILTER_BY.filterBy);

function getKidsStyle(item) {
  let kidsStyle = [];
  if (item && item.length) {
    item.forEach( child => {
      if (child?.values.length) {
        child.values.forEach( styleItem => {
          if (styleItem.id === '3-5') {
            kidsStyle = styleItem?.style;
          }
        });
      }
    });
  }

  return kidsStyle;
}

function LessonsPage() {
  const history = useHistory();
  const { state } = useStoreConsumer();
  const loggedInUser = state.loggedInUser;
  // eslint-disable-next-line no-unused-vars
  const [activeCategory, setActiveCategory] = useState(Lessons[0]);
  const [lessonsData, setLessonsList] = useState(null);
  const [filterEmptyMessage, setFilterEmptyMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [lessonsSubHeading, setLessonSubHeading] = useState("");
  const [isDataPresentAndFilterApplied, toggleFilterOptionVisiblity] =
    useState(false);
  const [isOtherFiltersActive, toggleOtherFilterOverlay] = useState(false);
  const [levelFilterValue, setLevelFilter] = useState("");
  const [styleFilterValue, setStyleFilter] = useState("");
  const [kidsFilterValue, setKidsAgeFilter] = useState("");
  const [planTypeFilterValue, setPlanTypeFilter] = useState("");
  const [subPageNameValue, setSubPageName] = useState("all");
  const [isKidsPage, setKidsPageValue] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isFilterEnabled, shouldEnableFilters] = useState(false);
  const [isLoaderActive, toggleLoading] = useState(false);
  const [shouldShowMenus, toggleSubNavVisiblity] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [shouldShowSubMenus, toggleSubMenus] = useState(false);

  const levelFiltersRef = useRef(levelFilterRefs.map(() => createRef()));
  const allFilterBtnRef = useRef();
  const AllLessonNavRef = useRef();
  const TrialLessonNavRef = useRef();
  const ClassLessonNavRef = useRef();
  const KidsLessonNavRef = useRef();
  const MovesLessonNavRef = useRef();

  // const proFilterBtnRef = useRef();
  // const freeFilterBtnRef = useRef();
  // const paidFilterBtnRef = useRef();
  // const premiumFilterBtnRef = useRef();
  const otherFiltersBtnRef = useRef();

  useEffect(() => {
    logAnalyticsEvent('page_view', {
      page_location: window.location.href,
      page_path: '/events',
      page_title: `HomePage-${window.location.href}`
    });
  }, [])

  useEffect(() => {
    $("html,body").animate(
      {
        scrollTop: 0,
      },
      700
    );
    
    // let planFilterParam = getParameterByName("pricing", window.location.href);
    // let levelFilterParam = getParameterByName("el", window.location.href);
    // let styleFilterParam = getParameterByName("ds", window.location.href);
    // let kidsFilterParam = getParameterByName("ag", window.location.href);
    
    // if (
    //   (planFilterParam && planFilterParam?.length) ||
    //   (levelFilterParam && levelFilterParam?.length) ||
    //   (styleFilterParam && styleFilterParam?.length) ||
    //   (kidsFilterParam && kidsFilterParam?.length)
    // ) {
    //   planFilterParam = planFilterParam?.toLocaleLowerCase();
    //   levelFilterParam = levelFilterParam?.toLocaleLowerCase();
    //   styleFilterParam = styleFilterParam?.toLocaleLowerCase();
    //   kidsFilterParam = kidsFilterParam?.toLocaleLowerCase();
    //   setPlanTypeFilter(planFilterParam);
    //   setLevelFilter(levelFilterParam);
    //   setStyleFilter(styleFilterParam);
    //   setKidsAgeFilter(kidsFilterParam);
    //   filterLesson(null, planFilterParam);
    // } else {
    //   if (allFilterBtnRef.current) {
    //     allFilterBtnRef.current.classList.add("active");
    //   }
    //   // getAllLessonsData();
    // }
    getLessonsBySubPage()
    document.addEventListener("keyup", escFunction, false);
    return () => document.removeEventListener("keyup", escFunction, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function escFunction(event) {
    if (event && event.keyCode === 27) {
      toggleOtherFilterOverlay(false);
    }
  }

  function getAllLessonsData(from) {
    try {
      toggleLoading(true);
      if (!isObjectEmpty(loggedInUser)) {
        getAllLessons().subscribe((lessons) => {
          toggleLoading(false);
          setLessonsListData(lessons, from);
        });
      } else {
        getLessonsWithOnlyPreview().subscribe((lessons) => {
          toggleLoading(false);
          setLessonsListData(lessons, from);
        });
      }
    } catch (e) {
      toggleLoading(false);
      console.log("Error: ", e);
    }
  }

  function setLessonsListData(lessons, from) {
    toggleFilterOptionVisiblity(true);
    const filterBtns = document
      .querySelectorAll(".js-filterWrap")[0]
      .querySelectorAll("button");
    if (filterBtns.length) {
      filterBtns.forEach((item) => {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        }
      });
    }
    if (allFilterBtnRef.current) {
      allFilterBtnRef.current.classList.add("active");
    }

    if (from && from === "filters") {
      window.history.replaceState(null, null, `?pricing=all`);
    }

    if (lessons.length) {
      toggleFilterOptionVisiblity(true);
      setLessonsList(lessons);
      setLessonSubHeading("Learn from the experts and many dance forms!");
    } else {
      toggleFilterOptionVisiblity(false);
      setLessonSubHeading("Lessons video launching soon, stay connected!");
    }
  }

  useEffect(() => {
    if (planTypeFilterValue) {
      filterLesson(null, planTypeFilterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planTypeFilterValue]);

  function filterLesson(event, filter) {
    if (event) {
      event.stopPropagation();
      setPlanTypeFilter(filter);
    }
    setLessonsList([]);
    toggleOtherFilterOverlay(false);
    if (filter === "all") {
      getAllLessonsData("filters");
      setPlanTypeFilter(filter);
      setLevelFilter("");
      setStyleFilter("");
    } else {
      try {
        toggleLoading(true);
        if (!isObjectEmpty(loggedInUser)) {
          getLessonByPlanType(
            planTypeFilterValue === "startup" ? "paid" : planTypeFilterValue,
            levelFilterValue,
            styleFilterValue
          ).subscribe((lessons) => {
            toggleLoading(false);
            setLessonsDataByFilter(lessons, event, planTypeFilterValue);
          });
        } else {
          getLessonByPlanTypeOnlyPreview(
            planTypeFilterValue === "startup" ? "paid" : planTypeFilterValue,
            levelFilterValue,
            styleFilterValue
          ).subscribe((lessons) => {
            toggleLoading(false);
            setLessonsDataByFilter(lessons, event, planTypeFilterValue);
          });
        }
      } catch (e) {
        toggleLoading(false);
        console.log("Error: ", e);
      }
    }
  }

  function getLessonsBySubPage(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    let subPageParam = getParameterByName("sp", window.location.href);

    const getSubPage = event ? event.currentTarget.getAttribute('href').split('#')[1] : subPageParam || 'all';
    const allSubPageMenus = document.querySelectorAll('.js-subPageMenu');
    
    if (getSubPage === 'kids') {
      setKidsAgeFilter(getSubPage);
      setKidsPageValue(true);
    } else {
      setKidsAgeFilter('');
      setKidsPageValue(false);
    }

    allSubPageMenus.forEach( item => {
      if (item.classList.contains('active')) {
        item.classList.remove('active');
      }
    });

    if (event) {
      event.currentTarget.classList.add('active');
    } else {
      switch(getSubPage) {
        case 'all': if (AllLessonNavRef.current) {
                      AllLessonNavRef.current.classList.add('active');
                    }
                    break;
        case 'trials': if (TrialLessonNavRef.current) {
                        TrialLessonNavRef.current.classList.add('active');
                      }
                    break;
        case 'class': if (ClassLessonNavRef.current) {
                        ClassLessonNavRef.current.classList.add('active');
                      }
                    break;
        case 'kids': if (KidsLessonNavRef.current) {
                        KidsLessonNavRef.current.classList.add('active');
                      }
                    break;
        case 'moves': if (MovesLessonNavRef.current) {
                        MovesLessonNavRef.current.classList.add('active');
                      }
                    break;

        default: break;    
      }
    }

    history.push(`/lessons?sp=${getSubPage}`);
    setSubPageName(getSubPage);

    try {
      toggleLoading(true);
      setLessonsList([]);
      getLessonsBySubPageType(getSubPage).subscribe( lessons => {
        toggleLoading(false);
        if (!shouldShowMenus) {
          toggleSubNavVisiblity(true);
        }
        setLessonsList(lessons);
        if (lessons.length) {
          toggleFilterOptionVisiblity(true);
        } else {
          toggleFilterOptionVisiblity(false);
          setFilterEmptyMessage(
            "Sorry, no lessons for the category you have selected. Launching soon!"
          );
        }
      });
    } catch(e) {
      toggleLoading(false);
      console.log("get sub page data error: ", e);
    }
  }

  function setLessonsDataByFilter(lessons, event, filter) {
    setLessonsList([]);
    toggleFilterOptionVisiblity(true);
    // const filterBtns = document
    //   .querySelectorAll(".js-filterWrap")[0]
    //   .querySelectorAll("button");
    // if (filterBtns.length) {
    //   filterBtns.forEach((item) => {
    //     if (!(event && event.target.classList.contains("js-otherFilterIcon"))) {
    //       if (item.classList.contains("active")) {
    //         item.classList.remove("active");
    //       }
    //     }
    //   });
    // }
    // if (planTypeFilterValue && levelFilterValue && styleFilterValue) {
    //   window.history.replaceState(
    //     null,
    //     null,
    //     `?pricing=${planTypeFilterValue}&el=${levelFilterValue}&ds=${styleFilterValue}`
    //   );
    // } else if (planTypeFilterValue && levelFilterValue) {
    //   window.history.replaceState(
    //     null,
    //     null,
    //     `?pricing=${planTypeFilterValue}&el=${levelFilterValue}`
    //   );
    // } else if (planTypeFilterValue && styleFilterValue) {
    //   window.history.replaceState(
    //     null,
    //     null,
    //     `?pricing=${planTypeFilterValue}&ds=${styleFilterValue}`
    //   );
    // } else if (levelFilterValue && styleFilterValue) {
    //   window.history.replaceState(
    //     null,
    //     null,
    //     `?el=${levelFilterValue}&ds=${styleFilterValue}`
    //   );
    // } else if (levelFilterValue) {
    //   window.history.replaceState(null, null, `?el=${levelFilterValue}`);
    // } else if (styleFilterValue) {
    //   window.history.replaceState(null, null, `?ds=${styleFilterValue}`);
    // } else {
    //   window.history.replaceState(
    //     null,
    //     null,
    //     `?pricing=${planTypeFilterValue}`
    //   );
    // }
    // if (event) {
    //   event.target.classList.add("active");
    // } else {
    //   switch (filter) {
    //     case "all":
    //       if (allFilterBtnRef.current) {
    //         allFilterBtnRef.current.classList.add("active");
    //       }
    //       break;

    //     case "startup":
    //       // if (paidFilterBtnRef.current) {
    //       //   paidFilterBtnRef.current.classList.add("active");
    //       // }
    //       break;

    //     case "premium":
    //       // if (premiumFilterBtnRef.current) {
    //       //   premiumFilterBtnRef.current.classList.add("active");
    //       // }
    //       break;

    //     case "free":
    //       // if (freeFilterBtnRef.current) {
    //       //   freeFilterBtnRef.current.classList.add("active");
    //       // }
    //       break;

    //     case "pro":
    //       // if (proFilterBtnRef.current) {
    //       //   proFilterBtnRef.current.classList.add("active");
    //       // }
    //       break;

    //     default:
    //       break;
    //   }
    // }
    if (lessons.length) {
      setLessonsList(lessons);
    } else {
      setLessonsList([]);
      setFilterEmptyMessage(
        "Sorry, no lessons for the filters you have selected. Please try with another filters!"
      );
    }
  }

  function toggleOtherFilters(event, action) {
    event.stopPropagation();
    setLessonsList([]);
    if (!action) {
      setLevelFilter("");
      setStyleFilter("");
    }
    toggleOtherFilterOverlay(action);
  }

  function resetAppliedFilters(event, action) {
    event.stopPropagation();
    if (!action) {
      setLevelFilter("");
      setStyleFilter("");
      setKidsAgeFilter("");
    }
    // filterLesson(null, "all");
    getLessonsBySubPage();
    toggleOtherFilterOverlay(action);
  }

  function handleOtherFilters(event, filterName) {
    event.stopPropagation();
    setLessonsList([]);
    if (filterName === "kidsAgeFilter") {
      setKidsAgeFilter(event.target.value);
    } else {
      setStyleFilter(event.target.value);
    }
  }

  // This function will trigger in outside level filters
  function filterLessonByLevel(event, filter) {
    if (event) {
      event.stopPropagation();
    }
    
    if (allFilterBtnRef.current && levelFiltersRef.current[0].current && levelFiltersRef.current[1].current && levelFiltersRef.current[2].current) {
      allFilterBtnRef.current.classList.remove('active');
      levelFiltersRef.current[0].current.classList.remove('active');
      levelFiltersRef.current[1].current.classList.remove('active');
      levelFiltersRef.current[2].current.classList.remove('active');
    }

    event.currentTarget.classList.add('active');
    setLessonsList([]);
    if (filter === 'all') {
      getSubpagesLessonsByFilter(event, null, null, null, subPageNameValue);
    } else {
      getSubpagesLessonsByFilter(event, filter, null, null, subPageNameValue);
    }
  }

  function filterLessonByOthers(event) {
    if (event) {
      event.stopPropagation();
    }
    
    if (allFilterBtnRef.current && levelFiltersRef.current[0].current && levelFiltersRef.current[1].current && levelFiltersRef.current[2].current) {
      allFilterBtnRef.current.classList.add('active');
      levelFiltersRef.current[0].current.classList.remove('active');
      levelFiltersRef.current[1].current.classList.remove('active');
      levelFiltersRef.current[2].current.classList.remove('active');
    }

    toggleOtherFilterOverlay(false);
    setLessonsList([]);
    getSubpagesLessonsByFilter(event, null, styleFilterValue, kidsFilterValue, subPageNameValue);
  }

  function getSubpagesLessonsByFilter(event, levelFilterValue, styleFilterValue, kidsAgeValue, subPageNameValue) {
    try {
      toggleLoading(true);
      if (!isObjectEmpty(loggedInUser)) {
        getLessonByPlanType(
          null,
          levelFilterValue,
          styleFilterValue,
          kidsAgeValue,
          subPageNameValue
        ).subscribe((lessons) => {
          toggleLoading(false);
          setLessonsDataByFilter(lessons, event, planTypeFilterValue);
        });
      } else {
        setLessonsList([]);
        getLessonByPlanTypeOnlyPreview(
          null,
          levelFilterValue,
          styleFilterValue,
          kidsAgeValue,
          subPageNameValue
        ).subscribe((lessons) => {
          toggleLoading(false);
          setLessonsDataByFilter(lessons, event, planTypeFilterValue);
        });
      }
    } catch (e) {
      toggleLoading(false);
      console.log("Error: ", e);
    }
  }

  return (
    <div
      className="lessons lessons-wrap"
      id="upcomingLessons"
    >
      <Loader value={isLoaderActive} />
      <div className={`lessonsNav ${shouldShowMenus ? 'showNavs' : ''}`}>
        <a href="#all" ref={AllLessonNavRef} className="menus active js-subPageMenu" onClick={(e) => getLessonsBySubPage(e)}>
          <i><MdSelectAll /></i>
          <span>All</span>
        </a>
        <a href="#trials" ref={TrialLessonNavRef} className="menus js-subPageMenu" onClick={(e) => getLessonsBySubPage(e)}>
          <i><MdToc /></i>
          <span>Trials</span>
        </a>
        <a href="#class" ref={ClassLessonNavRef} className="menus js-subPageMenu" onClick={(e) => getLessonsBySubPage(e)}>
          <i><MdLibraryBooks /></i>
          <span>Classes</span>
        </a>
        {
          shouldShowSubMenus ?
          <a href="#kids" ref={KidsLessonNavRef} className="menus js-subPageMenu" onClick={(e) => getLessonsBySubPage(e)}>
            <i><FaChild /></i>
            <span>Kids</span>
          </a> : ''
        }
        {
          shouldShowSubMenus ?
          <a href="#moves" ref={MovesLessonNavRef} className="menus js-subPageMenu" onClick={(e) => getLessonsBySubPage(e)}>
            <i><MdDirectionsWalk /></i>
            <span>Moves</span>
          </a> : ''
        }
      </div>
      <div className="inner-page">
        {
          shouldShowSubMenus ?
          <h1>
          {`Lessons from ${(subPageNameValue === 'all' || '') ? 'all' : subPageNameValue} ${(subPageNameValue === 'all' || '') ? 'categories' : 'category'}`}</h1>
          : <h1>Learn different types of dance forms</h1>
        }
        {/* <p>
                    Lessons for all users from our expert faculty members.
                    From Hip-Hop to Bharatnatyam. You'll get all learning videos
                    at one place.
                </p>  */}
        {/* {lessonsData && lessonsData.length ? (
          <p className="launching-soon">Our recent lessons!</p>
        ) : (
          <p className="launching-soon">{lessonsSubHeading}</p>
        )} */}
      </div>

      <div className="lesson-wrap">
        {isDataPresentAndFilterApplied ? (
          <div className="filterWrap js-filterWrap">
            <button
              ref={allFilterBtnRef}
              title="apply all filter"
              className="btn primary-dark active"
              onClick={(e) => filterLessonByLevel(e, "all")}
            >
              All
            </button>
            {
              FILTER_BY?.filterBy && FILTER_BY?.filterBy.length ?
              FILTER_BY.filterBy.map( item => {
                return (
                  item.id === 'expertiseLevel' ? 
                  item.values.map( (option, index) => {
                    return (
                      <button
                        key={`levelsBtns-${index}`}
                        ref={levelFiltersRef.current[index]}
                        title={`apply ${option.id} filter`}
                        className="btn primary-dark"
                        onClick={(e) => filterLessonByLevel(e, option.id)}
                      >
                        {option.label}
                      </button>
                    )
                  }) : ''
                )
              }) : ''
            }
            {/* <button
              ref={allFilterBtnRef}
              title="apply all filter"
              className="btn primary-dark active"
              onClick={(e) => filterLesson(e, "all")}
            >
              All
            </button>
            <button
              ref={freeFilterBtnRef}
              title="apply free filter"
              className="btn primary-dark"
              onClick={(e) => filterLesson(e, "free")}
            >
              Free
            </button>
            <button
              ref={paidFilterBtnRef}
              title="apply startup filter"
              className="btn primary-dark"
              onClick={(e) => filterLesson(e, "startup")}
            >
              Startup
            </button>
            {isFilterEnabled ? (
              <button
                ref={proFilterBtnRef}
                title="apply pro filter"
                className="btn primary-dark"
                onClick={(e) => filterLesson(e, "pro")}
              >
                Pro
              </button>
            ) : (
              ""
            )}
            {isFilterEnabled ? (
              <button
                ref={premiumFilterBtnRef}
                title="apply premium filter"
                className="btn primary-dark"
                onClick={(e) => filterLesson(e, "premium")}
              >
                Premium
              </button>
            ) : (
              ""
            )} */}
            <button
              ref={otherFiltersBtnRef}
              title="apply other filters"
              className="btn primary-dark otherFilterIcon js-otherFilterIcon"
              onClick={(e) => toggleOtherFilters(e, true)}
            >
              <span>
                <FaFilter />
              </span>
            </button>
          </div>
        ) : (
          ""
        )}
        {isOtherFiltersActive ? (
          <div className="otherFiltersWrap">
            <div className="innerBox">
              <p
                className="close-modal-icon dark"
                onClick={(e) => toggleOtherFilters(e, false)}
                title="close filter"
              ></p>
              <h3>Filter by</h3>
              <div className="filterInnerWrap">
              {isKidsPage && FILTER_BY && FILTER_BY?.filterBy.length
                  ? FILTER_BY?.filterBy.map((filterItem) => {
                    return (
                      filterItem.id === 'kidsAgeFilter' ?
                      <div className="filterItem" key={filterItem?.id}>
                        <p className="filterTitle">{filterItem?.name}</p>
                        <div className="optionsWrap">
                          <RadioGroup
                            className="radioGroupControls"
                            aria-label={`aria label for ${filterItem?.name}`}
                            name={filterItem?.id}
                            value={kidsFilterValue}
                            onChange={(e) =>
                              handleOtherFilters(e, filterItem?.id)
                            }
                          >
                            {
                              filterItem?.values && filterItem?.values.length
                              ? filterItem.values.map(option => {
                                return (
                                  <FormControlLabel
                                    key={option?.id}
                                    value={option?.id}
                                    control={<Radio />}
                                    label={option?.label}
                                  />)
                                })
                              : ""
                            }
                          </RadioGroup>
                        </div>
                      </div> : ''
                    );
                  })
                  : ""}
                {FILTER_BY && FILTER_BY?.filterBy.length
                  ? FILTER_BY?.filterBy.map((filterItem) => {
                    return (
                      filterItem.id === 'danceStyles' ?
                      <div className="filterItem" key={filterItem?.id}>
                        <p className="filterTitle">{filterItem?.name}</p>
                        <div className="optionsWrap">
                          <RadioGroup
                            className="radioGroupControls"
                            aria-label={`aria label for ${filterItem?.name}`}
                            name={filterItem?.id}
                            value={styleFilterValue}
                            onChange={(e) =>
                              handleOtherFilters(e, filterItem?.id)
                            }
                          >
                            {
                              kidsFilterValue && kidsFilterValue === '3-5' ?
                                KIDS_3TO5_STYLES.length
                                ? KIDS_3TO5_STYLES.map(option => {
                                  return (
                                    <FormControlLabel
                                      key={option?.id}
                                      value={option?.id}
                                      control={<Radio />}
                                      label={option?.label}
                                    />
                                  )
                                }) : ""
                              :
                                filterItem?.values && filterItem?.values.length
                                ? filterItem.values.map(option => {
                                  return (
                                    <FormControlLabel
                                      key={option?.id}
                                      value={option?.id}
                                      control={<Radio />}
                                      label={option?.label}
                                    />)
                                  })
                                : "" 
                              }
                          </RadioGroup>
                        </div>
                      </div> : ''
                    );
                  })
                  : ""}
              </div>
              <div className="filterActionWrap">
                <button
                  className="btn primary-light"
                  onClick={(e) => resetAppliedFilters(e, false)}
                >
                  Reset &amp; cancel
                </button>
                <button
                  className="btn primary-dark"
                  onClick={(e) => filterLessonByOthers(e)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="lessons-vdo-wrap">
          {lessonsData && lessonsData.length ? (
            lessonsData.map((videoData, index) => {
              return (
                <LessonsVideoContainer
                  lessonId={videoData.id}
                  lessonKey={videoData.key}
                  title={videoData.name}
                  artist={videoData.teacher}
                  desc={videoData.desc}
                  lessonPlayTime={videoData?.lessonPlayedTimes}
                  videoUserLevel={videoData?.expertiseLevel}
                  artForm={videoData?.artForm}
                  duration={videoData?.videoDuration}
                  isPaid={videoData.accessbility}
                  uploadedOn={videoData.uploadedTime}
                  thumbNail={videoData.thumbnailImage}
                  activeVideosList={videoData.videoList}
                  m3u8VideoList={videoData.m3u8VideoList}
                  videoId={`lessonVideo-${index + 1}`}
                  key={"lesson-" + index}
                />
              );
            })
          ) : filterEmptyMessage && filterEmptyMessage.length ? (
            <p className="emptyLessonsFilterMessage">{filterEmptyMessage}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default LessonsPage;
