import React, { useEffect, useState, useRef } from "react";
import { sendEmail } from "../../Services/Email.service";
import boogaluLogo from "../../Images/da logo.png";
import { useHistory } from "react-router-dom";
import JudgesLogin from "./judgeslogin";
import JudgesPrivacyPolicy from "./judgesPrivacyPolicy";
import JudgesTermsUse from "./judgesTermsOfUse";
import { FINAL_JUDGES_USER, FINAL_JUDGES_PWD } from "../../Constants";
import {
  updateCompetition,
  getCompetitionsByFilter,
} from "../../Services/EnrollCompetition.service";
import { getCompetition } from "../../Services/Competition.service";
import { getUserById } from "../../Services/User.service";
import RippleLoader from "../RippleLoader";
import Loader from "../Loader";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { getYearDifferenceInTwoDates } from "../../helpers";

const checkJudgesLogin = JSON.parse(sessionStorage.getItem("finalJudgeLogin"));
const checkFinalRulesRead = JSON.parse(
  sessionStorage.getItem("finalJudgeRules")
);

const ONLY_SELECTED = "Selected";

let winnersPriceData = [];

function FinalRound() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  const [isJudgesLoggedIn, toggleJudgesLogin] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const [isPrivacyPolicyVisible, togglePrivacyPolicy] = useState(false);
  const [isTermsOfUseVisible, toggleTermsOfUse] = useState(false);
  const [shouldEnableVideoList, enableVideoLists] = useState(false);
  const [isVideosLoading, toggleRippleLoader] = useState(false);
  const [compeitionListData, setComptitionList] = useState([]);
  const [messageHandler, handleDataFectchMessages] = useState("");
  const [finalWinnersPrizes, setFinaWinnersPrize] = useState([]);
  const [isVideoActionModalOpen, toggleVideoActionModal] = useState(false);
  const [contestantName, setContestantName] = useState("");
  const [competionForAction, setCompetitionActionValue] = useState(null);
  const [filterBtnElements, setFilterBtns] = useState(null);
  const [finalPrizes, setFinalPrizes] = useState([]);
  const [winnersFinalPrizeData, setWinnersFinalPrizeData] = useState([]);
  const [alreadyAwardGivenError, setAlreadyAppliedAwardError] = useState("");
  const [pageLoader, activatePageLoader] = useState(false);

  const submitBtnRef = useRef(null);
  const contestantCommentRef = useRef(null);
  const judgeNameInputRef = useRef(null);
  const judgeNameMessageRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const { REACT_APP_URL } = process.env;

  useEffect(() => {
    toggleJudgesLogin(checkJudgesLogin);
    if (checkFinalRulesRead) {
      enableVideoLists(true);
      getFilteredCompetitionLists(ONLY_SELECTED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function judgesLoginStatus(value) {
    if (value && !value?.user && !value?.pwd) {
      setLoginMessage("Enter username & Password!");
    } else if (
      value &&
      (value?.user !== FINAL_JUDGES_USER || value?.pwd !== FINAL_JUDGES_PWD)
    ) {
      setLoginMessage("Enter correct username & Password!");
    } else {
      setLoginMessage("");
      toggleJudgesLogin(true);
      sessionStorage.setItem("finalJudgeLogin", true);
    }
  }

  function shouldShowPrivacyPolicy(action) {
    togglePrivacyPolicy(action);
  }

  function shouldShowTermsOfUse(action) {
    toggleTermsOfUse(action);
  }

  function showCompetitionVideos(event, stage) {
    event.stopPropagation();
    if (judgeNameInputRef.current && judgeNameInputRef.current.value) {
      const finalJudgeData = {
        termsAccepted: true,
        stage: stage,
        judgeName: judgeNameInputRef.current.value,
      };
      sessionStorage.setItem("finalJudgeRules", JSON.stringify(finalJudgeData));
      enableVideoLists(true);
      getFilteredCompetitionLists("Selected");
    } else {
      if (judgeNameInputRef.current) {
        judgeNameInputRef.current.classList.add("shake");
        setTimeout(() => {
          judgeNameInputRef.current.classList.remove("shake");
        }, 500);
      }
      if (judgeNameMessageRef.current) {
        judgeNameMessageRef.current.classList.add("error");
        judgeNameMessageRef.current.innerText = "Please enter judge name!";
      }
    }
  }

  function getFilteredCompetitionLists(filterValue) {
    toggleRippleLoader(true);
    try {
      getCompetitionsByFilter(filterValue).subscribe((response) => {
        handleDataFectchMessages("");
        if (response.length) {
          const compList = [];
          response.forEach((item) => {
            getUserById(item.userId).subscribe((resp) => {
              const compObj = {
                ...item,
                userDetails: JSON.parse(JSON.stringify(resp)),
              };
              compList.push(compObj);
              if (compList.length === response.length) {
                toggleRippleLoader(false);
                setComptitionList(compList);
                setPreviousPirzeWinnersData(compList);
                if (!filterBtnElements) {
                  const filterBtns = document.querySelectorAll(".js-filterBtn");
                  setFilterBtns(filterBtns);
                }
              } else {
                toggleRippleLoader(false);
                setComptitionList([]);
              }
            });
          });
        } else {
          setComptitionList([]);
          toggleRippleLoader(false);
          handleDataFectchMessages(
            "There are no competition videos matching the filter applied, change filter & try!"
          );
        }
      });
    } catch (e) {
      toggleRippleLoader(false);
      handleDataFectchMessages(
        "Oops! something went wrong, please try in sometime!"
      );
      console.log("Competition filter error: ", e);
    }
  }

  function setPreviousPirzeWinnersData(data) {
    const prizeData = [];
    if (data && data.length) {
      data.forEach((item) => {
        if (item?.finalResult && item?.finalResult.length) {
          prizeData.push(...item.finalResult);
        }
      });
    }
    setWinnersFinalPrizeData(prizeData);
  }

  function getCompPrizesData(comp) {
    if (comp) {
      activatePageLoader(true);
      setFinalPrizes([]);
      try {
        getCompetition(comp?.compId).subscribe((resp) => {
          activatePageLoader(false);
          if (resp && resp?.prices && resp?.prices.length) {
            setFinalPrizes(resp.prices);
          }
          toggleActionModalBox(comp);
        });
      } catch (e) {
        activatePageLoader(false);
        console.log("Error: ", e);
      }
    }
  }

  function toggleUserVideoSelection(event, prize) {
    event.stopPropagation();

    if (event.currentTarget.classList.contains("active")) {
      event.currentTarget.classList.remove("active");
      winnersPriceData = winnersPriceData.filter((obj) => {
        return obj.key !== prize.key;
      });
    } else {
      event.currentTarget.classList.add("active");
      winnersPriceData.push(prize);
    }

    if (submitBtnRef.current && submitBtnRef.current.disabled) {
      submitBtnRef.current.disabled = false;
    }

    const allActionBtns = document.querySelectorAll(".js-mainActionBtn");
    let allBtnCounter = 0;
    if (allActionBtns && allActionBtns.length) {
      allActionBtns.forEach((btn) => {
        if (btn.classList.contains("active")) {
          allBtnCounter++;
        }
      });
    }
    if (!allBtnCounter) {
      submitBtnRef.current.disabled = true;
    }
    setAlreadyAppliedAwardError("");
    setFinaWinnersPrize(winnersPriceData);
  }

  function submitActionSelection(event) {
    event.stopPropagation();
    if (competionForAction?.key) {
      if (winnersFinalPrizeData?.length && finalWinnersPrizes?.length) {
        winnersFinalPrizeData.forEach((item) => {
          finalWinnersPrizes.forEach((item2) => {
            if (item2.key === item.key) {
              setAlreadyAppliedAwardError(
                `The ${item.name} award has been already given, please select another award!`
              );
            }
          });
        });
      }
      if (!alreadyAwardGivenError) {
        if (
          competionForAction?.finalResult &&
          competionForAction?.finalResult.length
        ) {
          competionForAction.finalResult = [
            ...competionForAction.finalResult,
            ...finalWinnersPrizes,
          ];
        } else {
          competionForAction["finalResult"] = finalWinnersPrizes;
        }
        if (
          contestantCommentRef.current &&
          contestantCommentRef.current.value
        ) {
          competionForAction["finalJudgeComment"] =
            contestantCommentRef.current.value;
        }
        if (submitBtnRef.current) {
          submitBtnRef.current.classList.add("loading");
        }
        setAlreadyAppliedAwardError("");
        try {
          updateCompetition(
            competionForAction.key,
            competionForAction
          ).subscribe((resp) => {
            console.log("update enrolled comp: ", resp);
            getFilteredCompetitionLists(ONLY_SELECTED);
            toggleVideoActionModal(false);
            setFinaWinnersPrize([]);
            sendEmailToUser(competionForAction);
            if (submitBtnRef.current) {
              submitBtnRef.current.classList.remove("loading");
            }
          });
        } catch (e) {
          console.log("update enrolled comp error: ", e);
          toggleVideoActionModal(false);
          if (submitBtnRef.current) {
            submitBtnRef.current.classList.remove("loading");
          }
        }
      }
    }
  }

  function sendEmailToUser(comp) {
    const userEmail = comp?.userDetails?.email;
    const userName = comp?.userDetails?.name;
    const compName = comp?.compName;
    const compImg = comp?.compImg;
    const judgeComment = comp?.judgesComment;
    let awardsPrizes = `
                            ${
                              comp?.finalResult && comp?.finalResult.length
                                ? comp?.finalResult.map((item) => {
                                    return item.name;
                                  })
                                : ""
                            }
        `;
    awardsPrizes = awardsPrizes.split(",").join(", ");
    let emailBody = `<div>
            <h3 style="font-weight: 400;">Hi ${userName}</h3>
            <p style="color: #f50057; font-size: 20px; text-align: center;">Congratulations</p>
            <p>You have won some prizes and rewards for your performances:</p>
            <p style="font-size: 14px; color: #962049; text-transform: uppercase;">${awardsPrizes}</p>
            <h4>Judges comment: <span style="font-weight: 400;">${judgeComment}</span></h4>
            <p>"Congralutations! now let's gear up for upcoming competitions!"</p>
            <h4> Till then, keep dancing and keep shining!</h4>
            <p style="height: 150px;">
                <img style="width: 100%;" src=${compImg} alt=${compName}/>
            </p>
            <p>
                For more information visit:<br/>
                <a href="https://appda.in" target="_blank" rel="noreferrer">www.appda.in</a>
            </p>
            `;
    let payload = {
      mailTo: userEmail,
      title: `${compName} - results for Final round!`,
      content: emailBody,
    };
    sendEmail(payload).subscribe((res) => {
      if (!("error" in res)) {
        console.log("User Email Send Successfully.");
      } else console.log("User Email Send Failed.");
    });
  }

  function toggleActionModalBox(compItem) {
    setContestantName("");
    setCompetitionActionValue(null);
    toggleVideoActionModal(!isVideoActionModalOpen);
    setTimeout(() => {
      if (submitBtnRef.current) {
        submitBtnRef.current.disabled = true;
      }
      const getAllBtns = document.querySelectorAll(".js-mainActionBtn");
      if (getAllBtns && getAllBtns.length) {
        if (winnersFinalPrizeData && winnersFinalPrizeData.length) {
          getAllBtns.forEach((ele) => {
            winnersFinalPrizeData.forEach((item) => {
              if (ele.getAttribute("data-id") === item.key) {
                ele.disabled = true;
                ele.setAttribute(
                  "title",
                  "This award has been given already, select from the active awards!"
                );
              }
            });
          });
        }
      }
    }, 1);

    if (compItem) {
      const userFirstName = `${compItem?.userDetails?.name?.split(" ")[0]}'s`;
      setContestantName(userFirstName);
      setCompetitionActionValue(compItem);
    }
  }

  return (
    <div className="judgementWrap finalRoundWrap">
      {pageLoader ? <Loader value={pageLoader} /> : ""}
      {isVideoActionModalOpen ? (
        <div className="videoActionsWrap">
          <div className="actionsBox">
            <h2>What is your verdict for {contestantName} performance?</h2>
            <p
              className="closeModalLink"
              title="Close modal"
              onClick={() => toggleActionModalBox(null)}
            ></p>
            <div className="actions">
              {finalPrizes && finalPrizes.length
                ? finalPrizes.map((prize) => {
                    return (
                      <button
                        key={prize.key}
                        data-id={prize.key}
                        className={`mainActionBtn js-mainActionBtn`}
                        onClick={(e) => toggleUserVideoSelection(e, prize)}
                      >
                        {prize.name}
                      </button>
                    );
                  })
                : ""}
            </div>
            <textarea
              className="comments"
              ref={contestantCommentRef}
              placeholder="Comment for the winners"
            ></textarea>
            <span
              className={`awardInfo ${alreadyAwardGivenError ? "error" : ""}`}
            >
              {alreadyAwardGivenError}
            </span>
            <button
              className="btn mainActionBtn submitBtn"
              ref={submitBtnRef}
              onClick={(e) => submitActionSelection(e)}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {isJudgesLoggedIn ? (
        <div className="compeitionsVideoAndRules">
          <a href="/" className="logo" title="Back to Home">
            <img src={boogaluLogo} alt="Da" />
          </a>
          <h1>Welcome to Competitions judgement Final round</h1>
          <div
            className={`innerContentBox ${
              shouldEnableVideoList ? "videoListInnerContent" : ""
            }`}
          >
            {!shouldEnableVideoList ? (
              <div className="rulesBox">
                <h2>Rules of Judging</h2>
                <p className="paraTexts">
                  All the videos which is visible in the portal are the videos
                  uploaded by our contestants for the competition we have
                  organised on{" "}
                  <a
                    href="https://www.appda.in/events"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.appda.in/events
                  </a>
                  .
                </p>
                <p className="paraTexts">
                  The purpose of this portal is to only judge the videos and
                  give the verdict to the contestants fairly. By accepting this
                  terms you are giving the consent that these videos and
                  information of our contestants will not be used for any
                  personal or advertisment purpose apart from judging them in
                  our portal.
                </p>
                <p className="paraTexts">
                  For more information and future contact we would like to take
                  some informations of judges such as name. This information
                  will only going to use inside our website for future reference
                  and getting information about the verdict a judge provided to
                  a contestant's video.
                </p>
                <label
                  htmlFor="judgeName"
                  className="judgeNameBox"
                  title="enter judge name"
                >
                  <input
                    type="text"
                    id="judgeName"
                    ref={judgeNameInputRef}
                    placeholder="Enter judge name"
                  />
                  <span className="messageBox" ref={judgeNameMessageRef}></span>
                </label>
                <button
                  className="btn"
                  onClick={(e) => showCompetitionVideos(e, "pre-judge")}
                >
                  Agreed &amp; continue
                </button>
              </div>
            ) : (
              <div className="videoListBox">
                {isVideosLoading ? (
                  <RippleLoader />
                ) : compeitionListData && compeitionListData.length ? (
                  compeitionListData.map((item, index) => {
                    return (
                      <div className="competitionBox" key={`comp-box-${index}`}>
                        <p
                          className={`compTitle ${
                            item?.status ? item.status.toLowerCase() : ""
                          }`}
                        >
                          <span className="name">{item?.compName}</span>
                          <span className="status">{item?.status || ""}</span>
                        </p>
                        <div className="videoWrap">
                          <VideoPlayer
                            vdoObj={item.vdo}
                            shouldDownload={true}
                          />
                        </div>
                        <div className="userVideoDetails">
                          <span className="innerValues ageGroup">
                            Age criteria: {item.ageGroup}
                          </span>
                          <span className="innerValues userName">
                            Contestant Name: {item?.userDetails?.name}
                          </span>
                          <span className="innerValues userAge">
                            Age:{" "}
                            {getYearDifferenceInTwoDates(
                              new Date(),
                              new Date(item?.userDetails?.dob)
                            )}{" "}
                            years old
                          </span>
                          <button
                            className="videoActionBtn"
                            title="Video actions"
                            onClick={() => getCompPrizesData(item)}
                          >
                            <span></span>
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  ""
                )}

                {messageHandler ? (
                  <p className="messageHandlingBox">{messageHandler}</p>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {!isJudgesLoggedIn ? (
        <JudgesLogin
          message={loginMessage}
          showPrivacyPolicy={shouldShowPrivacyPolicy}
          showTermsOfUse={shouldShowTermsOfUse}
          loginAction={judgesLoginStatus}
        />
      ) : (
        ""
      )}

      {isPrivacyPolicyVisible ? (
        <JudgesPrivacyPolicy closeModalBox={shouldShowPrivacyPolicy} />
      ) : (
        ""
      )}

      {isTermsOfUseVisible ? (
        <JudgesTermsUse closeModalBox={shouldShowTermsOfUse} />
      ) : (
        ""
      )}
    </div>
  );
}

export default FinalRound;
