import React, { useState, useEffect, useRef } from "react";
import { LinearProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import { FaCloudUploadAlt, FaInfoCircle, FaTrashAlt } from "react-icons/fa";
import boogaluLogo from "../../Images/da logo.png";
import { FaPowerOff } from "react-icons/fa";
import { Link } from "react-router-dom";
import GenericInfoModal from "../genericInfoModal";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import SwitchLabels from "../SwitchToggle";
import Navigation from "./Navigation";
import {
  saveLesson,
  getLessonByName,
  getAllLessons,
  saveLessonVideo,
  deleteLesson,
  updateLessonActiveStatus,
  updateLessonsVideoListAndThumbnail,
} from "../../Services/Lessons.service";
import Notification from "../Notification";
import {
  NOTIFICATION_ERROR,
  LESSONS_STYLES_ALL,
  LESSONS_SUBPAGES,
} from "../../Constants";
import { uploadVideo } from "../../Services/Upload.service";
import Loader from "../Loader";
import AdminLogin from "./AdminLogin";
import { getAdminCredentials } from "../../Services/Other.service";

if (localStorage.getItem("adminLoggedIn")) {
  localStorage.removeItem("adminLoggedIn");
}

const checkAdminLogIn = JSON.parse(sessionStorage.getItem("adminLoggedIn"));

let previewVideoArray = [];
let previewThumbnail = "";

export default function UploadLessons() {
  const uploaderRefThumbnailImage = useRef(),
    uploaderPreview = useRef(),
    uploaderRefFrontView = useRef(),
    uploaderRefVRView = useRef();
  const lessonFormDetails = {
    name: "",
    teacher: "",
    desc: "",
    expertiseLevel: "",
    subPage: "class",
    kidsAgeGroup: "3-5",
    artForm: "",
    videoDuration: "",
    accessbility: "paid",
    isLocal: false,
    files: [],
  };

  const [isAdminLoggedIn, toggleAdminLogin] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPwd, setAdminPwd] = useState("");
  const [loggedInMessages, setLoginMessage] = useState("");
  const [uploadNewLesson, setInternalLink] = useState(true);
  const [formMessageBox, setFormMessage] = useState("");
  const [messageClass, setFormMessageClass] = useState("");
  const [lessonsData, setLessonsList] = useState(null);
  const [isUploadingInProgress, toggleUploadingMessage] = useState(false);
  const [btnLoadingClass, toggleBtnLoadingClass] = useState("");
  const [previewModeOn, togglePreviewMode] = useState(false);
  const [isLoaderActive, toggleLoading] = useState(false);
  const [lessonsStyleData, setLessonsStyle] = useState(LESSONS_STYLES_ALL);
  const [kidsAgeGroupData, setKidsAgeGroupData] = useState([]);
  const [isInfoModalOpen, toggleInfoModal] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState("");
  const [infoModalStatus, setInfoModalStatus] = useState("info");
  // eslint-disable-next-line no-unused-vars
  const [isLocalSelected, toggleLocalSelection] = useState(false);
  const [shouldShowNotification, toggleNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMsg, setNotificationMsg] = useState("");
  const [isthumbnailUploading, toggleThumbnailUploader] = useState(false);
  const [isPreviewUploading, togglePreviewUploader] = useState(false);
  const [isMainVideoUploading, toggleMainVideoUploader] = useState(false);
  const [isVRVideoUploading, toggleVRVideoUploader] = useState(false);

  const createTabRef = useRef(null);
  const listTabRef = useRef(null);
  const previewVideoPreviewRef = useRef(null);
  const previewVideoFrontRef = useRef(null);
  // const previewVideoFrontMirrorRef = useRef(null);
  // const previewVideoBackRef = useRef(null);
  // const previewVideoBackMirrorRef = useRef(null);
  const previewVideoVRModeRef = useRef(null);

  const requiredUploadFieldsForLesson = [
    "thumbnailImage",
    "preview",
    "frontView",
  ];
  const initialVideosToUploadData = {
    thumbnailImage: null,
    preview: null,
    frontView: null,
    vrView: null,
  };

  const [videosToUpload, setVideosToUpload] = useState(
    initialVideosToUploadData
  );

  const initialVideoUploadProgress = {
    thumbnailImage: 0,
    preview: 0,
    frontView: 0,
    vrView: 0,
  };
  const [videoUploadProgess, setVideoUploadProgess] = useState(
    initialVideoUploadProgress
  );
  const initialVideoProgressBarState = {
    thumbnailImage: false,
    preview: false,
    frontView: false,
    vrView: false,
  };
  const [showVideoProgressBar, setShowVideoProgressBar] = useState(
    initialVideoProgressBarState
  );
  const [fileUploadValue, setFileUploadValue] = useState("");

  const [disableUploadButton, setUploadButtonState] = useState(false);
  const [SelectedVideoData, setSelectedVideoData] = useState(lessonFormDetails);

  useEffect(() => {
    if (checkAdminLogIn) {
      toggleAdminLogin(checkAdminLogIn);
      setInternalLink(true);
    }
  }, []);

  useEffect(() => {
    const validateRequiredFieldsData = [];
    if (videosToUpload) {
      requiredUploadFieldsForLesson.forEach((field) => {
        const itemValue = videosToUpload[field];
        if (itemValue) {
          validateRequiredFieldsData.push(videosToUpload[field]);
        }
      });
    }
    if (
      requiredUploadFieldsForLesson &&
      validateRequiredFieldsData &&
      validateRequiredFieldsData.length === requiredUploadFieldsForLesson.length
    ) {
      setFormMessageClass("");
      setFormMessage("");
      setSelectedVideoData({ ...SelectedVideoData, files: videosToUpload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videosToUpload]);

  function validateVideoFiles() {
    const validateRequiredFieldsData = [];
    if (videosToUpload) {
      requiredUploadFieldsForLesson.forEach((field) => {
        const itemValue = videosToUpload[field];
        if (itemValue) {
          validateRequiredFieldsData.push(videosToUpload[field]);
        }
      });
    }
    if (
      requiredUploadFieldsForLesson &&
      validateRequiredFieldsData &&
      validateRequiredFieldsData.length === requiredUploadFieldsForLesson.length
    ) {
      return true;
    } else {
      return false;
    }
  }

  function handleAdminLogin(value, type) {
    if (type === "email") {
      setAdminEmail(value?.target?.value);
    } else {
      setAdminPwd(value?.target?.value);
    }
  }

  function triggerLogin(action) {
    if (action) {
      if (adminEmail === "" || adminPwd === "") {
        setLoginMessage("Please enter Email and Password!");
        toggleAdminLogin(false);
        sessionStorage.setItem("adminLoggedIn", false);
      } else {
        getAdminCredentials().subscribe((resp) => {
          if (resp?.email) {
            if (adminEmail === resp.email && adminPwd === resp.pwd) {
              setLoginMessage("");
              toggleAdminLogin(true);
              sessionStorage.setItem("adminLoggedIn", true);
            } else {
              toggleAdminLogin(false);
              sessionStorage.setItem("adminLoggedIn", false);
              setLoginMessage(
                "Invalid credentials, please enter valid email-Id and Password!"
              );
            }
          }
        });
      }
    }
  }

  function tiggerAdminLogout(event, action) {
    setAdminEmail("");
    setAdminPwd("");
    toggleAdminLogin(action);
    sessionStorage.setItem("adminLoggedIn", action);
    window.location.reload();
  }

  const setInternalLinkFn = (internalLink) => {
    if (internalLink) {
      if (createTabRef.current && listTabRef.current) {
        createTabRef.current.classList.add("active");
        listTabRef.current.classList.remove("active");
      }
    } else {
      getAllLessonsData("admin");
      if (createTabRef.current && listTabRef.current) {
        createTabRef.current.classList.remove("active");
        listTabRef.current.classList.add("active");
      }
    }
    setInternalLink(internalLink);
  };

  const handleChange = (prop, index) => (event) => {
    if (prop === "subPage") {
      renderCategoryDanceStyle(event.target.value);
    }
    if (prop === "kidsAgeGroup") {
      renderCategoryDanceStyleForKids(event.target.value);
    }
    let value = prop === "isLocal" ? event.target.checked : event.target.value;
    setSelectedVideoData({ ...SelectedVideoData, [prop]: value });
  };

  function renderCategoryDanceStyle(pageValue) {
    const matchedPage = LESSONS_SUBPAGES.filter((item) => {
      return item.name === pageValue;
    });
    if (matchedPage[0].name !== "kids") {
      setLessonsStyle(matchedPage[0].styles);
    } else {
      setKidsAgeGroupData(matchedPage[0].ageGroup);
      setLessonsStyle(matchedPage[0].ageGroup[0].styles);
    }
  }

  function renderCategoryDanceStyleForKids(pageValue) {
    const matchedPage = LESSONS_SUBPAGES.filter((item) => {
      return item.name === "kids";
    });
    const matchedAgeGroup = matchedPage[0].ageGroup.filter((item) => {
      return item.value === pageValue;
    });
    setLessonsStyle(matchedAgeGroup[0].styles);
  }

  async function onChangeFile(event, ref, view) {
    event.stopPropagation();
    event.preventDefault();
    const currentElement = event.currentTarget;
    const file = event.target.files[0];
    const selectedElement = event.currentTarget.parentNode;
    const blobURL = URL.createObjectURL(file);
    if (view === "thumbnailImage") {
      previewThumbnail = blobURL;
    }
    if (view === "preview" || view === "frontView" || view === "vrView") {
      const previewVideoObject = {};
      previewVideoObject["panel"] = view;
      previewVideoObject["url"] = blobURL;
      if (previewVideoArray.length) {
        const objIndex = previewVideoArray.findIndex(
          (previewVideoObject) => previewVideoObject.panel === view
        );
        if (objIndex > -1) {
          previewVideoArray[objIndex]["url"] = blobURL;
        } else {
          previewVideoArray.push(previewVideoObject);
        }
      } else {
        previewVideoArray.push(previewVideoObject);
      }
    }
    if (!file) {
      selectedElement.classList.remove("selected");
      setShowVideoProgressBar({ ...showVideoProgressBar, [view]: false });
    }
    // 1MB in Bytes is 1,048,576 so you can multiply it by the limit you need.
    if (file) {
      if (view === "thumbnailImage" && file.size > 2200000) {
        setNotificationType(NOTIFICATION_ERROR);
        setNotificationMsg(
          "Thumbnail file size is too big, should not exceed 2 MB!"
        );
        toggleNotification(true);
        setTimeout(() => {
          toggleNotification(false);
          setNotificationType("");
          setNotificationMsg("");
        }, 5000);
      } else if (
        ((view === "preview" || view === "frontView") &&
          file.size > 520000000) ||
        (view === "vrView" && file.size > 1200000000)
      ) {
        setNotificationType(NOTIFICATION_ERROR);
        if (view === "vrView") {
          setNotificationMsg(
            "VR Video file size is too big, should not exceed 1 GB!"
          );
        } else {
          setNotificationMsg(
            "Video file size is too big, should not exceed 500 MB!"
          );
        }
        toggleNotification(true);
        setTimeout(() => {
          toggleNotification(false);
          setNotificationType("");
          setNotificationMsg("");
        }, 5000);

        setVideosToUpload({ ...videosToUpload });
      } else {
        setVideosToUpload({ ...videosToUpload });
        const reader = new FileReader();
        let videoFinalDuration = "";
        if (view === "thumbnailImage") {
          reader.readAsDataURL(file);
          toggleThumbnailUploader(true);
        }
        if (view === "preview") {
          reader.readAsDataURL(file);
          togglePreviewUploader(true);
        }
        if (view === "frontView") {
          reader.readAsArrayBuffer(file);
          toggleMainVideoUploader(true);
        }
        if (view === "vrView") {
          reader.readAsArrayBuffer(file);
          toggleVRVideoUploader(true);
        }
        if (view && view === "frontView" && file?.type?.includes("video")) {
          const video = await loadVideo(file);
          videoFinalDuration = setVideoDuration(video.duration);
          setSelectedVideoData({
            ...SelectedVideoData,
            videoDuration: videoFinalDuration,
          });
        }
        reader.onload = (e) => {
          setVideosToUpload({ ...videosToUpload, [view]: reader.result });
          setShowVideoProgressBar({ ...showVideoProgressBar, [view]: true });
          selectedElement.classList.add("selected");
          if (currentElement.getAttribute("id") === "thumbnailImage") {
            toggleThumbnailUploader(false);
          }
          if (currentElement.getAttribute("id") === "frontPreview") {
            togglePreviewUploader(false);
          }
          if (currentElement.getAttribute("id") === "frontView") {
            toggleMainVideoUploader(false);
          }
          if (currentElement.getAttribute("id") === "vrView") {
            toggleVRVideoUploader(false);
          }
        };
        reader.onerror = (error) => console.error(error);
      }
    }
  }

  function setVideoDuration(duration) {
    let totalDuration = "";
    const hours = parseInt(duration / 3600, 10);
    const minutes = parseInt(duration / 60, 10);
    const seconds = duration % 60;
    if (hours) {
      totalDuration = `${hours} ${hours < 2 ? "hr" : "hrs"}`;
    } else if (minutes) {
      totalDuration = `${minutes} ${minutes < 2 ? "min" : "mins"}`;
    } else if (seconds) {
      totalDuration = `${seconds} ${Math.floor(seconds) < 2 ? "sec" : "secs"}`;
    }
    return totalDuration;
  }

  const loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  function openPreviewMode() {
    togglePreviewMode(true);
    setTimeout(() => {
      if (previewVideoArray && previewVideoArray.length) {
        previewVideoArray.forEach((item) => {
          if (item.panel === "preview") {
            if (previewVideoPreviewRef.current) {
              previewVideoPreviewRef.current.src = item.url;
              previewVideoPreviewRef.current.controls = true;
              previewVideoPreviewRef.current.pause();
              if (previewThumbnail) {
                previewVideoPreviewRef.current.poster = previewThumbnail;
              }
            }
          }

          if (item.panel === "frontView") {
            if (previewVideoFrontRef.current) {
              previewVideoFrontRef.current.src = item.url;
              previewVideoFrontRef.current.controls = true;
              previewVideoFrontRef.current.pause();
            }
          }

          if (item.panel === "vrView") {
            if (previewVideoVRModeRef.current) {
              previewVideoVRModeRef.current.src = item.url;
              previewVideoVRModeRef.current.controls = true;
              previewVideoVRModeRef.current.pause();
            }
          }
        });
      }
    }, 100);
  }

  function closePreviewModal(event) {
    event.stopPropagation();
    togglePreviewMode(false);
  }

  const sendSelectedVdosToUpload = () => {
    const { name, teacher, desc, artForm } = SelectedVideoData;
    if (name === "" && teacher === "" && desc === "") {
      togglePreviewMode(false);
      setFormMessageClass("error");
      setFormMessage(
        "Please fill Name, Teacher, Styles and About lesson fields!"
      );
    } else {
      if (name === "") {
        togglePreviewMode(false);
        setFormMessageClass("error");
        setFormMessage("Please fill Name!");
      } else if (teacher === "") {
        togglePreviewMode(false);
        setFormMessageClass("error");
        setFormMessage("Please fill Teacher's Name!");
      } else if (desc === "") {
        togglePreviewMode(false);
        setFormMessageClass("error");
        setFormMessage("Please add About lesson!");
      } else if (artForm === "") {
        togglePreviewMode(false);
        setFormMessageClass("error");
        setFormMessage("Please select a style!");
      } else if (!validateVideoFiles()) {
        togglePreviewMode(false);
        setFormMessageClass("error");
        setFormMessage(
          "Please upload thumbnail, preview video and main video!"
        );
      } else {
        setFormMessageClass("");
        setFormMessage("");
        openPreviewMode();
      }
    }
  };

  function proceedForSaveLesson() {
    const { name } = SelectedVideoData;
    toggleBtnLoadingClass("loading");
    getLessonByNameCall(name.trim());
  }

  const getLessonByNameCall = (name) => {
    getLessonByName(name).subscribe((response) => {
      if (Object.keys(response).length === 0) {
        uploadLessonVideos();
      } else {
        setFormMessageClass("error");
        setFormMessage(
          `Lesson is already exist with this name : ${name}, Please enter unique Lesson Name each time!`
        );
      }
    });
  };

  const getAllLessonsData = (userType) => {
    try {
      toggleLoading(true);
      getAllLessons(userType).subscribe((lessons) => {
        toggleLoading(false);
        if (lessons.length) {
          setLessonsList(lessons);
        }
      });
    } catch (e) {
      toggleLoading(false);
    }
  };

  const uploadLessonVideos = () => {
    const {
      name,
      teacher,
      desc,
      files,
      expertiseLevel,
      subPage,
      isLocal,
      kidsAgeGroup,
      artForm,
      videoDuration,
      accessbility,
    } = SelectedVideoData;
    let lessonDetails = {
      name,
      teacher,
      desc,
      expertiseLevel,
      subPage,
      isLocal,
      kidsAgeGroup,
      artForm,
      videoDuration,
      accessbility,
      thumbnailImage: "",
      videoList: {
        preview: "",
        frontView: "",
      },
      m3u8VideoList: {
        preview: "",
        frontView: "",
      },
    };
    let videoListObj = {};
    let videoProgess = {};
    let filesToUpload = {};
    lessonDetails.name = lessonDetails.name.trim();
    toggleBtnLoadingClass("");
    if (Object.values(files) && Object.values(files).length > 0) {
      togglePreviewMode(false);
      toggleUploadingMessage(true);
      try {
        saveLesson(lessonDetails).subscribe((savedLessonRes) => {
          const savedLessonsKey = savedLessonRes.key;
          for (const [key, value] of Object.entries(files)) {
            if (key && value) {
              filesToUpload = { ...filesToUpload, [key]: value };
              // eslint-disable-next-line no-loop-func
              uploadVideo(value, "lessons", name, key).subscribe((response) => {
                toggleLoading(true);
                if (response.donePercentage) {
                  videoProgess[key] = response.donePercentage;
                  setVideoUploadProgess(videoProgess);
                }
                if (response.downloadURL) {
                  videoListObj = {
                    ...videoListObj,
                    [key]: response.downloadURL,
                  };
                  lessonDetails.videoList = videoListObj;

                  //upload vdo to uploadedVIDEOS COLLECTION to sync with publitio
                  const vdoObj = {
                    lessonKey: savedLessonsKey,
                    viewType: key,
                    videoName: lessonDetails.name + " " + key,
                    firebaseUrl: response.downloadURL,
                    title: lessonDetails.name + " " + key,
                    desc: lessonDetails.desc,
                    url: response.downloadURL,
                    thumbnail: "",
                    folder: "lessons",
                    finishedProcessing: false,
                    createdOn: new Date(),
                    modifiedOn: new Date(),
                  };
                  if (key === "thumbnailImage") {
                    lessonDetails = {
                      ...lessonDetails,
                      [key]: lessonDetails.videoList[key],
                    };
                    if (
                      Object.values(filesToUpload).length ===
                      Object.values(lessonDetails.videoList).length
                    ) {
                      delete lessonDetails.videoList["thumbnailImage"];
                      updateLessonsVideos(
                        savedLessonsKey,
                        lessonDetails.videoList
                      );
                    }
                  } else {
                    saveLessonVideo(vdoObj).subscribe((res) => {
                      if (
                        Object.values(filesToUpload).length ===
                        Object.values(lessonDetails.videoList).length
                      ) {
                        delete lessonDetails.videoList["thumbnailImage"];
                        updateLessonsVideos(
                          savedLessonsKey,
                          lessonDetails.videoList,
                          lessonDetails.thumbnailImage
                        );
                      }
                    });
                  }
                }
              });
            }
          }
        });
      } catch (e) {
        toggleUploadingMessage(false);
        setInfoModalMessage("Something went wrong, try in sometime!");
        setInfoModalStatus("error");
        toggleInfoModal(true);
      }
    }
  };

  const updateLessonsVideos = (savedLessonsKey, videoList, thumbnailImage) => {
    try {
      updateLessonsVideoListAndThumbnail(
        savedLessonsKey,
        videoList,
        thumbnailImage
      ).subscribe((response) => {
        setSelectedVideoData(lessonFormDetails);
        setVideosToUpload(initialVideosToUploadData);
        setVideoUploadProgess(initialVideoUploadProgress);
        setShowVideoProgressBar(initialVideoProgressBarState);
        setUploadButtonState(true);
        setFileUploadValue("");
        toggleUploadingMessage(false);
        setFormMessageClass("");
        setFormMessage("");
        toggleLoading(false);
        setInfoModalMessage("Lesson uploaded successfully!");
        setInfoModalStatus("success");
        toggleInfoModal(true);
      });
    } catch (e) {
      setInfoModalMessage("Something went wrong, try in sometime!");
      setInfoModalStatus("error");
      toggleInfoModal(true);
    }
  };

  function closeInfoModal() {
    setInfoModalMessage("");
    setInfoModalStatus("info");
    toggleInfoModal(false);
  }

  function toggleSwitch(value, item) {
    toggleLocalSelection(!value);
    toggleLoading(true);
    try {
      updateLessonActiveStatus(item, !value).subscribe((resp) => {
        toggleLoading(false);
      });
    } catch (e) {
      toggleLoading(false);
    }
  }

  function removeLesson(data) {
    toggleLoading(true);
    try {
      deleteLesson(data.key).subscribe((resp) => {
        toggleLoading(false);
        setLessonsList([]);
        getAllLessonsData("admin");
      });
    } catch (e) {
      toggleLoading(false);
    }
  }

  return (
    <div className="adminPanelSection">
      <Loader value={isLoaderActive} />
      {shouldShowNotification ? (
        <Notification type={notificationType} msg={notificationMsg} />
      ) : (
        ""
      )}
      {isInfoModalOpen ? (
        <GenericInfoModal
          message={infoModalMessage}
          status={infoModalStatus}
          shouldHaveAction={false}
          confirmUserAction={closeInfoModal}
          closeInfoModal={closeInfoModal}
        />
      ) : (
        ""
      )}
      <Navigation />
      <div className="logoWrap">
        <a href="/" title="Da home">
          <img src={boogaluLogo} alt="Da" />
        </a>
      </div>
      {isAdminLoggedIn || checkAdminLogIn ? (
        <div className="optionsTab">
          <p
            className="tabItem active"
            onClick={(e) => setInternalLinkFn(true)}
            ref={createTabRef}
          >
            Upload new
          </p>
          <p
            className="tabItem"
            onClick={(e) => setInternalLinkFn(false)}
            ref={listTabRef}
          >
            View lessons
          </p>
        </div>
      ) : (
        ""
      )}
      <div
        className={`competition-bo-wrap clearfix ${
          (isAdminLoggedIn || checkAdminLogIn) && "loggedInAdmin usersListBox"
        }`}
      >
        {isAdminLoggedIn || checkAdminLogIn ? (
          <p
            className="logOutIconWrap"
            title="logout"
            onClick={(e) => tiggerAdminLogout(e, false)}
          >
            <FaPowerOff />
          </p>
        ) : (
          ""
        )}
        {isAdminLoggedIn || checkAdminLogIn ? (
          <h1>
            <Link
              to="/adminpanel"
              title="back to admin"
              className="backToAdmin"
            >
              <span>&#8592;</span>
            </Link>

            {uploadNewLesson
              ? "Upload a new lesson"
              : "View lists of lessons created"}
          </h1>
        ) : (
          <h1>
            <Link
              to="/adminpanel"
              title="back to admin"
              className="backToAdmin"
            >
              <span>&#8592;</span>
            </Link>
            Login to Manage Lessons
          </h1>
        )}
        {isAdminLoggedIn || checkAdminLogIn ? (
          uploadNewLesson ? (
            <div className="usersListWrap">
              <div className="inner-form-wrap">
                <div className="input-wrap">
                  <TextField
                    className="input-field"
                    required
                    id="outlined-required-name"
                    label="Name"
                    variant="outlined"
                    value={SelectedVideoData.name}
                    onChange={handleChange("name")}
                  />
                </div>
                <div className="input-wrap">
                  <TextField
                    className="input-field"
                    required
                    id="outlined-required-teacher"
                    label="Artist/Teacher"
                    variant="outlined"
                    value={SelectedVideoData.teacher}
                    onChange={handleChange("teacher")}
                  />
                </div>
                {LESSONS_SUBPAGES && LESSONS_SUBPAGES.length ? (
                  <div className="input-wrap">
                    <FormControl variant="outlined" className="input-field">
                      <InputLabel id="select-outlined-label-level">
                        Select subpages
                      </InputLabel>
                      <Select
                        required
                        labelId="select-outlined-label-subpage"
                        id="select-outlined"
                        value={SelectedVideoData.subPage}
                        onChange={handleChange("subPage")}
                        label="Select subpages"
                      >
                        {LESSONS_SUBPAGES && LESSONS_SUBPAGES.length
                          ? LESSONS_SUBPAGES.map((item, index) => {
                              return (
                                <MenuItem
                                  value={item.name}
                                  key={`${item.name}-${index}`}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })
                          : ""}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
                {SelectedVideoData?.subPage === "kids" ? (
                  <div className="input-wrap">
                    <FormControl variant="outlined" className="input-field">
                      <InputLabel id="select-outlined-label-artform">
                        Select kids age group
                      </InputLabel>
                      <Select
                        required
                        labelId="select-outlined-label-agegroup"
                        id="select-outlined"
                        value={SelectedVideoData.kidsAgeGroup}
                        onChange={handleChange("kidsAgeGroup")}
                        label="Select kids age group"
                      >
                        {kidsAgeGroupData && kidsAgeGroupData.length
                          ? kidsAgeGroupData.map((item) => {
                              return (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.name}
                                </MenuItem>
                              );
                            })
                          : ""}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
                <div className="input-wrap">
                  <FormControl variant="outlined" className="input-field">
                    <InputLabel id="select-outlined-label-artform">
                      Styles
                    </InputLabel>
                    <Select
                      required
                      labelId="select-outlined-label-level"
                      id="select-outlined"
                      value={SelectedVideoData.artForm}
                      onChange={handleChange("artForm")}
                      label="Styles"
                    >
                      {lessonsStyleData && lessonsStyleData.length
                        ? lessonsStyleData.map((item, index) => {
                            return (
                              <MenuItem
                                value={item.value}
                                key={`${item.value}-${index}`}
                              >
                                {item.label}
                              </MenuItem>
                            );
                          })
                        : ""}
                    </Select>
                  </FormControl>
                </div>
                {SelectedVideoData?.subPage !== "kids" ||
                (SelectedVideoData?.subPage === "kids" &&
                  SelectedVideoData?.kidsAgeGroup !== "3-5") ? (
                  <div className="input-wrap">
                    <FormControl variant="outlined" className="input-field">
                      <InputLabel id="select-outlined-label-level">
                        Expertise Level
                      </InputLabel>
                      <Select
                        required
                        labelId="select-outlined-label-level"
                        id="select-outlined"
                        value={SelectedVideoData.expertiseLevel}
                        onChange={handleChange("expertiseLevel")}
                        label="Expertise Level"
                      >
                        <MenuItem value="beginners">Beginners</MenuItem>
                        <MenuItem value="intermediate">Intermediate</MenuItem>
                        <MenuItem value="advance">Advance</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
                <div className="controlGroupWrap">
                  <div className="input-wrap input-wrap-full">
                    <div className="uploadContainer">
                      <div
                        className={
                          videosToUpload.thumbnailImage !== null
                            ? "upload-input-wrap selected"
                            : "upload-input-wrap"
                        }
                      >
                        {isthumbnailUploading ? (
                          <div className="boxSpinner"></div>
                        ) : (
                          ""
                        )}
                        <h6 className="heading">
                          Thumbnail <sup className="mandatAsterisk">*</sup>
                        </h6>
                        <h6 className="sub-heading">Maximum size 2 MB</h6>
                        <i className="upload-icon">
                          <FaCloudUploadAlt />
                        </i>
                        <input
                          id="thumbnailImage"
                          type="file"
                          accept="image/*"
                          value={fileUploadValue}
                          ref={uploaderRefThumbnailImage}
                          onChange={(e) =>
                            onChangeFile(
                              e,
                              uploaderRefThumbnailImage,
                              "thumbnailImage"
                            )
                          }
                        />
                        {showVideoProgressBar?.thumbnailImage && (
                          <LinearProgress
                            className="uploadProgessBar"
                            variant="determinate"
                            value={videoUploadProgess.thumbnailImage}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-wrap input-wrap-full lessonsDescriptionBox">
                    <TextField
                      required
                      className="textarea"
                      id="outlined-required-desc"
                      variant="outlined"
                      label="About lesson"
                      multiline
                      rowsMax={4}
                      value={SelectedVideoData.desc}
                      onChange={handleChange("desc")}
                    />
                  </div>
                </div>

                <div className="input-wrap input-wrap-full video-control-wrap">
                  <label className="controlLabel">
                    Lesson Videos
                    <sup className="mandatAsterisk">*</sup>
                  </label>
                  <div className="uploadContainer">
                    <div
                      className={
                        videosToUpload.preview !== null
                          ? "upload-input-wrap selected"
                          : "upload-input-wrap"
                      }
                    >
                      {isPreviewUploading ? (
                        <div className="boxSpinner"></div>
                      ) : (
                        ""
                      )}
                      <h6 className="heading">
                        Preview video(must){" "}
                        <sup className="mandatAsterisk">*</sup>
                      </h6>
                      <h6 className="sub-heading">30-40 secs only</h6>
                      <i className="upload-icon">
                        <FaCloudUploadAlt />
                      </i>
                      <input
                        id="frontPreview"
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        value={fileUploadValue}
                        ref={uploaderPreview}
                        onChange={(e) =>
                          onChangeFile(e, uploaderPreview, "preview")
                        }
                      />
                      {showVideoProgressBar?.frontView && (
                        <LinearProgress
                          className="uploadProgessBar"
                          variant="determinate"
                          value={videoUploadProgess.frontView}
                        />
                      )}
                    </div>
                    <div
                      className={
                        videosToUpload.frontView !== null
                          ? "upload-input-wrap selected"
                          : "upload-input-wrap"
                      }
                    >
                      {isMainVideoUploading ? (
                        <div className="boxSpinner"></div>
                      ) : (
                        ""
                      )}
                      <h6 className="heading">
                        Main video(must) <sup className="mandatAsterisk">*</sup>
                      </h6>
                      <h6 className="sub-heading">
                        Default View (Max size 500 MB)
                      </h6>
                      <i className="upload-icon">
                        <FaCloudUploadAlt />
                      </i>
                      <input
                        id="frontView"
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        value={fileUploadValue}
                        ref={uploaderRefFrontView}
                        onChange={(e) =>
                          onChangeFile(e, uploaderRefFrontView, "frontView")
                        }
                      />
                      {showVideoProgressBar?.frontView && (
                        <LinearProgress
                          className="uploadProgessBar"
                          variant="determinate"
                          value={videoUploadProgess.frontView}
                        />
                      )}
                    </div>
                    {/* <div
                      className={
                        videosToUpload.frontMirrorView !== null
                          ? "upload-input-wrap selected"
                          : "upload-input-wrap"
                      }
                    >
                      <h6 className="heading">
                        Front Mirror <sup className="mandatAsterisk">*</sup>
                      </h6>
                      <i className="upload-icon">
                        <FaCloudUploadAlt />
                      </i>
                      <input
                        id="frontMirrorView"
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        value={fileUploadValue}
                        ref={uploaderRefFrontMirrorView}
                        onChange={(e) =>
                          onChangeFile(
                            e,
                            uploaderRefFrontMirrorView,
                            "frontMirrorView"
                          )
                        }
                      />
                      {showVideoProgressBar?.frontMirrorView && (
                        <LinearProgress
                          className="uploadProgessBar"
                          variant="determinate"
                          value={videoUploadProgess.frontMirrorView}
                        />
                      )}
                    </div>
                    <div
                      className={
                        videosToUpload.rearView !== null
                          ? "upload-input-wrap selected"
                          : "upload-input-wrap"
                      }
                    >
                      <h6 className="heading">
                        Back Side <sup className="mandatAsterisk">*</sup>
                      </h6>
                      <i className="upload-icon">
                        <FaCloudUploadAlt />
                      </i>
                      <input
                        id="rearView"
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        value={fileUploadValue}
                        ref={uploaderRefRearView}
                        onChange={(e) =>
                          onChangeFile(e, uploaderRefRearView, "rearView")
                        }
                      />
                      {showVideoProgressBar?.rearView && (
                        <LinearProgress
                          className="uploadProgessBar"
                          variant="determinate"
                          value={videoUploadProgess.rearView}
                        />
                      )}
                    </div>
                    <div
                      className={
                        videosToUpload.rearMirrorView !== null
                          ? "upload-input-wrap selected"
                          : "upload-input-wrap"
                      }
                    >
                      <h6 className="heading">
                        Back Mirror <sup className="mandatAsterisk">*</sup>
                      </h6>
                      <i className="upload-icon">
                        <FaCloudUploadAlt />
                      </i>
                      <input
                        id="rearMirrorView"
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        value={fileUploadValue}
                        ref={uploaderRefRearMirrorView}
                        onChange={(e) =>
                          onChangeFile(
                            e,
                            uploaderRefRearMirrorView,
                            "rearMirrorView"
                          )
                        }
                      />
                      {showVideoProgressBar?.rearMirrorView && (
                        <LinearProgress
                          className="uploadProgessBar"
                          variant="determinate"
                          value={videoUploadProgess.rearMirrorView}
                        />
                      )}
                    </div> */}
                    <div
                      className={
                        videosToUpload.vrView !== null
                          ? "upload-input-wrap selected"
                          : "upload-input-wrap"
                      }
                    >
                      {isVRVideoUploading ? (
                        <div className="boxSpinner"></div>
                      ) : (
                        ""
                      )}
                      <h6 className="heading">VR Mode</h6>
                      <h6 className="sub-heading">Max size 1 GB</h6>
                      <i className="upload-icon">
                        <FaCloudUploadAlt />
                      </i>
                      <input
                        id="vrView"
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        value={fileUploadValue}
                        ref={uploaderRefVRView}
                        onChange={(e) =>
                          onChangeFile(e, uploaderRefVRView, "vrView")
                        }
                      />
                      {showVideoProgressBar?.vrView && (
                        <LinearProgress
                          className="uploadProgessBar"
                          variant="determinate"
                          value={videoUploadProgess.vrView}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="input-wrap input-wrap-full lessons-radio-group">
                  <RadioGroup
                    className="radioGroupControls lessonsRadioGroup"
                    aria-label="accessbility"
                    name="accessbility"
                    value={SelectedVideoData.accessbility}
                    defaultValue="paid"
                    onChange={handleChange("accessbility")}
                  >
                    <FormControlLabel
                      value="premium"
                      control={<Radio />}
                      label="Premium"
                    />
                    <FormControlLabel
                      value="pro"
                      control={<Radio />}
                      label="Pro"
                    />
                    <FormControlLabel
                      value="paid"
                      control={<Radio />}
                      label="Startup"
                    />
                    <FormControlLabel
                      value="free"
                      control={<Radio />}
                      label="Free"
                    />
                  </RadioGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        className="selected-item-checkbox"
                        checked={SelectedVideoData.isLocal}
                        onChange={handleChange("isLocal")}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Local testing (Enable if video is for testing)"
                  />
                </div>

                <p className={`messageWrap ${messageClass}`}>
                  {formMessageBox}
                </p>
                <div className="upload-input-wrap button-container">
                  <Button
                    className={btnLoadingClass}
                    disabled={disableUploadButton}
                    variant="contained"
                    color="primary"
                    onClick={() => sendSelectedVdosToUpload()}
                  >
                    Preview &amp; Create Lesson
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="lessonsList adminItemlistView">
              {lessonsData && lessonsData.length ? (
                lessonsData.map((item) => {
                  return (
                    <div className="boxItem compBox" key={item.id}>
                      <div className="lessonVdoWrap">
                        <VideoPlayer
                          vdoObj={item?.videoList}
                          thumbnail={item?.thumbnailImage}
                        />
                      </div>
                      <p className="actionWrap">
                        <SwitchLabels
                          onSwitchChange={(e) => toggleSwitch(e, item)}
                          checked={item?.isLocal}
                          label={item?.isLocal ? "Inactive" : "Active"}
                        />
                        {item?.isLocal ? (
                          <button
                            title="Delete lesson(only delete inactive lessons)"
                            onClick={() => removeLesson(item)}
                          >
                            <FaTrashAlt />
                          </button>
                        ) : (
                          ""
                        )}
                      </p>
                      <p className="title">
                        Name: <span>{item.name}</span>
                      </p>
                      <p className="statusBlock">
                        Teacher: <span>{item.teacher}</span>
                      </p>
                      <p className="date">
                        Date Created: <span>{item.uploadedTime}</span>
                      </p>
                    </div>
                  );
                })
              ) : (
                <p className="noDataInListMessage">
                  You haven't uploaded any Lessons!
                </p>
              )}
            </div>
          )
        ) : (
          <AdminLogin
            statusMessage={loggedInMessages}
            inputChange={(value, field) => handleAdminLogin(value, field)}
            submitLogin={(event, action) => triggerLogin(event, action)}
          />
        )}
      </div>
      {isUploadingInProgress ? (
        <div className="uploadingNotificationForAdmin">
          <i className="infoIcon">
            <FaInfoCircle />
          </i>
          Uploading is in progress, please do not redirect, refresh or close the
          browser!
        </div>
      ) : (
        ""
      )}

      {previewModeOn ? (
        <div className="previewLessonBox">
          <div className="innerPreviewBox">
            <h3>Preview your Lesson videos</h3>
            <p
              className="closeModalBtn"
              onClick={(e) => closePreviewModal(e)}
            ></p>
            <div className="videosWrap">
              {previewVideoArray.findIndex((item) => item.panel === "preview") >
              -1 ? (
                <div className="videoItem">
                  <p className="videoLabel">Preview Video</p>
                  <video
                    className="videoTag"
                    ref={previewVideoPreviewRef}
                  ></video>
                </div>
              ) : (
                ""
              )}
              {previewVideoArray.findIndex(
                (item) => item.panel === "frontView"
              ) > -1 ? (
                <div className="videoItem">
                  <p className="videoLabel">Main View</p>
                  <video
                    className="videoTag"
                    ref={previewVideoFrontRef}
                  ></video>
                </div>
              ) : (
                ""
              )}
              {previewVideoArray.findIndex((item) => item.panel === "vrView") >
              -1 ? (
                <div className="videoItem">
                  <p className="videoLabel">VR-Mode View</p>
                  <video
                    className="videoTag"
                    ref={previewVideoVRModeRef}
                  ></video>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="actionWrap">
              <Button
                className={`actionBtns ${btnLoadingClass}`}
                disabled={disableUploadButton}
                variant="contained"
                color="primary"
                onClick={() => proceedForSaveLesson()}
              >
                Create Lesson
              </Button>
              <Button
                className="actionBtns"
                disabled={disableUploadButton}
                variant="outlined"
                color="secondary"
                onClick={(e) => closePreviewModal(e)}
              >
                Cancel &amp; modify
              </Button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="footerBox">
        &copy; 2021 A CHOREO CULTURE PVT LTD, All rights reserved.
      </div>
    </div>
  );
}
