import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import { IS_LESSONS_UPCOMING_PAGE } from "./Constants";
import Feeds from "./Components/Feeds";
import Signup from "./Components/Signup";
import Loader from "./Components/Loader";
import Page404 from "./Components/Page404";
import AboutUs from "./Components/Aboutus";
import Homepage from "./Components/NewHomePage";
import Login from "./Components/Login/Login";
import LessonsPage from "./Components/LessonsPage";
import TestLessonsPage from "./Components/TestLessons";
import UpcomingLessonsPage from "./Components/Upcoming";
import Footer from "./Components/Footer/index";
import ContactUs from "./Components/Contactus";
import SplashScreen from "./Components/Splash";
import UsersInfo from "./Components/Admin/Users";
import OfflineStudios from "./Components/Admin/OfflineStudios";
import AdminPanel from "./Components/Admin/Admin";
import ViewAllMembers from "./Components/members";
import Profile from "./Components/Profile/Profile";
import Competitions from "./Components/Competitions";
import TestCompetitions from "./Components/TestCompetitions";
import RefundPolicy from "./Components/RefundPolicy";
import Notification from "./Components/Notification";
import Subscriptions from "./Components/Subscriptions";
import Navigation from "./Components/Navigation/index";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import VideoUploader from "./Components/VideoUploader";
import Competition from "./Components/Admin/Competition";
import TermsConditions from "./Components/TermsConditions";
import Subscription from "./Components/Admin/Subscription";
import UploadLessons from "./Components/Admin/UploadLessons";
import { useStoreConsumer } from "./Providers/StateProvider";
import EditProfile from "./Components/EditProfile/EditProfile";
import PreFinalRound from "./Components/judgements/firstround";
import FinalRound from "./Components/judgements/finalround";
import LiveEventPage from "./Components/LiveEventPage";
import Results from "./Components/Results";
import Studios from "./Components/Studios/Studio";
import SelectedStudio from "./Components/Studios/SelectedStudio";

const appStaticPagesPath = [
  "/app/aboutus",
  "/app/contactus",
  "/app/results",
  "/app/privacypolicy",
  "/app/termsandconditions",
  "/app/refundpolicy",
  "/app/live-events",
  "/app/live-event",
];

function App() {
  const { state } = useStoreConsumer();
  const [isSplashVisible, toggleSplash] = useState(true);
  const [isRootPath, rootPathToggle] = useState(true);
  const [adminPathClass, setAdminPathClass] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isResultsLive, setResultsPageUrl] = useState(true);
  const [transitionOpacityClass, toggleTransition] = useState("");
  const [visibleClass, toggleVisibleClass] = useState("");

  useEffect(() => {
    if (appStaticPagesPath.includes(window.location.pathname)) {
      toggleSplash(false);
      toggleTransition("visible-transition");
    } else {
      setTimeout(() => {
        const pathName = window.location.pathname.split("/")[1];
        if (pathName === "") {
          rootPathToggle(true);
        } else {
          rootPathToggle(false);
        }

        if (
          pathName.includes("adminpanel") ||
          pathName.includes("judgements")
        ) {
          setAdminPathClass(true);
        } else {
          setAdminPathClass(false);
        }

        toggleSplash(false);
      }, 5000);

      setTimeout(() => {
        toggleTransition("visible-transition");
      }, 5100);
    }

    window.addEventListener("scroll", onWindowScroll);
    return () => window.removeEventListener("scroll", onWindowScroll);
  }, []);

  function onWindowScroll(event) {
    event.stopPropagation();
    if (!window.location.href.includes("adminpanel")) {
      if (window.scrollY > 100) {
        toggleVisibleClass("active");
      } else {
        toggleVisibleClass("");
      }
    }
  }

  function routeChanged(event) {
    rootPathToggle(event);
  }

  function isObjectEmpty(obj) {
    return obj
      ? Object.keys(obj).length === 0 && obj.constructor === Object
      : false;
  }

  function callbackHomeFunction() {
    if (isObjectEmpty(state.loggedInUser)) {
      rootPathToggle(true);
    }
  }

  function scrollToTop(event) {
    event.stopPropagation();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <Router>
      <div
        className={`App ${adminPathClass ? "adminPanel" : ""} ${
          isRootPath && isObjectEmpty(state.loggedInUser) ? "top-padding0" : ""
        }`}
      >
        {isSplashVisible ? (
          <SplashScreen />
        ) : (
          <div className={`main-content-wrapper ${transitionOpacityClass}`}>
            {!adminPathClass &&
            !appStaticPagesPath.includes(window.location.pathname) ? (
              <Navigation
                isUserLoggedIn={
                  state.loggedInUser && state.loggedInUser.username
                }
                routeChangeTrigger={(e) => routeChanged(e)}
              />
            ) : (
              ""
            )}
            {!adminPathClass ? <Notification /> : ""}
            {state?.isLoading && <Loader />}
            <Switch>
              <Route exact path="/aboutus">
                <AboutUs />
              </Route>
              <Route exact path="/contactus">
                <ContactUs />
              </Route>
              <Route exact path="/pricing">
                <Subscriptions pageTitle="Pricing" />
              </Route>
              <Route exact path="/privacypolicy">
                <PrivacyPolicy />
              </Route>
              <Route exact path="/termsandconditions">
                <TermsConditions />
              </Route>
              <Route exact path="/refundpolicy">
                <RefundPolicy />
              </Route>
              <Route exact path="/login:plan">
                <Login backToHome={callbackHomeFunction} />
              </Route>
              <Route exact path="/login">
                <Login backToHome={callbackHomeFunction} />
              </Route>
              <Route exact path="/profile/edit">
                <EditProfile />
              </Route>
              <Route exact path="/register:plan">
                <Signup backToHome={callbackHomeFunction} />
              </Route>
              <Route exact path="/register">
                <Signup backToHome={callbackHomeFunction} />
              </Route>

              <Route exact path="/lessons">
                {IS_LESSONS_UPCOMING_PAGE ? (
                  <UpcomingLessonsPage />
                ) : (
                  <LessonsPage />
                )}
              </Route>

              {/* Testing sites route Start */}
              <Route exact path="/testing/pricing">
                <Subscriptions />
              </Route>
              <Route exact path="/testing/subscription">
                <Subscriptions />
              </Route>
              {/* testing sites route End */}

              <Route exact path="/test/classes">
                <TestLessonsPage />
              </Route>
              <Route exact path="/events">
                <Competitions />
              </Route>
              <Route exact path="/testing/events">
                <TestCompetitions />
              </Route>
              <Route exact path="/subscription">
                <Subscriptions pageTitle="Pricing"/>
              </Route>
              <Route exact path="/profile">
                <Profile />
              </Route>
              <Route exact path="/profile/:id">
                <Profile />
              </Route>
              <Route exact path="/studios">
                <Studios />
              </Route>
              <Route exact path="/studios/:name/:id">
                <SelectedStudio />
              </Route>
              <Route exact path={["/", "/home", "/landing"]}>
                <Homepage />
              </Route>
              <Route exact path="/showcase">
                {state.loggedInUser && state.loggedInUser.username ? (
                  <Feeds />
                ) : (
                  <Login />
                )}
              </Route>
              <Route exact path="/upload-video">
                <VideoUploader />
              </Route>
              <Route exact path="/members">
                <ViewAllMembers />
              </Route>
              <Route exact path={["/live-events", "/live-event"]}>
                <LiveEventPage />
              </Route>
              <Route exact path="/testLiveEvents">
                <LiveEventPage mode="test" />
              </Route>
              {isResultsLive ? (
                <Route exact path="/results">
                  <Results />
                </Route>
              ) : (
                ""
              )}

              {/* Routes for React-native app integration */}
              <Route exact path="/app/aboutus">
                <AboutUs isCallByNativeApp={true} />
              </Route>
              <Route exact path="/app/privacypolicy">
                <PrivacyPolicy isCallByNativeApp={true} />
              </Route>
              <Route exact path="/app/termsandconditions">
                <TermsConditions isCallByNativeApp={true} />
              </Route>
              <Route exact path="/app/refundpolicy">
                <RefundPolicy isCallByNativeApp={true} />
              </Route>
              <Route exact path={["/app/live-events", "/app/live-event"]}>
                <LiveEventPage isCallByNativeApp={true} />
              </Route>
              <Route exact path="/app/results">
                <Results isCallByNativeApp={true} />
              </Route>
              <Route exact path="/app/contactus">
                <ContactUs isCallByNativeApp={true} />
              </Route>

              {/* admin routes */}
              <Route exact path="/adminpanel">
                <AdminPanel />
              </Route>
              <Route exact path="/adminpanel/competition">
                <Competition />
              </Route>
              <Route exact path="/adminpanel/competitions">
                <Competition />
              </Route>
              <Route exact path="/adminpanel/subscription">
                <Subscription />
              </Route>
              <Route exact path="/adminpanel/subscriptions">
                <Subscription />
              </Route>
              <Route exact path="/adminpanel/lessons">
                <UploadLessons />
              </Route>
              <Route exact path="/adminpanel/users">
                <UsersInfo />
              </Route>
              <Route exact path="/adminpanel/offline-studios">
                <OfflineStudios />
              </Route>

              {/* Judgement Routes */}
              <Route exact path="/judgements/prefinalround">
                <PreFinalRound />
              </Route>
              <Route exact path="/judgements/finalround">
                <FinalRound />
              </Route>

              {/* Handling 404 */}
              <Route>
                <Page404 />
              </Route>
            </Switch>
            {!adminPathClass &&
            !appStaticPagesPath.includes(window.location.pathname) ? (
              <Footer />
            ) : (
              ""
            )}
            <p
              className={`backToTopBtn ${visibleClass}`}
              title="back to top"
              onClick={(e) => scrollToTop(e)}
            ></p>
          </div>
        )}
      </div>
    </Router>
  );
}
export default App;
