import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { MdSettingsBackupRestore, MdShare, MdFlip, MdClose } from 'react-icons/md';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import LessonsVideoPlayer from '../LessonsVideoPlayer/LessonsVideoPlayer';
import { SHOULD_ENABLE_M3U8 } from '../../Constants';

function VideoWrapper({ 
    videosList, 
    m3u8VideoList, 
    loggedInUser, 
    videoId, 
    videoLastPlayedTime,
    thumbNail,
    setVideoCurrentTimeToDB, 
    setLessonsDataInUser, 
    handleVdoWrapperClose, 
    userLesson 
}) {

    const [fullScreenMode, setFullScreenMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [shouldShowFullScreen, toggleFullScreenFeatureIcons] = useState(false);
    const [activeVideosList, setActiveVideosList] = useState(videosList);
    const [frontClass, setFrontClass] = useState('front');
    const [mirrorClass, setMirrorClass] = useState('');
    const [backClass, setBackClass] = useState('');
    const [frontBackText, setFlipText] = useState('Back');
    const [mirrorTitleText, setMirrorText] = useState('On');
    const [videoHalfHeight, setMaxHalfHeight] = useState('');
    const [videoLastPlayTime, setVideoLastPlayTime] = useState(0);
    
    useEffect(() => {
        if (SHOULD_ENABLE_M3U8 && m3u8VideoList) {
            let newList = {};
            for (const [key, value] of Object.entries(m3u8VideoList)) {
                if (key) {
                    if (value) {
                        newList = { ...newList, [key]: value.url_preview };
                    } else {
                        newList = { ...newList, [key]: videosList[key] };
                    }
                }
            }
            setActiveVideosList(newList);
        } else {
            setActiveVideosList(videosList);
        }

    }, [videosList, m3u8VideoList]);

    function setVideoWrapperMaxHeightOnResize(size) {
        setMaxHalfHeight(size / 2);
    }

    useEffect(() => {
        playVideo('initial-load-start');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeVdoModal = () => {
        handleVdoWrapperClose();
        if (videoLastPlayTime) {
            setVideoCurrentTimeToDB(videoLastPlayTime);
        }
    }

    function setVideoPlayTime(time) {
        setVideoLastPlayTime(time);
    }

    function triggerFullScreen(e) {
        const fullScreenWrapper = document.getElementById(videoId);
        if (!document.fullscreenElement) {
            setFullScreenMode(true);
            if (fullScreenWrapper.requestFullscreen) {
                fullScreenWrapper.requestFullscreen();
            } else if (fullScreenWrapper.webkitRequestFullscreen) { /* Safari */
                fullScreenWrapper.webkitRequestFullscreen();
            } else if (fullScreenWrapper.msRequestFullscreen) { /* IE11 */
                fullScreenWrapper.msRequestFullscreen();
            }
        } else {
            setFullScreenMode(false);
            document.exitFullscreen();
        }
    }

    function onVideoPauseStop(event) {
        if (event) {
            setVideoCurrentTimeToDB(event);
        }
    }

    function playVideo(status) {
        if (status && (status === 'play' || status === 'initial-load-start')) {
            if (loggedInUser?.myLessons && loggedInUser?.myLessons?.length) {
                const isLessonPresent = loggedInUser.myLessons.some(item => item.lessonKey === userLesson.lessonKey);
                if (!isLessonPresent) {
                    setLessonsDataInUser(userLesson);
                }
            } else {
                setLessonsDataInUser(userLesson);
            }
        }
    }

    // New Implementation - flip video function(front and back)
    function flipVideos(event) {
        event.stopPropagation();
        if (frontClass === 'front') {
            setFrontClass('');
            setBackClass('back');
            setFlipText('Front');
            event.currentTarget.classList.add('active');
        } else {
            setFrontClass('front');
            setFlipText('Back');
            setBackClass('');
            event.currentTarget.classList.remove('active');
        }
    }
    
    // New Implementation - Mirror video function(Mirror view)
    function mirrorVideos(event) {
        event.stopPropagation();
        if (mirrorClass === 'mirror') {
            setMirrorClass('');
            setMirrorText('On');
            event.currentTarget.classList.remove('active');
        } else {
            setMirrorClass('mirror');
            setMirrorText('Off');
            event.currentTarget.classList.add('active');
        }
    }

    function shareLessonDetails(event) {
        event.stopPropagation();
        if (navigator.share) {
            navigator.share({
                title: 'Learn from the Experts',
                url: `${window.location.origin}/lessons`
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        }
    }

    return (
        <>
            {
                activeVideosList ?
                    <div className="vdo-modal-wrap">
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                            <div>
                                <div className="modal-close" onClick={() => closeVdoModal()}>
                                    <MdClose title="Share this lesson" variant="contained" />
                                </div >
                                <Paper elevation={4} className="outer">
                                    <div
                                        style={
                                            {
                                                'minHeight': `${videoHalfHeight}px`
                                            }
                                        } 
                                        height={videoHalfHeight}
                                        className={`innerLessonVideoWrap activeOverlay  showVideoBox js-${videoId}`}>
                                        <div className="inner-video-wrap" id={videoId}>
                                            <div className="actions">
                                                <MdSettingsBackupRestore title={`Flip to ${frontBackText}`} className="vdo-controlls flipVideoIcon" variant="contained" type="submit" onClick={(e) => flipVideos(e)} />
                                                <MdFlip title={`Switch ${mirrorTitleText} mirror`} className="vdo-controlls mirrorVideoIcon" variant="contained" type="submit" onClick={(e) => mirrorVideos(e)} />
                                                <MdShare title="Share this lesson" className="vdo-controlls" variant="contained" onClick={(e) => shareLessonDetails(e)} />
                                                {
                                                    shouldShowFullScreen ?
                                                    fullScreenMode ?
                                                    <FullscreenExitIcon title="Fullscreen mode" className="vdo-controlls fullScreenToggleIcon" variant="contained" type="submit" onClick={(e) => triggerFullScreen(e)} />
                                                    :
                                                    <FullscreenIcon title="Exit fullscreen" className="vdo-controlls fullScreenToggleIcon" variant="contained" type="submit" onClick={(e) => triggerFullScreen(e)} />
                                                    : ''
                                                }
                                            </div>
                                            <LessonsVideoPlayer
                                                dataId={activeVideosList?.frontView}
                                                classNames={`${frontClass} ${mirrorClass} ${backClass}`}
                                                src={activeVideosList?.frontView}
                                                thumbNail={thumbNail}
                                                videoLastPlayedTime={videoLastPlayedTime}
                                                onVideoSeek={(e) => setVideoPlayTime(e)}
                                                onVideoPause={(e) => onVideoPauseStop(e)}
                                                onVideoPlay={(e) => playVideo(e)}
                                                onFullScreenToggle={(e) => triggerFullScreen(e)}
                                                onWindowResize={(e) => setVideoWrapperMaxHeightOnResize(e)}
                                            />
                                        </div>
                                    </div>

                                </Paper>
                            </div>
                        </Slide>
                    </div > : null
            }
        </>
    );
}

export default VideoWrapper;
