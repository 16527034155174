import React, { useEffect, useState, useRef } from "react";
import Navigation from "./Navigation";
import OfflineStudioForm from "./OfflineStudioForm";
import boogaluLogo from "../../Images/da logo.png";
import { FaPowerOff } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import {
  getOfflineStudiosForAdmin,
  toggleActivateDeactivateStudio,
  toggleAdmissionStatus,
  deleteStudioByKey,
  getOfflineStudioById,
} from "../../Services/OfflineStudios.service";
import offlineStudioIcon from "../../Images/offline-studios.png";
import ActionToolTip from "../ActionTooltip";
import ConfirmationModal from "../ConfirmationModal";
import StudioLandingDetails from "./StudioLandingDetails";
import AdminLogin from "./AdminLogin";
import { getAdminCredentials } from "../../Services/Other.service";

if (localStorage.getItem("adminLoggedIn")) {
  localStorage.removeItem("adminLoggedIn");
}

const checkAdminLogIn = JSON.parse(sessionStorage.getItem("adminLoggedIn"));

const OfflineStudios = () => {
  const [isAdminLoggedIn, toggleAdminLogin] = useState(false);
  const [loggedInMessages, setLoginMessage] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPwd, setAdminPwd] = useState("");
  const [isCreateFormTab, toggleCreateList] = useState(true);
  const [isLoaderActive, toggleLoading] = useState(false);
  const [studioListData, setStudioList] = useState(null);
  const [isStudioDeleteClicked, toggleStudioDeleteModal] = useState(false);
  const [studioDataModify, setStudioDataModify] = useState(null);
  const [deletedStudioMessage, setDeleteMessage] = useState(null);
  const [landingPageModal, toggleLandingPageModal] = useState(false);
  const [isFromEditStudio, enableEditStudio] = useState(false);
  const [studioData, setStudioData] = useState([]);
  const createTabRef = useRef(null);
  const listTabRef = useRef(null);

  function triggerAdminLogout(event, action) {
    // setAdminEmail("");
    // setAdminPwd("");
    toggleAdminLogin(action);
    sessionStorage.setItem("adminLoggedIn", action);
    window.location.reload();
  }

  useEffect(() => {
    if (checkAdminLogIn) {
      toggleAdminLogin(checkAdminLogIn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAdminLogin(value, type) {
    if (type === "email") {
      setAdminEmail(value?.target?.value);
    } else {
      setAdminPwd(value?.target?.value);
    }
  }

  function triggerLogin(action) {
    if (action) {
      if (adminEmail === "" || adminPwd === "") {
        setLoginMessage("Please enter Email and Password!");
        toggleAdminLogin(false);
        sessionStorage.setItem("adminLoggedIn", false);
      } else {
        getAdminCredentials().subscribe((resp) => {
          if (resp?.email) {
            if (adminEmail === resp.email && adminPwd === resp.pwd) {
              setLoginMessage("");
              toggleAdminLogin(true);
              sessionStorage.setItem("adminLoggedIn", true);
            } else {
              toggleAdminLogin(false);
              sessionStorage.setItem("adminLoggedIn", false);
              setLoginMessage(
                "Invalid credentials, please enter valid email-Id and Password!"
              );
            }
          }
        });
      }
    }
  }

  const getStudioListdata = () => {
    try {
      toggleLoading(true);
      getOfflineStudiosForAdmin().subscribe((resp) => {
        toggleLoading(false);
        setStudioList(resp);
      });
    } catch (e) {
      toggleLoading(false);
      console.log("Error: ", e);
    }
  };

  function switchTabs(action) {
    if (action && action === "create") {
      if (createTabRef.current && listTabRef.current) {
        createTabRef.current.classList.add("active");
        listTabRef.current.classList.remove("active");
        setStudioData([]);
      }
      toggleCreateList(true);
      enableEditStudio(false);
    } else {
      if (createTabRef.current && listTabRef.current) {
        listTabRef.current.classList.add("active");
        createTabRef.current.classList.remove("active");
        getStudioListdata();
      }
      toggleCreateList(false);
    }
  }

  function confirmItsAdmin(event, value) {
    console.log(event, value);
    const adminActionData = {
      event: event,
      value: value,
    };

    if (event && value?.id) {
      switch (event) {
        case "activate":
          toggleLoading(true);
          try {
            toggleActivateDeactivateStudio(adminActionData, true).subscribe(
              (resp) => {
                toggleLoading(false);
                console.log("resp: ", resp);
              }
            );
          } catch (e) {
            toggleLoading(false);
            console.log("change fail: ", e);
          }
          break;
        case "deactivate":
          toggleLoading(true);
          try {
            toggleActivateDeactivateStudio(adminActionData, false).subscribe(
              (resp) => {
                toggleLoading(false);
                console.log("resp: ", resp);
              }
            );
          } catch (e) {
            toggleLoading(false);
            console.log("change fail: ", e);
          }
          break;
        case "off":
          toggleLoading(true);
          try {
            toggleAdmissionStatus(adminActionData, false).subscribe((resp) => {
              toggleLoading(false);
              console.log("resp: ", resp);
            });
          } catch (e) {
            toggleLoading(false);
            console.log("change fail: ", e);
          }
          break;
        case "on":
          toggleLoading(true);
          try {
            toggleAdmissionStatus(adminActionData, true).subscribe((resp) => {
              toggleLoading(false);
              console.log("resp: ", resp);
            });
          } catch (e) {
            toggleLoading(false);
            console.log("change fail: ", e);
          }
          break;
        case "remove":
          setDeleteMessage("Are you sure, you want to delete this Studio?");
          setStudioDataModify(adminActionData);
          toggleStudioDeleteModal(true);
          break;
        case "edit":
          switchTabs("create");
          enableEditStudio(true);
          setStudioForUpdate(adminActionData);
          break;
        default:
          break;
      }
    }
  }

  function setStudioForUpdate(data) {
    if (data?.event === "edit") {
      // console.log("data", data);
      const studioKey = window.btoa(data?.value?.id);
      try {
        // setCompetitionData(initialCompetitionData);
        // toggleLoading(true);
        getOfflineStudioById(studioKey).subscribe((studioData) => {
          // console.log("studioData", { studioData });
          setStudioData(studioData);
          // const filteredImages = studioData?.photos.filter(
          //   (image) => image.id !== "previewImage"
          // );
          // setStudioGallery([...studioData?.videos, ...studioData?.photos]);
          // setStudioDetails(studioData);
          // setAdvanceFilters(studioData.advancedFilter || []);
          // setActiveCategory(filterCategoryWithTypes(studioData.advancedFilter)[0]);
        });
      } catch (e) {
        toggleLoading(false);
        console.log("error: ", e);
      }
    }
  }

  function studioDeleteConfirmation(action, data) {
    if (action && data?.value?.id) {
      toggleLoading(true);
      toggleStudioDeleteModal(false);
      try {
        deleteStudioByKey(data.value.id).subscribe((response) => {
          toggleLoading(false);
        });
      } catch (e) {
        console.log("error: ", e);
      }
    } else {
      toggleStudioDeleteModal(false);
    }
  }

  function uploadBannerForLanding() {
    toggleLandingPageModal(true);
  }

  function closeLandingPageModal() {
    toggleLandingPageModal(false);
  }

  return (
    <div className="adminPanelSection">
      {landingPageModal ? (
        <StudioLandingDetails closeModal={closeLandingPageModal} />
      ) : (
        ""
      )}
      <Loader value={isLoaderActive} />
      <Navigation />
      {isStudioDeleteClicked ? (
        <ConfirmationModal
          screen="studio"
          message={deletedStudioMessage}
          actionData={studioDataModify}
          confirmationResponse={studioDeleteConfirmation}
        />
      ) : (
        ""
      )}
      <div className="logoWrap">
        <a href="/" title="Da home">
          <img src={boogaluLogo} alt="Da" />
        </a>
      </div>
      {isAdminLoggedIn || checkAdminLogIn ? (
        <div className="optionsTab">
          <p
            onClick={() => switchTabs("create")}
            className="tabItem active"
            ref={createTabRef}
          >
            Create new
          </p>
          <p
            onClick={() => switchTabs("list")}
            className="tabItem"
            ref={listTabRef}
          >
            View list
          </p>
          <p
            onClick={() => uploadBannerForLanding("banner")}
            className="tabItem"
          >
            Landing Background
          </p>
        </div>
      ) : (
        ""
      )}
      <div
        className={`competition-bo-wrap clearfix ${
          (isAdminLoggedIn || checkAdminLogIn) && "loggedInAdmin usersListBox"
        }`}
      >
        {isAdminLoggedIn || checkAdminLogIn ? (
          <>
            <h1>
              <Link
                to="/adminpanel"
                title="back to admin"
                className="backToAdmin"
              >
                <span>&#8592;</span>
              </Link>
              {isCreateFormTab
                ? isFromEditStudio
                  ? "Update Studio Data"
                  : "Create studio data"
                : "List of studios"}
            </h1>
            {isCreateFormTab ? (
              <OfflineStudioForm
                data={studioData}
                isEditEnabled={isFromEditStudio}
              />
            ) : (
              <div className="adminItemlistView studioItemList">
                {studioListData && studioListData.length ? (
                  studioListData.map((item, index) => {
                    return (
                      <div
                        className="boxItem compBox"
                        key={`event-comp-${index}`}
                      >
                        <p className="title" title={item.name}>
                          {item.name}
                        </p>
                        <div className="compImageWrap">
                          {item?.photos?.length ? (
                            <img
                              src={item.photos[0].downloadURL}
                              alt={item.photos[0].id}
                            />
                          ) : (
                            <img src={offlineStudioIcon} alt="studio default" />
                          )}
                        </div>
                        <ActionToolTip
                          id={item.key}
                          name={item.name}
                          isEditEnabled={true}
                          isActive={item?.isActive}
                          isAdmissionEnabled={true}
                          isDeleteEnabled={true}
                          isAdmissionOpen={item?.isAdmissionOpen}
                          onActionClicked={(e) =>
                            confirmItsAdmin(e, {
                              id: item.key,
                              name: item.name,
                            })
                          }
                        />
                        <p className="statusBlock">
                          Status:&nbsp;
                          <strong>
                            {item?.isActive ? "Active" : "Inactive"}
                          </strong>
                        </p>
                        <p className="statusBlock">
                          Admission Status:&nbsp;
                          <strong>
                            {item?.isAdmissionOpen ? "Open" : "Closed"}
                          </strong>
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p className="noDataInListMessage">
                    You haven't created any Studios!
                  </p>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <h1>
              <Link
                to="/adminpanel"
                title="back to admin"
                className="backToAdmin"
              >
                <span>&#8592;</span>
              </Link>
              Login to create new Studio
            </h1>
            <AdminLogin
              statusMessage={loggedInMessages}
              inputChange={(value, field) => handleAdminLogin(value, field)}
              submitLogin={(event, action) => triggerLogin(event, action)}
            />
          </>
        )}
        {isAdminLoggedIn || checkAdminLogIn ? (
          <p
            className="logOutIconWrap"
            title="logout"
            onClick={(e) => triggerAdminLogout(e, false)}
          >
            <FaPowerOff />
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default OfflineStudios;
