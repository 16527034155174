import React, { useState, useRef, useEffect } from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FaCloudUploadAlt } from "react-icons/fa";
import { getStudioLandingPage, updateStudioLandingPage } from "../../Services/Other.service";
import { uploadVideo, uploadImage } from "../../Services/Upload.service";

const StudioLandingDetails = (props) => {
    const landingPageData = {
        title: '', 
        bgImage: null,
        bgVideo: null
    };

    const [formMessage, setFormMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [isVideoUploading, toggleVideoUploader] = useState(false);
    const [isImageUploading, toggleImageUploader] = useState(false);
    const [landingData, setLandingData] = useState(landingPageData);
    const [inputValidClass, toggleInputValidClass] = useState("");
    const [btnLoadingClass, toggleLoadingClass] = useState("");
    const [isDataLoading, toggleDataLoading] = useState(false);

    const uploaderPreview = useRef();
    const bannerImageRef = useRef();

    useEffect(() => {
        toggleDataLoading(true);
        getStudioLandingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getStudioLandingData() {
        try {
            getStudioLandingPage().subscribe( response => {
                toggleDataLoading(false);
                setLandingData(response);
            });
        } catch(e) {
            toggleDataLoading(false);
            setFormMessage('Something went wrong, close Popup and open again!');
            setMessageClass('error');
        }
    }

    function saveLandingPageDetails() {
        if (validateData()) {
            toggleLoadingClass('loading');
            if (landingData.bgVideo) {
                try {
                    uploadVideo(landingData.bgVideo, null, null, null).subscribe( resp => {
                        if (resp?.downloadURL) {
                            landingData.bgVideo = resp.downloadURL;
                            try {
                                updateStudioLandingPage(landingData).subscribe( updateResp => {
                                    toggleLoadingClass('');
                                    setFormMessage('Details saved successfully');
                                    setMessageClass('success');
                                    setTimeout(() => {
                                        props.closeModal();
                                    }, 2000);
                                });
                            } catch(e) {
                                toggleLoadingClass('');
                                setFormMessage('Something went wrong, try after sometime!');
                                setMessageClass('error');
                            }
                        }
                    });
                } catch(e) {
                    console.log('Video upload error: ', e);
                }
            } else if (landingData.bgImage) {
                try {
                    uploadImage(landingData.bgImage, "bgImage", "medium").subscribe(
                      (resp) => {
                        if (resp?.downloadURL) {
                            landingData.bgImage = resp.downloadURL;
                            try {
                                updateStudioLandingPage(landingData).subscribe( updateResp => {
                                    toggleLoadingClass('');
                                    setFormMessage('Details saved successfully');
                                    setMessageClass('success');
                                    setTimeout(() => {
                                        props.closeModal();
                                    }, 2000);
                                });
                            } catch(e) {
                                toggleLoadingClass('');
                                setFormMessage('Something went wrong, try after sometime!');
                                setMessageClass('error');
                            }
                        }
                      }
                    );
                  } catch (e) {
                    console.log('Image upload error: ', e);
                  }
            }
        }
    }
    
    function closeLandingPageModal() {
        props.closeModal();
    }

    function validateData() {
        if (!landingData.title) {
            setFormMessage('Enter title for landing page!');
            setMessageClass('error');
            toggleInputValidClass('invalid');
            return false;
        } 
        
        if(landingData.bgImage || landingData.bgVideo) {
            return true;
        } else {
            setFormMessage('Upload Image or Video for landing page');
            setMessageClass('error');
            return false;
        }
    }

    function onChangeFile(event, type) {
        event.stopPropagation();
        event.preventDefault();
        const currentElement = event.currentTarget;
        const selectedElement = event.currentTarget.parentNode;
        let title = '';
        let file = null;
        setFormMessage('');
        setMessageClass('');
        toggleInputValidClass('');
        if (type === 'bgImage' || type === 'bgVideo') {
            file = event.target.files[0];
        } else {
            title = currentElement.value;
            if (!title) {
                setFormMessage('Enter title for landing page!');
                setMessageClass('error');
                toggleInputValidClass('invalid');
            } else {
                setLandingData({...landingData, [type]: title})
            }
        }
        if (file) {
            const reader = new FileReader();
            switch(type) {
                case 'bgImage': if(file.size > 5200000) {
                                setFormMessage('Image file size is too big, should not exceed 5 MB!');
                                setMessageClass('error');
                            } else {
                                setLandingData({...landingData});
                                reader.readAsDataURL(file);
                                toggleImageUploader(true);
                            }
                            break;
                case 'bgVideo': if(file.size > 62000000) {
                                setFormMessage('Video file size is too big, should not exceed 60 MB!');
                                setMessageClass('error');
                            } else {
                                setLandingData({...landingData});
                                reader.readAsDataURL(file);
                                toggleVideoUploader(true);
                            }
                            break;

                default: break; 
            }
            reader.onload = () => {
                setLandingData({...landingData, [type]: reader.result});
                selectedElement.classList.add("selected");
                if (currentElement.getAttribute('id') === 'bgImage') {
                    toggleImageUploader(false);
                }
                if (currentElement.getAttribute('id') === 'bgVideo') {
                    toggleVideoUploader(false);
                }
            }
        }
    }

    return (
        <div className="studioLandingUpdateContent">
            <div className="studioLandingInner">
                <p
                    className="closeModalBtn"
                    onClick={() => closeLandingPageModal()}
                ></p>
                <h2>Update text and background of Studio landing page</h2>
                <div className="studioFormWrap">
                    <div className="input-wrap">
                        <TextField
                            className={`input-field ${inputValidClass}`}
                            required
                            id="studio-title"
                            label="Studio landing page title"
                            variant="outlined"
                            onBlur={(e) => onChangeFile(e, 'title')}
                        />
                    </div>
                    <div className="input-wrap assetsUpload">
                        <div
                            className={`upload-input-wrap imageUpload ${landingData.bgImage !== null && 'selected'}`}
                        >
                            {
                                isImageUploading ?
                                    <div className="boxSpinner"></div> : ''
                            }
                            <h6 className="heading">
                                Upload background image
                            </h6>
                            <h6 className="sub-heading">Maximum size 5 MB</h6>
                            <i className="upload-icon">
                                <FaCloudUploadAlt />
                            </i>
                            <input
                                id="bgImage"
                                type="file"
                                accept="image/*"
                                ref={bannerImageRef}
                                onChange={(e) =>
                                    onChangeFile(
                                        e,
                                        "bgImage"
                                    )
                                }
                            />
                        </div>
                        <p className="orMessage">Or</p>
                        <div
                            className={`upload-input-wrap videoUpload ${landingData.bgVideo !== null && 'selected'}`}
                        >
                            {
                                isVideoUploading ?
                                    <div className="boxSpinner"></div> : ''
                            }
                            <h6 className="heading">
                               Upload background video
                            </h6>
                            <h6 className="sub-heading">30-40 secs only</h6>
                            <i className="upload-icon">
                                <FaCloudUploadAlt />
                            </i>
                            <input
                                id="bgVideo"
                                type="file"
                                accept="video/mp4,video/x-m4v,video/*"
                                ref={uploaderPreview}
                                onChange={(e) =>
                                    onChangeFile(e, "bgVideo")
                                }
                            />
                        </div>
                    </div>
                    <p className={`messageText ${messageClass}`}>{formMessage}</p>
                    <div className="input-wrap action-wrap">
                        <Button
                            variant="contained"
                            color="primary"
                            className={`${btnLoadingClass}`}
                            onClick={() => saveLandingPageDetails()}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => closeLandingPageModal()}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                {
                    isDataLoading ?
                    <div className="modalLoader" /> :
                    ''
                }
            </div>
        </div>
    )
}

export default StudioLandingDetails;
