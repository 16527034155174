import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';

const FeedsVideoPlayer = ({ vdoObj, videoKey, autoPlay }) => {
    const [videoPlayClass, toggleVideoPlayClass] = useState('');
    const videoPlayerWrapRef = useRef();
    const reactPlayerRef = useRef();

    function videoPlayed() {
        toggleVideoPlayClass('played');
    }

    function videoPaused() {
        toggleVideoPlayClass('');
    }

    return (
        <div className="vdo-outer">
            <div
                ref={videoPlayerWrapRef}
                className={`vdo-wrap ${videoPlayClass}`}>
                {
                    vdoObj?.key === videoKey ?
                        <ReactPlayer
                            className="react-player"
                            url={vdoObj?.url}
                            ref={reactPlayerRef}
                            playing={autoPlay}
                            controls={true}
                            muted={true}
                            width='100%'
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            onContextMenu={e => e.preventDefault()}
                            onPlay={() => videoPlayed()}
                            onPause={() => videoPaused()}
                            style={{
                                'position': 'relative',
                                'min-width': '100%',
                                'min-height': '100%',
                                'background': 'var(--ternary-dark-grey)',
                                'border-radius': '5px',
                                'margin': '0 auto'
                            }}
                        /> : ''
                }
            </div>
        </div>
    )
}

export default FeedsVideoPlayer;