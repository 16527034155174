import React, { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import boogaluLogo from "../../Images/da logo.png";
import { FaPowerOff } from "react-icons/fa";
import ActionToolTip from "../ActionTooltip";
import ConfirmationModal from "../ConfirmationModal";
import { Link } from "react-router-dom";
import {
  saveSubscription,
  getSubscriptionsList,
  toggleActivateDeactivateSubscription,
  deleteSubscriptionByKey,
} from "../../Services/Subscription.service";
import Navigation from "./Navigation";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Loader from "../Loader";
import AdminLogin from "./AdminLogin";
import { getAdminCredentials } from "../../Services/Other.service";

if (localStorage.getItem("adminLoggedIn")) {
  localStorage.removeItem("adminLoggedIn");
}

const checkAdminLogIn = JSON.parse(sessionStorage.getItem("adminLoggedIn"));

export default function Subscription() {
  const initialSubscriptionData = {
    name: "",
    desc: "",
    active: true,
    type: "",
    amount: 199,
    planType: "startup",
    isLessonAccess: true,
    isCompetitionAccess: false,
    merchandiseFeat: {
      isMerchandiseAccess: false,
      percent: 0,
    },
    startAt: new Date(),
    endAt: new Date(),
    plans: "1",
  };
  const [Subscription, setSubscription] = useState(initialSubscriptionData);
  const [isAdminLoggedIn, toggleAdminLogin] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPwd, setAdminPwd] = useState("");
  const [loggedInMessages, setLoginMessage] = useState("");
  const [submitFormMessages, setSubmitFormMessage] = useState("");
  const [isSubscriptionLoading, toggleLoadingClass] = useState("");
  const [isSavedSuccessValue, toggleSaveSuccessValue] = useState("");
  const [isCreateFormTab, toggleCreateList] = useState(true);
  const [subscriptionsList, setSubscriptionList] = useState(null);
  const [isPageLoaderActive, togglePageLoader] = useState(false);
  const [isSubscriptionDeleteClicked, toggleSubscriptionModal] =
    useState(false);
  const [deleteSubscritionMessage, subscriptionActionMessage] = useState("");
  const [subscriptionDataToModify, setSubscriptionActionData] = useState(null);

  const createTabRef = useRef(null);
  const listTabRef = useRef(null);

  useEffect(() => {
    if (checkAdminLogIn) {
      toggleAdminLogin(checkAdminLogIn);
      getSubscriptionList();
    }
  }, []);

  function switchTabs(event, action) {
    if (action && action === "create") {
      if (createTabRef.current && listTabRef.current) {
        createTabRef.current.classList.add("active");
        listTabRef.current.classList.remove("active");
      }
      toggleCreateList(true);
    } else {
      if (createTabRef.current && listTabRef.current) {
        listTabRef.current.classList.add("active");
        createTabRef.current.classList.remove("active");
        getSubscriptionList();
      }
      toggleCreateList(false);
    }
  }

  function handleAdminLogin(value, type) {
    if (type === "email") {
      setAdminEmail(value?.target?.value);
    } else {
      setAdminPwd(value?.target?.value);
    }
  }

  function triggerLogin(action) {
    if (action) {
      if (adminEmail === "" || adminPwd === "") {
        setLoginMessage("Please enter Email and Password!");
        toggleAdminLogin(false);
        sessionStorage.setItem("adminLoggedIn", false);
      } else {
        getAdminCredentials().subscribe((resp) => {
          if (resp?.email) {
            if (adminEmail === resp.email && adminPwd === resp.pwd) {
              setLoginMessage("");
              toggleAdminLogin(true);
              sessionStorage.setItem("adminLoggedIn", true);
              getSubscriptionList();
            } else {
              toggleAdminLogin(false);
              sessionStorage.setItem("adminLoggedIn", false);
              setLoginMessage(
                "Invalid credentials, please enter valid email-Id and Password!"
              );
            }
          }
        });
      }
    }
  }

  function tiggerAdminLogout(event, action) {
    setAdminEmail("");
    setAdminPwd("");
    toggleAdminLogin(action);
    sessionStorage.setItem("adminLoggedIn", action);
    window.location.reload();
  }

  const setPlanTypeOnPlanTenure = (planTenure) => {
    let planType = "startup";
    switch (planTenure) {
      case "1":
        planType = "startup";
        break;

      case "3":
        planType = "plus";
        break;

      case "6":
        planType = "pro";
        break;

      case "12":
        planType = "premium";
        break;

      default:
        planType = "startup";
        break;
    }
    return planType;
  };

  const handleChange = (prop, index) => (event) => {
    let value = event.target.value;
    if (prop === "active") value = event.target.checked;
    if (prop === "isLessonAccess") value = event.target.checked;
    if (prop === "isCompetitionAccess") value = event.target.checked;
    if (prop === "merchandiseFeat" && index === "checkbox") {
      value = {
        isMerchandiseAccess: event.target.checked,
        percent: Subscription?.merchandiseFeat?.percent || 0,
      };
    }
    if (prop === "merchandiseFeat" && index === "input") {
      value = {
        isMerchandiseAccess: Subscription?.merchandiseFeat?.isMerchandiseAccess,
        percent: value,
      };
    }
    if (prop === "prices") {
      Subscription.prices[index] = event.target.value;
      value = Subscription.prices;
    }

    if (prop === "plans") {
      setSubscription({
        ...Subscription,
        ["planType"]: setPlanTypeOnPlanTenure(value),
        [prop]: value,
      });
    } else {
      setSubscription({ ...Subscription, [prop]: value });
    }
  };

  function setStartDate(date) {
    try {
      setSubscription({ ...Subscription, startAt: date });
    } catch (e) {
      console.log("Start date error: ", e);
    }
  }

  function setEndDate(date) {
    try {
      setSubscription({ ...Subscription, endAt: date });
    } catch (e) {
      console.log("End date error: ", e);
    }
  }

  function validateFormData() {
    let isFormValid = true;
    if (Subscription.name === "") {
      isFormValid = false;
      setSubmitFormMessage("Please enter subscription name/title!");
    } else if (Subscription.amount === "") {
      isFormValid = false;
      setSubmitFormMessage("Please enter subscription amount!");
    } else if (Subscription.amount < 199) {
      isFormValid = false;
      setSubmitFormMessage("Subscription amount cannot be less than 199!");
    } else if (
      !Subscription.isLessonAccess &&
      !Subscription.isCompetitionAccess
    ) {
      isFormValid = false;
      setSubmitFormMessage("Please at-least select one feature for user!");
    } else if (
      typeof Subscription?.startAt === "object" &&
      typeof Subscription?.endAt === "object"
    ) {
      if (
        Subscription?.startAt?.toISOString()?.split("T")[0] ===
        Subscription?.endAt?.toISOString()?.split("T")[0]
      ) {
        isFormValid = false;
        setSubmitFormMessage(
          "Subscription start and end date connot be same day!"
        );
      }
    } else if (
      typeof Subscription?.startAt === "string" &&
      typeof Subscription?.endAt === "string"
    ) {
      if (
        Subscription?.startAt?.split("T")[0] ===
        Subscription?.endAt?.split("T")[0]
      ) {
        isFormValid = false;
        setSubmitFormMessage(
          "Subscription start and end date connot be same day!"
        );
      }
    } else {
      isFormValid = true;
    }

    if (!isFormValid) {
      toggleSaveSuccessValue("error");
    }

    return isFormValid;
  }

  async function saveDetails(e) {
    // upload Subscription image to bucket
    setSubmitFormMessage("");
    try {
      if (validateFormData()) {
        Subscription.startAt =
          typeof Subscription.startAt === "object"
            ? Subscription.startAt.toISOString()
            : Subscription.startAt;
        Subscription.endAt =
          typeof Subscription.endAt === "object"
            ? Subscription.endAt.toISOString()
            : Subscription.endAt;
        if (
          subscriptionsList?.length &&
          subscriptionsList.some(
            (item) => item.planType === Subscription.planType
          )
        ) {
          toggleLoadingClass("");
          toggleSaveSuccessValue("error");
          setSubmitFormMessage(
            "Subscription type already present, please select other plan type!"
          );
        } else {
          toggleLoadingClass("loading");
          saveSubscription(Subscription).subscribe(() => {
            toggleLoadingClass("");
            toggleSaveSuccessValue("success");
            setSubmitFormMessage("Subscription saved successfully!");
            setSubscription(initialSubscriptionData);
          });
        }

        setTimeout(() => {
          setSubmitFormMessage("");
        }, 4000);
      }
    } catch (e) {
      toggleLoadingClass("");
      toggleSaveSuccessValue("error");
      setSubmitFormMessage("Something went wrong, please try in sometime!");
      setTimeout(() => {
        setSubmitFormMessage("");
      }, 4000);
      console.log("Error: ", e);
    }
  }

  async function getSubscriptionList() {
    togglePageLoader(true);
    try {
      getSubscriptionsList(Subscription).subscribe((response) => {
        togglePageLoader(false);
        setSubscriptionList(response);
      });
    } catch (e) {
      togglePageLoader(false);
      console.log("Erro: ", e);
    }
  }

  function setMaxDateSelectionYear() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    return new Date(year + 1, month, day);
  }

  function onItemActionSelected(event, value) {
    if (event && event === "deactivate") {
      togglePageLoader(true);
      try {
        toggleActivateDeactivateSubscription(value, false).subscribe(
          (response) => {
            togglePageLoader(false);
          }
        );
      } catch (e) {
        togglePageLoader(false);
        console.log("Error: ", e);
      }
    }

    if (event && event === "activate") {
      togglePageLoader(true);
      try {
        toggleActivateDeactivateSubscription(value, true).subscribe(
          (response) => {
            togglePageLoader(false);
          }
        );
      } catch (e) {
        togglePageLoader(false);
        console.log("Error: ", e);
      }
    }

    if (event && event === "remove") {
      toggleSubscriptionModal(true);
      subscriptionActionMessage(
        "Are you sure, you want to delete this Subscription?"
      );
      setSubscriptionActionData(value);
    }
  }

  function subscriptionDeleteConfirmation(action, data) {
    if (action) {
      togglePageLoader(true);
      toggleSubscriptionModal(false);
      try {
        deleteSubscriptionByKey(data).subscribe((response) => {
          togglePageLoader(false);
        });
      } catch (e) {
        console.log("error: ", e);
      }
    } else {
      toggleSubscriptionModal(false);
    }
  }

  return (
    <div className="adminPanelSection">
      {isPageLoaderActive ? <Loader /> : ""}
      {isSubscriptionDeleteClicked ? (
        <ConfirmationModal
          screen="subscription"
          message={deleteSubscritionMessage}
          actionData={subscriptionDataToModify}
          confirmationResponse={subscriptionDeleteConfirmation}
        />
      ) : (
        ""
      )}
      <Navigation />
      <div className="logoWrap">
        <img src={boogaluLogo} alt="Da" />
      </div>
      {isAdminLoggedIn || checkAdminLogIn ? (
        <div className="optionsTab">
          <p
            onClick={(e) => switchTabs(e, "create")}
            className="tabItem active"
            ref={createTabRef}
          >
            Create new
          </p>
          <p
            onClick={(e) => switchTabs(e, "list")}
            className="tabItem"
            ref={listTabRef}
          >
            View list
          </p>
        </div>
      ) : (
        ""
      )}
      <div
        className={`subscription-bo-wrap clearfix ${
          (isAdminLoggedIn || checkAdminLogIn) && "loggedInAdmin"
        }`}
      >
        {isAdminLoggedIn || checkAdminLogIn ? (
          <p
            className="logOutIconWrap"
            title="logout"
            onClick={(e) => tiggerAdminLogout(e, false)}
          >
            <FaPowerOff />
          </p>
        ) : (
          ""
        )}
        {isAdminLoggedIn || checkAdminLogIn ? (
          <h1>
            <Link
              to="/adminpanel"
              title="back to admin"
              className="backToAdmin"
            >
              <span>&#8592;</span>
            </Link>
            {isCreateFormTab
              ? "Create a new Subscription"
              : "List of Subscriptions"}
          </h1>
        ) : (
          <h1>
            <Link
              to="/adminpanel"
              title="back to admin"
              className="backToAdmin"
            >
              <span>&#8592;</span>
            </Link>
            Login to create new Subscription
          </h1>
        )}
        {isAdminLoggedIn || checkAdminLogIn ? (
          isCreateFormTab ? (
            <div className="inner-form-wrap">
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  required
                  id="outlined-required-name"
                  label="Name"
                  onChange={handleChange("name")}
                  value={Subscription.name}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  required
                  id="outlined-required-amount"
                  label="Amount"
                  type="number"
                  InputProps={{ inputProps: { min: 199 } }}
                  onChange={handleChange("amount")}
                  value={Subscription.amount}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap feature-group-wrap fullWidthInput">
                <InputLabel required id="select-outlined-label">
                  Include features
                </InputLabel>
                <FormControl className="checkBoxControlGroup">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="features"
                        color="primary"
                        className="selected-item-checkbox"
                        checked={Subscription.isLessonAccess}
                        onChange={handleChange("isLessonAccess")}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Lessons"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="features"
                        color="primary"
                        checked={Subscription.isCompetitionAccess}
                        className="selected-item-checkbox"
                        onChange={handleChange("isCompetitionAccess")}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Competitions"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="features"
                        color="primary"
                        checked={
                          Subscription.merchandiseFeat.isMerchandiseAccess
                        }
                        className="selected-item-checkbox"
                        onChange={handleChange("merchandiseFeat", "checkbox")}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Merchandises"
                  />
                  {Subscription.merchandiseFeat.isMerchandiseAccess ? (
                    <TextField
                      className="input-field"
                      id="outlined-required-merchandise"
                      type="number"
                      label="How much % off on merchandises?"
                      onChange={handleChange("merchandiseFeat", "input")}
                      value={Subscription.merchandiseFeat.percent}
                      variant="outlined"
                    />
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
              <div className="input-wrap fullWidthInput">
                <TextField
                  className="input-field"
                  id="outlined-required-desc"
                  label="Subscription short description"
                  onChange={handleChange("desc")}
                  value={Subscription.desc}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap">
                <FormControl variant="outlined" className="input-field">
                  <InputLabel id="select-outlined-label">
                    Plan Tenure
                  </InputLabel>
                  <Select
                    labelId="select-outlined-label"
                    id="select-outlined"
                    value={Subscription.plans}
                    onChange={handleChange("plans")}
                    label="Plan Tenure"
                  >
                    <MenuItem value="1">Monthly</MenuItem>
                    <MenuItem value="3">Quarterly</MenuItem>
                    <MenuItem value="6">Half-yearly</MenuItem>
                    <MenuItem value="12">Annually</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="input-wrap">
                <FormControl variant="outlined" className="input-field">
                  <InputLabel id="select-outlined-label-plantype" required>
                    Plan type
                  </InputLabel>
                  <Select
                    required
                    labelId="select-outlined-label-plantype"
                    id="select-outlined-plantype"
                    value={Subscription.planType}
                    disabled
                    onChange={handleChange("planType")}
                    label="Plan Type"
                  >
                    <MenuItem value="startup">Start-up</MenuItem>
                    <MenuItem value="plus">Plus</MenuItem>
                    <MenuItem value="pro">Pro</MenuItem>
                    <MenuItem value="premium">Premium</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="input-wrap data-time-wrap">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    minDate={new Date()}
                    maxDate={setMaxDateSelectionYear()}
                    id="date-picker-start"
                    label="Select subscription start date"
                    format="MM/dd/yyyy"
                    value={Subscription.startAt}
                    onChange={(e) => setStartDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="input-wrap data-time-wrap">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-end"
                    minDate={new Date()}
                    maxDate={setMaxDateSelectionYear()}
                    label="Select subscription expiry date"
                    format="MM/dd/yyyy"
                    value={Subscription.endAt}
                    onChange={(e) => setEndDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="input-wrap action-wrap">
                <p className={`messageWrap ${isSavedSuccessValue}`}>
                  {submitFormMessages}
                </p>
                <Button variant="contained" color="primary">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={isSubscriptionLoading}
                  onClick={(e) => saveDetails(e)}
                >
                  Save
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      color="primary"
                      className="selected-item-checkbox"
                      checked={Subscription.active}
                      onChange={handleChange("active")}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label="Active Subscription"
                />
              </div>
            </div>
          ) : (
            <div className="adminItemlistView subscriptionAdminList">
              {subscriptionsList && subscriptionsList.length ? (
                subscriptionsList.map((item) => {
                  return (
                    <div
                      key={`subscription-${item.key}`}
                      className="boxItem compBox"
                    >
                      <p className="title">{item.name}</p>
                      <p className="amount">
                        Amount: &#8377;&nbsp;{item.amount}
                      </p>
                      <ActionToolTip
                        id={item.key}
                        name={item.name}
                        isActive={item.active}
                        isDeleteEnabled={true}
                        onActionClicked={(e) =>
                          onItemActionSelected(e, {
                            id: item.key,
                            name: item.name,
                          })
                        }
                      />
                      <p className="statusBlock">
                        Status:{" "}
                        <span>{item.active ? "Active" : "Inactive"}</span>
                      </p>
                      <p className="date">
                        Starting Date: <span>{item.startingDate}</span>
                      </p>
                    </div>
                  );
                })
              ) : (
                <p className="noDataInListMessage">
                  You haven't created any Subscription!
                </p>
              )}
            </div>
          )
        ) : (
          <AdminLogin
            statusMessage={loggedInMessages}
            inputChange={(value, field) => handleAdminLogin(value, field)}
            submitLogin={(event, action) => triggerLogin(event, action)}
          />
        )}
      </div>
      <div className="footerBox">
        &copy; 2021 A CHOREO CULTURE PVT LTD, All rights reserved.
      </div>
    </div>
  );
}
