/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { useRef, useState, useEffect, createRef } from "react";
import {
  OFFLINE_STUDIO_LOCATION,
  OFFLINE_STUDIO_ADVANCED_FILTER,
  OPENING_HOURS,
} from "../../Constants";
import OpeningHours from "./OpeningHours";
import PackagesComponent from "./PackagesComponent";
import { checkIfTextHasUrl } from "../../helpers";
import { ca } from "date-fns/locale";

const AdvancedFilterComponent = (props) => {
  console.log("AdvancedFilterComponent props", props);
  const { callbackHandler, selectedData } = props;
  const [advancedFilter, setAdvancedFilter] = useState(() => {
    const staticData = JSON.parse(
      JSON.stringify(OFFLINE_STUDIO_ADVANCED_FILTER)
    );
    if (selectedData && selectedData?.length > 0) {
      for (let i = 0; i < staticData.length; i++) {
        const defaultItem = staticData[i];
        for (let j = 0; j < selectedData.length; j++) {
          const selectedItem = selectedData[j];
          if (
            selectedItem &&
            selectedItem?.types &&
            selectedItem?.types?.length > 0 &&
            defaultItem?.id === selectedItem?.id
          ) {
            if (staticData[i].types && staticData[i].types?.length > 0) {
              for (let k = 0; k < staticData[i].types?.length; k++) {
                for (let l = 0; l < selectedItem?.types?.length; l++) {
                  if (staticData[i].types[k].id === selectedItem?.types[l].id) {
                    staticData[i].types[k].selectedPackages =
                      selectedItem?.types[l].packages;
                    staticData[i].types[k].packages =
                      selectedItem?.types[l].packages;
                    staticData[i].types[k].selectedTiming =
                      selectedItem?.types[l].timing ?? [];
                    staticData[i].types[k].timing =
                      selectedItem?.types[l].timing ?? [];
                  }
                }
              }
            }
          }
        }
      }
    }
    return staticData;
  });
  const [addButtonState, setAddButtonState] = useState(true);
  const [openingHoursForCategory, setOpeningHoursForCategory] = useState({});

  let tempAdvancedFilters = advancedFilter;

  const setTempAdvancedFilters = (data) => {
    tempAdvancedFilters = data;
  };

  const ChildrenComponent = (props) => {
    const {
      data,
      parentNode,
      itemIndex,
      setAddButtonState,
      advancedFilterData,
      setTempAdvancedFilters,
      selectedTypes,
    } = props;
    let tempAdvancedFiltersInChild = [];

    const [advancedFilterTempData, setAdvancedFilterTempData] =
      useState(advancedFilterData);
    const [categories, setCategories] = useState(() => {
      const categoriesData = data;
      if (selectedTypes && selectedTypes.length > 0) {
        for (let i = 0; i < categoriesData.length; i++) {
          for (let j = 0; j < selectedTypes[j].length; j++) {
            if (categoriesData[i].id === selectedTypes[j].id) {
              categoriesData[i] = selectedTypes[j];
            }
          }
        }
      }
      return categoriesData;
    });
    const [packages, setPackages] = useState();

    const videoInputRefs = useRef(data.map(() => createRef()));
    const videoErrorSpanRefs = useRef(data.map(() => createRef()));

    const onStyleDataChange = (event, type, parentIndex, currentIndex) => {
      if (event && event.target.value) {
        if (type === "styleVideo" && checkIfTextHasUrl(event.target.value)) {
          if (videoInputRefs.current[currentIndex].current) {
            videoInputRefs.current[currentIndex].current.classList.add("error");
          }
          if (videoErrorSpanRefs.current[currentIndex].current) {
            videoErrorSpanRefs.current[currentIndex].current.innerText =
              "Only enter youtube video code, not URL!";
          }

          advancedFilterData[parentIndex]["types"][currentIndex][type] = "";
        } else {
          if (videoInputRefs.current[currentIndex].current) {
            videoInputRefs.current[currentIndex].current.classList.remove(
              "error"
            );
          }
          if (videoErrorSpanRefs.current[currentIndex].current) {
            videoErrorSpanRefs.current[currentIndex].current.innerText = "";
          }
          advancedFilterData[parentIndex]["types"][currentIndex][type] =
            event.target.value;
        }
        setAdvancedFilterTempData(advancedFilterData);
        setTempAdvancedFilters(advancedFilterData);
      }
    };

    const updatedPackagesData = (updatedPackages) => {
      console.log("updatedPackages", updatedPackages);
      const { validItems, currentIndex, currentNode, parentIndex, parentNode } =
        updatedPackages;
      setPackages(updatedPackages);
      advancedFilterData[parentIndex]["types"][currentIndex].packages =
        validItems;
      if (
        ((advancedFilterData[parentIndex]["types"][currentIndex].packages &&
          advancedFilterData[parentIndex]["types"][currentIndex].packages
            .length > 0) ||
          (advancedFilterData[parentIndex]["types"][currentIndex]
            .selectedPackages &&
            advancedFilterData[parentIndex]["types"][currentIndex]
              .selectedPackages.length > 0)) &&
        ((advancedFilterData[parentIndex]["types"][currentIndex].timing &&
          advancedFilterData[parentIndex]["types"][currentIndex].timing.length >
            0) ||
          (advancedFilterData[parentIndex]["types"][currentIndex]
            .selectedTiming &&
            advancedFilterData[parentIndex]["types"][currentIndex]
              .selectedTiming.length > 0))
      ) {
        advancedFilterData[parentIndex]["types"][currentIndex].valid = true;
        advancedFilterData[parentIndex].valid = true;
      }
      advancedFilterData[parentIndex]["types"][currentIndex].packages =
        advancedFilterData[parentIndex]["types"][currentIndex].packages ??
        advancedFilterData[parentIndex]["types"][currentIndex].selectedPackages;
      advancedFilterData[parentIndex]["types"][currentIndex].timing =
        advancedFilterData[parentIndex]["types"][currentIndex].timing ??
        advancedFilterData[parentIndex]["types"][currentIndex].selectedTiming;
      delete advancedFilterData[parentIndex]["types"][currentIndex]
        .selectedPackages;
      delete advancedFilterData[parentIndex]["types"][currentIndex]
        .selectedTiming;
      setAdvancedFilterTempData(advancedFilterData);
      setTempAdvancedFilters(advancedFilterData);
    };

    const [courseTimings, setCourseTimings] = useState();
    const updatedTimingsData = (updatedTimings) => {
      console.log("updatedTimingsData", updatedTimings);
      setCourseTimings(updatedTimings);
      const {
        checkedItems,
        currentNode,
        currentIndex,
        expanded,
        parentIndex,
        parentNode,
      } = updatedTimings;
      advancedFilterData[parentIndex]["types"][currentIndex].timing =
        checkedItems;
      if (
        ((advancedFilterData[parentIndex]["types"][currentIndex].packages &&
          advancedFilterData[parentIndex]["types"][currentIndex].packages
            .length > 0) ||
          (advancedFilterData[parentIndex]["types"][currentIndex]
            .selectedPackages &&
            advancedFilterData[parentIndex]["types"][currentIndex]
              .selectedPackages.length > 0)) &&
        ((advancedFilterData[parentIndex]["types"][currentIndex].timing &&
          advancedFilterData[parentIndex]["types"][currentIndex].timing.length >
            0) ||
          (advancedFilterData[parentIndex]["types"][currentIndex]
            .selectedTiming &&
            advancedFilterData[parentIndex]["types"][currentIndex]
              .selectedTiming.length > 0))
      ) {
        advancedFilterData[parentIndex]["types"][currentIndex].valid = true;
        advancedFilterData[parentIndex].valid = true;
      }
      advancedFilterData[parentIndex]["types"][currentIndex].packages =
        advancedFilterData[parentIndex]["types"][currentIndex].packages ??
        advancedFilterData[parentIndex]["types"][currentIndex].selectedPackages;
      advancedFilterData[parentIndex]["types"][currentIndex].timing =
        advancedFilterData[parentIndex]["types"][currentIndex].timing ??
        advancedFilterData[parentIndex]["types"][currentIndex].selectedTiming;
      delete advancedFilterData[parentIndex]["types"][currentIndex]
        .selectedPackages;
      delete advancedFilterData[parentIndex]["types"][currentIndex]
        .selectedTiming;
      setAdvancedFilterTempData(advancedFilterData);
      setTempAdvancedFilters(advancedFilterData);
    };

    return (
      <Box className="advance-filter-childs">
        {categories.map((item, currentIndex) => {
          return (
            <Accordion key={`${currentIndex + 1}`}>
              <AccordionSummary
                expandIcon={<ArrowBackIos className="iconDown" />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
                // onClick={() => expandedAccordionHandler(currentIndex)}
              >
                <label>{item.label}</label>
              </AccordionSummary>
              <AccordionDetails className="accordionDetailsContainerHolder">
                <div className="accordionDetailsContainer">
                  <PackagesComponent
                    selectedPackages={item?.selectedPackages}
                    parentNode={parentNode.id}
                    parentIndex={itemIndex}
                    currentNode={item.id}
                    currentIndex={currentIndex}
                    packagesData={item?.packages}
                    updatedPackagesData={updatedPackagesData}
                    setTempAdvancedFilters={setTempAdvancedFilters}
                  />
                  <OpeningHours
                    data={item.data}
                    selectedTiming={item?.selectedTiming || item?.timing}
                    setOpeningHours={setOpeningHoursForCategory}
                    parentNode={parentNode.id}
                    parentIndex={itemIndex}
                    currentNode={item.id}
                    currentIndex={currentIndex}
                    updatedTimingsData={updatedTimingsData}
                  />
                </div>
                <div className="styleBasicDetails input-field">
                  <input
                    id={`styleVideo-${item.id}`}
                    type="text"
                    ref={videoInputRefs.current[currentIndex]}
                    placeholder="Youtube video code(eg: 9t9833jUXx4 or ubf-IhCcgns)"
                    className="fileType"
                    onBlur={(e) =>
                      onStyleDataChange(
                        e,
                        "styleVideo",
                        itemIndex,
                        currentIndex
                      )
                    }
                  />
                  <textarea
                    id={`styleVideo-${item.id}`}
                    placeholder="Enter style description"
                    className="fileType"
                    rows={4}
                    onBlur={(e) =>
                      onStyleDataChange(e, "styleDesc", itemIndex, currentIndex)
                    }
                  />
                </div>
                <span
                  ref={videoErrorSpanRefs.current[currentIndex]}
                  className="styleVideoError"
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  };

  return (
    <div className="accordionParents">
      {advancedFilter &&
        advancedFilter.length &&
        advancedFilter.map((item, itemIndex) => {
          return (
            <div className="accordionChild" key={`${itemIndex + 1}`}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowBackIos className="iconDown" />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <label>{item.label}</label>
                </AccordionSummary>
                <AccordionDetails className="accordionBlock">
                  {item.types && (
                    <ChildrenComponent
                      data={item.types}
                      selectedTypes={item.selectedTypes}
                      parentNode={item}
                      itemIndex={itemIndex}
                      setAddButtonState={setAddButtonState}
                      advancedFilterData={advancedFilter}
                      setTempAdvancedFilters={setTempAdvancedFilters}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      <Button
        variant="contained"
        color="primary"
        onClick={() => callbackHandler(tempAdvancedFilters)}
        // disabled={addButtonState}
      >
        Add Selected Filters
      </Button>
    </div>
  );
};

export default AdvancedFilterComponent;
