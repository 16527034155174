import { Observable } from 'rxjs';
import { getServerTimeStamp } from "./firebase";
import db from '../Database';

const enquiryRef = db.collection('enquiries');
const studioLandingRef = db.collection('studioLandingDb');
const adminResetPwdRef = db.collection('adminLoginDb');

export const submitEnquiry = (data) => {
    data.createdOn = new Date();
    data.modifiedOn = new Date();
    data.status = 'Pending';
    return new Observable((observer) => {
        enquiryRef.add(data).then((doc) => {
            observer.next({
                key: doc.id,
            });
        });
    });
}

// Studio landing page background and title data update
export const getStudioLandingPage = () => {
    return new Observable((observer) => {
        studioLandingRef.onSnapshot((querySnapshot) => {
            let studioData;
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.key = doc.id;
                studioData = data;
            });
            observer.next(studioData);
        });
    });
}
export const updateStudioLandingPage = (data) => {
    data.createdOn = data.createdOn || new Date();
    data.modifiedOn = new Date();
    const id = data.key;
    return new Observable((observer) => {
        studioLandingRef.doc(id).update(data).then(() => {
            observer.next();
        });
    });
}

export const serverTimeStamp = () => {
    return getServerTimeStamp();
}

export const selectedUsersMailForRegisterFirebaseRef = db.collection(`selectedUsersMailForRegister`);
export const selectedUsersMailToUserForCompFirebaseRef = db.collection(`selectedUsersMailToUserForComp`);
export const selectedUsersMailToAdminForCompFirebaseRef = db.collection(`selectedUsersMailToAdminForComp`);
export const selectedUsersMailToAdminForCompErrorFirebaseRef = db.collection(`selectedUsersMailToAdminForCompError`);
export const selectedUsersMailToAdminForSubscriptionFirebaseRef = db.collection(`selectedUsersMailToAdminForSubscription`);
export const selectedUsersMailToUserForSubscriptionFirebaseRef = db.collection(`selectedUsersMailToUserForSubscription`);

export const getCompRegisteredUserMails = (userData) => {
    return new Observable((observer) => {
        selectedUsersMailToUserForCompFirebaseRef.where('to', '==', userData.email).where('compId', '==', userData.compId).get().then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
                let data = doc.data();
                data.key = doc.id;
                observer.next(data);
            });
        });
    });
}

export const getAdminCredentials = () => {
    return new Observable((observer) => {
        adminResetPwdRef.onSnapshot((querySnapshot) => {
            let adminData;
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.key = doc.id;
                adminData = data;
            });
            observer.next(adminData);
        });
    });
}

export const getAdminOTP = () => {
    return new Observable((observer) => {
        adminResetPwdRef.onSnapshot((querySnapshot) => {
            let adminOTP;
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                adminOTP = data?.otp;
            });
            observer.next(adminOTP);
        });
    });
}

export const setAdminOTP = (otp) => {
    const modifiedOn = new Date();
    return new Observable((observer) => {
        adminResetPwdRef.onSnapshot( querySnapshot => {
            querySnapshot.forEach( doc => {
                adminResetPwdRef.doc(doc.id).update({'modifiedOn': modifiedOn, 'otp': otp}).then(() => {
                    observer.next({ status: true });
                });
            });
        })
    });
}

export const setAdminPwd = (data) => {
    const modifiedOn = new Date();
    return new Observable((observer) => {
        adminResetPwdRef.onSnapshot( querySnapshot => {
            querySnapshot.forEach( doc => {
                adminResetPwdRef.doc(doc.id).update({'modifiedOn': modifiedOn, ...data}).then(() => {
                    observer.next({ status: true });
                });
            });
        })
    });
}