import { Observable } from 'rxjs';
import db from '../Database';

const liveEventsRef = db.collection('liveevents');

export function getLiveEventsList() {
    return new Observable((observer) => {
        liveEventsRef.onSnapshot((querySnapshot) => {
            let liveEvents = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.key = doc.id;
                liveEvents.push(data);
            });
            observer.next(liveEvents);
        });
    });
}

export function getVisibleLiveEventsList() {
    return new Observable((observer) => {
        liveEventsRef.onSnapshot((querySnapshot) => {
            let liveEvents = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.key = doc.id;
                if (data.isVisible === 'yes') {
                    liveEvents.push(data);
                }
            });
            observer.next(liveEvents);
        });
    });
}

export function createLiveEvents(data) {
    data.createdOn = new Date();
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        liveEventsRef.add(data).then((doc) => {
            observer.next({
                key: doc.id,
            });
        });
    });
};

export function toggleActivateDeactivateEvents(data, action) {
    data.createdOn = data.createdOn || new Date();
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        liveEventsRef.doc(data.id).update({ 'isVisible': action }).then(() => {
            observer.next();
        });
    });
}

export function deleteEventsByKey(id) {
    return new Observable((observer) => {
        liveEventsRef.doc(id).delete().then(() => {
            console.log("Event successfully deleted!");
            observer.next({ deleted: true });
        }).catch((error) => {
            console.error("Error removing Event: ", error);
            observer.next({ deleted: false, error: error });
        });
    });
}
