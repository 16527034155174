import React, { useState, useEffect, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useStoreConsumer } from "../../Providers/StateProvider";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import "./CompetitionsDetails.scss";
import EnrollCompetition from "../EnrollCompetition";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { setActiveCompetition } from "../../Actions/Competition";
import { enableLoginFlow } from "../../Actions/LoginFlow";
import { getUploadedVideosByUserId } from "../../Services/UploadedVideo.service";
import { GetUserByCompId } from "../../Services/CompSelectedUser.service";
import { formatDate, formatTime } from "../../Services/Utils";
import VideoUploader from "../VideoUploader";
import { NOTIFICATION_ERROR, INDIAN_HIP_HOP } from "../../Constants";
import { serverTimeStamp } from "../../Services/Other.service";
import { displayNotification } from "../../Actions/Notification";
import Loader from "../Loader";
//activestep 1 === Competition details
//activestep 2 === User submitted competition details if already enrolled
//activestep 3 === Video selection
//activestep 4 === final age group and video submission for competition

export default function CompetitionsDetails({
  isTesting,
  open,
  handleClose,
  initialStep,
}) {
  const { state, dispatch } = useStoreConsumer();
  const uploaderRef = useRef(null);
  const history = useHistory();
  const competitionDetails = state.activeCompetition;
  const loggedInUser = state.loggedInUser;
  const [userUploadedVdos, setUserUploadedVideoList] = useState([]);
  const tncRef = useRef();
  const [TnC, setTnC] = useState(false);
  const [isTncAccepted, tncAcceptedValue] = useState(false);
  const [ActiveStep, setActiveStep] = useState(initialStep || 1);
  const [isVideoSelected, toggleVideoSelect] = useState(false);
  const [selectedVideoData, setVideoData] = useState(null);
  const [disableSubmitVdoButton, setDisableSubmitVdoButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [VdoUploadDateLimit, setVdoUploadDateLimit] = useState(null);
  const [serverDateTime, setServerDateTime] = useState();
  const [SelectedVideo, setSelectedVideo] = useState({
    title: "",
    desc: "",
    file: null,
    enrolledCompetition: null,
  });
  const [isLoaderActive, toggleLoading] = useState(false);
  const [videoUploadStatus, setVideoUploadStatus] = useState("optional");
  const [isRejectMessageOn, toggleRejecteMessageModal] = useState(false);
  const [rejectedMessage, setRejectMessage] = useState("");
  const [compTitle, setCompTitle] = useState(
    competitionDetails.name.toLowerCase()
  );

  useEffect(() => {
    setCompTitle(competitionDetails.name.toLowerCase());
    const compNameParam = competitionDetails.name.toLowerCase().split(' ').join('-');
    let params = new URLSearchParams();

    params.append('comp', compNameParam);
    history.push({ search: params.toString()});
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [competitionDetails.name]);

  function isCurrentDateMatchStartDate(startDate) {
    const myDate = new Date(startDate);

    const startDateDay = new Date(myDate).getDate();
    const startDateMonth = new Date(myDate).getMonth() + 1;
    const startDateYear = new Date(myDate).getFullYear();

    const todayISODay = new Date(serverDateTime).getDate();
    const todayISOMonth = new Date(serverDateTime).getMonth() + 1;
    const todayISOYear = new Date(serverDateTime).getFullYear();

    return (
      new Date(`${todayISOYear}-${todayISOMonth}-${todayISODay}`) <
      new Date(`${startDateYear}-${startDateMonth}-${startDateDay}`)
    );
  }

  function isEndDateExpired(endDate) {
    const myDate = new Date(endDate);
    myDate.setDate(myDate.getDate() + parseInt(1));
    const endDateDay = new Date(myDate).getDate();
    const endDateMonth = new Date(myDate).getMonth() + 1;
    const endDateYear = new Date(myDate).getFullYear();

    const todayISODay = new Date(serverDateTime).getDate();
    const todayISOMonth = new Date(serverDateTime).getMonth() + 1;
    const todayISOYear = new Date(serverDateTime).getFullYear();

    return (
      new Date(`${todayISOYear}-${todayISOMonth}-${todayISODay}`) >=
      new Date(`${endDateYear}-${endDateMonth}-${endDateDay}`)
    );
  }

  useEffect(() => {
    if (competitionDetails) {
      setVideoUploadStatus(competitionDetails.shouldVideoUpload);

      // TODO: This end date logic will use later
      let vdoUploadUpto = new Date(competitionDetails.startAt);
      new Date(vdoUploadUpto.setDate(vdoUploadUpto.getDate() + 30));
      let displayDate =
        formatDate(vdoUploadUpto, 3) + " " + formatTime(vdoUploadUpto);
      if (loggedInUser.key && competitionDetails?.isUserEnrolled) {
        tncAcceptedValue(true);
      }
      setVdoUploadDateLimit(displayDate);
      const servTime = serverTimeStamp();
      setServerDateTime(servTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionDetails]);

  useEffect(() => {
    loggedInUser.email &&
      loggedInUser.phone &&
      ActiveStep === 3 &&
      userUploadedVdos.length === 0 &&
      getUploadedVideosByUserId(loggedInUser.key).subscribe((vdoList) => {
        if (vdoList && vdoList.length) {
          vdoList.forEach((uploadedVdo) => {
            if (competitionDetails.isUserEnrolled) {
              if (
                uploadedVdo?.key ===
                competitionDetails?.userSubmitedDetails?.vdo?.key
              ) {
                uploadedVdo.isSelected = true;
                let updatedCompetition = competitionDetails;
                updatedCompetition.selectedVideo = uploadedVdo;
                dispatch(setActiveCompetition(updatedCompetition));
                setDisableSubmitVdoButton(true);
              }
            } else if (
              state.currentLoginFlow === "profile-competition" &&
              state.activeVideoForCompetition === uploadedVdo?.key
            ) {
              // if user come from profile page by clicking upload for competition
              uploadedVdo.isSelected = true;
              let updatedCompetition = competitionDetails;
              updatedCompetition.selectedVideo = uploadedVdo;
              dispatch(setActiveCompetition(updatedCompetition));
              setVideoData(uploadedVdo);
              toggleVideoSelect(false);
            }
          });
          setUserUploadedVideoList(vdoList);
        } else {
          // if user not uploaded any video then set upload new tab active
          setActiveTabInVdoSelection(2);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ActiveStep]);

  const setActiveTabInVdoSelection = (tab) => {
    const tabsLinks = Array.from(document.querySelectorAll(".tab-links"));
    const tabsBoxes = Array.from(
      document.querySelectorAll(".js-inner-tab-box")
    );
    let currentTab = tab === 1 ? "tab-1" : "tab-2";
    tabsBoxes.forEach((item) => {
      if (item.getAttribute("id") === currentTab) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
    tabsLinks.forEach((item) => {
      if (item?.dataset?.id === currentTab) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  };

  function handleClickOutside(event) {
    if (tncRef && tncRef.current && !tncRef.current.contains(event.target)) {
      setTnC(false);
    }
  }

  const selectVdo = (e, vdo) => {
    e.preventDefault();
    e.stopPropagation();
    if (vdo && vdo?.key && vdo?.key.length) {
      toggleVideoSelect(false);
      if (vdo?.enrolledCompetition !== competitionDetails.key) {
        vdo.enrolledCompetition = competitionDetails.key;
      }
      setVideoData(vdo);
    }
    if (
      competitionDetails.isUserEnrolled &&
      competitionDetails?.userSubmitedDetails?.vdo?.key === vdo?.key
    ) {
      setDisableSubmitVdoButton(true);
      let updatedCompetition = competitionDetails;
      delete updatedCompetition.selectedVideo;
      dispatch(setActiveCompetition(updatedCompetition));
    } else setDisableSubmitVdoButton(false);
    let updatedVdos = userUploadedVdos;
    updatedVdos.forEach((item) => {
      if (item.key === vdo.key) {
        item.isSelected = true;
        let updatedCompetition = competitionDetails;
        updatedCompetition.selectedVideo = item;
        dispatch(setActiveCompetition(updatedCompetition));
      } else item.isSelected = false;
    });
    setUserUploadedVideoList([...updatedVdos]);
  };

  function toggleTabination(event) {
    event.stopPropagation();

    const getCurrentData = event.target.getAttribute("data-id");

    const tabsLinks = Array.from(document.querySelectorAll(".tab-links"));
    const tabsBoxes = Array.from(
      document.querySelectorAll(".js-inner-tab-box")
    );

    tabsLinks.forEach((item) => {
      item.classList.remove("active");
    });
    event.target.classList.add("active");
    tabsBoxes.forEach((item) => {
      if (item.getAttribute("id") === getCurrentData) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  }

  const proceedForLogin = () => {
    handleClose();
    dispatch(enableLoginFlow({ type: "competition" }));
    history.push({
      pathname: "/login",
      state: null,
    });
  };

  async function onChangeFile(event) {
    event.preventDefault();
    var file = event.target.files[0];
    if (file) {
      if (file.size > 52428800) {
        alert("File is too big!");
        dispatch(
          displayNotification({
            msg: "File is too big!",
            type: NOTIFICATION_ERROR,
            time: 3000,
          })
        );
        setSelectedVideo({ ...SelectedVideo, file: null });
        uploaderRef.current.click();
      } else {
        setSelectedVideo({ ...SelectedVideo, file: null });
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setSelectedVideo({ ...SelectedVideo, file: reader.result });
          dispatch(enableLoginFlow({ type: "competition-uploadvdo" }));
          // handleClose();
          // open = false;
        };
        reader.onerror = (error) => console.error(error);
      }
    }
  }

  const handleVdoUploadResponse = (value) => {
    if (value) {
      toggleLoading(true);
      try {
        getUploadedVideosByUserId(loggedInUser.key).subscribe((vdoList) => {
          if (vdoList) {
            setActiveTabInVdoSelection(1);
            vdoList.forEach((uploadedVdo) => {
              if (competitionDetails.isUserEnrolled) {
                if (
                  uploadedVdo.key ===
                  competitionDetails.userSubmitedDetails.vdo.key
                ) {
                  uploadedVdo.isSelected = true;
                  let updatedCompetition = competitionDetails;
                  updatedCompetition.selectedVideo = uploadedVdo;
                  dispatch(setActiveCompetition(updatedCompetition));
                  setDisableSubmitVdoButton(true);
                }
              }
            });
            toggleLoading(false);
            setUserUploadedVideoList(vdoList);
          }
        });
      } catch (e) {
        toggleLoading(false);
        console.log("Error in get uploaded video: ", e);
      }
    }
  };

  function acceptTnC(event) {
    const isTncChecked = event.target.checked;
    tncAcceptedValue(isTncChecked);
  }

  function setActiveVideoActiveStep(step, action) {
    if (
      (selectedVideoData?.key && selectedVideoData?.key.length) ||
      action === "skip"
    ) {
      toggleVideoSelect(false);
      setActiveStep(step);
    } else {
      toggleVideoSelect(true);
    }
  }

  function setCompSteps(event, step) {
    if (loggedInUser?.email) {
      if (event && event.target.parentElement) {
        event.target.parentElement.classList.add("loading");
      }
      try {
        GetUserByCompId(competitionDetails.key).subscribe((resp) => {
          if (event && event.target.parentElement) {
            event.target.parentElement.classList.remove("loading");
          }
          if (resp?.length) {
            const isUserPresent = resp.some(
              (item) => item.email === loggedInUser.email
            );
            if (isUserPresent) {
              setActiveStep(step);
            } else {
              if (competitionDetails?.rejectMessage) {
                toggleRejecteMessageModal(true);
                setRejectMessage(competitionDetails.rejectMessage);
              } else {
                toggleRejecteMessageModal(false);
                setRejectMessage("");
              }
            }
          } else {
            setActiveStep(step);
          }
        });
      } catch (e) {
        if (event && event.target.parentElement) {
          event.target.parentElement.classList.remove("loading");
        }
        console.log("user check failed: ", e);
      }
    } else {
      setActiveStep(step);
    }
  }

  function sectionScrolled(event) {
    event.stopPropagation();
    if (event?.currentTarget?.scrollTop > 5) {
      event?.currentTarget.classList.add("scrolled");
    } else {
      event?.currentTarget.classList.remove("scrolled");
    }
  }

  // this function will extract links, phone and email form text and convert them into tags
  function linksTextToTag(text, linkTitle) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const emailRegex = /([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/gi;
    const phoneRegex =
      /((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}/g;

    // conversion of links
    let convertedText = text.replace(urlRegex, (url) => {
      const title = linkTitle || url;
      return (
        '<a target="_blank" title="' +
        title +
        '" href="' +
        url +
        '">' +
        url +
        "</a>"
      );
    });

    // conversion of phone numbers
    convertedText = convertedText.replace(phoneRegex, (url) => {
      return '<a title="reach us" href="tel: ' + url + '">' + url + "</a>";
    });

    return convertedText.replace(emailRegex, (url) => {
      return '<a title="reach us" href="mailto: ' + url + '">' + url + "</a>";
    });
  }

  const getDiscountedPrice = (fee, discount) => {
    if (fee && discount) {
      return fee - Math.round((fee * discount) / 100);
    }
  };

  return (
    <div>
      <Loader value={isLoaderActive} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="competition-modal-box"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <div className="outer-modal-wrap">
            {
              <div className="inner-modal-wrap">
                <IconButton
                  className="close-modal-btn"
                  onClick={() => {
                    handleClose();
                    state.activeCompetition &&
                      !state.currentLoginFlow &&
                      dispatch(setActiveCompetition(null));
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {(ActiveStep === 3 || ActiveStep === 4) && (
                  <IconButton
                    className="close-modal-btn back-step-btn"
                    onClick={() =>
                      setCompSteps(
                        null,
                        videoUploadStatus && videoUploadStatus === "off"
                          ? ActiveStep - 2
                          : ActiveStep - 1
                      )
                    }
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <h2 id="title">{competitionDetails.name}</h2>
                {(ActiveStep === 1 || ActiveStep === 2) && (
                  <div>
                    <div className="image-contentWrap">
                      <div className="image-wrap">
                        <img
                          src={competitionDetails.bannerImg}
                          alt={competitionDetails.name}
                        />
                      </div>
                      <div
                        className="about-competition-wrap"
                        onScroll={(e) => sectionScrolled(e)}
                      >
                        <div className="sub-titles">
                          {!(
                            compTitle.includes("passes") ||
                            compTitle.includes("tickets")
                          )
                            ? "Championship Details"
                            : "Details"}{" "}
                        </div>
                        <p
                          id="description"
                          className="breakInnerContent"
                          dangerouslySetInnerHTML={{
                            __html: linksTextToTag(competitionDetails?.desc),
                          }}
                        ></p>

                        {/* <div className="sub-titles">Age Category</div>
                        <ul>
                          <li>Age 4 to 13 years</li>
                          <li>Age 14 to 17 years</li>
                          <li>Age 18 and above</li>
                        </ul> */}
                        {competitionDetails.offer ||
                        competitionDetails.merchandise ||
                        competitionDetails.specialOffer ? (
                          <div className="sub-titles exclusiveTexts offerSection">
                            Special offers:
                            {competitionDetails.offer ? (
                              <p
                                className="exclusiveTexts offerSubTexts"
                                id="offer"
                              >
                                -{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: competitionDetails.offer,
                                  }}
                                ></span>
                              </p>
                            ) : (
                              ""
                            )}
                            {competitionDetails.merchandise ? (
                              <p
                                className="exclusiveTexts offerSubTexts"
                                id="merchandise"
                              >
                                - <span>{competitionDetails.merchandise}</span>
                              </p>
                            ) : (
                              ""
                            )}
                            {competitionDetails.specialOffer ? (
                              <p
                                className="exclusiveTexts offerSubTexts"
                                id="specialOffer"
                              >
                                - <span>{competitionDetails.specialOffer}</span>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {competitionDetails.fee &&
                        String(competitionDetails.fee) !== "0" ? (
                          <div className="sub-titles feesBlock" id="fees">
                            {compTitle.includes("passes") ||
                            compTitle.includes("tickets")
                              ? "Fee"
                              : "Registration Fee"}
                            :
                            {competitionDetails?.offerPercent ? (
                              <>
                                <span className="discountedAmt">
                                  <span>&#8377;</span>
                                  <span>
                                    {getDiscountedPrice(
                                      competitionDetails.fee,
                                      competitionDetails?.offerPercent
                                    )}
                                  </span>
                                </span>
                                &nbsp;
                                <del className="orginalAmt">
                                  <span>&#8377;</span>
                                  <span>{competitionDetails.fee}</span>
                                </del>
                                &nbsp;
                                <span className="offertext">
                                  ({competitionDetails?.offer})
                                </span>
                              </>
                            ) : (
                              <span className="highlightAmount">
                                <span>&#8377;</span>
                                <span>{competitionDetails.fee}</span>
                              </span>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {competitionDetails.fee &&
                        String(competitionDetails.fee) !== "0" ? (
                          <div className="start-at sub-titles">
                            {!(
                              compTitle.includes("passes") ||
                              compTitle.includes("tickets")
                            )
                              ? "Registration"
                              : ""}{" "}
                            start date:{" "}
                            <span>{competitionDetails.startingDate}</span>
                            {competitionDetails.name
                              .toLowerCase()
                              .includes(INDIAN_HIP_HOP) &&
                            !(
                              compTitle.includes("passes") ||
                              compTitle.includes("tickets")
                            ) ? (
                              <span>
                                {" "}
                                (NOTE: The registration will close soon once the
                                organisation gets maximum participants)
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {!competitionDetails.name
                          .toLowerCase()
                          .includes(INDIAN_HIP_HOP) &&
                        competitionDetails.fee &&
                        String(competitionDetails.fee) !== "0" ? (
                          <div className="end-at sub-titles">
                            {!(
                              compTitle.includes("passes") ||
                              compTitle.includes("tickets")
                            )
                              ? "Registration"
                              : ""}{" "}
                            last date:{" "}
                            <span>{competitionDetails.endingDate}</span>
                          </div>
                        ) : (
                          ""
                        )}

                        {competitionDetails.shouldVideoUpload !== "off" &&
                        !competitionDetails.name
                          .toLowerCase()
                          .includes(INDIAN_HIP_HOP) &&
                        competitionDetails.fee &&
                        String(competitionDetails.fee) !== "0" ? (
                          competitionDetails &&
                          competitionDetails.isUserEnrolled ? (
                            <div className="sub-titles">
                              You can change/modify video till
                              <span>{competitionDetails.endingDate}</span>
                            </div>
                          ) : (
                            <div className="sub-titles">
                              Upload video till{" "}
                              <span>{competitionDetails.endingDate}</span>
                            </div>
                          )
                        ) : (
                          ""
                        )}

                        {competitionDetails.prices &&
                        competitionDetails.prices.length ? (
                          <div className="sub-titles">Prizes for winners</div>
                        ) : (
                          ""
                        )}
                        <ul className="prices">
                          {competitionDetails.prices &&
                          competitionDetails.prices.length
                            ? competitionDetails.prices.map((item, index) => {
                                if (item.key) {
                                  return (
                                    <li
                                      className="sub-titles price-details"
                                      key={item.key}
                                    >
                                      {item.name} : <span>{item.value}</span>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li
                                      className="sub-titles price-details"
                                      key={`price-item-${index}`}
                                    >
                                      {`${index + 1} ${
                                        index === 0
                                          ? "st"
                                          : index === 1
                                          ? "nd"
                                          : "rd"
                                      }`}{" "}
                                      : <span>{item}</span>
                                    </li>
                                  );
                                }
                              })
                            : ""}
                        </ul>

                        {competitionDetails?.rules ? (
                          <>
                            {!(
                              compTitle.includes("passes") ||
                              compTitle.includes("tickets")
                            ) ? (
                              <div className="sub-titles">
                                Rules &amp; Regulations
                              </div>
                            ) : (
                              ""
                            )}
                            <pre
                              className="breakInnerContent"
                              dangerouslySetInnerHTML={{
                                __html: linksTextToTag(
                                  competitionDetails?.rules,
                                  "Rules and regulations"
                                ),
                              }}
                            ></pre>
                          </>
                        ) : (
                          ""
                        )}

                        {competitionDetails?.judges &&
                        !(
                          compTitle.includes("passes") ||
                          compTitle.includes("tickets")
                        ) ? (
                          <>
                            <div className="sub-titles">Judges Panel</div>
                            <pre className="breakInnerContent">
                              {competitionDetails?.judges}
                            </pre>
                          </>
                        ) : (
                          ""
                        )}

                        {!(
                          compTitle.includes("passes") ||
                          compTitle.includes("tickets")
                        ) ? (
                          <h4 className="before-submit-message breakInnerContent">
                            This is your chance to showcase your talent. Show us
                            what you’ve got!!
                          </h4>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {competitionDetails?.startAt &&
                    !isCurrentDateMatchStartDate(competitionDetails.startAt) &&
                    !isEndDateExpired(competitionDetails.endAt) ? (
                      <div className="action-wrap">
                        {loggedInUser.key &&
                          competitionDetails?.isUserEnrolled && (
                            <p className="alreadyEnrolledMessage">
                              You are already enrolled for this compeition
                            </p>
                          )}
                        <div
                          className="terms-button"
                          ref={tncRef}
                          onClick={() => setTnC(TnC ? false : true)}
                        >
                          <input
                            type="checkbox"
                            checked={isTncAccepted}
                            title="accept terms and condition"
                            onChange={(e) =>
                              console.log("event: ", isTncAccepted)
                            }
                            onClick={(e) => acceptTnC(e)}
                          />
                          Terms &amp; Conditions
                          {TnC && (
                            <div
                              className={`tool-tip-wrap ${
                                loggedInUser.username ? "loggedInToolTip" : ""
                              }`}
                            >
                              <p className="closeToolTip">
                                <CloseIcon />
                              </p>
                              {competitionDetails?.tnc ? (
                                <div
                                  className="innerTncSection"
                                  dangerouslySetInnerHTML={{
                                    __html: competitionDetails?.tnc,
                                  }}
                                ></div>
                              ) : (
                                <>
                                  <div>
                                    You may not be able to attend the live
                                    session if you are late.
                                  </div>
                                  <div>
                                    You may face interruptions during the course
                                    of the live stream due to internet
                                    connectivity issues.
                                  </div>
                                  <div>
                                    Show details and the artist lineup are
                                    subject to change as per the artist’s
                                    discretion.
                                  </div>
                                  <div>
                                    {" "}
                                    No refunds on purchased tickets are
                                    possible, even in case of any rescheduling.
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        {/* check for user logged in or not */}
                        {loggedInUser.email && loggedInUser.username ? (
                          <div>
                            {!competitionDetails?.isUserEnrolled && (
                              <Button
                                variant="contained"
                                disabled={!isTncAccepted}
                                color="primary"
                                onClick={(e) =>
                                  setCompSteps(
                                    e,
                                    videoUploadStatus &&
                                      videoUploadStatus === "off"
                                      ? 4
                                      : 3
                                  )
                                }
                              >
                                {videoUploadStatus &&
                                (videoUploadStatus === "off" ||
                                  videoUploadStatus === "optional")
                                  ? "Continue"
                                  : "Select Video"}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div>
                            {/* <div>To upload video you need to login first</div> */}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => proceedForLogin()}
                            >
                              Login
                            </Button>
                          </div>
                        )}
                        {loggedInUser.key &&
                          competitionDetails?.isUserEnrolled && (
                            <div className="change-video-wrap">
                              <div>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={(e) => setCompSteps(e, videoUploadStatus &&
                                    videoUploadStatus === "off"
                                    ? 4
                                    : 3)}
                                >
                                  {(videoUploadStatus &&
                                  (videoUploadStatus !== "off" &&
                                  videoUploadStatus !== "optional")) && competitionDetails?.userSubmitedDetails?.vdo
                                    ? "Change Video"
                                    : "Continue"}
                                </Button>
                              </div>
                            </div>
                          )}
                      </div>
                    ) : isEndDateExpired(competitionDetails.endAt) ? (
                      <span className="endLabel">Registration closed</span>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {ActiveStep === 3 && (
                  <div className="video-submit-section">
                    <div className="lessons-vdo-wrap">
                      <div className="tabination-wrap">
                        <div className="tab-btn-section">
                          <button
                            className="tab-links active"
                            data-id="tab-1"
                            onClick={(e) => toggleTabination(e)}
                          >
                            My video list
                          </button>
                          <button
                            className="tab-links"
                            data-id="tab-2"
                            onClick={(e) => toggleTabination(e)}
                          >
                            Upload new
                          </button>
                        </div>
                        <div className="tab-content-wrap">
                          <div
                            id="tab-1"
                            className="inner-box js-inner-tab-box list-box active"
                          >
                            {userUploadedVdos.length ? (
                              <p className="videosMessage">
                                Click/tap on video box to select
                              </p>
                            ) : (
                              <p className="videosMessage noVideoMessage">
                                No videos in the list, click on Upload new
                              </p>
                            )}
                            {userUploadedVdos.length !== 0 &&
                              userUploadedVdos.map((item, index) => {
                                return (
                                  <div
                                    className={
                                      item.isSelected
                                        ? "vdo-outer selected-vdo"
                                        : "vdo-outer"
                                    }
                                    key={index}
                                    onClick={(e) => selectVdo(e, item)}
                                  >
                                    <div className="vdo-wrap">
                                      <VideoPlayer vdoObj={item} />
                                    </div>
                                    <div className="video-title">
                                      {item.title}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div
                            id="tab-2"
                            className="inner-box js-inner-tab-box new-upload-box"
                          >
                            <div className="input-upload-wrap">
                              <input
                                accept="video/mp4,video/x-m4v,video/*"
                                ref={uploaderRef}
                                onChange={(e) => onChangeFile(e)}
                                type="file"
                                id="video-upload"
                                title="upload video for competition"
                              />
                              <i className="upload-icon">
                                <FaCloudUploadAlt />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!disableSubmitVdoButton ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={
                          videoUploadStatus && videoUploadStatus === "optional"
                            ? "multiButtonGroup"
                            : ""
                        }
                        onClick={() => setActiveVideoActiveStep(4)}
                      >
                        Upload video
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled
                        className={
                          videoUploadStatus && videoUploadStatus === "optional"
                            ? "multiButtonGroup"
                            : ""
                        }
                        color="primary"
                      >
                        Already selected
                      </Button>
                    )}
                    {videoUploadStatus && videoUploadStatus === "optional" ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={
                          videoUploadStatus && videoUploadStatus === "optional"
                            ? "multiButtonGroup"
                            : ""
                        }
                        onClick={() => setActiveVideoActiveStep(4, "skip")}
                      >
                        Skip
                      </Button>
                    ) : (
                      ""
                    )}
                    {isVideoSelected ? (
                      <p className="error_message">
                        Please select a video from top list or upload a new
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {ActiveStep === 4 && (
                  <div>
                    <EnrollCompetition
                      isTesting={isTesting}
                      handleClose={(e) => handleClose(e)}
                      changeSelectedVdo={() => setCompSteps(null, 3)}
                    />
                  </div>
                )}
                {SelectedVideo?.file && (
                  <VideoUploader
                    selectedVdo={SelectedVideo}
                    competitionKey={competitionDetails.key}
                    handleVdoUploadResponse={(e) => handleVdoUploadResponse(e)}
                  />
                )}
                {isCurrentDateMatchStartDate(competitionDetails.startAt) ? (
                  <span className="upcomingEventMessage">
                    Upcoming Event, we'll notify you once active!
                  </span>
                ) : isEndDateExpired(competitionDetails.endDate) ? (
                  <span className="upcomingEventMessage">
                    The registration for the Event has been closed!
                  </span>
                ) : (
                  ""
                )}
                {isRejectMessageOn && rejectedMessage ? (
                  <div className="rejectedMessageForComp">
                    <IconButton
                      className="close-modal-btn"
                      onClick={() => toggleRejecteMessageModal(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                    <div
                      className="innerMessage"
                      dangerouslySetInnerHTML={{ __html: rejectedMessage }}
                    ></div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            }
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
