const Lessons = [
    {
        title: 'Ghungroo Song Dance Tutorial',
        category: 'Free Style',
        desc: "Bollywood song dance performance of Ghungroo Song By Rupesh Sir",
        videos: [
            {
                title: "Ghungroo Song Dance Tutorial",
                desc: "Bollywood song dance performance of Ghungroo Song By Rupesh Sir",
                active: true,
                length: 24,
                levels: ['Bigginer', 'Intermediate', 'Professional'],
                fUrl: 'https://firebasestorage.googleapis.com/v0/b/boogalusite.appspot.com/o/uploads%2Flessons%2F1%2Fsample%20video%20-%20rupesh%20FRONT%20normal%20view.mp4?alt=media&token=595c51fe-4c7e-4dbb-b9d9-f198623adbd5',//front vdo url
                bUrl: 'https://firebasestorage.googleapis.com/v0/b/boogalusite.appspot.com/o/uploads%2Flessons%2F1%2Fsample%20video%201%20-%20rupesh%20BACK%20normal%20view.mp4?alt=media&token=18665ce7-55d9-47a9-8d13-88b55f43c17d',//back vdo url
                fMUrl: 'https://firebasestorage.googleapis.com/v0/b/boogalusite.appspot.com/o/uploads%2Flessons%2F1%2Fsample%20video%201%20-%20RUPESH%20FRONT%20mirror%20VIEW.mp4?alt=media&token=2b82bfbb-5add-4727-a391-a08ccb89a026',//front mirror url
                bMUrl: 'https://firebasestorage.googleapis.com/v0/b/boogalusite.appspot.com/o/uploads%2Flessons%2F1%2Fsample%20video%201%20-%20BACK%20mirror%20VIEW.mp4?alt=media&token=6b6a1e4e-01b0-4a32-8d79-6f18f9ad7a6b',//back mirror url
                roundUrl: 'https://www.youtube.com/embed/dM1ghaspLyc',//360 vdo url
                instructor: '12345'
            }
        ]
    }
]
export default Lessons;