import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import { disableLoginFlow, enableLoginFlow } from "../../Actions/LoginFlow";
import { useStoreConsumer } from "../../Providers/StateProvider";
import ImageCarousel from "../ImageCarousel";
import { useHistory } from "react-router-dom";
import GenericInfoModal from "../genericInfoModal";
import { timeConvert24To12 } from "../../helpers";
import {
  getVisibleLiveEventsList,
  getLiveEventsList,
} from "../../Services/LiveEvents.service";
import { getUserById } from "../../Services/User.service";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import * as $ from "jquery";
import { decode } from "string-encode-decode";

function LiveEventPage(props) {
  const { isCallByNativeApp, mode } = props;
  const history = useHistory();
  const { state, dispatch } = useStoreConsumer();
  const loggedInUser = state.loggedInUser;
  const [liveEventData, setLiveEventsData] = useState([]);
  const [isLoaderActive, toggleLoading] = useState(false);
  const [liveEventLink, loadLiveEventLink] = useState(null);
  const [liveEventSection, enableLiveEventSection] = useState(false);
  const [isInfoModalOpen, enableInfoModal] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [fullScreenMode, toggleFullScreenMode] = useState(false);

  useEffect(() => {
    $("html,body").animate(
      {
        scrollTop: 0,
      },
      700
    );

    toggleLoading(true);

    if (state.currentLoginFlow === "live-events") {
      dispatch(disableLoginFlow());
    }

    try {
      if (mode && mode === "test") {
        getLiveEventsList().subscribe((events) => {
          toggleLoading(false);
          if (events && events.length) {
            setLiveEventsData(events);
          }
        });
      } else {
        getVisibleLiveEventsList().subscribe((events) => {
          toggleLoading(false);
          if (events && events.length) {
            setLiveEventsData(events);
          }
        });
      }
    } catch (e) {
      toggleLoading(false);
      console.log("live event load issue: ", e);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function eventImageClicked(data, event) {
    if (event) {
      event.stopPropagation();
    }
    if (loggedInUser.key) {
      loadEventOnClicked(data);
    } else {
      if (isCallByNativeApp && window.location.href.includes("token=")) {
        const userToken = window.location.href.split("?")[1].split("token=")[1];
        if (userToken) {
          const decodedUserValue = decode(userToken);
          toggleLoading(true);
          if (decodedUserValue) {
            getUserById(decodedUserValue).subscribe((resp) => {
              if (resp.key === decodedUserValue) {
                toggleLoading(false);
                loadEventOnClicked(data);
              } else {
                redirectToLogin(data);
              }
            });
          } else {
            redirectToLogin(data);
          }
        } else {
          redirectToLogin(data);
        }
      } else {
        redirectToLogin(data);
      }
    }
  }

  function redirectToLogin(data) {
    toggleLoading(false);
    dispatch(enableLoginFlow({ type: "live-events", data: data }));
    history.push({
      pathname: "/login",
      state: null,
    });
  }

  function loadEventOnClicked(data) {
    if (shouldEventLive(data)) {
      loadLiveEventLink(data.videoLink);
      enableLiveEventSection(true);
    } else {
      enableInfoModal(true);
      const get12HourTime = timeConvert24To12(
        new Date(data.startTime).toLocaleTimeString().substr(0, 5)
      );
      const infoMessage = `This event will go live on ${new Date(
        data.startDate
      ).toDateString()} at ${get12HourTime}. Till then stay tuned to Da.`;
      setInfoModalMessage(infoMessage);
    }
  }

  function shouldEventLive(data) {
    const newDate = new Date();
    const today = newDate.toLocaleDateString();
    const currentTime = newDate.toLocaleTimeString().substr(0, 5);
    return (
      new Date(data.startDate).toLocaleDateString() === today &&
      new Date(data.startTime).toLocaleTimeString().substr(0, 5) <= currentTime
    );
  }

  function closeInfoModal() {
    enableInfoModal(false);
  }

  function closeEventModal() {
    enableLiveEventSection(false);
  }

  function triggerFullScreen(e) {
    const fullScreenWrapper = document.getElementById("js-liveIframe");

    fullScreenWrapper.addEventListener("fullscreenchange", (event) => {
      if (!document.fullscreenElement) {
        toggleFullScreenMode(false);
      }
    });

    if (!document.fullscreenElement) {
      toggleFullScreenMode(true);
      if (fullScreenWrapper.requestFullscreen) {
        fullScreenWrapper.requestFullscreen();
      } else if (fullScreenWrapper.webkitRequestFullscreen) {
        /* Safari */
        fullScreenWrapper.webkitRequestFullscreen();
      } else if (fullScreenWrapper.msRequestFullscreen) {
        /* IE11 */
        fullScreenWrapper.msRequestFullscreen();
      }
    } else {
      toggleFullScreenMode(false);
      document.exitFullscreen();
    }
  }

  return (
    <div className={`liveEventWrap ${isCallByNativeApp ? "inNativeApp" : ""}`}>
      <Loader value={isLoaderActive} />
      <div className="innerContainer">
        {liveEventData && liveEventData.length ? (
          <h1>Our Live Events</h1>
        ) : (
          <h1 className="noLiveEventHeading">
            No live events at this moment, however, stay connected for
            upcomings!
          </h1>
        )}
        {liveEventData && liveEventData.length ? (
          <ImageCarousel
            carouselData={liveEventData}
            imgClickCallback={eventImageClicked}
          />
        ) : (
          ""
        )}
        {liveEventSection ? (
          <div className="liveEventSection">
            <p
              className="closeModal"
              title="close event"
              onClick={() => closeEventModal()}
            ></p>
            <div className="videoIframe" id="js-liveIframe">
              <iframe
                src={`https://www.youtube.com/embed/${liveEventLink}`}
                title="events youtube vidoes"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div className="fullScreenIcons">
                {fullScreenMode ? (
                  <FullscreenExitIcon
                    title="Fullscreen mode"
                    className="vdo-controlls fullScreenToggleIcon"
                    variant="contained"
                    type="submit"
                    onClick={(e) => triggerFullScreen(e)}
                  />
                ) : (
                  <FullscreenIcon
                    title="Exit fullscreen"
                    className="vdo-controlls fullScreenToggleIcon"
                    variant="contained"
                    type="submit"
                    onClick={(e) => triggerFullScreen(e)}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {isInfoModalOpen ? (
        <GenericInfoModal
          message={infoModalMessage}
          status="info"
          shouldHaveAction={false}
          confirmUserAction={closeInfoModal}
          closeInfoModal={closeInfoModal}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default LiveEventPage;
