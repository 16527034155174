import { Observable } from "rxjs";
import axios from "axios";
const { REACT_APP_API_URL } = process.env;
const header = new Headers();
header.append("Access-Control-Allow-Origin", "*");
header.append("Content-Type", "application/json");
header.append("mode", "cors");

export function sendEmail(data) {
  // const EMAIL_API_URL = 'http://localhost:5001/boogalusite/us-central1/sendAppEmails';
  const EMAIL_API_URL = REACT_APP_API_URL + "/sendAppEmails";
  const emailBody = {
    unique: "1666327297890",
    from: "info@appda.in",
    to: data.mailTo,
    subject: data.title,
    content: data.content,
  };
  return new Observable((observer) => {
    axios.post(EMAIL_API_URL, emailBody, header).then((response) => {
      observer.next(response);
    });
  });
}
