import React from "react";
import defaultImage from "../../Images/dance-studio.jpg";

const StudioCard = (props) => {
  const { studio } = props;
  const { name, photos, studioURL, key } = studio;
  return (
    <a className="studioCard" href={`/studios/${studioURL}/${key}`}>
      <img
        alt={name}
        src={
          photos?.length && photos[0].id === "previewImage"
            ? photos[0].downloadURL
            : defaultImage
        }
      />
      <div className="name">{name}</div>
    </a>
  );
};

export default StudioCard;
