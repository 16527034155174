import axios from "axios";
import { Observable } from "rxjs";
import { POSTAL_PINCODE_API } from "../Constants";

export function getPostOfficeDataByPincode(pincode) {
  return new Observable((observer) => {
    axios.get(`${POSTAL_PINCODE_API}${pincode}`).then((response) => {
      console.log("getPostalCodesByLocation API response", response);
      console.log(
        "getPostalCodesByLocation API response?.data[0]",
        response?.data[0]
      );
      if (
        response?.status === 200 &&
        response?.data[0].Status === "Success" &&
        response?.data[0].PostOffice &&
        response?.data[0].PostOffice.length > 0
      ) {
        observer.next(response?.data[0]);
      } else {
        observer.next({ message: "No Records found!", PostOffice: [] });
      }
    });
  });
}
