import React, { useRef } from 'react';
import { truncateLargeText } from "../../helpers";
import { timeStampToNewDate } from '../../Services/Utils';
import Loader from "../Loader";

const EnrolledCompMembers = (props) => {
    const { totalEnrolledMembers, isListLoadingOverlay, emailConfirmation } = props;

    const enrollUserInputRef = useRef(null);
    let compData = null;

    if (totalEnrolledMembers.length) {
        compData = {
            id: totalEnrolledMembers[0].compId,
            name: totalEnrolledMembers[0].compName,
            isLive: null,
            isTesting: null
        }
    }

    function closeModal(action) {
        props.closeEnrollModal(action);
    }

    function renderMembers(members) {
        return members.map(item => {
            return (
                <div className='listMembers'>
                    <p>{item.name}</p>
                    <p>{item.email}</p>
                    <p>{item.phone}</p>
                    {
                        item.age ? 
                        <p><strong>Age:</strong> {item.age}</p>
                        : ''
                    }
                </div>
            )
        });
    }

    function sendMailToUser(user) {
        const userRequiredData = {
            email: user.email,
            compId: user.compId,
            phone: user.phone,
            enrolledDate: user.enrolledDate
        }

        props.sendMailToUser(userRequiredData);
    }

    function searchUser(event) {
        event.stopPropagation();
        if (enrollUserInputRef?.current) {
            enrollUserInputRef.current.classList.remove('error');
            if (enrollUserInputRef.current?.value) {
                props.searchUserByInput(compData, enrollUserInputRef.current.value);
            } else {
                setTimeout(() => {
                    enrollUserInputRef.current.classList.add('error');
                }, 1);
            }
        }
    }
    
    function resetSearch(event) {
        event.stopPropagation();
        if (enrollUserInputRef?.current) {
            enrollUserInputRef.current.classList.remove('error');
            if (enrollUserInputRef.current?.value) {
                enrollUserInputRef.current.value = '';
                props.searchUserByInput(compData, null);
            }
        }
    }

    return (
        <div className='enrolledMemberOverlay'>
            {
                isListLoadingOverlay ? 
                <Loader value={isListLoadingOverlay} />
                : ''
            }
            <div className='innerBox'>
                {
                    emailConfirmation.message ?
                    <div className={`emailNotification ${emailConfirmation.status}`}>{emailConfirmation.message}</div> : ''
                }
                <p className="closeUserModal" title="close modal" onClick={() => closeModal('close')}></p>
                {
                    totalEnrolledMembers.length ?
                        <h3>Enrolled users in <span>{truncateLargeText(totalEnrolledMembers[0].compName, 40)}</span></h3>
                        : ''
                }
                <div className="enrollUserInput">
                    <input
                        className="enroll-input-field"
                        id="enrolledUser"
                        ref={enrollUserInputRef}
                        title="Enter enrolled user email or phone"
                        placeholder="Search user by Email or Phone"
                    />
                    <button className="searchBtn" title="click to search enrolled user" onClick={(e) => searchUser(e)}>Search</button>
                    <button className="searchBtn resetBtn" title="click to reset search" onClick={(e) => resetSearch(e)}>Reset</button>
                </div>
                <div className="usersListWrap">
                    <table>
                        <thead>
                            <tr>
                                <th>Sr. no.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Category(if any)</th>
                                <th>Crew name(if any)</th>
                                <th>Crew leader(if any)</th>
                                <th>Crew Place(if any)</th>
                                <th>Members(if any)</th>
                                <th>Merchandise</th>
                                <th>Enrolled Date</th>
                            </tr>
                        </thead>
                        {totalEnrolledMembers && totalEnrolledMembers.length ? (
                            <tbody className="dataTbody">
                                {
                                    // eslint-disable-next-line array-callback-return
                                    totalEnrolledMembers.map((item, index) => {
                                        if (item?.role !== "admin") {
                                            return (
                                                <tr key={`user-item-${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td className='emailBlock'>
                                                        <span>
                                                            {item.email || "N/A"}
                                                        </span>
                                                         <button onClick={() => sendMailToUser(item)}>Send mail</button>
                                                    </td>
                                                    <td>{item.phone || "N/A"}</td>
                                                    <td>{item.category ? item.category.name : "N/A"}</td>
                                                    <td>{item.category?.crewName ? item.category?.crewName : "N/A"}</td>
                                                    <td>{item.category?.crewLeader ? item.category?.crewLeader : "N/A"}</td>
                                                    <td>{item.category?.crewLocation ? item.category?.crewLocation : "N/A"}</td>
                                                    <td>{(item.category && item.category.members?.length) ? renderMembers(item.category.members) : "N/A"}</td>
                                                    <td>{item.buyMerchandise || item?.category?.merchandise ? 'Bought merchandise' : "N/A"}</td>
                                                    <td>{item.enrolledDate ? new Date(timeStampToNewDate(item.enrolledDate)).toDateString() : "N/A"}</td>
                                                </tr>
                                            );
                                        }
                                    })
                                }
                            </tbody>
                        ) : (
                            
                            totalEnrolledMembers.length < 1 ?
                            <tbody className="emptyTbody">
                                <tr>
                                    <td>
                                        <p>
                                            It seems no one yet enrolled for this Competition!
                                        </p>
                                    </td>
                                </tr>
                            </tbody> : '' 
                        )}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default EnrolledCompMembers;
