import { Observable } from 'rxjs';
import { formatDate, formatTime, timeStampToNewDate } from "./Utils";
import db from '../Database';

const competitionRef = db.collection('test_competitions');

export function getTestCompetitionsList() {
    return new Observable((observer) => {
        competitionRef.onSnapshot((querySnapshot) => {
            let cat = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                if (data.isTesting) {
                    data.key = doc.id;
                    let startingDate = (data?.startAt?.seconds || data?.startAt?.nanoseconds) ? timeStampToNewDate(data?.startAt) : new Date(data.startAt);
                    data.startingDate = formatDate(startingDate, 3);
                    data.startingTime = formatTime(startingDate);
                    let endingDate = (data?.endAt?.seconds || data?.endAt?.nanoseconds) ? timeStampToNewDate(data?.endAt) : new Date(data.endAt);
                    data.endingDate = formatDate(endingDate, 3);
                    data.endingTime = formatTime(endingDate);
                    cat.push(data);
                }
            });
            cat.sort((a, b) => a.index - b.index);
            observer.next(cat);
        });
    });
}

export function getActiveTestCompetitionsList() {
    return new Observable((observer) => {
        competitionRef.onSnapshot((querySnapshot) => {
            let cat = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                if (data.isTesting) {
                    let startingDate = (data?.startAt?.seconds || data?.startAt?.nanoseconds) ? timeStampToNewDate(data?.startAt) : new Date(data.startAt);
                    data.startingDate = formatDate(startingDate, 3);
                    data.startingTime = formatTime(startingDate);
                    let endingDate = (data?.endAt?.seconds || data?.endAt?.nanoseconds) ? timeStampToNewDate(data?.endAt) : new Date(data.endAt);
                    data.endingDate = formatDate(endingDate, 3);
                    data.endingTime = formatTime(endingDate);
                    data.key = doc.id;
                    cat.push(data);
                }
            });
            cat.sort((a, b) => a.index - b.index);
            observer.next(cat);
        });
    });
}

export function getTestCompetition(id) {
    return new Observable((observer) => {
        competitionRef.doc(id).get().then((doc) => {
            let data = doc.data();
            if (data && data.isTesting) {
                data.key = doc.id;
                let startingDate = (data?.startAt?.seconds || data?.startAt?.nanoseconds) ? timeStampToNewDate(data?.startAt) : new Date(data.startAt);
                data.startingDate = formatDate(startingDate, 3);
                data.startingTime = formatTime(startingDate);
                let endingDate = (data?.endAt?.seconds || data?.endAt?.nanoseconds) ? timeStampToNewDate(data?.endAt) : new Date(data.endAt);
                data.endingDate = formatDate(endingDate, 3);
                data.endingTime = formatTime(endingDate);
            } else {
                data = null;
            }
            observer.next(data);
        });
    });
}

export function saveTestCompetition(data) {
    data.createdOn = new Date();
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        competitionRef.add(data).then((doc) => {
            observer.next({
                key: doc.id,
            });
        });
    });
};

export function toggleActivateDeactivateTestCompetition(data, action) {
    data.createdOn = data.createdOn || new Date();
    data.modifiedOn = new Date();
    return new Observable((observer) => {
        competitionRef.doc(data.id).update({ 'active': action }).then(() => {
            observer.next();
        });
    });
}

export function updatePrevTestCompetition(data) {
    data.createdOn = data.createdOn || new Date();
    data.modifiedOn = new Date();
    const id = data.key;
    delete data.key;
    return new Observable((observer) => {
        competitionRef.doc(id).update(data).then(() => {
            observer.next();
        });
    });
}

export function deleteTestCompetitionByKey(id) {
    return new Observable((observer) => {
        competitionRef.doc(id).delete().then(() => {
            console.log("Competition successfully deleted!");
            observer.next({ deleted: true });
        }).catch((error) => {
            console.error("Error removing Competition: ", error);
            observer.next({ deleted: false, error: error });
        });
    });
}
