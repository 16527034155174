import React, { useEffect, useState } from 'react';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getActiveCompetitionsList } from "../../Services/Competition.service";

function JudgesFilters(props) {
  const [isLoaderActive, toggleLoading] = useState(true);
  const [comptetionFilters, setComptetitionFilters] = useState(null);
  const [selectedFilterValue, setSelectedFilterValue] = useState(null);
  
    useEffect(() => {
        toggleLoading(true);
        try {
            getActiveCompetitionsList().subscribe( allCompList => {
                toggleLoading(false);
                if (allCompList?.length) {
                    setComptetitionFilters(allCompList);
                }
            });
        } catch(e) {
            console.log('error: ', e);
            toggleLoading(false);
        }
    }, []);

    function closeFilter() {
        props.toggleFilter(false);
    }

    function setFilterValue(event, keyName) {
        const nameFilter = {
            key: keyName,
            value: event.target.value
        };
        setSelectedFilterValue(nameFilter);
    }

    function applyFilter() {
        if (selectedFilterValue) {
            props.setFilter(selectedFilterValue);
        }
    }

    function resetAppliedFilters() {
        setSelectedFilterValue(null);
        props.toggleFilter(false, 'reset');
    }

    return (
        <div className="otherFiltersWrap filtersForJudges">
            <div className="innerBox">
                <p
                    className="close-modal-icon dark"
                    onClick={() => closeFilter()}
                    title="close filter"
                ></p>
                <h3>
                    {
                        isLoaderActive ?
                        'Filters loading..'
                        :
                        'Filter by'
                    }
                </h3>
                <div className="filterInnerWrap">
                    {
                        isLoaderActive ?
                        <div className="spinnerLoader">
                            <div className="loader"></div>
                        </div> :
                        <div className="filterItem">
                            <p className="filterTitle">Competition name</p>
                            <div className="optionsWrap">
                                <RadioGroup
                                    className="radioGroupControls"
                                    aria-label='aria label for competition name'
                                    name='competition'
                                    defaultValue={props?.defaultNameFilterSelection}
                                    onChange={(e) => setFilterValue(e, 'compName')}
                                >
                                    {
                                        comptetionFilters && comptetionFilters?.length ?
                                        comptetionFilters.map( compItem => {
                                            return (
                                                <FormControlLabel
                                                    key={compItem.key}
                                                    value={compItem.name}
                                                    control={<Radio />}
                                                    label={compItem.name}
                                                />
                                            )
                                        }) : ''
                                    }
                                </RadioGroup>
                            </div>
                        </div>
                    }
                </div>
                {
                    !isLoaderActive ?
                    <div className="filterActionWrap">
                        <button
                            className="btn primary-light"
                            onClick={() => resetAppliedFilters()}
                        >
                            reset &amp; cancel
                        </button>
                        <button
                            className="btn primary-dark"
                            onClick={() => applyFilter()}
                        >
                            Apply
                        </button>
                    </div> : ''
                }
            </div>
        </div>
    )
}

export default JudgesFilters;
