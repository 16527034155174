/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getOfflineStudioById } from "../../Services/OfflineStudios.service";
import {
  ADMIN_EMAIL_STAGING,
  NOTIFICATION_SUCCCESS,
  NOTIFICATION_ERROR,
  OFFLINE_STUDIO_LOCATION,
  PERSONAL_CLASS_DETAILS,
} from "../../Constants";
import { InputLabel } from "@material-ui/core";
import parse from "html-react-parser";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useStoreConsumer } from "../../Providers/StateProvider";
import {
  enrollOfflineStudio,
  postOrder,
} from "./../../Services/Razorpay.service";
import { displayNotification } from "../../Actions/Notification";
import Loader from "../Loader";
import ImageCarousel from "../ImageCarousel";
import { sendEmail } from "../../Services/Email.service";
import { enrolledOfflineStudio } from "../../Services/EnrollOfflineStudio.service";
import WhatsAppIcon from "../../Images/icon-whatsapp.svg";
import { isObjectEmpty } from "../../helpers";
import { enableLoginFlow } from "../../Actions/LoginFlow";

const SelectedStudio = () => {
  const { state, dispatch } = useStoreConsumer();
  const loggedInUser = state.loggedInUser;
  const isUserLoggedIn = loggedInUser && Object.keys(loggedInUser).length > 0;
  const [studioDetails, setStudioDetails] = useState({});
  const [studioGallery, setStudioGallery] = useState([]);
  const history = useHistory();
  const [advanceFilters, setAdvanceFilters] = useState();
  const [activeCategory, setActiveCategory] = useState("");
  const [activeCategoryType, setActiveCatgoryType] = useState("");
  const [termsCondtions, setTermsCondtions] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const locationPath = history.location.pathname.split("/");
  const studioKey = locationPath[locationPath.length - 1];
  const studioInformationTabRef = useRef(null);
  const studioGalleryTabRef = useRef(null);
  const [isStudioGalleryTab, toggleCreateList] = useState(true);
  const [enrolledStudioDetails, setEnrolledStudioDetails] = useState(null);
  const [isTermsConditionClicked, toggleTncView] = useState(false);
  const [activeTab, setActiveTab] = useState("studioInformation");

  let activePackage = null;
  const switchTabs = (action) => {
    if (action && action === "studioInformation") {
      if (studioInformationTabRef.current && studioGalleryTabRef.current) {
        studioInformationTabRef.current.classList.add("active");
        studioGalleryTabRef.current.classList.remove("active");
      }
      toggleCreateList(true);
    } else {
      if (studioInformationTabRef.current && studioGalleryTabRef.current) {
        studioGalleryTabRef.current.classList.add("active");
        studioInformationTabRef.current.classList.remove("active");
      }
      toggleCreateList(false);
    }
  };

  function goToPrevious(event) {
    event.stopPropagation();
    event.preventDefault();
    history.goBack();
  }

  const enrollHandler = () => {
    if (isUserLoggedIn) {
      const { name, key } = studioDetails;
      const amount = parseInt(
        studioDetails?.fees -
          Number(studioDetails?.fees) *
            (Number(studioDetails?.offerPercentage) / 100)
      );
      const filteredImages = studioDetails?.photos.filter(
        (image) => image.id === "previewImage"
      );
      const enrollData = {
        amount: amount * 100,
        currency: "INR",
        receipt: loggedInUser?.key,
      };
      // console.log({ enrollData });
      let orderObj = {};
      orderObj[enrollData?.type] = enrollData;
      enrollOfflineStudio(
        orderObj,
        "OfflineStudio",
        "Monthly Subscription",
        loggedInUser,
        afterPaymentResponse(enrollData?.amount)
      ).subscribe((response) => {
        // console.log("enrollOfflineStudio", { response });
      });
    } else {
      history.push({
        pathname: "/login",
        state: null,
      });
    }
  };

  useEffect(() => {
    getOfflineStudioById(studioKey).subscribe((studioData) => {
      const filteredImages = studioData?.photos.filter(
        (image) => image.id !== "previewImage"
      );
      setStudioGallery([...studioData?.videos, ...studioData?.photos]);
      setStudioDetails(studioData);
      setAdvanceFilters(studioData.advancedFilter || []);
      setActiveCategory(filterCategoryWithTypes(studioData.advancedFilter)[0]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function filterCategoryWithTypes(advanceFilters) {
    const filteredData = advanceFilters.filter((item) => item.types.length > 0);
    return filteredData;
  }

  const afterPaymentResponse = (responseData, planType) => {
    if (responseData.error) {
      console.log(
        "afterPaymentResponse : studio payment responseData.error",
        responseData.error
      );
      dispatch(
        displayNotification({
          msg: `Payment for offline studio registration failed.\n\nPlease try after some time!`,
          type: NOTIFICATION_ERROR,
          time: 3000,
        })
      );
    } else {
      setIsLoaderActive(true);
      const currentDate = new Date();
      const studioObj = {
        studioId: studioDetails.key,
        studioName: studioDetails.name,
        studioImg: studioDetails.img ?? "",
        userId: loggedInUser.key,
        razorId: responseData[planType[0]]?.razorpay_payment_id ?? "",
        status: "Submitted",
        totalAmount: responseData[planType[0]].amount,
        createdOn: new Date(),
        modifiedOn: new Date(),
      };
      if (planType[0].includes("Membership")) {
        studioObj.isMembership = true;
        studioObj.packageExpire = new Date(
          currentDate.setMonth(
            currentDate.getMonth() + studioDetails.membershipMonth
          )
        );
      } else {
        const pkgMonths =
          (activePackage.id === "monthly" && 1) ||
          (activePackage.id === "quarterly" && 3) ||
          (activePackage.id === "halfYearly" && 6) ||
          12;
        studioObj.category = {
          id: activeCategory.id,
          label: activeCategory.label,
        };
        studioObj.categoryType = {
          id: activeCategoryType.id,
          label: activeCategoryType.label,
        };
        studioObj.package = activePackage;
        studioObj.packageExpire =
          activePackage.id === "monthly" ||
          activePackage.id === "quarterly" ||
          activePackage.id === "halfYearly" ||
          activePackage.id === "yearly"
            ? new Date(currentDate.setMonth(currentDate.getMonth() + pkgMonths))
            : null;
      }

      // console.log("afterPaymentResponse studioObj", studioObj);
      enrolledOfflineStudioDetails(studioObj);
    }
  };

  const proceedForPayment = (
    totalAmount,
    category,
    categoryType,
    packageDetails
  ) => {
    if (!isObjectEmpty(loggedInUser)) {
      //call razorPay api and payment
      setIsLoaderActive(true);
      // let amount = 25;
      const userData = {
        amount: Number(totalAmount) * 100,
        currency: "INR",
        receipt: loggedInUser.key,
      };

      let orderObj = {};
      const type = `${category.label}-${categoryType.label}-${packageDetails.label}`;
      orderObj[type] = userData;

      try {
        postOrder(
          orderObj,
          [type],
          "Offline Studio Enrollment",
          loggedInUser,
          afterPaymentResponse
        ).subscribe(
          (response) => {
            setIsLoaderActive(false);
          },
          (e) => {
            setIsLoaderActive(false);
            console.log("Error: ", e);
          }
        );
      } catch (e) {
        setIsLoaderActive(false);
        console.log("Error: ", e);
      }
    } else {
      dispatch(enableLoginFlow({ type: window.location.pathname, data: null }));
      history.push({
        pathname: "/login",
        state: null,
      });
    }
  };

  const sendEmailToAdmin = (studioObj, enrolledId) => {
    return new Promise((resolve, reject) => {
      let emailBody = "";
      if (studioObj.isMembership) {
        emailBody = `<div>
   <h6 style="font-size: 17px;margin-bottom: 26px;">Offline Studio ${
     studioObj.studioName
   }</h6>
    <h4>User Details -</h4>
    <h4>Name:- ${
      loggedInUser != null && loggedInUser?.name != null
        ? loggedInUser?.name
        : ""
    }</h4>
    <h4>Email:- ${loggedInUser?.email}</h4>
    <h4>Phone:- ${`+91 ${loggedInUser.phone}`}</h4>
    <h4>Amount Received:- ${studioObj.totalAmount / 100}</h4>
    <h4>Enrolled Id:- ${enrolledId}</h4>
    <h4>Razor Id:- ${studioObj.razorId}</h4>
    <h4>Studio Membership: ${studioDetails?.membershipMonth} Month</h4>
    <br/>
    </div>`;
      } else {
        emailBody = `<div>
    <h6 style="font-size: 17px;margin-bottom: 26px;">Offline Studio ${
      studioObj.studioName
    }</h6>
    <h4>User Details -</h4>
    <h4>Name:- ${
      loggedInUser != null && loggedInUser?.name != null
        ? loggedInUser?.name
        : ""
    }</h4>
    <h4>Email:- ${loggedInUser?.email}</h4>
    <h4>Phone:- ${`+91 ${loggedInUser.phone}`}</h4>
    <h4>Amount Received:- ${studioObj.totalAmount / 100}</h4>
    <h4>Enrolled Id:- ${enrolledId}</h4>
    <h4>Razor Id:- ${studioObj.razorId}</h4>
    <h4>Studio Category: ${studioObj.category.label ?? ""}</h4>
    <h4>Studio Category Type: ${studioObj.categoryType.label ?? ""}</h4>
    <h4>Studio Package: ${studioObj.package.label ?? ""}</h4>
    <br/>
    </div>`;
      }

      let payload = {
        mailTo: ADMIN_EMAIL_STAGING,
        title: "User subscribed",
        content: emailBody,
      };
      // console.log("sendEmailToAdmin", payload)
      // resolve()
      try {
        sendEmail(payload).subscribe((res) => {
          if (!("error" in res)) {
            resolve();
            console.log("Admin Email Send Successfully.");
          } else console.log("Admin Email Send Failed.");
        });
      } catch (error) {
        console.log("Admin Email Send Failed.", error);
        resolve();
      }
    });
  };

  const sendEmailToUser = (studioObj, enrolledId) => {
    return new Promise((resolve, reject) => {
      let emailBody = "";
      if (studioObj.isMembership) {
        emailBody = `<div>
          <h2>Offline Studio ${studioObj.studioName}</h2>
          <br/>
  
          <h3 style="color: #4bb543;">Congratulations!!</h3>
          <h4>Enrollment No.:- ${enrolledId}</h4>
            <p>We are pleased to inform you that your registration is confirmed</p>
            <p>We have successfully received your payment. We will be sending you booking details within 24 hours.</p>
            <p>If you have any queries, you can contact our support team</p>
            <br/>
  
            <br/>
          
            <h4>User Details -</h4>
            <h4>Name:- ${
              loggedInUser != null && loggedInUser?.name != null
                ? loggedInUser?.name
                : ""
            }</h4>
            <h4>Email:- ${loggedInUser?.email}</h4>
      <h4>Phone:- ${`+91 ${loggedInUser.phone}`}</h4>
      <h4>Amount Received:- ${studioObj.totalAmount / 100}</h4>
            <h4>Studio Membership: ${studioDetails?.membershipMonth} Month</h4>
            <br/>
  
          <p style="margin-top: 30px;">If you have any queries,you can contact our support team</p>
          <p><strong>Email : <a href="mailto: ${ADMIN_EMAIL_STAGING}">${ADMIN_EMAIL_STAGING}</a></strong></p>
          `;
      } else {
        emailBody = `<div>
        <h2>Offline Studio ${studioObj.studioName}</h2>
        <br/>
  
        <h3 style="color: #4bb543;">Congratulations!!</h3>
        <h4>Enrollment No.:- ${enrolledId}</h4>
          <p>We are pleased to inform you that your registration is confirmed</p>
          <p>We have successfully received your payment. We will be sending you booking details within 24 hours.</p>
          <p>If you have any queries, you can contact our support team</p>
          <br/>
  
          <br/>
        
          <h4>User Details -</h4>
          <h4>Name:- ${
            loggedInUser != null && loggedInUser?.name != null
              ? loggedInUser?.name
              : ""
          }</h4>
          <h4>Email:- ${loggedInUser?.email}</h4>
        <h4>Phone:- ${`+91 ${loggedInUser.phone}`}</h4>
      <h4>Amount Received:- ${studioObj.totalAmount / 100}</h4>
      <h4>Enrolled Id:- ${enrolledId}</h4>
      <h4>Razor Id:- ${studioObj.razorId}</h4>
      <h4>Studio Category: ${studioObj.category.label ?? ""}</h4>
      <h4>Studio Category Type: ${studioObj.categoryType.label ?? ""}</h4>
      <h4>Studio Package: ${studioObj.package.label ?? ""}</h4>
          <br/>
  
        <p style="margin-top: 30px;">If you have any queries,you can contact our support team</p>
        <p><strong>Email : <a href="mailto: ${ADMIN_EMAIL_STAGING}">${ADMIN_EMAIL_STAGING}</a></strong></p>
        `;
      }
      let payload = {
        mailTo: loggedInUser.email,
        title: "Da subscription successfull",
        content: emailBody,
      };
      // console.log("sendEmailToUser", payload)
      // resolve()
      try {
        sendEmail(payload).subscribe((res) => {
          resolve();
          if (!("error" in res)) {
            console.log("Email to user Send Successfully.");
          } else console.log("Email to user Send Failed.");
        });
      } catch (error) {
        console.log("User Email Send Failed.", error);
        resolve();
      }
    });
  };

  const enrolledOfflineStudioDetails = (studioObj) => {
    try {
      enrolledOfflineStudio(studioObj).subscribe((res) => {
        let enrolledId = res.key;
        setEnrolledStudioDetails(studioObj);
        // console.log('enrolledOfflineStudioRef doc:', enrolledId);
        let promiseAll = [
          sendEmailToAdmin(studioObj, enrolledId),
          sendEmailToUser(studioObj, enrolledId),
        ];
        Promise.all(promiseAll).finally((values) => {
          setActiveTab("studioInformation");
        });
        setIsLoaderActive(false);
        dispatch(
          displayNotification({
            msg: `Payment for offline studio registration recieved successfully.\n\nYou can see confirmation on mail!`,
            type: NOTIFICATION_SUCCCESS,
            time: 3000,
          })
        );
      });
    } catch (error) {
      let emailBody = `
      <html>
        <body>
          <p>studioId: ${studioObj.studioId}</p>
          <p>studioName: ${studioObj.studioName}</p>
          <p>RazorId: ${studioObj.razorId}</p>
          <p>User Id: ${loggedInUser.key}</p>
          <p>User Name: ${loggedInUser?.name ?? ""}</p>
          <p>Studio Category: ${studioObj.category?.label ?? ""}</p>
          <p>Studio Category Type: ${studioObj.categoryType?.label ?? ""}</p>
          <p>Studio Package: ${studioObj.package?.label ?? ""}</p>
          <br>
          <p>Error: ${JSON.stringify(error)}</p>
        </body>
      </html>
      `;

      let payload = {
        mailTo: loggedInUser.email,
        title: "Da subscription successfull",
        content: emailBody,
      };
      // console.log("sendEmailToAdmin:Error", payload)
      // sendEmail(payload).subscribe((res) => {
      //   if (!("error" in res)) {
      //     console.log("Email to user Send Successfully.");
      //   } else console.log("Email to user Send Failed.");
      // });

      // setTimeout(() => {
      //   const data1 = {
      //     type: 'Offline Studio Registration',
      //     to: ADMIN_EMAIL_STAGING,
      //     "subject": `Error after enrolled for Offline Studio Registration`,
      //     "content": emailContent,
      //     totalAmount: studioObj.totalAmount,
      //     studioId: studioObj.studioId
      //   }
      //   OtherConstant.showConsoleLog('-----selectedUsersMailToAdminForCompErrorFirebaseRef data1 hitt:', data1)
      //   selectedUsersMailToAdminForOfflineStudioErrorFirebaseRef.add(data1).then((doc) => { });;
      // }, 0);

      // const raw = JSON.stringify({
      //   "unique": ServiceMethod.sendEmailUnique,
      //   "to": ADMIN_EMAIL_STAGING,
      //   "subject": `Error after enrolled for Offline Studio Registration`,
      //   "content": emailContent
      // });
      // let service = ServiceMethod.baseUrl + servicesEndPoint.firebaseSendEmails;
      // await Fetch(service, {
      //   method: ServiceType.post,
      //   ...OtherConstant.getServiceHeaderData(0),
      //   body: raw
      // })
    }
  };

  const buyMembership = () => {
    if (!isObjectEmpty(loggedInUser)) {
      //call razorPay api and payment
      setIsLoaderActive(true);
      // let amount = 25;
      const userData = {
        amount:
          Number(
            getDiscountedPrice(
              studioDetails.membershipFee,
              studioDetails.membershipOffer
            )
          ) * 100,
        currency: "INR",
        receipt: loggedInUser.key,
      };

      let orderObj = {};
      const type = `Offline Studio Membership Enrollment`;
      orderObj[type] = userData;

      try {
        postOrder(
          orderObj,
          [type],
          "Offline Studio Membership Enrollment",
          loggedInUser,
          afterPaymentResponse
        ).subscribe(
          (response) => {
            setIsLoaderActive(false);
          },
          (e) => {
            setIsLoaderActive(false);
            console.log("Error: ", e);
          }
        );
      } catch (e) {
        setIsLoaderActive(false);
        console.log("Error: ", e);
      }
    } else {
      dispatch(enableLoginFlow({ type: window.location.pathname, data: null }));
      history.push({
        pathname: "/login",
        state: null,
      });
    }
  };

  const onClickPackage = (category, categoryType, packageDetails, tnc) => {
    activePackage = packageDetails;
    const total =
      packageDetails.discount !== "" && packageDetails.discount !== "0"
        ? getDiscountedPrice(packageDetails?.price, packageDetails.discount) +
          " "
        : packageDetails?.price;
    const pCategory = {
      id: category?.id ?? "",
      label: category?.label ?? "",
    };
    const pCategoryType = {
      id: categoryType.id ?? "",
      label: categoryType.label ?? "",
    };

    if (tnc) {
      if (termsCondtions) {
        proceedForPayment(total, pCategory, pCategoryType, packageDetails);
      } else {
        dispatch(
          displayNotification({
            msg: "Please agree terms & conditions",
            type: NOTIFICATION_ERROR,
            time: 3000,
          })
        );
      }
    } else {
      proceedForPayment(total, pCategory, pCategoryType, packageDetails);
    }
  };

  const getDiscountedPrice = (fee, discount) => {
    if (fee && discount) {
      return (
        Number(fee) - Number(Math.round((Number(fee) * Number(discount)) / 100))
      );
    }
  };

  function eventImageClicked(data, event) {
    if (event) {
      event.stopPropagation();
    }
  }

  function getLocationLabel(location) {
    let locationLabel = location;
    if (location) {
      OFFLINE_STUDIO_LOCATION.forEach((item) => {
        if (item.value === location) {
          locationLabel = item.label;
        }
      });
    }

    return locationLabel;
  }

  return (
    <div className="studiosWrapper">
      <div className="innerSection">
        <div className="flexChild previewImageContainer">
          {studioDetails?.photos && studioDetails?.photos?.length > 0 && (
            <div className="imageWrap">
              <i
                onClick={(e) => goToPrevious(e)}
                className="arrow-back-home"
                title="Back to list"
              >
                <ArrowBackIcon />
              </i>
              <ImageCarousel
                carouselData={studioGallery}
                dataFrom="studio"
                imgClickCallback={eventImageClicked}
              />
            </div>
          )}
          <div className="studioNameContainer">
            <div className="leftSide">
              <div className="studioName">
                <h1>{studioDetails?.name}</h1>
                {studioDetails?.location ? (
                  <span className="studioLocation">
                    ({getLocationLabel(studioDetails.location)})
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="studioAddressContainer">
                <p>{studioDetails?.addressLineOne}</p>
                <p>{studioDetails?.addressLineTwo}</p>
                <p>{studioDetails?.addressPincode}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flexChild tabSectionWrap">
          <div className="optionsTab">
            <p
              onClick={() => setActiveTab("studioInformation")}
              className={`tabItem ${
                activeTab === "studioInformation" ? "active" : ""
              }`}
            >
              Studio Information
            </p>
            <p
              onClick={() => setActiveTab("personalInstructor")}
              className={`tabItem ${
                activeTab === "personalInstructor" ? "active" : ""
              }`}
            >
              {PERSONAL_CLASS_DETAILS.title}
            </p>
          </div>
          <div className="studioDescriptionContainer">
            {activeTab === "studioInformation" ? (
              <>
                <h2>Studio basic details</h2>
                {studioDetails?.membershipFee ? (
                  <div className="studioDescriptionRow">
                    <div className="studioInformation fullWidth">
                      <InputLabel>Membership</InputLabel>
                      <div className="membership-details">
                        {enrolledStudioDetails?.isMembership ? (
                          <div className="aleradyMember">Already Member</div>
                        ) : (
                          <div className="buyMembershipWrap">
                            <div className="detail">
                              <span>
                                ₹
                                {getDiscountedPrice(
                                  studioDetails?.membershipFee,
                                  studioDetails?.membershipOffer
                                )}
                              </span>
                              <span className="discounted">
                                {studioDetails?.membershipFee}
                              </span>
                              <span className="offPercent">
                                {studioDetails?.membershipOffer}% OFF
                              </span>
                            </div>
                            <div className="detail">
                              <p>
                                Validity: {studioDetails?.membershipMonth}{" "}
                                <span className="monthDetail">
                                  {studioDetails?.membershipMonth > 1
                                    ? "months"
                                    : "month"}
                                </span>
                              </p>
                            </div>
                            {studioDetails?.membershipDesc ? (
                              <div
                                className="offerDesc"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    studioDetails?.membershipDesc.toLowerCase(),
                                }}
                              />
                            ) : (
                              ""
                            )}
                            <button
                              className="btn new-blue-primary membershipBtnWrap"
                              onClick={buyMembership}
                            >
                              Buy Membership
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="studioDescriptionRow">
                  <div className="studioInformation fullWidth">
                    <InputLabel>Select your program</InputLabel>
                    {advanceFilters?.length ? (
                      <>
                        <div className="studio-styles-wrap">
                          <div className="studio-style-details">
                            {advanceFilters.map((category, fIndex) => {
                              return (
                                <React.Fragment key={fIndex}>
                                  {category.valid && (
                                    <div
                                      onClick={() => {
                                        setActiveCategory(category);
                                        setActiveCatgoryType(null);
                                        activePackage = null;
                                      }}
                                      className={`style ${
                                        activeCategory?.id === category?.id
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      {category?.label}
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>

                          <div className="style-type-list">
                            {advanceFilters?.length &&
                              advanceFilters.map((category, fIndex) => {
                                return (
                                  <React.Fragment key={fIndex}>
                                    {category?.label ===
                                      activeCategory?.label && (
                                      <>
                                        {category.types.map(
                                          (categoryType, fTIndex) => {
                                            return (
                                              <React.Fragment key={fTIndex}>
                                                {categoryType.valid && (
                                                  <div
                                                    onClick={() => {
                                                      setActiveCatgoryType(
                                                        categoryType
                                                      );
                                                      activePackage = null;
                                                    }}
                                                    className={`style-type ${
                                                      activeCategoryType?.label ===
                                                      categoryType?.label
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                  >
                                                    <span>
                                                      {categoryType?.label}
                                                    </span>
                                                  </div>
                                                )}
                                                {categoryType?.label ===
                                                  activeCategoryType?.label && (
                                                  <div className="styleDetailsOverlay">
                                                    <div className="style-type-details">
                                                      <p
                                                        className="closeModal"
                                                        title="Close category modal"
                                                        onClick={() =>
                                                          setActiveCatgoryType(
                                                            null
                                                          )
                                                        }
                                                      />
                                                      <div className="styleBasicInfo">
                                                        {categoryType?.styleVideo ||
                                                        categoryType?.styleDesc ? (
                                                          <div className="videoDescription flexChild">
                                                            {categoryType?.styleVideo ? (
                                                              <iframe
                                                                title={`categoryType?.label-${categoryType.styleVideo}`}
                                                                src={`https://www.youtube.com/embed/${categoryType.styleVideo}?autoplay=1&mute=1`}
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {categoryType?.styleDesc ? (
                                                              <>
                                                                <InputLabel className="styleDescLabel">
                                                                  Style
                                                                  description
                                                                </InputLabel>
                                                                <div
                                                                  className="styleDescInfo"
                                                                  dangerouslySetInnerHTML={{
                                                                    __html:
                                                                      categoryType?.styleDesc,
                                                                  }}
                                                                />
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                        <div className="otherPaymentInfo flexChild">
                                                          <div className="timings-wrap">
                                                            <InputLabel>
                                                              {
                                                                categoryType.label
                                                              }{" "}
                                                              session timings
                                                            </InputLabel>
                                                            {categoryType.timing.map(
                                                              (
                                                                timing,
                                                                tIndex
                                                              ) => {
                                                                return (
                                                                  <div
                                                                    key={tIndex}
                                                                    className="timing"
                                                                  >
                                                                    <div className="day">
                                                                      {
                                                                        timing?.label
                                                                      }
                                                                    </div>
                                                                    <div className="time">
                                                                      {
                                                                        timing?.opensAt
                                                                      }{" "}
                                                                      -{" "}
                                                                      {
                                                                        timing?.closesAt
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                            {studioDetails?.termsAndConditions ? (
                                                              <div
                                                                className="tnc-wrap"
                                                                onClick={() =>
                                                                  setTermsCondtions(
                                                                    !termsCondtions
                                                                  )
                                                                }
                                                              >
                                                                <div
                                                                  className={`check ${
                                                                    termsCondtions
                                                                      ? "active"
                                                                      : ""
                                                                  }`}
                                                                />
                                                                <span
                                                                  onClick={() =>
                                                                    toggleTncView(
                                                                      !isTermsConditionClicked
                                                                    )
                                                                  }
                                                                  className="tncText"
                                                                >
                                                                  Terms &
                                                                  Conditions
                                                                </span>
                                                                {isTermsConditionClicked ? (
                                                                  <div
                                                                    className="termsText"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html:
                                                                        studioDetails?.termsAndConditions,
                                                                    }}
                                                                  />
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </div>
                                                          <div className="style-package-wrap">
                                                            <InputLabel>
                                                              Package details
                                                            </InputLabel>
                                                            {categoryType.packages.map(
                                                              (
                                                                packageDetails,
                                                                pIndex
                                                              ) => {
                                                                return (
                                                                  <React.Fragment
                                                                    key={pIndex}
                                                                  >
                                                                    {packageDetails.valid && (
                                                                      <>
                                                                        <div
                                                                          className="package-details"
                                                                          onClick={() =>
                                                                            onClickPackage(
                                                                              category,
                                                                              categoryType,
                                                                              packageDetails,
                                                                              studioDetails?.termsAndConditions
                                                                            )
                                                                          }
                                                                        >
                                                                          <div className="pricing">
                                                                            ₹
                                                                            {getDiscountedPrice(
                                                                              packageDetails.price,
                                                                              packageDetails.discount
                                                                            )}{" "}
                                                                            <span>
                                                                              {" "}
                                                                              <span className="discounted">
                                                                                ₹
                                                                                {
                                                                                  packageDetails.price
                                                                                }{" "}
                                                                              </span>
                                                                              <br />
                                                                              <span>
                                                                                For{" "}
                                                                                {
                                                                                  packageDetails?.label
                                                                                }
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div className="button-wrap">
                                                                            Enroll
                                                                          </div>
                                                                        </div>
                                                                      </>
                                                                    )}
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>Studio Styles not available</>
                    )}
                  </div>
                </div>

                <div className="studioDescriptionRow directionRow">
                  {studioDetails?.timings?.length ? (
                    <div className="studioInformation fullWidth">
                      <InputLabel>Studio Timing</InputLabel>
                      {studioDetails?.timings.map((timing) => {
                        return (
                          <div className="studioTimingWrap" key={timing?.id}>
                            <div className="timingRow">
                              <span>{timing?.label}</span>
                              <span>
                                {timing?.opensAt} to {timing?.closesAt}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <>
                <h2>{PERSONAL_CLASS_DETAILS.label}</h2>
                <div className="studioDescriptionRow directionRow">
                  <div className="studioInformation fullWidth">
                    <p>
                      For more information on personal instructor, please click
                      on below form link and fill the form.
                    </p>
                    <a
                      href={PERSONAL_CLASS_DETAILS.formLink}
                      title="fill the form"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {PERSONAL_CLASS_DETAILS.label}
                    </a>
                  </div>
                </div>
              </>
            )}

            {studioDetails?.termsAndConditions ? (
              <div className="studioDescriptionRow">
                <div className="studioInformation fullWidth">
                  <InputLabel>Terms and Conditions</InputLabel>
                  <div>{parse(studioDetails?.termsAndConditions)}</div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="studioDescriptionRow contactDetails directionRow">
              {studioDetails?.phoneNumber ? (
                <div className="studioInformation phoneNumberWrap">
                  <InputLabel>Chat for more details</InputLabel>
                  <a
                    aria-label="Chat on WhatsApp"
                    target="_blank"
                    rel="noreferrer"
                    title="chat with us"
                    className="whatsappLink"
                    href={`https://wa.me/${studioDetails?.phoneNumber}`}
                  >
                    {" "}
                    {studioDetails?.phoneNumber}{" "}
                    <img alt="Chat on WhatsApp" src={WhatsAppIcon} />
                  </a>
                </div>
              ) : (
                ""
              )}
              {studioDetails?.emailAddress ? (
                <div className="studioInformation">
                  <InputLabel>Mail us your queries</InputLabel>
                  <a href={`mailto:${studioDetails?.emailAddress}`}>
                    {studioDetails?.emailAddress}
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Loader value={isLoaderActive} />
    </div>
  );
};

export default SelectedStudio;
