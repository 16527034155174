import React, { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ImageUploader from "react-images-upload";
import Button from "@material-ui/core/Button";
import boogaluLogo from "../../Images/da logo.png";
import { FaPowerOff } from "react-icons/fa";
import { uploadImage } from "../../Services/Upload.service";
import { Link } from "react-router-dom";
import { ADMIN_PWD } from "../../Constants";
import Navigation from "./Navigation";
import {
  saveCompetition,
  getCompetition,
  getCompetitionsList,
  toggleActivateDeactivateCompetition,
  deleteCompetitionByKey,
  updatePrevCompetition,
  getActiveCompetitionsList,
  getInActiveCompetitionsList,
} from "../../Services/Competition.service";
import { getEnrolledCompetitionByCompId } from "../../Services/EnrollCompetition.service";
import { getUserById, getUserByEmail } from "../../Services/User.service";
import { SaveSelectedUser } from "../../Services/CompSelectedUser.service";
import {
  saveTestCompetition,
  getTestCompetitionsList,
  updatePrevTestCompetition,
  deleteTestCompetitionByKey,
} from "../../Services/TestingCompetition.service";
import {
  getLiveEventsList,
  createLiveEvents,
  toggleActivateDeactivateEvents,
} from "../../Services/LiveEvents.service";
import ActionToolTip from "../ActionTooltip";
import AddMoreInputGroup from "../AddMoreInputGroup";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Loader from "../Loader";
import ConfirmationModal from "../ConfirmationModal";
import { timeConvert24To12 } from "../../helpers";
import AddCompititionCategories from "./addCompititionCategories";
import EnrolledCompMembers from "./enrolledMembers";
import { validateEmailId } from "../../helpers";
import {
  getCompRegisteredUserMails,
  getAdminCredentials,
} from "../../Services/Other.service";
import { sendEmail } from "../../Services/Email.service";
import AdminLogin from "./AdminLogin";

if (localStorage.getItem("adminLoggedIn")) {
  localStorage.removeItem("adminLoggedIn");
}

const checkAdminLogIn = JSON.parse(sessionStorage.getItem("adminLoggedIn"));

export default function Competition() {
  const initialCompetitionData = {
    name: "",
    type: "upcoming",
    desc: "",
    active: false,
    isTesting: false,
    fee: "",
    shouldVideoUpload: "optional",
    img: "",
    rules: "",
    judges: "",
    bannerImg: "",
    bannerMobileImg: "",
    startAt: new Date().toISOString(),
    endAt: new Date().toISOString(),
    prices: [],
    categories: [
      {
        name: "",
        fee: "",
        type: "crew",
        uniqueMemberPercent: 50,
        minMember: 1,
        maxMember: 1,
      },
    ],
    tnc: "",
    rejectMessage: "",
  };

  const initialLiveEventData = {
    name: "",
    videoLink: "",
    isVisible: "no",
    fee: "199",
    img: "",
    bannerImg: "",
    bannerMobileImg: "",
    startDate: new Date().toISOString(),
    startTime: new Date().toISOString(),
  };

  const [isAdminLoggedIn, toggleAdminLogin] = useState(false);
  const [CompetitionData, setCompetitionData] = useState(
    initialCompetitionData
  );
  const [LiveEventsData, setLiveEventData] = useState(initialLiveEventData);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPwd, setAdminPwd] = useState("");
  const [loggedInMessages, setLoginMessage] = useState("");
  const [isCreateFormTab, toggleCreateList] = useState(true);
  const [isListView, toggleListView] = useState(false);
  const [isLiveCreateFormTab, toggleLiveEventForm] = useState(false);
  const [CompetitionsList, setCompetitionsList] = useState(null);
  const [formMessageBox, setFormMessage] = useState("");
  const [messageClass, setFormMessageClass] = useState("");
  const [isSaveLoadingTrue, toggleSaveLoading] = useState(false);
  const [isPageLoaderActive, togglePageLoader] = useState(false);
  const [isCompetitionDeleteClicked, toggleCompetitionModal] = useState(false);
  const [deleteCompetitionMessage, competitionActionMessage] = useState("");
  const [competitionDataToModify, setCompetitionActionData] = useState(null);
  const [isLoaderActive, toggleLoading] = useState(false);
  const [isBannerImageEnable, enableBannerImageSection] = useState(true);
  const [promptAdminPsswordBox, toggleAdminPwdConfirm] = useState(false);
  const [isEditCompFeatureEnable, toggleCompEditFeature] = useState(false);
  const [isFromEditComp, enableEditComp] = useState(false);
  const [adminActionsSelection, setAdminActionSelection] = useState({});
  const [isFromImageUploadFile, setImageUploadFromFile] = useState(false);
  const [enrolledMembersData, setEnrolledMembersData] = useState([]);
  const [shouldEnrolledMemberModal, toggleEnrolledMemberModal] =
    useState(false);
  const [userSelectionModal, toggleUserSelectionModal] = useState(false);
  const [innerBoxLoader, toggleInnerBoxLoader] = useState(false);
  const [selectedUserMessage, setSelectedUserMessage] = useState("");
  const [selectedUserMessageClass, setSelectedUserMessageClass] = useState("");
  const [emailSendResponseStatus, setEmailSentResponse] = useState({
    message: "",
    status: "",
  });

  const createTabRef = useRef(null);
  const categoriesRef = useRef(null);
  const createLiveTabRef = useRef(null);
  const listTabRef = useRef(null);
  const adminConfirmValueRef = useRef(null);
  const adminMessageErrorRef = useRef(null);
  const selectedUserEmailRef = useRef(null);

  const emailSendSuccessData = {
    message: "Email sent successfully to user!",
    status: "success",
  };

  const emailSendErrorData = {
    message: "Email sending failed, please try again in sometime!",
    status: "error",
  };

  useEffect(() => {
    if (checkAdminLogIn) {
      toggleAdminLogin(checkAdminLogIn);
      toggleCompEditFeature(true);
    }
  }, []);

  function setEventStartDateTime(value, type) {
    try {
      if (type === "date") {
        setLiveEventData({ ...LiveEventsData, startDate: value.toISOString() });
      } else {
        setLiveEventData({ ...LiveEventsData, startTime: value.toISOString() });
      }
    } catch (e) {
      console.log("Event date and time errors: ", e);
    }
  }

  function setStartDate(date) {
    try {
      setCompetitionData({ ...CompetitionData, startAt: date.toISOString() });
    } catch (e) {
      console.log("Start date error: ", e);
    }
  }

  function setEndDate(date) {
    try {
      setCompetitionData({ ...CompetitionData, endAt: date.toISOString() });
    } catch (e) {
      console.log("End date error: ", e);
    }
  }

  function setMaxDateSelectionYear() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    return new Date(year + 1, month, day);
  }

  function handleAdminLogin(value, type) {
    if (type === "email") {
      setAdminEmail(value?.target?.value);
    } else {
      setAdminPwd(value?.target?.value);
    }
  }

  function triggerLogin(action) {
    if (action) {
      if (adminEmail === "" || adminPwd === "") {
        setLoginMessage("Please enter Email and Password!");
        toggleAdminLogin(false);
        sessionStorage.setItem("adminLoggedIn", false);
      } else {
        getAdminCredentials().subscribe((resp) => {
          if (resp?.email) {
            if (adminEmail === resp.email && adminPwd === resp.pwd) {
              setLoginMessage("");
              toggleAdminLogin(true);
              sessionStorage.setItem("adminLoggedIn", true);
            } else {
              toggleAdminLogin(false);
              sessionStorage.setItem("adminLoggedIn", false);
              setLoginMessage(
                "Invalid credentials, please enter valid email-Id and Password!"
              );
            }
          }
        });
      }
    }
  }

  function tiggerAdminLogout(event, action) {
    setAdminEmail("");
    setAdminPwd("");
    toggleAdminLogin(action);
    sessionStorage.setItem("adminLoggedIn", action);
    window.location.reload();
  }

  function switchTabs(action) {
    if (action && action === "create") {
      if (
        createTabRef.current &&
        listTabRef.current &&
        createLiveTabRef.current
      ) {
        createTabRef.current.classList.add("active");
        listTabRef.current.classList.remove("active");
        createLiveTabRef.current.classList.remove("active");
      }
      toggleListView(false);
      toggleCreateList(true);
      toggleLiveEventForm(false);
    } else if (action && action === "live") {
      if (
        createTabRef.current &&
        listTabRef.current &&
        createLiveTabRef.current
      ) {
        createLiveTabRef.current.classList.add("active");
        createTabRef.current.classList.remove("active");
        listTabRef.current.classList.remove("active");
      }
      toggleListView(false);
      toggleCreateList(false);
      toggleLiveEventForm(true);
    } else {
      if (createTabRef.current && listTabRef.current) {
        listTabRef.current.classList.add("active");
        createTabRef.current.classList.remove("active");
        createLiveTabRef.current.classList.remove("active");
        getCompetitionsListdata();
      }
      toggleListView(true);
      toggleCompEditFeature(true);
      toggleLiveEventForm(false);
      toggleCreateList(false);
    }
  }

  const getCompetitionsListdata = () => {
    try {
      toggleLoading(true);
      getCompetitionsList().subscribe((allCompList) => {
        try {
          getLiveEventsList().subscribe((events) => {
            try {
              getTestCompetitionsList().subscribe((testingEvents) => {
                toggleLoading(false);
                if (allCompList.length && events.length) {
                  const totalList = [
                    ...testingEvents,
                    ...allCompList,
                    ...events,
                  ];
                  setCompetitionsList(totalList);
                } else if (allCompList.length) {
                  setCompetitionsList(allCompList);
                } else if (events.length) {
                  setCompetitionsList(events);
                } else {
                  setCompetitionsList(null);
                }
              });
            } catch (e) {
              toggleLoading(false);
              console.log("Testing events fetching error: ", e);
            }
          });
        } catch (e) {
          toggleLoading(false);
          console.log("events fetching Error: ", e);
        }
      });
    } catch (e) {
      toggleLoading(false);
      console.log("Error: ", e);
    }
  };

  const getOnlyLiveEvents = () => {
    toggleLoading(true);
    try {
      getLiveEventsList().subscribe((events) => {
        toggleLoading(false);
        if (events.length) {
          setCompetitionsList(events);
        } else {
          setCompetitionsList(null);
        }
      });
    } catch (e) {
      toggleLoading(false);
      console.log("events fetching Error: ", e);
    }
  };

  const getOnlyActiveCompetitions = () => {
    toggleLoading(true);
    try {
      getActiveCompetitionsList().subscribe((comp) => {
        toggleLoading(false);
        if (comp.length) {
          setCompetitionsList(comp);
        } else {
          setCompetitionsList(null);
        }
      });
    } catch (e) {
      toggleLoading(false);
      console.log("active competition fetching Error: ", e);
    }
  };

  const getOnlyInActiveCompetitions = () => {
    toggleLoading(true);
    try {
      getInActiveCompetitionsList().subscribe((comp) => {
        toggleLoading(false);
        if (comp.length) {
          setCompetitionsList(comp);
        } else {
          setCompetitionsList(null);
        }
      });
    } catch (e) {
      toggleLoading(false);
      console.log("inactive competition fetching Error: ", e);
    }
  };

  const handleChange = (prop, index) => (event) => {
    let value = event.target.value;
    if (prop === "active") value = event.target.checked;
    if (prop === "isTesting") value = event.target.checked;
    if (prop === "prices") {
      CompetitionData.prices[index] = event.target.value;
      value = CompetitionData.prices;
    }

    if (prop === "type") {
      enableBannerImageSection(event.target.value === "upcoming");
    }

    setCompetitionData({ ...CompetitionData, [prop]: value });
  };

  const handleLiveEventChange = (prop, index) => (event) => {
    let value = event.target.value;
    setLiveEventData({ ...LiveEventsData, [prop]: value });
  };

  const onimageUpload = (picture) => {
    if (isFromEditComp && !isFromImageUploadFile) {
      setImageUploadFromFile(true);
    }
    setCompetitionData({ ...CompetitionData, img: picture });
  };

  const onBannerImageUpload = (picture) => {
    if (isFromEditComp && !isFromImageUploadFile) {
      setImageUploadFromFile(true);
    }
    setCompetitionData({ ...CompetitionData, bannerImg: picture });
  };

  const onMobileBannerImageUpload = (picture) => {
    if (isFromEditComp && !isFromImageUploadFile) {
      setImageUploadFromFile(true);
    }
    setCompetitionData({ ...CompetitionData, bannerMobileImg: picture });
  };

  const onEventImageUpload = (picture) => {
    if (isFromEditComp && !isFromImageUploadFile) {
      setImageUploadFromFile(true);
    }
    setLiveEventData({ ...LiveEventsData, img: picture });
  };

  const onEventImageUploadBanner = (picture) => {
    if (isFromEditComp && !isFromImageUploadFile) {
      setImageUploadFromFile(true);
    }
    setLiveEventData({ ...LiveEventsData, bannerImg: picture });
  };

  const onEventImageUploadMobile = (picture) => {
    if (isFromEditComp && !isFromImageUploadFile) {
      setImageUploadFromFile(true);
    }
    setLiveEventData({ ...LiveEventsData, bannerMobileImg: picture });
  };

  function setPricesData(e) {
    CompetitionData.prices = e;
  }

  function validatePriceData(priceData) {
    let isValid = true;
    priceData.forEach((item) => {
      if (!Object.values(item).every((o) => o)) {
        isValid = false;
      }
    });
    return isValid;
  }

  function resetEventImages() {
    const uploadImageBtn = document.querySelectorAll(".deleteImage");
    if (uploadImageBtn && uploadImageBtn.length) {
      uploadImageBtn.forEach((ele) => {
        ele.click();
      });
    }
  }

  async function saveLiveEventDetails(e) {
    // upload competition image to bucket
    if (
      LiveEventsData.img === "" ||
      LiveEventsData.bannerImg === "" ||
      LiveEventsData.bannerMobileImg === "" ||
      LiveEventsData.videoLink === "" ||
      LiveEventsData.startDate === "" ||
      LiveEventsData.fee === "" ||
      LiveEventsData.name === "" ||
      LiveEventsData.startTime === ""
    ) {
      setFormMessageClass("error");
      setFormMessage("Please fill all fields!");
    } else if (LiveEventsData.fee.toString() === "0") {
      setFormMessageClass("error");
      setFormMessage("Fees cannot be zero!");
    } else {
      setFormMessageClass("");
      setFormMessage("");
      toggleSaveLoading(true);
      const reader = new FileReader();
      try {
        reader.readAsDataURL(LiveEventsData.img[0]);
        reader.onload = () => {
          uploadImage(reader.result, "event", "small").subscribe(
            (downloadableUrl) => {
              LiveEventsData.img = downloadableUrl;
              // save event data to db with imag url
              reader.readAsDataURL(LiveEventsData.bannerImg[0]);
              reader.onload = () => {
                uploadImage(reader.result, "event", "banner").subscribe(
                  (downloadableUrl) => {
                    LiveEventsData.bannerImg = downloadableUrl;
                    // save event data to db with imag url
                    reader.readAsDataURL(LiveEventsData.bannerMobileImg[0]);
                    reader.onload = () => {
                      uploadImage(
                        reader.result,
                        "event",
                        "bannerMobile"
                      ).subscribe((downloadableUrl) => {
                        LiveEventsData.bannerMobileImg = downloadableUrl;
                        // save event data to db with imag url
                        createLiveEvents(LiveEventsData).subscribe(
                          (response) => {
                            toggleSaveLoading(false);
                            setFormMessageClass("success");
                            setFormMessage("Live event created successfully!");
                            setLiveEventData(initialLiveEventData);
                            resetEventImages();
                          }
                        );
                      });
                    };
                  }
                );
              };
            }
          );
        };
      } catch (e) {
        toggleSaveLoading(false);
        setFormMessageClass("error");
        setFormMessage("Something went wrong, please try after sometime!");
        console.log("Erro: ", e);
      }
    }
  }

  async function saveDetails(e, type) {
    // upload competition image to bucket
    if (
      (isBannerImageEnable
        ? CompetitionData.img === "" &&
          CompetitionData.bannerImg === "" &&
          CompetitionData.bannerMobileImg === ""
        : CompetitionData.img === "") ||
      CompetitionData.desc === "" ||
      CompetitionData.endAt === "" ||
      CompetitionData.name === "" ||
      CompetitionData.startAt === ""
    ) {
      setFormMessageClass("error");
      setFormMessage("Please fill all fields!");
    } else if (
      CompetitionData.prices.length >= 3 &&
      !validatePriceData(CompetitionData.prices)
    ) {
      setFormMessageClass("error");
      setFormMessage("Please enter both name and value for Prices!");
    } else if (
      type &&
      type.toLowerCase() !== "update" &&
      CompetitionData.startAt.split("T")[0] ===
        CompetitionData.endAt.split("T")[0]
    ) {
      setFormMessageClass("error");
      setFormMessage("Competition start and end date connot be same day!");
    } else if (
      categoriesRef.current
        ? !categoriesRef.current.validateCategories()
        : false
    ) {
      setFormMessageClass("error");
      setFormMessage("Please enter category details(Name and Fee)");
    } else {
      setFormMessageClass("");
      setFormMessage("");
      toggleSaveLoading(true);
      if (categoriesRef.current) {
        CompetitionData.categories = categoriesRef.current.getCategories();
      }
      if (
        (!isFromEditComp && !isFromImageUploadFile) ||
        (isFromEditComp && isFromImageUploadFile)
      ) {
        const reader = new FileReader();
        try {
          reader.readAsDataURL(CompetitionData.img[0]);
          reader.onload = () => {
            uploadImage(reader.result, "competition", "small").subscribe(
              (downloadableUrl) => {
                CompetitionData.img = downloadableUrl;
                CompetitionData.startAt =
                  typeof CompetitionData.startAt === "string"
                    ? CompetitionData.startAt
                    : CompetitionData.startAt.toISOString();
                CompetitionData.endAt =
                  typeof CompetitionData.endAt === "string"
                    ? CompetitionData.endAt
                    : CompetitionData.endAt.toISOString();
                // save competition data to db with imag url
                if (isBannerImageEnable) {
                  reader.readAsDataURL(CompetitionData.bannerImg[0]);
                  reader.onload = () => {
                    uploadImage(
                      reader.result,
                      "competition",
                      "banner"
                    ).subscribe((downloadableUrl) => {
                      CompetitionData.bannerImg = downloadableUrl;
                      // save competition data to db with imag url
                      reader.readAsDataURL(CompetitionData.bannerMobileImg[0]);
                      reader.onload = () => {
                        uploadImage(
                          reader.result,
                          "competition",
                          "bannerMobile"
                        ).subscribe((downloadableUrl) => {
                          CompetitionData.bannerMobileImg = downloadableUrl;
                          // save competition data to db with imag url
                          if (type && type.toLowerCase() === "update") {
                            if (CompetitionData.isTesting) {
                              updatePrevTestCompetition(
                                CompetitionData
                              ).subscribe((response) => {
                                toggleSaveLoading(false);
                                setFormMessageClass("success");
                                setFormMessage(
                                  "Competition updated successfully!"
                                );
                                resetCompUpdate();
                              });
                            } else {
                              updatePrevCompetition(CompetitionData).subscribe(
                                (response) => {
                                  toggleSaveLoading(false);
                                  setFormMessageClass("success");
                                  setFormMessage(
                                    "Competition updated successfully!"
                                  );
                                  resetCompUpdate();
                                }
                              );
                            }
                          } else {
                            if (CompetitionData.isTesting) {
                              saveTestCompetition(CompetitionData).subscribe(
                                (response) => {
                                  toggleSaveLoading(false);
                                  setFormMessageClass("success");
                                  setFormMessage(
                                    "Competition created successfully!"
                                  );
                                  setCompetitionData(initialCompetitionData);
                                  resetEventImages();
                                }
                              );
                            } else {
                              saveCompetition(CompetitionData).subscribe(
                                (response) => {
                                  toggleSaveLoading(false);
                                  setFormMessageClass("success");
                                  setFormMessage(
                                    "Competition created successfully!"
                                  );
                                  setCompetitionData(initialCompetitionData);
                                  resetEventImages();
                                }
                              );
                            }
                          }
                        });
                      };
                    });
                  };
                } else {
                  if (type && type.toLowerCase() === "update") {
                    updatePrevCompetition(CompetitionData).subscribe(
                      (response) => {
                        toggleSaveLoading(false);
                        setFormMessageClass("success");
                        setFormMessage("Competition updated successfully!");
                        resetCompUpdate();
                      }
                    );
                  } else {
                    saveCompetition(CompetitionData).subscribe((response) => {
                      toggleSaveLoading(false);
                      setFormMessageClass("success");
                      setFormMessage("Competition created successfully!");
                      setCompetitionData(initialCompetitionData);
                      resetEventImages();
                    });
                  }
                }
              }
            );
          };
        } catch (e) {
          toggleSaveLoading(false);
          setFormMessageClass("error");
          setFormMessage("Something went wrong, please try after sometime!");
          console.log("Erro: ", e);
        }
      } else {
        try {
          if (type && type.toLowerCase() === "update") {
            updatePrevCompetition(CompetitionData).subscribe((response) => {
              toggleSaveLoading(false);
              setFormMessageClass("success");
              setFormMessage("Competition updated successfully!");
              resetCompUpdate();
            });
          } else {
            saveCompetition(CompetitionData).subscribe((response) => {
              toggleSaveLoading(false);
              setFormMessageClass("success");
              setFormMessage("Competition created successfully!");
              setCompetitionData(initialCompetitionData);
              resetEventImages();
            });
          }
        } catch (e) {
          toggleSaveLoading(false);
          setFormMessageClass("error");
          setFormMessage("Something went wrong, please try after sometime!");
          console.log("Erro: ", e);
        }
      }
    }
  }

  function onItemActionSelected(event, value) {
    if (event && event === "deactivate") {
      togglePageLoader(true);
      if (value && value.isLive) {
        try {
          toggleActivateDeactivateEvents(value, "no").subscribe((response) => {
            setAdminActionSelection({});
            togglePageLoader(false);
          });
        } catch (e) {
          togglePageLoader(false);
          console.log("event Error: ", e);
        }
      } else {
        try {
          toggleActivateDeactivateCompetition(value, false).subscribe(
            (response) => {
              setAdminActionSelection({});
              togglePageLoader(false);
            }
          );
        } catch (e) {
          togglePageLoader(false);
          console.log("Error: ", e);
        }
      }
    }

    if (event && event === "activate") {
      togglePageLoader(true);
      if (value && value.isLive) {
        try {
          toggleActivateDeactivateEvents(value, "yes").subscribe((response) => {
            setAdminActionSelection({});
            togglePageLoader(false);
          });
        } catch (e) {
          togglePageLoader(false);
          console.log("event Error: ", e);
        }
      } else {
        try {
          toggleActivateDeactivateCompetition(value, true).subscribe(
            (response) => {
              setAdminActionSelection({});
              togglePageLoader(false);
            }
          );
        } catch (e) {
          togglePageLoader(false);
          console.log("Error: ", e);
        }
      }
    }

    if (event && event === "remove") {
      toggleCompetitionModal(true);
      competitionActionMessage(
        "Are you sure, you want to delete this Competition?"
      );
      setCompetitionActionData(value);
    }
  }

  function closeEnrolledUserModal(action) {
    if (action && action === "close") {
      toggleEnrolledMemberModal(false);
    }
  }

  function confirmItsAdmin(event, value) {
    const adminActionData = {
      event: event,
      value: value,
    };

    if (event) {
      enableEditComp(false);
      switch (event) {
        case "list":
          if (!isEditCompFeatureEnable) {
            toggleCompEditFeature(true);
            break;
          } else {
            break;
          }

        case "participants":
          loadEnrolledParticipants(value);
          break;

        case "edit":
          switchTabs("create");
          enableEditComp(true);
          setCompForUpdate(adminActionData);
          break;

        case "selectionEntry":
          toggleUserSelectionModal(true);
          setAdminActionSelection(adminActionData);
          break;

        case "deactivate":
        case "activate":
        case "remove":
          setAdminActionSelection(adminActionData);
          toggleAdminPwdConfirm(true);
          break;

        default:
          break;
      }
    }
  }

  function loadEnrolledParticipants(value, searchData) {
    toggleLoading(true);
    let participantData = [];
    try {
      getEnrolledCompetitionByCompId(value.id).subscribe((resp) => {
        toggleLoading(false);
        if (resp?.length) {
          toggleLoading(true);
          resp.forEach((item) => {
            if (item.userId) {
              getUserById(item.userId).subscribe((user) => {
                toggleLoading(false);
                if (user) {
                  const enrolledData = {
                    email: user.email,
                    phone: user.phone,
                    name: user.name,
                    buyMerchandise: item.buyMerchandise,
                    enrolledDate: item.createdOn,
                    category: item.category,
                    compName: item.compName,
                    compId: item.compId,
                    ageGroup: item.ageGroup,
                  };
                  participantData.push(enrolledData);
                  let userFilteredData = null;
                  if (resp.length === participantData.length) {
                    if (searchData) {
                      userFilteredData = participantData.filter(
                        (item) =>
                          item.email === searchData || item.phone === searchData
                      );
                    }
                    if (
                      userFilteredData &&
                      userFilteredData.length &&
                      (userFilteredData[0]?.email || userFilteredData[0]?.phone)
                    ) {
                      setEnrolledMembersData(userFilteredData);
                    } else {
                      if (searchData) {
                        setEmailSentResponse({
                          message:
                            "Searched user not enrolled in this competition!",
                          status: "error",
                        });
                        hideEmailNotification(5000);
                      }
                      setEnrolledMembersData(participantData);
                    }
                  }

                  toggleEnrolledMemberModal(true);
                }
              });
            } else {
              toggleEnrolledMemberModal(true);
            }
          });
        } else {
          toggleEnrolledMemberModal(true);
        }
      });
    } catch (e) {
      toggleLoading(false);
      console.log("Enrolled comp get Error: ", e);
    }
  }

  function setCompForUpdate(data) {
    if (data?.event === "edit") {
      try {
        setCompetitionData(initialCompetitionData);
        toggleLoading(true);
        getCompetition(data?.value?.id).subscribe((resp) => {
          toggleLoading(false);
          setCompetitionData({ ...CompetitionData, ...resp });
        });
      } catch (e) {
        toggleLoading(false);
        console.log("error: ", e);
      }
    }
  }

  function resetCompUpdate() {
    enableEditComp(false);
    setCompetitionData(initialCompetitionData);
    setImageUploadFromFile(false);
    resetEventImages();
    setTimeout(() => {
      setFormMessage("");
    }, 3000);
  }

  /**
   * @method filterCompetionList filter competitions on the basis of active status
   * @param {*} event
   */
  function filterCompetionList(event) {
    switch (event?.target?.value) {
      case "all":
        getCompetitionsListdata();
        break;
      case "live":
        getOnlyLiveEvents();
        break;
      case true:
        getOnlyActiveCompetitions();
        break;
      case false:
        getOnlyInActiveCompetitions();
        break;
      default:
        getCompetitionsListdata();
        break;
    }
  }

  function submitAdminAction(event, action) {
    event.stopPropagation();
    if (action && action === "close") {
      setAdminActionSelection({});
      toggleAdminPwdConfirm(false);
    } else {
      if (adminConfirmValueRef.current) {
        if (adminConfirmValueRef.current.value === ADMIN_PWD) {
          adminConfirmValueRef.current.classList.remove("error");
          if (adminMessageErrorRef.current) {
            adminMessageErrorRef.current.classList.remove("error");
          }
          onItemActionSelected(
            adminActionsSelection.event,
            adminActionsSelection.value
          );
          toggleAdminPwdConfirm(false);
        } else {
          adminConfirmValueRef.current.classList.add("error");
          if (adminMessageErrorRef.current) {
            adminMessageErrorRef.current.innerText =
              "Enter correct admin password";
            adminMessageErrorRef.current.classList.add("error");
          }
        }
      }
    }
  }

  function competitionDeleteConfirmation(action, data) {
    if (action) {
      togglePageLoader(true);
      toggleCompetitionModal(false);
      try {
        if (data?.isTesting) {
          deleteTestCompetitionByKey(data.id).subscribe((response) => {
            togglePageLoader(false);
          });
        } else {
          deleteCompetitionByKey(data.id).subscribe((response) => {
            togglePageLoader(false);
          });
        }
      } catch (e) {
        console.log("error: ", e);
      }
    } else {
      toggleCompetitionModal(false);
    }
  }

  function submitUserEnrollAction(event, action) {
    setSelectedUserMessage("");
    setSelectedUserMessageClass("");
    if (action && action === "submit") {
      if (selectedUserEmailRef.current && selectedUserEmailRef.current.value) {
        if (validateEmailId(selectedUserEmailRef.current.value)) {
          try {
            toggleInnerBoxLoader(true);
            getUserByEmail(selectedUserEmailRef.current.value).subscribe(
              (resp) => {
                toggleInnerBoxLoader(false);
                if (resp?.length && resp[0].key) {
                  toggleInnerBoxLoader(true);
                  const selectedUser = {
                    email: selectedUserEmailRef?.current?.value,
                    compKey: adminActionsSelection?.value?.id,
                    compName: adminActionsSelection?.value?.name,
                  };
                  try {
                    SaveSelectedUser(selectedUser).subscribe((resp) => {
                      toggleInnerBoxLoader(false);
                      if (resp) {
                        setSelectedUserMessageClass("success");
                        setSelectedUserMessage("Email saved successfully!");
                        selectedUserEmailRef.current.value = "";
                      } else {
                        setSelectedUserMessageClass("error");
                        setSelectedUserMessage(
                          "Error in saving details, try again!"
                        );
                      }
                    });
                  } catch (e) {
                    toggleInnerBoxLoader(false);
                    setSelectedUserMessageClass("error");
                    setSelectedUserMessage(
                      "Error in saving details, try again in sometime!"
                    );
                    console.log("save selected user error: ", e);
                  }
                } else {
                  setSelectedUserMessageClass("error");
                  setSelectedUserMessage("Email id is not registered with Da!");
                }
              }
            );
          } catch (e) {
            toggleInnerBoxLoader(false);
            setSelectedUserMessageClass("error");
            setSelectedUserMessage(
              "Error in saving details, try again in sometime!"
            );
            console.log("Error fetching user", e);
          }
        } else {
          setSelectedUserMessageClass("error");
          setSelectedUserMessage("Invalid email, enter valid email ID");
        }
      }
    } else {
      toggleUserSelectionModal(false);
    }
  }

  const sendEmailToUser = (emailData) => {
    if (emailData.content && emailData.to) {
      let emailBody = emailData.content;
      togglePageLoader(true);
      let payload = {
        mailTo: emailData.to,
        title: emailData.subject,
        content: emailBody,
      };
      try {
        sendEmail(payload).subscribe((res) => {
          togglePageLoader(false);
          if (!("error" in res)) {
            setEmailSentResponse(emailSendSuccessData);
            hideEmailNotification(5000);
          } else {
            setEmailSentResponse(emailSendErrorData);
            hideEmailNotification(5000);
          }
        });
      } catch (error) {
        togglePageLoader(false);
        setEmailSentResponse(emailSendErrorData);
        hideEmailNotification(5000);
      }
    }
  };

  function hideEmailNotification(timer) {
    setTimeout(() => {
      setEmailSentResponse({ message: "", status: "" });
    }, timer);
  }

  /**
   * This function used when user dosen't get mail of registration automatically after
   * enrolling. Then Admin can manully send the mail to user by clicking send mail button
   * in <EnrolledCompMembers /> component
   * @param {*} userData
   */
  function sendMailToUserManually(userData) {
    if (userData.email && userData.compId) {
      try {
        togglePageLoader(true);
        getCompRegisteredUserMails(userData).subscribe((resp) => {
          togglePageLoader(false);
          sendEmailToUser(resp);
        });
      } catch (e) {
        togglePageLoader(false);
        console.log("error: ", e);
      }
    }
  }

  function searchEnrolledUser(compData, data) {
    loadEnrolledParticipants(compData, data);
  }

  return (
    <div className="adminPanelSection">
      <Loader value={isLoaderActive} />
      {isPageLoaderActive ? <Loader /> : ""}
      {isCompetitionDeleteClicked ? (
        <ConfirmationModal
          screen="competition"
          message={deleteCompetitionMessage}
          actionData={competitionDataToModify}
          confirmationResponse={competitionDeleteConfirmation}
        />
      ) : (
        ""
      )}
      {promptAdminPsswordBox ? (
        <div className="adminPasswordOverlay">
          <div className="innerBox">
            <p
              className="closeUserModal"
              title="close modal"
              onClick={(e) => submitAdminAction(e, "close")}
            ></p>
            <div className="controlGroup">
              <label htmlFor="adminPwdConfirm">
                Enter the admin password to proceed!
              </label>
              <input
                id="adminPwdConfirm"
                ref={adminConfirmValueRef}
                type="password"
                title="enter admin password"
                placeholder="Admin password"
              />
              <span className="messageBox" ref={adminMessageErrorRef}></span>
            </div>
            <button
              className="btn primary-light"
              onClick={(e) => submitAdminAction(e, "close")}
            >
              Close
            </button>
            <button
              className="btn primary-dark"
              onClick={(e) => submitAdminAction(e, "submit")}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      {shouldEnrolledMemberModal ? (
        <EnrolledCompMembers
          totalEnrolledMembers={enrolledMembersData}
          isListLoadingOverlay={isPageLoaderActive}
          sendMailToUser={(userData) => sendMailToUserManually(userData)}
          closeEnrollModal={(action) => closeEnrolledUserModal(action)}
          emailConfirmation={emailSendResponseStatus}
          searchUserByInput={(comp, userData) =>
            searchEnrolledUser(comp, userData)
          }
        />
      ) : (
        ""
      )}

      {userSelectionModal ? (
        <div className="adminPasswordOverlay userSelectionModal">
          <div className="innerBox">
            {innerBoxLoader ? <div className="innerLoaderBox"></div> : ""}
            <h2>{adminActionsSelection?.value?.name}</h2>
            <p
              className="closeUserModal"
              title="close modal"
              onClick={(e) => submitUserEnrollAction(e, "close")}
            ></p>
            <div className="controlGroup">
              <label htmlFor="adminPwdConfirm">
                Enter email which is registered in Da!
              </label>
              <input
                id="adminPwdConfirm"
                ref={selectedUserEmailRef}
                type="email"
                title="Enter selected user email"
                placeholder="Enter selected user email"
              />
              <span className={`messageBox ${selectedUserMessageClass}`}>
                {selectedUserMessage}
              </span>
            </div>
            <button
              className="btn primary-light"
              onClick={(e) => submitUserEnrollAction(e, "close")}
            >
              Close
            </button>
            <button
              className="btn primary-dark"
              onClick={(e) => submitUserEnrollAction(e, "submit")}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      <Navigation />
      <div className="logoWrap">
        <a href="/" title="Da home">
          <img src={boogaluLogo} alt="Da" />
        </a>
      </div>
      {isAdminLoggedIn || checkAdminLogIn ? (
        <div className="optionsTab">
          <p
            onKeyUp={() => switchTabs("create")}
            onClick={() => switchTabs("create")}
            className="tabItem active"
            ref={createTabRef}
            tabIndex={6}
          >
            Create new
          </p>
          <p
            onKeyUp={() => switchTabs("live")}
            onClick={() => switchTabs("live")}
            className="tabItem"
            ref={createLiveTabRef}
            tabIndex={7}
          >
            Create Live
          </p>
          <p
            onKeyUp={() => switchTabs("list")}
            onClick={() => switchTabs("list")}
            className="tabItem"
            ref={listTabRef}
            tabIndex={8}
          >
            View list
          </p>
        </div>
      ) : (
        ""
      )}
      <div
        className={`competition-bo-wrap clearfix ${
          (isAdminLoggedIn || checkAdminLogIn) && "loggedInAdmin"
        }`}
      >
        {isAdminLoggedIn || checkAdminLogIn ? (
          <p
            className="logOutIconWrap"
            title="logout"
            onClick={(e) => tiggerAdminLogout(e, false)}
          >
            <FaPowerOff />
          </p>
        ) : (
          ""
        )}
        {isAdminLoggedIn || checkAdminLogIn ? (
          <div className="compHeadingSection">
            <h1>
              <Link
                to="/adminpanel"
                title="back to admin"
                className="backToAdmin"
              >
                <span>&#8592;</span>
              </Link>
              {isCreateFormTab
                ? isFromEditComp
                  ? "Update the competition"
                  : "Launch a new competition"
                : ""}
              {isLiveCreateFormTab ? "Launch a new Live event" : ""}
              {isListView ? "List of competitions" : ""}
            </h1>
            {isListView ? (
              <div className="filterSection">
                {CompetitionsList && CompetitionsList.length ? (
                  <p>
                    Total Competitions:{" "}
                    <span>
                      {CompetitionsList && CompetitionsList.length
                        ? CompetitionsList.length
                        : 0}
                    </span>
                  </p>
                ) : (
                  ""
                )}
                {CompetitionsList && CompetitionsList.length ? (
                  <div className="filterBySection">
                    <FormControl variant="outlined" className="input-field">
                      <InputLabel id="outlined-filter-comp-label">
                        Filter competition by:
                      </InputLabel>
                      <Select
                        labelId="select-outlined-label"
                        id="outlined-filter-comp-visiblity"
                        onChange={(e) => filterCompetionList(e)}
                        label="Filter competition by:"
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                        <MenuItem value="live">Live Events</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <h1>
            <Link
              to="/adminpanel"
              title="back to admin"
              className="backToAdmin"
            >
              <span>&#8592;</span>
            </Link>
            Login to create new Competition
          </h1>
        )}
        {isAdminLoggedIn || checkAdminLogIn ? (
          isCreateFormTab ? (
            <div className="inner-form-wrap">
              <div className="input-wrap fullWidthInput">
                <TextField
                  className="input-field"
                  required
                  id="outlined-required-name"
                  label="Name"
                  onChange={handleChange("name")}
                  value={CompetitionData.name}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  required
                  id="outlined-required-desc"
                  label="Description"
                  onChange={handleChange("desc")}
                  value={CompetitionData.desc}
                  variant="outlined"
                />
              </div>

              {/* <div className="input-wrap">
                <FormControl variant="outlined" className="input-field">
                  <InputLabel id="outlined-required-fee-label" required>
                    Plan type
                  </InputLabel>
                  <Select
                    required
                    labelId="select-outlined-label"
                    id="outlined-required-fee"
                    value={CompetitionData.fee}
                    onChange={handleChange("fee")}
                    label="Plan type"
                  >
                    <MenuItem value="199">Start-up</MenuItem>
                    <MenuItem value="399">Pro</MenuItem>
                    <MenuItem value="599">Premium</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              {/* <div className="input-wrap">
                <FormControl variant="outlined" className="input-field">
                  <InputLabel id="select-outlined-label" required>
                    Running status
                  </InputLabel>
                  <Select
                    required
                    labelId="select-outlined-label"
                    id="select-outlined"
                    value={CompetitionData.type}
                    onChange={handleChange("type")}
                    label="Running status"
                  >
                    <MenuItem value="running">Currently Running</MenuItem>
                    <MenuItem value="upcoming">Up-Coming</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <div className="videoUploadOption input-wrap">
                <FormControl variant="outlined" className="input-field">
                  <InputLabel id="outlined-required-fee-label" required>
                    Should video upload ?
                  </InputLabel>
                  <Select
                    required
                    labelId="select-outlined-label"
                    id="outlined-required-visiblity"
                    value={CompetitionData.shouldVideoUpload}
                    onChange={handleChange("shouldVideoUpload")}
                    label="Should video upload ?"
                  >
                    <MenuItem value="optional">Optional</MenuItem>
                    <MenuItem value="mandatory">Mandatory</MenuItem>
                    <MenuItem value="off">Off</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="input-wrap input-wrap-full compImageWrap">
                <ImageUploader
                  withIcon={true}
                  buttonText={isFromEditComp ? "Change image" : "Upload image"}
                  onChange={onimageUpload}
                  imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                  maxFileSize={5242880}
                  accept="image/*"
                  withPreview={true}
                  singleImage={true}
                  label="Events tiles image (Max size 5 MB &amp; 644x861)"
                />
                {isBannerImageEnable ? (
                  <ImageUploader
                    withIcon={true}
                    buttonText={
                      isFromEditComp ? "Change image" : "Upload image"
                    }
                    onChange={onBannerImageUpload}
                    imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                    maxFileSize={5242880}
                    accept="image/*"
                    withPreview={true}
                    singleImage={true}
                    label="Upcoming banner image (Max size 5 MB &amp; 1340x380)"
                  />
                ) : (
                  <p className="noBannerImageMessage">
                    Select upcoming to enable uploading banner image
                  </p>
                )}
                {isBannerImageEnable ? (
                  <ImageUploader
                    withIcon={true}
                    buttonText={
                      isFromEditComp ? "Change image" : "Upload image"
                    }
                    onChange={onMobileBannerImageUpload}
                    imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                    maxFileSize={5242880}
                    accept="image/*"
                    withPreview={true}
                    singleImage={true}
                    label="Upcoming banner mobile (Max size 5 MB &amp; 800x800)"
                  />
                ) : (
                  <p className="noBannerImageMessage">
                    Select upcoming to enable uploading banner image for mobile
                  </p>
                )}
              </div>

              <div className="input-wrap input-wrap-full category-input-wrap">
                {/* Add the new category input row here, create a component for that */}
                <AddCompititionCategories
                  competitionData={CompetitionData}
                  ref={categoriesRef}
                  isFromEdit={isFromEditComp}
                />
              </div>
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  id="outlined-registration-offer"
                  label="Registration Offer if any"
                  onChange={handleChange("offer")}
                  value={CompetitionData.offer}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  id="outlined-offer-value"
                  label="Offer percentage from 1-100(e.g. 5) or leave it blank"
                  onChange={handleChange("offerPercent")}
                  value={CompetitionData.offerPercent}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  id="outlined-merchandise"
                  label="Merchandise if any"
                  onChange={handleChange("merchandise")}
                  value={CompetitionData.merchandise}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  id="outlined-merchandise-amount"
                  label="Merchandise amount per member/user"
                  onChange={handleChange("merchandiseAmount")}
                  value={CompetitionData.merchandiseAmount}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap input-wrap-full">
                <TextField
                  className="input-field"
                  id="outlined-special-offer"
                  label="Specials offer if any"
                  onChange={handleChange("specialOffer")}
                  value={CompetitionData.specialOffer}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap textAreaWrap">
                <textarea
                  className="input-field"
                  id="outlined-required-rules"
                  onChange={handleChange("rules")}
                  value={CompetitionData.rules}
                  placeholder="Rules(winners, events &amp; prize)"
                ></textarea>
              </div>
              <div className="input-wrap textAreaWrap">
                <textarea
                  className="input-field"
                  id="outlined-required-judges"
                  onChange={handleChange("judges")}
                  value={CompetitionData.judges}
                  placeholder="Our judges"
                ></textarea>
              </div>
              <div className="input-wrap data-time-wrap">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required
                    margin="normal"
                    minDate={
                      isFromEditComp ? CompetitionData.startAt : new Date()
                    }
                    maxDate={setMaxDateSelectionYear()}
                    id="datetime-local-start"
                    label="Registration start date"
                    format="MM/dd/yyyy"
                    disabled={isFromEditComp}
                    value={CompetitionData.startAt}
                    onChange={(e) => setStartDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    margin="normal"
                    id="datetime-local-end"
                    minDate={new Date()}
                    maxDate={setMaxDateSelectionYear()}
                    label="Registration end date"
                    format="MM/dd/yyyy"
                    value={CompetitionData.endAt}
                    onChange={(e) => setEndDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {/* <div className="input-wrap">
                                <TextField className="input-field"
                                    required
                                    id="outlined-required-name"
                                    label="First Price"
                                    onChange={handleChange('prices', 0)}
                                    value={CompetitionData.prices[0]}
                                    variant="outlined"
                                />
                            </div>
                            <div className="input-wrap">
                                <TextField className="input-field"
                                    required
                                    id="outlined-required-name"
                                    label="Second Price"
                                    onChange={handleChange('prices', 1)}
                                    value={CompetitionData.prices[1]}
                                    variant="outlined"
                                />
                            </div>
                            <div className="input-wrap">
                                <TextField className="input-field"
                                    required
                                    id="outlined-required-name"
                                    label="Third Price"
                                    onChange={handleChange('prices', 2)}
                                    value={CompetitionData.prices[2]}
                                    variant="outlined"
                                />
                            </div>
                             */}
              {isFromEditComp &&
              CompetitionData.prices &&
              CompetitionData.prices.length ? (
                <div className="input-wrap prize-wrap">
                  <AddMoreInputGroup
                    preSelectedData={
                      isFromEditComp ? CompetitionData.prices : []
                    }
                    setPriceData={(e) => setPricesData(e)}
                  />
                </div>
              ) : (
                ""
              )}
              {!isFromEditComp ? (
                <div className="input-wrap prize-wrap">
                  <AddMoreInputGroup setPriceData={(e) => setPricesData(e)} />
                </div>
              ) : (
                ""
              )}
              <div className="input-wrap fullWidthInput termsTextarea">
                <TextareaAutosize
                  className="input-field"
                  aria-label="minimum height"
                  minRows={3}
                  maxRows={3}
                  placeholder="Terms &amp; conditions"
                  id="outlined-required-terms"
                  onChange={handleChange("tnc")}
                  value={CompetitionData.tnc}
                />
              </div>
              <div className="input-wrap fullWidthInput termsTextarea rejectMessageArea">
                <TextareaAutosize
                  className="input-field"
                  aria-label="minimum height"
                  minRows={3}
                  maxRows={3}
                  placeholder="Message for not selected users"
                  id="outlined-required-terms"
                  onChange={handleChange("rejectMessage")}
                  value={CompetitionData.rejectMessage}
                />
              </div>
              <div className="input-wrap action-wrap">
                <p className={`messageWrap ${messageClass}`}>
                  {formMessageBox}
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resetCompUpdate()}
                >
                  {isFromEditComp ? "Reset" : "Cancel"}
                </Button>
                <Button
                  variant="contained"
                  className={isSaveLoadingTrue ? "loading" : ""}
                  color="secondary"
                  onClick={(e) =>
                    saveDetails(e, isFromEditComp ? "Update" : "Save")
                  }
                >
                  {isFromEditComp ? "Update" : "Save"}
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      color="primary"
                      className="selected-item-checkbox"
                      checked={CompetitionData.active}
                      onChange={handleChange("active")}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label="Active Competition"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      color="secondary"
                      className="selected-item-checkbox"
                      checked={CompetitionData.isTesting}
                      onChange={handleChange("isTesting")}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label="Testing Competition"
                />
              </div>
            </div>
          ) : isLiveCreateFormTab ? (
            <div className="inner-form-wrap">
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  required
                  id="outlined-required-name-live"
                  label="Name"
                  onChange={handleLiveEventChange("name")}
                  value={LiveEventsData.name}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap">
                <TextField
                  className="input-field"
                  required
                  id="outlined-required-desc"
                  label="Live video link id (eg. AIxBhcW15uA)"
                  onChange={handleLiveEventChange("videoLink")}
                  value={LiveEventsData.videoLink}
                  variant="outlined"
                />
              </div>
              <div className="input-wrap data-time-wrap event-data-time-wrap">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required
                    margin="normal"
                    minDate={new Date()}
                    maxDate={setMaxDateSelectionYear()}
                    id="datetime-local-start"
                    label="Select start date"
                    format="MM/dd/yyyy"
                    value={LiveEventsData.startDate}
                    onChange={(e) => setEventStartDateTime(e, "date")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardTimePicker
                    required
                    margin="normal"
                    id="event-time-picker"
                    label="Select start time"
                    value={LiveEventsData.startTime}
                    onChange={(e) => setEventStartDateTime(e, "time")}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="input-wrap">
                <FormControl variant="outlined" className="input-field">
                  <InputLabel id="outlined-required-fee-label" required>
                    Plan type
                  </InputLabel>
                  <Select
                    required
                    labelId="select-outlined-label"
                    id="outlined-required-fee"
                    value={LiveEventsData.fee}
                    onChange={handleLiveEventChange("fee")}
                    label="Plan type"
                  >
                    <MenuItem value="199">Start-up</MenuItem>
                    <MenuItem value="399">Pro</MenuItem>
                    <MenuItem value="599">Premium</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="input-wrap">
                <FormControl variant="outlined" className="input-field">
                  <InputLabel id="outlined-required-fee-label" required>
                    Should visible to user ?
                  </InputLabel>
                  <Select
                    required
                    labelId="select-outlined-label"
                    id="outlined-required-visiblity"
                    value={LiveEventsData.isVisible}
                    onChange={handleLiveEventChange("isVisible")}
                    label="Should visible to user ?"
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="input-wrap input-wrap-full compImageWrap">
                <ImageUploader
                  withIcon={true}
                  buttonText={isFromEditComp ? "Change image" : "Upload image"}
                  onChange={onEventImageUpload}
                  imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                  maxFileSize={5242880}
                  accept="image/*"
                  withPreview={true}
                  singleImage={true}
                  label="Events tiles image (Max size 5 MB &amp; 644x861)"
                />
                <ImageUploader
                  withIcon={true}
                  buttonText={isFromEditComp ? "Change image" : "Upload image"}
                  onChange={onEventImageUploadBanner}
                  imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                  maxFileSize={5242880}
                  accept="image/*"
                  withPreview={true}
                  singleImage={true}
                  label="Events banner image (Max size 5 MB &amp; 1340x380)"
                />
                <ImageUploader
                  withIcon={true}
                  buttonText={isFromEditComp ? "Change image" : "Upload image"}
                  onChange={onEventImageUploadMobile}
                  imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                  maxFileSize={5242880}
                  accept="image/*"
                  withPreview={true}
                  singleImage={true}
                  label="Events banner mobile (Max size 5 MB &amp; 800x800)"
                />
              </div>
              <div className="input-wrap action-wrap">
                <p className={`messageWrap ${messageClass}`}>
                  {formMessageBox}
                </p>
                <Button variant="contained" color="primary">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={isSaveLoadingTrue ? "loading" : ""}
                  color="secondary"
                  onClick={(e) => saveLiveEventDetails(e)}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <div className="adminItemlistView">
              {CompetitionsList && CompetitionsList.length ? (
                CompetitionsList.map((item, index) => {
                  return (
                    <div
                      className="boxItem compBox"
                      key={`event-comp-${index}`}
                    >
                      {item?.isVisible ? (
                        <p className="liveLabel">
                          <span>live</span>
                        </p>
                      ) : (
                        ""
                      )}
                      <p className="title" title={item.name}>
                        {item.name}
                      </p>
                      <div className="compImageWrap">
                        <img src={item.img} alt="Competition item" />
                      </div>
                      <ActionToolTip
                        id={item.key}
                        name={item.name}
                        isTestingComp={item?.isTesting}
                        isEditEnabled={
                          isEditCompFeatureEnable && !item?.videoLink
                        }
                        isActive={item?.active || item?.isVisible === "yes"}
                        showUsersEnrolled={true}
                        selectionEntry={true}
                        isDeleteEnabled={true}
                        onActionClicked={(e) =>
                          confirmItsAdmin(e, {
                            id: item.key,
                            name: item.name,
                            isLive: item?.isVisible,
                            isTesting: item?.isTesting,
                          })
                        }
                      />
                      <p className="statusBlock">
                        Status:{" "}
                        <span>
                          {item?.active || item?.isVisible === "yes"
                            ? "Active"
                            : "Inactive"}
                        </span>
                      </p>
                      <p className="date">
                        Starting Date:{" "}
                        <span>
                          {item.startingDate ||
                            new Date(item.startTime).toDateString()}
                        </span>
                      </p>
                      {item.endingDate ? (
                        <p className="date">
                          Ending on: <span>{item.endingDate}</span>
                        </p>
                      ) : (
                        ""
                      )}
                      {item?.isVisible ? (
                        <p className="date">
                          Start time:{" "}
                          <span>
                            {timeConvert24To12(
                              new Date(item.startTime)
                                .toLocaleTimeString()
                                .substr(0, 5)
                            )}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              ) : (
                <p className="noDataInListMessage">
                  You haven't created any Competition!
                </p>
              )}
            </div>
          )
        ) : (
          <AdminLogin
            statusMessage={loggedInMessages}
            inputChange={(value, field) => handleAdminLogin(value, field)}
            submitLogin={(event, action) => triggerLogin(event, action)}
          />
        )}
      </div>
      <div className="footerBox">
        &copy; 2021 A CHOREO CULTURE PVT LTD, All rights reserved.
      </div>
    </div>
  );
}
