import React from "react";
import Confetti from 'react-confetti';

function ConfettiBox() {
    const { width, height } = useWindowSize();
    function useWindowSize() {
        const width = window.outerWidth - 5;
        const height = window.outerHeight - 5;
        return {width: width, height: height};
    }
    return (
        <Confetti
            width={width}
            height={height}
        />
    )
}

export default ConfettiBox;