import React from "react";

export default function AboutUs(props) {
  const { isCallByNativeApp } = props;

  return (
    <div
      className={`about-us-page charcoal-bg paddingTop90 ${
        isCallByNativeApp ? "inNativeApp" : ""
      }`}
    >
      <div className="container">
        <h1>About us</h1>
        <p>
          DA is a web and app-based digital platform for dance enthusiasts that
          give them the freedom to learn from a wide variety of dance styles
          from their favorite dance masters through structured modules anytime &
          anywhere.
        </p>
        <p>
          At DA, we aspire to bridge the gap between physical and digital
          learning through our immersive technologies.
        </p>
        <p>
          DA’s learning modules are custom designed by renowned dancers and
          choreographers to cater to dance enthusiasts at various levels from
          kids, Adults - Beginners to Advanced. Our objective is to make
          learning dance fun and easy.
        </p>
        <p>
          DA brings a unique opportunity to all creative hearts to create,
          share, showcase and compete at a global level through our events.
        </p>
        <p>
          With DA one doesn’t have to worry anymore about hunting dance studios,
          traveling to learn from favorite dancers, expensive workshops, and
          Juggling with your daily schedule.
        </p>
        <p>
          <strong>VISION: </strong>
          To Democratize structured learning using futuristic digital
          technologies and create a community of creative hearts.
        </p>
        <p>
          <strong>MISSION: </strong>
          Create a digital platform that gives universal access to learn
          performing arts with structured content curated by the best
          instructors globally; provide freedom to showcase your talent and
          opportunity to grow as a community.
        </p>
      </div>
    </div>
  );
}
