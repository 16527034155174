import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';

let timeOut = null;
let isVideoPlayForFirstTime = false;

const LessonsVideoPlayer = ({ src,
    dataId,
    classNames,
    thumbNail,
    videoLastPlayedTime,
    onVideoSeek,
    onVideoPause,
    onVideoPlay,
    onFullScreenToggle,
    onWindowResize
}) => {
    const [player, setPlayer] = useState(null);
    const [btnPlayPause, setPlayPauseBtn] = useState(null);
    const [btnMute, setBtnMute] = useState(null);
    const [progressBar, setProgressBar] = useState(null);
    const [volumeBar, setVolumeBar] = useState(null);
    const [showControls, setShowControlClass] = useState('show');
    const [progressData, setProgressData] = useState(0);
    const [volumeValue, setVolumeValue] = useState('1');
    const [viewPortHeight, setViewPortHeight] = useState(625);
    const [isFullScreen, toggleFullscreen] = useState(false);
    const [isFullScreenToggleIcon, toggleFullScreenIcon] = useState(false);
    const [isVideoLoaded, toggleVideoloader] = useState(true);
    const [isVideoPlay, toggleVideoPlay] = useState(true);
    const [videoMaxDuration, setVideoMaxDuration] = useState(0);

    const videoElementRef = useRef();
    const progressRangeRef = useRef();

    useEffect(() => {
        if (src?.includes('m3u8')) {
            if (videoElementRef.current) {
                setPlayer(videoElementRef.current.getInternalPlayer());
            }
        } else {
            setPlayer(document.getElementById(dataId));
        }
        setPlayPauseBtn(document.getElementById('btnPlayPause'));
        setBtnMute(document.getElementById('btnMute'));
        setProgressBar(document.getElementById('progress-bar'));
        setVolumeBar(document.getElementById('volume-bar'));
        // Add a listener for the timeupdate event so we can update the progress bar
        if (player) {
            // Add a listener for the play and pause events so the buttons state can be updated
            player.addEventListener('play', function () {
                // Change the button to be a pause button
                changeButtonType(btnPlayPause, 'pause');
            }, false);
            player.addEventListener('pause', function () {
                // Change the button to be a play button
                changeButtonType(btnPlayPause, 'play');
            }, false);

            player.addEventListener('volumechange', function (e) {
                // Update the button to be mute/unmute
                if (player.muted) changeButtonType(btnMute, 'unmute');
                else changeButtonType(btnMute, 'mute');
            }, false);

            player.addEventListener('ended', function () { this.pause(); }, false);
        }

        if (progressBar) {
            progressBar.addEventListener("click", (e) => changeProgress(e));
        }

        setTimeout(() => {
            setShowControlClass('show');
        }, 1000);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener("keyup", escFunction, false);
        return () => {
            window.removeEventListener("keyup", escFunction, false);
        };
    }, []);

    function setPlayerHeightOnVideoLoad(event, from) {
        if (from && from === 'reactPlayer') {
            if (videoElementRef.current && videoElementRef.current) {
                const videoElementInternal = videoElementRef.current.getInternalPlayer();
                videoElementInternal.onloadedmetadata = (ele) => {
                    toggleVideoloader(false);
                    setVideoMaxDuration(Math.ceil(ele.target.duration));
                    setViewPortHeight((videoElementInternal.clientHeight / 2) - 5);
                    if (videoLastPlayedTime) {
                        loadPreviousPlayedTime();
                    }
                }
            }
        } else {
            if (event.currentTarget.readyState >= 3) {
                toggleVideoloader(false);
                if (videoElementRef.current) {
                    let maxPercentage = 0;
                    if (progressRangeRef.current) {
                        maxPercentage = Math.ceil(player.duration);
                    }
                    setVideoMaxDuration(maxPercentage);
                    setViewPortHeight((videoElementRef.current.clientHeight / 2) - 5);
                    if (videoLastPlayedTime) {
                        loadPreviousPlayedTime();
                    }
                }
            }
        }
    }

    function handleResize() {
        if (window.outerHeight <= 1023) {
            if (src?.includes('m3u8')) {
                if (videoElementRef.current) {
                    if (videoElementRef.current) {
                        setViewPortHeight((videoElementRef.current.getInternalPlayer().clientHeight / 2) - 5);
                        onWindowResize(videoElementRef.current.getInternalPlayer().clientHeight);

                    }
                }
            } else {
                if (videoElementRef?.current?.clientHeight) {
                    setViewPortHeight(videoElementRef.current.clientHeight / 2 - 5);
                    onWindowResize(videoElementRef.current.clientHeight);
                } else if (videoElementRef?.current?.getInternalPlayer()?.clientHeight) {
                    setViewPortHeight((videoElementRef.current.getInternalPlayer().clientHeight / 2) - 5);
                    onWindowResize(videoElementRef.current.getInternalPlayer().clientHeight);
                } else {
                    setViewPortHeight(videoElementRef.current.clientHeight / 2 - 5);
                    onWindowResize(videoElementRef.current.clientHeight);
                }
            }
        } else {
            if (src?.includes('m3u8')) {
                if (videoElementRef.current && videoElementRef.current.getInternalPlayer()) {
                    setViewPortHeight(videoElementRef.current.getInternalPlayer().clientHeight / 2 - 5);
                }
            } else {
                setViewPortHeight(videoElementRef.current.clientHeight / 2 - 5);
            }
        }
    }

    function playPauseVideo(stopTrigger) {
        if (src?.includes('m3u8')) {
            if (videoElementRef.current && videoElementRef.current.getInternalPlayer()) {
                if (videoElementRef.current.getInternalPlayer().paused || videoElementRef.current.getInternalPlayer().ended) {
                    toggleVideoPlay(true);
                } else {
                    toggleVideoPlay(false);
                }
            }
        } else {
            if (player) {
                if (player.paused || player.ended) {
                    // Change the button to a pause button
                    changeButtonType(btnPlayPause, 'pause');
                    player.play();
                } else {
                    // Change the button to a play button
                    changeButtonType(btnPlayPause, 'play');
                    player.pause();
                }
            }
        }
    }

    // Stop the current media from playing, and return it to the start position
    function stopVideo() {
        if (src?.includes('m3u8')) {
            if (videoElementRef.current && videoElementRef.current.getInternalPlayer()) {
                toggleVideoPlay(false);
            }
            if (videoElementRef.current && videoElementRef.current.getCurrentTime() > 0) {
                videoElementRef.current.seekTo(0, 'seconds');
                onVideoPause(videoElementRef.current.getCurrentTime());
            }
        } else {
            player.pause();
            if (player.currentTime) {
                player.currentTime = 0;
                onVideoPause(player.currentTime);
            }
        }
    }

    // Toggles the media player's mute and unmute status
    function muteVolume() {
        if (src?.includes('m3u8')) {
            if (videoElementRef.current && videoElementRef.current.getInternalPlayer()) {
                if (videoElementRef.current.getInternalPlayer().muted) {
                    changeButtonType(btnMute, 'mute');
                    videoElementRef.current.getInternalPlayer().muted = false;
                } else {
                    changeButtonType(btnMute, 'unmute');
                    videoElementRef.current.getInternalPlayer().muted = true;
                }
            }
        } else {
            if (player.muted) {
                // Change the button to a mute button
                changeButtonType(btnMute, 'mute');
                player.muted = false;
            }
            else {
                // Change the button to an unmute button
                changeButtonType(btnMute, 'unmute');
                player.muted = true;
            }
        }
    }

    // Replays the media currently loaded in the player
    function replayVideo() {
        resetPlayer();
        player.play();
    }

    function onChangePlaybackSpeed(speed) {
        if (player && player.currentTime) {
            player.playbackRate = speed?.target?.value;
        }
    }

    // Update the progress bar
    function updateProgressBar(event) {
        let percentage = 0;
        // Work out how much of the media has played via the duration and currentTime parameters
        if (src?.includes('m3u8')) {
            if (videoElementRef.current && videoElementRef.current) {
                percentage = Math.ceil(videoElementRef.current.getCurrentTime()) || 0;
                onVideoSeek(Math.round(videoElementRef.current.getCurrentTime()) - 2);
            }
        } else {
            percentage = Math.ceil(player.currentTime) || 0;
            onVideoSeek(Math.round(player.currentTime) - 2);
        }
        if (progressRangeRef.current) {
            const value = (progressRangeRef.current.value - progressRangeRef.current.min) / (progressRangeRef.current.max - progressRangeRef.current.min) * 100
            progressRangeRef.current.style.background = 'linear-gradient(to right, #5458F7 0%, #039be5 ' + (value + 1) + '%, #fff ' + (value + 1) + '%, white 100%)'
        }
        // Update the progress bar's value
        setProgressData(percentage);
        // Update the progress bar's text (for browsers that don't support the progress element)
        progressBar.innerText = percentage + '% played';
    }

    // Updates a button's title, innerHTML and CSS class
    function changeButtonType(btn, value) {
        btn.title = value;
        btn.innerHTML = value;
        btn.className = value;
        if (value && value === 'play') {
            if (src?.includes('m3u8')) {
                if (videoElementRef.current && videoElementRef.current) {
                    onVideoPause(Math.round(videoElementRef.current.getCurrentTime()) - 2);
                }
            } else {
                onVideoPause(Math.round(player.currentTime) - 2);
            }
        } else {
            if (!isVideoPlayForFirstTime) {
                onVideoPlay('play');
                isVideoPlayForFirstTime = true;
            }
        }
    }

    function resetPlayer() {
        progressBar.value = 0;
        // Move the media back to the start
        player.currentTime = 0;
        // Set the play/pause button to 'play'
        changeButtonType(btnPlayPause, 'play');
    }

    function changeVolume(evt) {
        // Update the video volume
        if (volumeBar && evt) {
            player.volume = evt.target.value;
            setVolumeValue(evt.target.value);
        }
    }

    function loadPreviousPlayedTime() {
        if (src?.includes('m3u8')) {
            if (videoElementRef.current && videoElementRef.current) {
                videoElementRef.current.seekTo(videoLastPlayedTime, 'seconds');
            }
        } else {
            player.currentTime = videoLastPlayedTime;
        }
    }

    function changeProgress(evt) {
        // Update the video volume
        if (evt) {
            setProgressData(evt.target.value);
            if (src?.includes('m3u8')) {
                if (videoElementRef.current && videoElementRef.current) {
                    videoElementRef.current.seekTo(evt.target.value, 'seconds');
                }
            } else {
                player.currentTime = parseInt(evt.target.value);
            }
        }
    }

    useEffect(() => {
        if (isFullScreen) {
            toggleFullScreenIcon(true);
            setShowControlClass('');
        } else {
            toggleFullScreenIcon(false);
            setShowControlClass('show');
        }
    }, [isFullScreen]);

    function toggleVideoFullScreen() {
        toggleFullscreen(!isFullScreen);
        onFullScreenToggle();
        setTimeout(() => {
            handleResize();
        }, 100);
    }

    function escFunction() {
        toggleFullscreen(false);
        toggleFullScreenIcon(false);
        setShowControlClass('show');
    }

    function showControlsOnMove() {
        clearTimeout(timeOut);
        setShowControlClass('show');
        timeOut = setTimeout(() => {
            setShowControlClass('');
        }, 3000);
    }

    return (
        <div className={`vdo-outer ${classNames}`}>
            <div className={`vdo-wrap`}>
                <div
                    className="react-player"
                    onClick={() => playPauseVideo()}
                    onMouseMove={() => showControlsOnMove()}
                    style={
                        {
                            'maxHeight': `${viewPortHeight}px`
                        }
                    }
                >
                    {
                        isVideoLoaded ?
                            <div className="thumbNailWrapper">
                                <img src={thumbNail} alt="my video" />
                                <div className="videoLoader"></div>
                            </div>
                            : ''
                    }
                    {
                        src?.includes('m3u8') ?
                            <ReactPlayer
                                url={src}
                                controls={false}
                                playing={isVideoPlay}
                                id={dataId}
                                width='100%'
                                height='100%'
                                ref={videoElementRef}
                                volume={Number(volumeValue)}
                                className="innerReactPlayer"
                                config={{
                                    file: {
                                        forceHLS: true,
                                        attributes: { controlsList: 'nodownload' }
                                    }
                                }}
                                onContextMenu={e => e.preventDefault()}
                                onPlay={() => changeButtonType(btnPlayPause, 'pause')}
                                onPause={() => changeButtonType(btnPlayPause, 'play')}
                                onProgress={(e) => updateProgressBar(e)}
                                onReady={(e) => setPlayerHeightOnVideoLoad(e, 'reactPlayer')}
                            />
                            :
                            <video
                                id={dataId}
                                onTimeUpdate={(e) => updateProgressBar(e)}
                                src={src}
                                autoPlay
                                ref={videoElementRef}
                                onLoadedData={(e) => setPlayerHeightOnVideoLoad(e)}
                                onPlay={() => changeButtonType(btnPlayPause, 'pause')}
                                onPause={() => changeButtonType(btnPlayPause, 'play')}
                                controls={false}
                            ></video>
                    }
                </div>
                <div id='controls' onMouseOver={() => showControlsOnMove()} className={`customControls ${showControls}`}>
                    <button id='btnPlayPause' className='play' title='play' onClick={() => playPauseVideo()}>Play</button>
                    <input type="range" ref={progressRangeRef} id="progress-bar" onChange={(e) => changeProgress(e)} title="progress" min="0" max={videoMaxDuration} value={progressData} />
                    <div className="extraControls">
                        <button id='btnReplay' className='replay' title='replay' onClick={() => replayVideo()}></button>
                        <select id='btnPlaybackSpeed' className='playbackSpeed' title='select playback speed' onChange={(e) => onChangePlaybackSpeed(e)}>
                            <option value={0.2}>0.2x</option>
                            <option value={0.5}>0.5x</option>
                            <option value={1} selected>1x</option>
                            <option value={1.5}>1.5x</option>
                            <option value={2}>2x</option>
                        </select>
                        <button id='btnStop' className='stop' title='stop' onClick={() => stopVideo()}>Stop</button>
                        <input type="range" id="volume-bar" onChange={(e) => changeVolume(e)} title="volume" min="0" max="1" step="0.1" value={volumeValue} />
                        <button id='btnMute' className='mute' title='mute' onClick={() => muteVolume()}>Mute</button>
                        <button id='btnFullScreen' className='fullscreen' title='toggle full screen' onClick={() => toggleVideoFullScreen()}>
                            {
                                isFullScreenToggleIcon ?
                                    <MdFullscreenExit />
                                    :
                                    <MdFullscreen />
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonsVideoPlayer;